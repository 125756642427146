export default function calculateImageSize(imageWidth, imageHeight, formatWidth, formatHeight) {
  let layerWidth = imageWidth;
  let layerHeight = imageHeight;

  if (imageWidth > formatWidth * 1.5 || imageHeight > formatHeight * 1.5) {
    const imageSizeRatio = imageWidth / imageHeight;
    const widthRatio = imageWidth / formatWidth;
    const heightRatio = imageHeight / formatHeight;
    if (widthRatio > heightRatio) {
      layerWidth = formatWidth * 1.5;
      layerHeight = (1 / imageSizeRatio) * layerWidth;
    } else {
      layerHeight = formatHeight * 1.5;
      layerWidth = imageSizeRatio * layerHeight;
    }
  }

  return { layerWidth: Math.ceil(layerWidth), layerHeight: Math.ceil(layerHeight) };
}
