/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import { takeEvery, select, call, put } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as api from '../../api';
import {
  getTemplates,
  deleteTemplate,
  getGroups,
  deleteGroup,
  moveTemplateIntoGroup,
  moveGroupIntoGroup,
  removeGroup,
  removeTemplate,
  replaceTemplates,
  replaceGroups,
  createTemplate,
  createGroup,
  addTemplate,
  addGroup,
  duplicateTemplate,
  setLoading,
  setGroupId,
  setGroupAncestors
} from '../slices/dashboard';

export const selectSelectedGroupId = (state) => state.dashboard.groupId;

export function* handleGetTemplates() {
  const selectedGroupId = yield select(selectSelectedGroupId);
  const response = yield call(api.templateStore.getTemplates, selectedGroupId);
  yield put(replaceTemplates(response.data));
}

export function* handleGetGroups() {
  const selectedGroupId = yield select(selectSelectedGroupId);
  const response = yield call(api.templateStore.getGroups, selectedGroupId);
  yield put(replaceGroups(response.data));
}
export function* handleGetGroupAncestors() {
  const selectedGroupId = yield select(selectSelectedGroupId);
  if (selectedGroupId !== null) {
    const response = yield call(api.templateStore.getGroupAncestors, selectedGroupId);
    yield put(setGroupAncestors(response.data));
    if (response.data.length === 0) {
      window.location.pathname = '/';
    }
  } else yield put(setGroupAncestors([]));
}

export function* handleCreateTemplate(action) {
  const { data, navigate } = action.payload;
  const selectedGroupId = yield select(selectSelectedGroupId);

  try {
    const response = yield call(api.templateStore.createTemplate, data, selectedGroupId);

    const template = yield response.data;
    if (!('formats' in template)) {
      template.formats = [];
    }

    yield put(addTemplate(template));
    yield navigate(`/editor/template/${template.id}`);

    yield toast.success(
      <span>
        Template <b>{data.title}</b> created.
      </span>,
      {
        id: 'create-new-template'
      }
    );
  } catch (error) {
    yield toast.error('Failed to create template', {
      id: 'create-new-template-failed'
    });
  }
}

export function* handleCreateGroup(action) {
  const name = action.payload;
  const selectedGroupId = yield select(selectSelectedGroupId);

  const data = {
    title: name,
    groupId: selectedGroupId
  };

  try {
    const response = yield call(api.templateStore.createGroup, data);
    const group = yield response.data;
    yield put(addGroup(group));
  } catch (error) {
    yield toast.error('Failed to create group', {
      id: 'create-new-group-failed'
    });
  }
}

export function* handleDeleteTemplate(action) {
  const templateId = action.payload;

  yield toast.loading('Deleting template ...', {
    id: 'delete-template'
  });

  try {
    yield call(api.templateStore.deleteTemplate, templateId);
    yield put(removeTemplate(templateId));

    yield toast.success('Template deleted.', {
      id: 'delete-template'
    });
  } catch (e) {
    yield toast.error('Failed to delete template', {
      id: 'delete-template'
    });
  }
}

export function* handleDuplicateTemplate(action) {
  const template = action.payload;
  yield toast.loading('Duplicate template ...', {
    id: 'duplicate-template'
  });

  try {
    const duplicatedTemplate = yield call(api.templateStore.duplicateTemplate, template.id);

    // TODO: move these calls to monorepo template service

    const hasImages =
      (template.object_data.images && template.object_data.images.length > 0) ||
      template.formats.some(
        (format) =>
          format.object_data && format.object_data.images && format.object_data.images.length > 0
      );

    const hasVideos =
      (template.object_data.videos && template.object_data.videos.length > 0) ||
      template.formats.some(
        (format) =>
          format.object_data && format.object_data.videos && format.object_data.videos.length > 0
      );

    if (hasImages) {
      yield call(
        api.imageService.duplicateImage,
        template.id,
        duplicatedTemplate.data.id,
        template.client_id
      );
    }

    if (hasVideos)
      yield call(
        api.videoService.duplicateVideo,
        template.id,
        duplicatedTemplate.data.id,
        template.client_id
      );

    yield put(addTemplate(duplicatedTemplate.data));

    yield toast.success('Template duplicated.', {
      id: 'duplicate-template'
    });
  } catch (e) {
    yield toast.error('Duplicate template failed.', {
      id: 'duplicate-template'
    });
  }
}

export function* handleDeleteGroup(action) {
  const groupId = action.payload;

  try {
    yield put(removeGroup(groupId));
    yield call(api.templateStore.deleteGroup, groupId);
  } catch (error) {
    yield toast.error('Failed to delete group', {
      id: 'delete-group'
    });
  }
}

export function* handleReloadDashboard() {
  yield put(setLoading(true));
  yield call(handleGetGroups);
  yield call(handleGetGroupAncestors);
  yield call(handleGetTemplates);
  yield put(setLoading(false));
}

export function* handleMoveTemplateIntoGroup(action) {
  const templateId = action.payload.templateId;

  try {
    yield put(removeTemplate(templateId));
    yield call(api.templateStore.moveTemplateIntoGroup, action.payload);
  } catch (error) {
    yield toast.error('Failed to move template', {
      id: 'move-template'
    });
  }
}

export function* handleMoveGroupIntoGroup(action) {
  const groupId = action.payload.groupId;

  try {
    yield put(removeGroup(groupId));
    yield call(api.templateStore.moveGroupIntoGroup, action.payload);
  } catch (error) {
    yield toast.error('Failed to move group', {
      id: 'move-group'
    });
  }
}

function* watchDashboardSaga() {
  yield takeEvery(getTemplates, handleGetTemplates);
  yield takeEvery(createTemplate, handleCreateTemplate);
  yield takeEvery(duplicateTemplate, handleDuplicateTemplate);
  yield takeEvery(deleteTemplate, handleDeleteTemplate);
  yield takeEvery(moveTemplateIntoGroup, handleMoveTemplateIntoGroup);
  yield takeEvery(getGroups, handleGetGroups);
  yield takeEvery(createGroup, handleCreateGroup);
  yield takeEvery(deleteGroup, handleDeleteGroup);
  yield takeEvery(moveGroupIntoGroup, handleMoveGroupIntoGroup);
  yield takeEvery(setGroupId, handleReloadDashboard);
}

export default watchDashboardSaga;
