import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MapColumnData from '../components/DPA/MapColumnData';
import ScreenshotsOverview from '../components/DPA/ScreenshotsOverview';
import Steps from '../components/DPA/Steps';

const InnerBox = styled.div`
  font-family: 'Barlow', sans-serif;
  width: 850px;
  height: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  // border: 1px solid #4a90e2;
`;
function App() {
  const [activeStep, setActiveStep] = useState(0);
  const errorMessage = useSelector((state) => state.dpa.errorMessage);

  const onUpdateActiveStep = (step) => {
    setActiveStep(step);
  };
  function renderStep() {
    switch (activeStep) {
      case 0:
        return <MapColumnData />;
      case 1:
        return <ScreenshotsOverview />;
      default:
        return null;
    }
  }

  return (
    <InnerBox>
      {errorMessage !== '' && (
        <div
          style={{
            height: '40px',
            width: 'auto',
            position: 'absolute',

            bottom: '30px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            borderRadius: '8px'
          }}>
          <span
            style={{
              color: '#DE6E4D',
              marginRight: '15px',
              marginLeft: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '35px',
              fontWeight: '600'
            }}>
            {errorMessage}
          </span>
        </div>
      )}
      <Steps onUpdateActiveStep={onUpdateActiveStep} activeStep={activeStep}>
        {renderStep()}
      </Steps>
    </InnerBox>
  );
}
export default App;
