import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { v4 as uuidv4 } from 'uuid';
import posthog from 'posthog-js';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import {
  ZuuviLogo,
  TextIcon,
  FormatsIcon,
  ImagesIcon,
  CloseIcon,
  RectangleIcon,
  CircleIcon,
  DynamicIcon,
  LogOutIcon,
  GoToStudioOrCreativeManagerIcon,
  ZuuviLogoSantaHat
} from '../../assets/icons';
import { getDefaultLayerObject } from '../../objectsTypes';
import OverviewMenu from '../DCO/OverviewMenu';
import CreateMenu from '../DCO/CreateMenu';
import ConfigureMenu from '../DCO/ConfigureMenu';
import theme from '../../assets/theme';
import Draggable from '../DragNDrop/Draggable';
import MediumIconButton from '../common/MediumIconButton';
import { setLeftMenu } from '../../redux/slices/editorSession';
import { addLayer } from '../../redux/slices/template';
import BreakTheWorld from '../BreakTheWorld';
import AssetsMenu from '../assetsMenu/AssetsMenu';
import DynamicMenu from './DynamicMenu';
import Formats from '../formats/Formats';

function LeftMenu(props) {
  const { leftMenuTabRef, leftNavRef, changeDcoSourceNameRef } = props;
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const capturePosthogData = usePosthogCapture();
  const { keycloak } = useKeycloak();
  const { dco } = useSelector((state) => state);
  const { dcoSelection, activeMenu } = dco;

  const { campaignId, selectedFormatId, selectedFormat, editorType, menus, studio } = useSelector(
    (state) => state.editorSession
  );
  const { selectedFonts, presentLayers } = studio;
  const { leftMenu } = menus;

  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const currentAccount = user.accounts.find((usrAcc) => usrAcc.account.id === currentAccountId);

  const isLeftMenuDisabled = selectedFormat === null;

  const formatsData = {
    title: editorType === 'banner' ? 'Creatives' : 'Formats',
    tooltip: 'Generate banner formats',
    icon: (
      <FormatsIcon
        className={
          isLeftMenuDisabled && leftMenu.activeButton !== 'banner-formats' ? 'pulsingButton' : ''
        }
        fill={
          leftMenu.activeButton === 'banner-formats' ||
          leftMenu.hoverActiveButton === 'banner-formats'
            ? '#4792E2'
            : 'white'
        }
      />
    ),
    alt: 'generate formats',
    posthogData: {
      event: 'open-submenu',
      type: 'format',
      menu: 'left',
      submenu: 'formats'
    },
    id: 'banner-formats',
    hidden: false,
    disabled: false,
    highlighted: !!(isLeftMenuDisabled && leftMenu.activeButton !== 'banner-formats')
  };

  const textLayerData = {
    title: 'Text',
    tooltip: 'Add text layer',
    icon: (
      <TextIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'text' || leftMenu.hoverActiveButton === 'text'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Text',
    object_type: getDefaultLayerObject('text'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'text',
      menu: 'left',
      dragndrop: false
    },
    id: 'text',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const rectangleLayerData = {
    title: 'Rectangle',
    tooltip: 'Add rectangle layer',
    icon: (
      <RectangleIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'rectangle' || leftMenu.hoverActiveButton === 'rectangle'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Rectangle',
    object_type: getDefaultLayerObject('rectangle'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'rectangle',
      menu: 'left',
      dragndrop: false
    },
    id: 'rectangle',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const circleLayerData = {
    title: 'Circle',
    tooltip: 'Add circle layer',
    icon: (
      <CircleIcon
        height='18'
        width='18'
        fill={
          leftMenu.activeButton === 'circle' || leftMenu.hoverActiveButton === 'circle'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'Circle',
    object_type: getDefaultLayerObject('circle'),
    posthogData: {
      event: 'add-element',
      type: 'element',
      element_type: 'circle',
      menu: 'left',
      dragndrop: false
    },
    id: 'circle',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const assetsData = {
    title: 'Assets',
    tooltip: 'Image assets',
    icon: (
      <ImagesIcon
        fill={
          leftMenu.activeButton === 'image-assets' || leftMenu.hoverActiveButton === 'image-assets'
            ? '#4792E2'
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'image assets',
    posthogData: {
      event: 'open-submenu',
      type: 'assets',
      menu: 'left',
      submenu: 'assets'
    },
    id: 'image-assets',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };

  const dynamicData = {
    title: 'Dynamic',
    tooltip: 'Manage dynamic sources',
    icon: (
      <DynamicIcon
        width={18}
        height={18}
        fill={
          leftMenu.activeButton === 'dynamic' || leftMenu.hoverActiveButton === 'dynamic'
            ? theme.colors.dcoPurple
            : isLeftMenuDisabled
            ? 'grey'
            : 'white'
        }
      />
    ),
    alt: 'manage dynamic sources',
    posthogData: {
      event: 'open-submenu',
      type: 'dynamic',
      menu: 'left',
      submenu: 'dynamic'
    },
    id: 'dynamic',
    hidden: false,
    disabled: isLeftMenuDisabled,
    highlighted: false
  };
  const logOutButtonData = {
    title: 'Logout',
    tooltip: 'Logout',
    icon: <LogOutIcon className='icon-hover' />,
    alt: 'logout',
    posthogData: {
      event: 'log-out',
      type: 'redirect',
      menu: 'left'
    },
    id: 'logout'
  };
  const goToStudioButtonData = {
    title: 'Studio',
    tooltip: 'Go to studio',
    icon: <GoToStudioOrCreativeManagerIcon className='icon-hover' />,
    alt: 'studio',
    posthogData: {
      event: 'redirect-to-studio',
      type: 'redirect',
      menu: 'left'
    },
    id: 'goToStudio',
    href: window._env_.REACT_ENV_STUDIO_URL
  };
  const goToCreativeManagerButtonData = {
    title: 'Manager',
    tooltip: 'Go to Creative Manager',
    icon: <GoToStudioOrCreativeManagerIcon className='icon-hover' />,
    alt: 'zuuvi app',
    posthogData: {
      event: 'redirect-to-creative-manager',
      type: 'redirect',
      menu: 'left'
    },
    id: 'goToCreativeManager',
    href: window._env_.REACT_ENV_APP_URL
  };

  let LEFT_BUTTONS_MENU_DATA = [];
  let LEFT_BUTTONS_NAV_DATA = [];

  const hasAccessToNewStudio = currentAccount.role.permissions.includes('studio');

  if (editorType === 'banner') {
    LEFT_BUTTONS_MENU_DATA = [formatsData, assetsData];

    if (
      dcoSelection.dynamicSourceIncluded ||
      dcoSelection.parameterSourceIncluded ||
      dcoSelection.customSourceIncluded
    ) {
      LEFT_BUTTONS_MENU_DATA.push(dynamicData);
    }

    if (hasAccessToNewStudio) {
      LEFT_BUTTONS_NAV_DATA = [goToStudioButtonData, logOutButtonData];
    }
    if (!hasAccessToNewStudio) {
      LEFT_BUTTONS_NAV_DATA = [logOutButtonData];
    }
  } else {
    LEFT_BUTTONS_MENU_DATA = [
      formatsData,
      textLayerData,
      rectangleLayerData,
      circleLayerData,
      assetsData,
      dynamicData
    ];

    LEFT_BUTTONS_NAV_DATA = [goToCreativeManagerButtonData, logOutButtonData];
  }

  const renderDcoMenu = () => {
    if (activeMenu.menu === 'create') {
      return <CreateMenu modalRef={changeDcoSourceNameRef} />;
    }
    if (activeMenu.menu === 'configure') {
      return <ConfigureMenu modalRef={changeDcoSourceNameRef} />;
    }

    return <OverviewMenu modalRef={changeDcoSourceNameRef} />;
  };

  return (
    <div className='left-nav-wrapper' ref={leftMenuTabRef}>
      {leftMenu.dynamicLargeMenu !== '' && renderDcoMenu()}

      <div className='main-nav left-nav' ref={leftNavRef}>
        {isAuthorized({ featureFlag: 'santa-hat-logo' }) ? (
          <ZuuviLogoSantaHat className='zuuvi-logo' />
        ) : (
          <ZuuviLogo className='zuuvi-logo' />
        )}
        {LEFT_BUTTONS_MENU_DATA.map(
          (button) =>
            !button.hidden &&
            (button.id === 'text' || button.id === 'rectangle' || button.id === 'circle' ? (
              <Draggable
                key={button.title}
                dataItem={{ type: 'element', name: button.id }}
                draggedObject={button.object_type}
                dropEffect='copy'>
                <MediumIconButton
                  id={button.id}
                  tooltip={button.tooltip}
                  text={button.title}
                  disabled={button.disabled}
                  icon={button.icon}
                  onHandleMouseEnter={() =>
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        hoverActiveButton: button.id
                      })
                    )
                  }
                  onHandleMouseLeave={() =>
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        hoverActiveButton: ''
                      })
                    )
                  }
                  onHandleClick={() => {
                    capturePosthogData(button.posthogData);
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        leftNavTabActive: '',
                        activeButton: ''
                      })
                    );
                    if (button.id === 'text') {
                      button.object_type.settings.font = selectedFonts.find(
                        (font) => font.name === button.object_type.settings.font.name
                      )
                        ? selectedFonts.find(
                            (font) => font.name === button.object_type.settings.font.name
                          )
                        : {
                            uuid: uuidv4(),
                            name: 'Arial',
                            reference_type: 'web_safe'
                          };
                    }
                    dispatch(addLayer(button.object_type, selectedFormatId));
                  }}
                />
              </Draggable>
            ) : (
              <MediumIconButton
                buttonRef={button.buttonRef}
                id={button.id}
                key={button.id}
                tooltip={button.tooltip}
                text={button.title}
                disabled={button.disabled}
                icon={button.icon}
                greyText={button.greyText}
                onHandleMouseEnter={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: button.id
                    })
                  )
                }
                onHandleMouseLeave={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: ''
                    })
                  )
                }
                onHandleClick={() => {
                  capturePosthogData(button.posthogData);
                  if (leftMenu.leftNavTabActive === button.id) {
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        activeButton: '',
                        leftNavTabActive: ''
                      })
                    );
                  } else {
                    dispatch(
                      setLeftMenu({
                        ...leftMenu,
                        activeButton: button.id,
                        leftNavTabActive: button.id
                      })
                    );
                  }
                }}
              />
            ))
        )}
        <BreakTheWorld />

        <div className='left-nav-bottom-buttons'>
          {LEFT_BUTTONS_NAV_DATA.map((button, i) =>
            button.id === 'logout' ? (
              <MediumIconButton
                id={button.id}
                key={button.id}
                tooltip={button.tooltip}
                text={button.title}
                disabled={button.disabled}
                icon={button.icon}
                onHandleMouseEnter={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: button.id
                    })
                  )
                }
                onHandleMouseLeave={() =>
                  dispatch(
                    setLeftMenu({
                      ...leftMenu,
                      hoverActiveButton: ''
                    })
                  )
                }
                onHandleClick={() => {
                  capturePosthogData(button.posthogData);
                  if (button.id === 'logout') {
                    keycloak.logout();
                    posthog.reset();
                  }
                }}
              />
            ) : (
              <a
                className='icon-link'
                href={button.href}
                target='_blank'
                rel='noreferrer'
                key={button.posthogData.event + i}>
                <MediumIconButton
                  id={button.id}
                  onHandleClick={() => {
                    capturePosthogData(button.posthogData);
                  }}
                  tooltip={button.tooltip}
                  text={button.title}
                  disabled={button.disabled}
                  icon={button.icon}
                />
              </a>
            )
          )}
        </div>
      </div>

      {leftMenu.leftNavTabActive !== '' && (
        <div className='left-tab-open'>
          <div className='close-cross-icon-wraper'>
            <button
              type='button'
              onClick={() =>
                dispatch(
                  setLeftMenu({
                    ...leftMenu,
                    activeButton: '',
                    leftNavTabActive: ''
                  })
                )
              }
              id='close-left-submenu'>
              <CloseIcon className='margin-right-pointer icon-hover' />
            </button>
          </div>
          <div className='scroll-container'>
            {leftMenu.leftNavTabActive === 'image-assets' && (
              <AssetsMenu currentLayers={presentLayers} campaignId={campaignId} />
            )}
            {leftMenu.leftNavTabActive === 'dynamic' && <DynamicMenu />}
            {leftMenu.leftNavTabActive === 'banner-formats' && <Formats />}
          </div>
        </div>
      )}
    </div>
  );
}

export default LeftMenu;
