import { connect } from 'react-redux';
import RightMenuClicktag from '../components/RightMenu/RightMenuClicktag';
import { setTemplateClicktag } from '../redux/slices/template';

const mapStateToProps = (state) => ({
  templateClicktag: state.template.object_data.settings.clicktag
    ? state.template.object_data.settings.clicktag
    : ''
});

const mapActionsToProps = {
  setTemplateClicktag
};

export default connect(mapStateToProps, mapActionsToProps)(RightMenuClicktag);
