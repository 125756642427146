/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReactEditor, useSlate } from 'slate-react';
import styled from 'styled-components';
import { Transforms, Editor } from 'slate';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import hasSpecificDcoTagInText from '../../../utils/hasSpecificDcoTagInText';
import getDcoButtonColor from '../../../utils/getDcoButtonColor';
import removeDcoTagFromTextByKey from '../../../utils/removeDcoTagFromTextByKey';

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 15px;

  background-color: ${(props) => props.theme.colors.backgroundColorSecondary};
  border-radius: 1px;
  color: white;
  padding: 0px 10px 10px 10px;
  border: 1px solid #3c3c3c;
  outline: none;

  position: absolute;
  top: 41px;
  left: ${({ isEditorMenu, isPositionExtended }) =>
    isEditorMenu
      ? isPositionExtended
        ? '-180px'
        : '-20px'
      : isPositionExtended
      ? '-2px'
      : '185px'};
  min-width: 210px;
  overflow-y: auto;
`;

const DcoButton = styled.button`
  background-color: ${(props) => {
    const color = getDcoButtonColor(props.objectList, props.longTitle);
    return color !== '' ? color : props.theme.colors.lightMediumGray;
  }};
  border-radius: 3px;
  font-size: 11px;
  width: 48%;
  color: #fff;
  margin: 3px 2px;
  padding: 5px 10px;
  width: 'auto';
  overflow: hidden;
  text-overflow: ellipsis;
`;

function DcoSelector(props) {
  const { isPositionExtended, setIsDcoSelectionOpen, value, isEditorMenu } = props;
  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const editor = useSlate();
  const capturePosthogData = usePosthogCapture();

  function mergeSelectedMarks() {
    Transforms.unwrapNodes(editor, {
      match: (node) => {
        if (node.hasOwnProperty('text')) {
          for (const [key, value] of Object.entries(node)) {
            if (key !== 'text') Editor.addMark(editor, key, value);
          }
        }
      }
    });
  }

  const wrapperRef = useRef(null);
  useEffect(() => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const maxHeight = window.innerHeight - rect.top;
      wrapperRef.current.style.maxHeight = `${maxHeight}px`;
    }
  }, []);

  const dcoColorArray = [
    { color: '#F5CC5C', name: 'Zuuvi Lemon' },
    { color: '#6ADAAE', name: 'Zuuvi Mint' },
    { color: '#DE6E4D', name: 'Zuuvi Ruby ' },
    { color: '#4A90E2', name: 'Zuuvi Blue' },
    { color: '#FAE6AD', name: 'Zuuvi 50% Lemon' },
    { color: '#BBE3D2', name: 'Zuuvi 50% Mint' },
    { color: '#EFB6A6', name: 'Zuuvi 50% Ruby' },
    { color: '#A8C6E5', name: 'Zuuvi 50% Blue' }
  ];

  function numberOfDcoTagsInLayer(fragments, number) {
    let count = number;
    for (const fragment of fragments) {
      if (fragment.dynamic_key && fragment.dynamic_uuid) {
        count += 1;
      }

      if (fragment.children) {
        count = numberOfDcoTagsInLayer(fragment.children, count);
      }
    }

    return count;
  }

  function getDcoColor(dcoName) {
    const dcoCount = numberOfDcoTagsInLayer(value, 0);
    const previouslyAssignedDcoColor = getDcoButtonColor(value, dcoName);

    const dcoColor =
      previouslyAssignedDcoColor !== ''
        ? previouslyAssignedDcoColor
        : dcoColorArray[dcoCount % dcoColorArray.length].color;

    return dcoColor;
  }

  const handleDcoSelection = (dcoName, dcoId) => {
    let eventType = '';

    if (hasSpecificDcoTagInText(editor.getFragment(), dcoName)) {
      const newObjectList = removeDcoTagFromTextByKey(editor.getFragment(), dcoName);
      Editor.insertFragment(editor, newObjectList);
      eventType = 'dco_removed';
    } else {
      mergeSelectedMarks();
      Editor.addMark(editor, 'dynamic_key', dcoName);
      Editor.addMark(editor, 'dynamic_uuid', dcoId);
      Editor.addMark(editor, 'dynamic_color', getDcoColor(dcoName));

      eventType = 'dco_inserted';
    }

    capturePosthogData({
      event: 'add-dco-text-inline',
      type: eventType
    });

    ReactEditor.focus(editor);
    setIsDcoSelectionOpen(false);
  };

  const isGoogleStudioDCO = dcoSelection.dynamicSource?.addon === 'google-studio';

  const dynamicSourceKeys = isGoogleStudioDCO
    ? dcoSelection.dynamicSource?.keys?.keys
    : dcoSelection.dynamicSource?.keys;

  return (
    <Wrapper isPositionExtended={isPositionExtended} isEditorMenu={isEditorMenu} ref={wrapperRef}>
      <p style={{ fontSize: '13px', width: '100%' }}>{`Available ${
        dcoSelection.dynamicSource?.export === 'dpa' ? 'Catalog Ads' : 'DCO'
      } values`}</p>
      {dcoSelection.dynamicSourceIncluded && !isGoogleStudioDCO && (
        <div style={{ width: '100%', marginBottom: '5px' }}>
          <p
            style={{
              width: '100%',
              color: 'rgba(255,255,255,0.8)',
              margin: '2px',
              fontSize: '11px'
            }}>
            {dcoSelection.dynamicSource.name}
          </p>
          {dynamicSourceKeys.map((parameter) => (
            <DcoButton
              data-cy='dco-tag'
              key={parameter}
              longTitle={parameter}
              title={parameter}
              objectList={editor.getFragment()}
              onClick={(e) => {
                handleDcoSelection(parameter, dcoSelection.dynamicSource.id);
              }}>
              {parameter}
            </DcoButton>
          ))}
        </div>
      )}

      {dcoSelection.dynamicSourceIncluded && isGoogleStudioDCO && (
        <div style={{ width: '100%', marginBottom: '5px' }}>
          <p
            style={{
              width: '100%',
              color: 'white',
              margin: '2px',
              fontSize: '11px',
              // textDecoration: 'underline',
              fontWeight: '500',
              marginBottom: '5px'
            }}>
            {dcoSelection.dynamicSource.name}
          </p>
          {Object.entries(
            dynamicSourceKeys.reduce((acc, item) => {
              const [key, value] = item.split(/\[\d\]\./i);
              acc[key] = acc[key]
                ? [...acc[key], { short: value, full: item }]
                : [{ short: value, full: item }];
              return acc;
            }, {})
          ).map(([key, value]) => (
            <>
              <p
                style={{
                  width: '100%',
                  color: 'rgba(255,255,255,0.8)',
                  margin: '2px',
                  fontSize: '11px'
                }}>
                {key}
              </p>
              {value.map(({ short, full }) => (
                <DcoButton
                  data-cy='dco-tag'
                  key={short}
                  longTitle={full}
                  title={short}
                  objectList={editor.getFragment()}
                  onClick={(e) => {
                    handleDcoSelection(full, dcoSelection.dynamicSource.id);
                  }}>
                  {short}
                </DcoButton>
              ))}
            </>
          ))}
        </div>
      )}

      {dcoSelection.parameterSourceIncluded && (
        <div style={{ width: '100%' }}>
          <p
            style={{
              width: '100%',
              color: 'rgba(255,255,255,0.8)',
              margin: '2px',
              fontSize: '11px'
            }}>
            {dcoSelection.parameterSource.name}
          </p>
          {dcoSelection.parameterSource.keys.map((parameter) => (
            <DcoButton
              data-cy='dco-tag'
              key={parameter.parameter}
              longTitle={parameter.parameter}
              title={parameter.parameter}
              objectList={editor.getFragment()}
              onClick={(e) => {
                handleDcoSelection(parameter.parameter, dcoSelection.parameterSource.id);
              }}>
              {parameter.parameter}
            </DcoButton>
          ))}
        </div>
      )}

      {dcoSelection.customSourceIncluded && (
        <div style={{ width: '100%' }}>
          <p
            style={{
              width: '100%',
              color: 'rgba(255,255,255,0.8',
              margin: '2px',
              fontSize: '11px'
            }}>
            {dcoSelection.customSource.name}
          </p>
          {dcoSelection.customSource.json.keys.map((custom) => (
            <DcoButton
              data-cy='dco-tag'
              key={custom.parameter}
              longTitle={custom.parameter}
              title={custom.parameter}
              objectList={editor.getFragment()}
              onClick={(e) => {
                handleDcoSelection(custom.parameter, dcoSelection.customSource.id);
              }}>
              {custom.parameter}
            </DcoButton>
          ))}
        </div>
      )}
    </Wrapper>
  );
}

export default DcoSelector;
