import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledMediumText, StyledSmallText } from './styled/StyledQuickExports';
import BaseButton from '../common/BaseButton';
import BannerTimelineSelector from '../common/BannerTimelineSelector';
import {
  updateQuickExportSeekTime,
  resetQuickExportSeekTime,
  getFormatHtml
} from '../../redux/slices/quickExport';

const StyledContentWrapper = styled.div`
  ${(props) =>
    props.simple && 'display: flex; justify-content: space-between; align-items: center;'};
`;
const StyledTextWrapper = styled.div`
  ${(props) => props.simple && `  width: 330px`};
`;
const StyledButtonWrapper = styled.div`
  ${(props) => props.alignRight && `  display: flex;justify-content: end`};
`;

const SelectedQuickExportType = (props) => {
  const { id, title, subtitle, buttonText, onButtonClick, buttonId, simple } = props;
  const dispatch = useDispatch();
  const { seekTime, formatHtml } = useSelector((state) => state.quickExport);
  const selectedFormat = useSelector((state) => state.editorSession.selectedFormat);
  const templateId = useSelector((state) => state.template.id);
  const { uuid, width, height } = selectedFormat.object_data;

  useEffect(() => {
    if (id === 'jpgExport' || id === 'pngExport') {
      dispatch(getFormatHtml({ templateId, formatId: uuid }));
      dispatch(resetQuickExportSeekTime());
    }
  }, [templateId, uuid]);

  function onHandleTimelineChange(value) {
    dispatch(updateQuickExportSeekTime(`${parseFloat(value).toFixed(2)}`));
  }

  return (
    <StyledContentWrapper simple={simple} id={id}>
      <StyledTextWrapper simple={simple}>
        <StyledMediumText>{title}</StyledMediumText>
        <StyledSmallText>{subtitle}</StyledSmallText>
      </StyledTextWrapper>
      {(id === 'jpgExport' || id === 'pngExport') && (
        <BannerTimelineSelector
          barWidth='300px'
          exportTimeline
          stopButtonHidden
          environment='studio'
          adHtml={formatHtml}
          seekTime={seekTime}
          adWidth={width}
          adHeight={height}
          onHandleTimelineChange={(value) => onHandleTimelineChange(value)}
        />
      )}
      <StyledButtonWrapper alignRight={id === 'jpgExport' || id === 'pngExport'}>
        <BaseButton
          id={buttonId}
          text={buttonText}
          onClick={onButtonClick}
          style={{
            backgroundColor: '#4792E2',
            width: 135,
            height: 35,
            borderRadius: ' 4px',
            color: 'white'
          }}
        />
      </StyledButtonWrapper>
    </StyledContentWrapper>
  );
};

SelectedQuickExportType.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  buttonId: PropTypes.string.isRequired,
  simple: PropTypes.bool.isRequired
};

export default SelectedQuickExportType;
