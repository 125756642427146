import React, { useState } from 'react';
import './AssetsMenu.scss';
import useUploadAssets from '../../../utils/hooks/useUploadAssets';
import Tabs from '../Tabs/Tabs';
import AssetsList from './AssetsList';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

function AssetsMenu(props) {
  const { currentLayers } = props;

  const uploadAssets = useUploadAssets();
  const capturePosthogData = usePosthogCapture();

  const [activeAssetsTab, setActiveAssetsTab] = useState('images');

  function changeHandler(event) {
    event.preventDefault();
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    uploadAssets(event.target.files);
    event.target.value = ''; // Reset the input field.
  }

  function onDropHandler(event) {
    event.preventDefault();
    event.stopPropagation();

    capturePosthogData({
      event: 'upload',
      type: 'asset',
      dragndrop: true,
      drop_on_canvas: false
    });

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      uploadAssets(event.dataTransfer.files);
    }
  }

  function onDragOverHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';
  }

  const ASSETS_TABS = [
    {
      label: 'Image',
      id: 'images'
    },
    {
      label: 'Vectors',
      id: 'vectors'
    },
    {
      label: 'Video',
      id: 'video'
    }
  ];

  return (
    <>
      <div className='padding-container'>
        <button
          id='assets'
          className='add-button no-padding'
          style={{ backgroundColor: '#4792E2' }}
          type='button'>
          <label
            className='flex-display align-center'
            id='upload-asset'
            htmlFor='upload-asset-input'
            onDrop={(e) => onDropHandler(e)}
            onDragOver={onDragOverHandler}>
            <p>Upload new media</p>
            <input
              id='upload-asset-input'
              type='file'
              name='file'
              accept='image/png,image/jpeg,image/svg+xml,video/mp4,video/webm,video/ogg'
              multiple
              onChange={(e) => {
                capturePosthogData({
                  event: 'upload',
                  type: 'asset',
                  dragndrop: false
                });

                changeHandler(e);
              }}
            />
          </label>
        </button>
      </div>
      <Tabs
        containerStyle={{ borderTop: '1px solid #353535' }}
        itemStyle={{ borderTop: 'none' }}
        items={ASSETS_TABS}
        activeId={activeAssetsTab}
        onClick={(item) => {
          setActiveAssetsTab(item.id);
        }}
      />
      <div id='assets'>
        <AssetsList activeTab={activeAssetsTab} currentLayers={currentLayers} />
      </div>
    </>
  );
}

export default AssetsMenu;
