/* eslint-disable import/order */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputNumber from '../common/InputNumber';
import './RightMenuAnimationForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateAnimation } from '../../redux/slices/template';
import { setActiveAnimation } from '../../redux/slices/editorSession';

const StyledExclaiment = styled.div`
  font-size: 11px;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 100%;
  text-align: center;
`;
const BottomMarginContainer = styled.div`
  margin-bottom: 10px;
`;
function AnimationForm(props) {
  const { animation, index } = props;

  const dispatch = useDispatch();

  const { selectedFormatId, studio } = useSelector((state) => state.editorSession);
  const { activeAnimation } = studio;

  const animationTypes = [
    {
      name: 'Play video',
      id: 'playVideo'
    },
    {
      name: 'Scale',
      id: 'scale',
      attribute: 'scale',
      transformOrigin: true,
      unit: '%',
      default: 100,
      min: 0,
      max: 1000,
      effect: false
    },
    {
      name: 'Opacity',
      id: 'opacity',
      attribute: 'opacity',
      transformOrigin: false,
      unit: '%',
      default: 0,
      min: 0,
      max: 100,
      effect: false
    },
    {
      name: 'Move X',
      id: 'moveX',
      attribute: 'left',
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Move Y',
      id: 'moveY',
      attribute: 'top',
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Move',
      id: 'moveXY',
      attribute: ['left', 'top'],
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Resize Width',
      id: 'resizeX',
      attribute: 'width',
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: 0,
      max: null,
      effect: false
    },
    {
      name: 'Resize Height',
      id: 'resizeY',
      attribute: 'height',
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: 0,
      max: null,
      effect: false
    },
    {
      name: 'Resize',
      id: 'resize',
      attribute: ['width', 'height'],
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: 0,
      max: null,
      effect: false
    },
    {
      name: 'Resize XY',
      id: 'resizeXY',
      attribute: ['width', 'height'],
      transformOrigin: true,
      unit: 'px',
      default: 0,
      min: 0,
      max: null,
      effect: false
    },
    {
      name: 'Rotate',
      id: 'rotate',
      attribute: 'rotation',
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: -36000,
      max: 36000,
      effect: false
    },
    {
      name: 'Skew X',
      id: 'skewX',
      attribute: 'skewX',
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Skew Y',
      id: 'skewY',
      attribute: 'skewY',
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Skew',
      id: 'skew',
      attribute: ['skewX', 'skewY'],
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: null,
      max: null,
      effect: false
    },
    {
      name: 'Flip X',
      id: 'flipX',
      attribute: 'rotationX',
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: -36000,
      max: 36000,
      effect: false
    },
    {
      name: 'Flip Y',
      id: 'flipY',
      attribute: 'rotationY',
      transformOrigin: true,
      unit: 'deg',
      default: 0,
      min: -36000,
      max: 36000,
      effect: false
    },
    {
      name: 'Corner radius',
      id: 'radius',
      attribute: 'borderRadius',
      transformOrigin: false,
      unit: 'px',
      default: 0,
      min: 0,
      max: 1000,
      effect: false
    },
    {
      name: 'Background',
      id: 'background',
      attribute: 'background',
      transformOrigin: false,
      unit: 'rgba',
      default: '',
      effect: false
    },
    {
      name: 'Fade Top',
      id: 'fadeTop',
      attribute: '{opacity: 0,marginTop: -40',
      transformOrigin: false,
      unit: null,
      default: null,
      effect: true
    },
    {
      name: 'Fade Bottom',
      id: 'fadeBottom',
      attribute: '{opacity: 0,marginTop: 40',
      transformOrigin: false,
      unit: null,
      default: null,
      effect: true
    },
    {
      name: 'Pulse',
      id: 'pulse',
      attribute: '{scale:110}',
      transformOrigin: false,
      unit: null,
      default: null,
      effect: true
    },
    {
      name: 'Visibility',
      id: 'makeVisible',
      attribute: '{ visibility: hidden}',
      transformOrigin: false,
      unit: null,
      default: null,
      effect: true
    }
  ];
  const animationEasings = [
    { name: 'Linear', value: 'none' },
    { name: 'Power 1', value: 'power1' },
    { name: 'Power 2', value: 'power2' },
    { name: 'Power 3', value: 'power3' },
    { name: 'Power 4', value: 'power4' },
    { name: 'Elastic', value: 'elastic' },
    { name: 'Bounce', value: 'bounce' },
    { name: 'Steps', value: 'steps(12)' },
    { name: 'Circle', value: 'circ' },
    { name: 'Exponential', value: 'expo' },
    { name: 'Back', value: 'back' },
    { name: 'Sine', value: 'sine' }
  ];
  const easingTypes = [
    { title: 'In', value: 'in' },
    { title: 'In out', value: 'inOut' },
    { title: 'Out', value: 'out' }
  ];

  const onUpdateAnimation = (value, index, target, uuid) => {
    dispatch(updateAnimation(selectedFormatId, target, value));
  };

  const animationDirection = ['End with', 'Start with'];
  const animationObject = animationTypes.find(
    (animationType) => animationType.id === animation.type
  );
  const [shiftPressed, onShiftPressed] = useState(false);

  // match everything inside round brackets
  const regexp = /\(.*?\)/g;

  function getDefaultAnimationEasingTypeValue(easeType) {
    if (!easeType) {
      return 'inOut';
    }
    // return simple text without extra setting
    return easeType.replace(regexp, '');
  }
  function getUpdatedAnimationEasingType(easing, easeType) {
    // if easing doesn have extra setting, remove what is there from before
    if (easing !== 'elastic' || easing !== 'back') {
      easeType = easeType.replace(regexp, '');
    }
    // add extra settings
    if (easing === 'elastic') {
      easeType = `${easeType}(1,0.3)`;
    }
    // add extra settings
    if (easing === 'back') {
      easeType = `${easeType}(1.7)`;
    }

    return easeType;
  }
  function getAnimationDirection(directionType, animationType) {
    if (animationType === 'makeVisible') {
      if (directionType === 'End with') {
        return 'Hidden';
      }
      if (directionType === 'Start with') {
        return 'Shown';
      }
    }
    return directionType;
  }

  const getTransformOrigins = (id) => {
    const transformOrigins = [
      { title: 'Center center', value: 'center center' },
      { title: 'Left center', value: 'left center' },
      { title: 'Right center', value: 'right center' },
      { title: 'Left top', value: 'left top' },
      { title: 'Center top', value: 'center top' },
      { title: 'Right top', value: 'right top' },
      { title: 'Left bottom', value: 'left bottom' },
      { title: 'Center bottom', value: 'center bottom' },
      { title: 'Right bottom ', value: 'right bottom' }
    ];

    const flipxTransformOrigins = [
      { title: 'Top', value: 'center top' },
      { title: 'Center', value: 'center center' },
      { title: 'Bottom', value: 'center bottom' }
    ];

    const flipyTransformOrigins = [
      { title: 'Left', value: 'left center' },
      { title: 'Center', value: 'center center' },
      { title: 'Right', value: 'right bottom' }
    ];

    switch (id) {
      case 'flipX':
        return flipxTransformOrigins;

      case 'flipY':
        return flipyTransformOrigins;

      default:
        return transformOrigins;
    }
  };
  const resizeWidthOrigin = [
    { title: 'Left', value: 'left' },
    { title: 'Center', value: 'center' },
    { title: 'Right', value: 'right' }
  ];
  const resizeHeightOrigin = [
    { title: 'Top', value: 'top' },
    { title: 'Center', value: 'center' },
    { title: 'Bottom', value: 'bottom' }
  ];

  return (
    <div
      className='animation-form-container'
      style={{
        ...(animation.uuid === activeAnimation.uuid && {
          backgroundColor: '#446ba5'
        })
      }}
      id={`animation-form-${animation.uuid}`}
      onClick={(e) => {
        dispatch(
          setActiveAnimation({
            uuid: animation.uuid,
            target: animation.target,
            index
          })
        );
      }}
      key={animation.uuid}
      onKeyDown={(e) => {
        if (e.key === 'Shift') {
          onShiftPressed(true);
        }
      }}
      onKeyUp={(e) => {
        if (e.key === 'Shift') {
          onShiftPressed(false);
        }
      }}>
      {animationObject?.id === 'background' && (
        <StyledExclaiment>Only compatible with solid background color</StyledExclaiment>
      )}
      <div className='flex-display wrap width-full'>
        <>
          <BottomMarginContainer>
            <p className='input-text attributes-top-label'>Start</p>
            <InputNumber
              style={{ width: 89, height: 30, padding: '0px 3px 0px 8px' }}
              id='startTime'
              className={`attributes-input attributes-input-big-animation ${
                activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
              }`}
              min={0}
              step={0.1}
              defaultValue={0.0}
              withDecimal
              value={animation.time}
              onUpdate={(value) => {
                onUpdateAnimation(
                  {
                    ...animation,
                    time: value
                  },
                  index,
                  animation.target,
                  animation.uuid
                );
              }}
            />
          </BottomMarginContainer>
          <BottomMarginContainer>
            <p className='input-text attributes-top-label'>Duration</p>
            <InputNumber
              style={{ width: 89, height: 30, padding: '0px 3px 0px 8px' }}
              id='duration'
              className={`attributes-input attributes-input-big-animation ${
                activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
              }`}
              min={0.1}
              max={animation.maxDuration}
              step={0.1}
              defaultValue={0.0}
              withDecimal
              value={animation.duration}
              onUpdate={(value) => {
                onUpdateAnimation(
                  {
                    ...animation,
                    duration: value
                  },
                  index,
                  animation.target,
                  animation.uuid
                );
              }}
            />
          </BottomMarginContainer>
        </>
        {animationObject?.id !== 'playVideo' &&
          (!animationObject.effect || animationObject.id === 'makeVisible') && (
            <>
              <BottomMarginContainer
                className={
                  ['moveXY', 'resize', 'resizeXY', 'skew'].includes(animationObject?.id)
                    ? 'margin-bottom'
                    : ''
                }
                style={{
                  ...(['moveXY', 'resize', 'resizeXY', ' skew'].includes(animationObject?.id) && {
                    width: '100%'
                  })
                }}>
                <p className='input-text attributes-top-label'>
                  {animation.type !== 'makeVisible' ? 'Direction' : 'Action'}
                </p>
                <select
                  style={{
                    textAlign: 'left',
                    ...(['moveXY', 'resize', 'resizeXY', 'skew'].includes(animationObject?.id) && {
                      width: '100%'
                    })
                  }}
                  defaultValue={
                    animation.direction && animation.direction === 'to' ? 'End with' : 'Start with'
                  }
                  className={`attributes-input attributes-input-big input-text ${
                    activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                  }`}
                  onChange={(e) => {
                    onUpdateAnimation(
                      {
                        ...animation,
                        direction: e.target.value === 'End with' ? 'to' : 'from'
                      },
                      index,
                      animation.target,
                      animation.uuid
                    );
                  }}>
                  {animationDirection.map((type, i) => (
                    <option key={type} value={type}>
                      {animation.type === 'makeVisible'
                        ? getAnimationDirection(type, animation.type)
                        : type}
                    </option>
                  ))}
                </select>
              </BottomMarginContainer>

              {!['background', 'moveXY', 'resize', 'resizeXY', 'skew', 'makeVisible'].includes(
                animationObject?.id
              ) && (
                <div>
                  <p className='input-text attributes-top-label'>
                    {animationObject?.unit ? `Value  (${animationObject?.unit})` : 'Value'}
                  </p>

                  <input
                    type='number'
                    min={animationObject?.min}
                    max={animationObject?.max}
                    id='animationValue'
                    name='animationValue'
                    onBlur={(e) =>
                      onUpdateAnimation(
                        {
                          ...animation,
                          settings: {
                            ...animation.settings,
                            [`${animationObject?.attribute}`]: e.target.value
                          }
                        },
                        index,
                        animation.target,
                        animation.uuid
                      )
                    }
                    className={`attributes-input attributes-input-big-animation ${
                      activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                    }`}
                    onChange={(e) => {
                      function applyStep(previousValue, newValue) {
                        const bigStep = 10;
                        const smallStep = 1;
                        const parsedPreviousValue = parseInt(previousValue);
                        let parsedNewValue = parseInt(newValue === '' ? 0 : newValue);

                        if (parsedPreviousValue < parsedNewValue) {
                          parsedNewValue = shiftPressed
                            ? parsedNewValue + bigStep - smallStep
                            : parsedNewValue;
                        } else {
                          parsedNewValue = shiftPressed
                            ? parsedNewValue - bigStep + smallStep
                            : parsedNewValue;
                        }

                        // make sure we dont exceed min and max values:
                        parsedNewValue =
                          animationObject?.min && parsedNewValue < animationObject?.min
                            ? animationObject?.min
                            : parsedNewValue;
                        parsedNewValue =
                          animationObject?.max && parsedNewValue > animationObject?.max
                            ? animationObject?.max
                            : parsedNewValue;

                        return parsedNewValue;
                      }
                      onUpdateAnimation(
                        {
                          ...animation,
                          settings: {
                            ...animation.settings,
                            [`${animationObject?.attribute}`]: applyStep(
                              animation.settings
                                ? animation.settings[`${animationObject?.attribute}`]
                                : animationObject?.default,
                              e.target.value
                            )
                          }
                        },
                        index,
                        animation.target,
                        animation.uuid
                      );
                    }}
                    value={
                      animation.settings
                        ? animation.settings[`${animationObject?.attribute}`]
                        : animationObject?.default
                    }
                  />
                </div>
              )}
              {animation.type === 'background' ? (
                <BottomMarginContainer>
                  <p className='input-text attributes-top-label'>
                    {animationObject?.unit ? `Value  (${animationObject?.unit})` : 'Value'}
                  </p>
                  <input
                    type='text'
                    id='animation-settings-value'
                    name='animationSettingsValue'
                    className={`attributes-input attributes-input-big-animation ${
                      activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                    }`}
                    onChange={(e) =>
                      onUpdateAnimation(
                        {
                          ...animation,
                          settings: {
                            ...animation.settings,
                            [`${animationObject?.attribute}`]: e.target.value
                          }
                        },
                        index,
                        animation.target,
                        animation.uuid
                      )
                    }
                    value={
                      animation.settings
                        ? animation.settings[`${animationObject?.attribute}`]
                        : animationObject?.default
                    }
                  />
                </BottomMarginContainer>
              ) : (
                ['moveXY', 'resize', 'resizeXY', 'skew'].includes(animation.type) &&
                animationObject?.attribute.map((attribute, i) => (
                  <BottomMarginContainer key={attribute}>
                    <p className='input-text color-white attributes-top-label'>
                      {animation.type === 'resize' || animation.type === 'resizeXY'
                        ? animationObject?.unit &&
                          `${attribute === 'width' ? 'Width' : 'Height'} (${animationObject?.unit})`
                        : animationObject?.unit
                        ? `${['left', 'width', 'skewX'].includes(attribute) ? 'X' : 'Y'} Value  (${
                            animationObject?.unit
                          })`
                        : 'Value'}
                    </p>{' '}
                    <input
                      type='number'
                      id={`animation-value-${animation.type}-${attribute}`}
                      name='animationSettingsValue'
                      className={`attributes-input attributes-input-big-animation${
                        activeAnimation.uuid === animation.uuid ? ' blue-background' : ''
                      }`}
                      onChange={(e) => {
                        function applyStep(previousValue, newValue) {
                          const bigStep = 10;
                          const smallStep = 1;
                          const parsedPreviousValue = parseInt(previousValue);
                          let parsedNewValue = parseInt(newValue === '' ? 0 : newValue);

                          if (parsedPreviousValue < parsedNewValue) {
                            parsedNewValue = shiftPressed
                              ? parsedNewValue + bigStep - smallStep
                              : parsedNewValue;
                          } else {
                            parsedNewValue = shiftPressed
                              ? parsedNewValue - bigStep + smallStep
                              : parsedNewValue;
                          }

                          // make sure we dont exceed min and max values:
                          parsedNewValue =
                            animationObject?.min && parsedNewValue < animationObject?.min
                              ? animationObject?.min
                              : parsedNewValue;
                          parsedNewValue =
                            animationObject?.max && parsedNewValue > animationObject?.max
                              ? animationObject?.max
                              : parsedNewValue;

                          return parsedNewValue;
                        }

                        const value = applyStep(
                          animation.settings
                            ? animation.settings[`${attribute}`]
                            : animationObject?.default,
                          e.target.value
                        );
                        if (animation.type !== 'resizeXY') {
                          onUpdateAnimation(
                            {
                              ...animation,
                              settings: {
                                ...animation.settings,
                                [`${attribute}`]: value
                              }
                            },
                            index,
                            animation.target,
                            animation.uuid
                          );
                        }

                        if (animation.type === 'resizeXY' && attribute === 'width') {
                          onUpdateAnimation(
                            {
                              ...animation,
                              settings: {
                                ...animation.settings,
                                [`${attribute}`]: value
                              },
                              transformOriginWidth: animation.transformOriginWidth || 'right'
                            },
                            index,
                            animation.target,
                            animation.uuid
                          );
                        }
                        if (animation.type === 'resizeXY' && attribute === 'height') {
                          // we need this to add the transformOriginWidth and the top value
                          onUpdateAnimation(
                            {
                              ...animation,
                              settings: {
                                ...animation.settings,
                                [`${attribute}`]: value
                              },
                              transformOriginHeight: animation.transformOriginHeight || 'bottom'
                            },
                            index,
                            animation.target,
                            animation.uuid
                          );
                        }
                      }}
                      value={
                        animation.settings
                          ? animation.settings[`${attribute}`]
                          : animationObject?.default
                      }
                    />
                  </BottomMarginContainer>
                ))
              )}
            </>
          )}
        {animationObject?.transformOrigin && animation.type !== 'resizeXY' && (
          <div className='flex-display wrap width-full'>
            <select
              style={{
                textAlign: 'left'
              }}
              defaultValue={animation.settings?.transformOrigin}
              className={`select-wide-input attributes-input ${
                activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
              }`}
              onChange={(e) =>
                onUpdateAnimation(
                  {
                    ...animation,
                    settings: {
                      ...animation.settings,
                      transformOrigin: e.target.value
                    }
                  },
                  index,
                  animation.target,
                  animation.uuid
                )
              }>
              {getTransformOrigins(animationObject.id).map((type) => (
                <option key={type.value} value={type.value}>
                  {type.title}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* if the animation is resize add the directions for each attribute */}
        {animationObject?.transformOrigin && animation.type === 'resizeXY' && (
          <>
            <div style={{ width: 100 }}>
              <select
                style={{
                  textAlign: 'left'
                }}
                defaultValue={animation.transformOriginWidth || 'right'}
                className={`select-wide-input attributes-input ${
                  activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                }`}
                onChange={(e) => {
                  onUpdateAnimation(
                    {
                      ...animation,
                      transformOriginWidth: e.target.value
                    },
                    index,
                    animation.target,
                    animation.uuid
                  );
                }}>
                {resizeWidthOrigin.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: 100 }}>
              <select
                style={{
                  textAlign: 'left'
                }}
                defaultValue={animation.transformOriginHeight || 'bottom'}
                className={`select-wide-input attributes-input ${
                  activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                }`}
                onChange={(e) => {
                  onUpdateAnimation(
                    {
                      ...animation,
                      transformOriginHeight: e.target.value
                    },
                    index,
                    animation.target,
                    animation.uuid
                  );
                }}>
                {resizeHeightOrigin.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.title}
                  </option>
                ))}
              </select>
            </div>
          </>
        )}
      </div>

      {animationObject?.id !== 'playVideo' && (
        <div className='flex-display width-full wrap margin-bottom'>
          {animationObject?.id !== 'pulse' && animationObject?.id !== 'makeVisible' && (
            <>
              <div>
                <p className='input-text attributes-top-label'>Easing</p>
                <select
                  className={`attributes-input attributes-input-big${
                    activeAnimation.uuid === animation.uuid ? ' blue-background' : ''
                  }`}
                  style={{ textAlign: 'left' }}
                  value={animation.ease ? animation.ease : 'none'}
                  onChange={(e) => {
                    onUpdateAnimation(
                      {
                        ...animation,
                        ease: e.target.value,
                        easeType: getUpdatedAnimationEasingType(e.target.value, animation.easeType)
                      },
                      index,
                      animation.target,
                      animation.uuid
                    );
                  }}>
                  {animationEasings.map((easing) => (
                    <option key={easing.value} value={easing.value}>
                      {easing.name}
                    </option>
                  ))}
                </select>
              </div>
              {animation.ease !== 'none' && !animation.ease.includes('steps') && (
                <div>
                  <p className='input-text attributes-top-label'>Easing Type</p>
                  <select
                    style={{ textAlign: 'left' }}
                    className={`attributes-input attributes-input-big ${
                      activeAnimation.uuid === animation.uuid ? 'blue-background' : ''
                    }`}
                    value={getDefaultAnimationEasingTypeValue(animation.easeType)}
                    onChange={(e) => {
                      const newEaseType = e.target.value === 'in out' ? 'inOut' : e.target.value;
                      onUpdateAnimation(
                        {
                          ...animation,
                          easeType: getUpdatedAnimationEasingType(animation.ease, newEaseType)
                        },
                        index,
                        animation.target,
                        animation.uuid
                      );
                    }}>
                    {easingTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </>
          )}
          <div className='flex-center-top-margin'>
            <input
              id='right-submenu-animation-on-repeat'
              className='attributes-input margin-right-pointer'
              type='checkbox'
              checked={animation.only_on_repeat}
              onChange={() => {
                onUpdateAnimation(
                  {
                    ...animation,
                    only_on_repeat: !animation.only_on_repeat
                  },
                  index,
                  animation.target,
                  animation.uuid
                );
              }}
            />{' '}
            <p className='input-text'>Only on repeat</p>
          </div>
        </div>
      )}
    </div>
  );
}

AnimationForm.propTypes = {
  animation: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};
export default AnimationForm;
