import React, { useState, useEffect } from 'react';

const SaveTimeView = (props) => {
  const { lastTemplateSave, isTemplateSaving, autosaveHasError } = props;
  const [secondsSinceLastSave, setSecondsSinceLastSave] = useState(null);
  let viewString = '';

  useEffect(() => {
    if (lastTemplateSave) {
      const updateSecondsSinceLastSave = () => {
        const now = new Date();
        const newTimeDiff = Math.floor((now.getTime() - lastTemplateSave.getTime()) / 1000);
        setSecondsSinceLastSave(newTimeDiff);
      };
      updateSecondsSinceLastSave();
      const interval = setInterval(() => {
        updateSecondsSinceLastSave();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [lastTemplateSave]);

  function secondsToString(seconds, fallbackDate) {
    if (seconds < 5) {
      return 'Just now';
    }
    if (seconds < 60) {
      return 'Less than a minute';
    }
    if (seconds >= 60 && seconds < 60 * 60) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minutes ago`;
    }
    if (seconds >= 60 * 60 && seconds < 60 * 60 * 24) {
      const hours = Math.floor(seconds / (60 * 60));
      return `${hours} hours ago`;
    }
    return fallbackDate.toLocaleDateString();
  }

  if (isTemplateSaving) {
    viewString = 'Saving ...';
  } else if (autosaveHasError) {
    viewString = 'Error saving template';
  } else if (secondsSinceLastSave === null) {
    viewString = 'Loading template ...';
  } else {
    viewString = `Last saved: ${secondsToString(secondsSinceLastSave, lastTemplateSave)}`;
  }

  return (
    <div className='text-medium' style={{ width: 200, paddingLeft: 20 }}>
      {viewString}
    </div>
  );
};

export default SaveTimeView;
