import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: [],
  templates: [],
  ancestors: [],
  isLoading: true,
  groupId: null
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // templates
    getTemplates() {},
    createTemplate() {},
    deleteTemplate() {},
    duplicateTemplate() {},
    addTemplate(state, action) {
      state.templates.push(action.payload);
    },
    removeTemplate(state, action) {
      const index = state.templates.findIndex((template) => template.id === action.payload);
      state.templates.splice(index, 1);
    },
    replaceTemplates(state, action) {
      state.templates = action.payload;
    },
    clearTemplates(state) {
      state.templates = [];
    },
    moveTemplateIntoGroup() {},

    // groups
    getGroups() {},

    getGroupAncestors() {},
    setGroupAncestors(state, action) {
      state.ancestors = action.payload;
    },
    createGroup() {},
    deleteGroup() {},
    addGroup(state, action) {
      state.groups.push(action.payload);
    },
    removeGroup(state, action) {
      const index = state.groups.findIndex((group) => group.id === action.payload);
      state.groups.splice(index, 1);
    },
    replaceGroups(state, action) {
      state.groups = action.payload;
    },
    clearGroups(state, action) {
      state.groups = [];
    },
    moveGroupIntoGroup() {},
    clearAncestors(state) {
      state.ancestors = [];
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setGroupId(state, action) {
      state.groupId = action.payload;
    }
  }
});

export const {
  getTemplates,
  createTemplate,
  duplicateTemplate,
  deleteTemplate,
  addTemplate,
  removeTemplate,
  replaceTemplates,
  clearTemplates,
  moveTemplateIntoGroup,
  getGroups,
  createGroup,
  deleteGroup,
  addGroup,
  removeGroup,
  replaceGroups,
  clearGroups,
  moveGroupIntoGroup,
  addAncestor,
  removeDescendants,
  clearAncestors,
  setLoading,
  setGroupId,
  getGroupAncestors,
  setGroupAncestors
} = dashboard.actions;
export default dashboard.reducer;
