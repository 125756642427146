import React from 'react';
import PropTypes from 'prop-types';
import './MediumIconButton.scss';

function MediumIconButton(props) {
  const {
    disabled,
    greyText,
    hidden,
    text,
    tooltip,
    icon,
    onHandleClick,
    id,
    buttonStyle,
    onHandleMouseEnter,
    onHandleMouseLeave,
    buttonRef
  } = props;

  const handleClick = (e) => {
    onHandleClick(e);
  };
  return (
    <button
      type='button'
      ref={buttonRef}
      style={{
        ...buttonStyle,
        cursor: disabled ? 'default' : 'pointer',
        display: hidden ? 'none' : 'block'
      }}
      id={`medium-icon-button-${id}`}
      disabled={disabled}
      className='right-tab-button'
      title={tooltip}
      onMouseEnter={onHandleMouseEnter}
      onMouseLeave={onHandleMouseLeave}
      onClick={(e) => handleClick(e)}>
      {icon}
      <p
        className='text-medium'
        style={{ color: disabled || greyText ? 'grey' : 'white', lineHeight: '16px' }}>
        {text}
      </p>
    </button>
  );
}

MediumIconButton.defaultProps = {
  disabled: false,
  greyText: false,
  tooltip: '',
  hidden: false,
  buttonRef: undefined,
  onHandleMouseEnter: null,
  onHandleMouseLeave: null
};
MediumIconButton.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onHandleClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  greyText: PropTypes.bool,
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onHandleMouseEnter: PropTypes.func,
  onHandleMouseLeave: PropTypes.func,
  buttonStyle: PropTypes.object
};

export default MediumIconButton;
