import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10000000000;
`;

const ModalOverlayPortal = (props) => {
  const { children, textAlign } = props;

  return <Portal style={{ textAlign }}>{children}</Portal>;
};

ModalOverlayPortal.defaultProps = {
  textAlign: 'left'
};

ModalOverlayPortal.propTypes = { textAlign: PropTypes.string, children: PropTypes.node.isRequired };

export default ModalOverlayPortal;

export const Portal = ({ children, style }) =>
  typeof document === 'object'
    ? ReactDOM.createPortal(
        <Overlay id='modal-overlay' style={style}>
          {children}
        </Overlay>,
        document.body
      )
    : null;
