import React from 'react';
import './DCOParameterTable.scss';
import { useTable, usePagination } from 'react-table';

import { CloseIcon } from '../../assets/icons';

export default function DCOParameterTable(props) {
  const { data, updateParameterData, removeParameterRow, isError = false } = props;
  // Create an editable cell renderer
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateParameterData // This is a custom function that we supplied to our table instance
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateParameterData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'URL parameter',
        accessor: 'parameter'
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Example',
        accessor: 'example'
      },
      {
        Header: () => null,
        id: 'delete',
        accessor: (str) => 'delete',
        Cell: (tableProps) => (
          <center>
            <span
              style={{
                cursor: 'pointer'
              }}
              onClick={() => removeParameterRow(tableProps.row.index)}>
              <CloseIcon stroke='#CF695E' />
            </span>
          </center>
        )
      }
    ],
    [data]
  );

  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateParameterData,
      isError
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <div
      id='dco-table'
      className='parameter-table'
      style={isError ? { border: '1px solid #ff4545' } : {}}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()} key={i}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps()} key={i}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
