import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlayIcon, PauseIcon, StopIcon } from '../../assets/icons';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { pauseAnimations, playAnimations, stopAnimations } from '../../redux/slices/editorSession';

function AnimationControl({}) {
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();

  const displayedTimelineTime = useSelector(
    (state) => state.editorSession.studio.displayedTimelineTime
  );

  const handlePlay = () => {
    capturePosthogData({ event: 'play animation', type: 'animation', menu: 'top' });
    dispatch(playAnimations());
  };

  const handlePause = () => {
    capturePosthogData({ event: 'pause-animation', type: 'animation', menu: 'top' });
    dispatch(pauseAnimations());
  };

  const handleStop = () => {
    capturePosthogData({ event: 'stop-animation', type: 'animation', menu: 'top' });
    dispatch(stopAnimations());
  };

  return (
    <>
      <button onClick={handlePlay} type='button'>
        <PlayIcon className='animation-button' />
      </button>
      <button onClick={handlePause} type='button'>
        <PauseIcon className='animation-button' />
      </button>
      <button onClick={handleStop} type='button'>
        <StopIcon className='animation-button' />
      </button>

      <div className='timeline-progress-container'>
        <p className='timeline-progress-container-text'>
          {displayedTimelineTime < 10
            ? `0${displayedTimelineTime.toFixed(3)}`
            : displayedTimelineTime.toFixed(3)}
        </p>
      </div>
    </>
  );
}

AnimationControl.propTypes = {};

export default AnimationControl;
