/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/browser';
import keycloak from '../../keycloak';
import { get, post, getWithoutHeaders } from './basicCalls';
import store from '../redux/store';

const TEMPLATE_STORE_URL = window._env_.REACT_ENV_TEMPLATE_STORE_URL;

// TODO: templateStoreRequest should be replaced by basicCalls
async function templateStoreRequest(path, method, data, clientId = null) {
  const storeState = store.getState();
  clientId = clientId || storeState.auth.currentAccountId;

  const response = await keycloak
    .updateToken(-1)
    .then(async () => {
      method = method || 'GET';
      const init = {
        method,
        headers: {
          'zuuvi-account-id': clientId,
          Authorization: `Bearer ${keycloak.token}`
        }
      };
      if (data) {
        init.body = JSON.stringify(data);
        init.headers['Content-Type'] = 'application/json';
      }
      const responseValue = await fetch(TEMPLATE_STORE_URL + path, init);
      return responseValue;
    })
    .catch((error) => {
      Sentry.captureMessage('tokenError');
      // alert('Your session expired');
      // if it fails go to login
      keycloak.logout();
    });
  return response;
}

// Template
async function getTemplate(templateId) {
  return get(`${TEMPLATE_STORE_URL}templates/${templateId}`);
}
async function getPreview(templateId) {
  return getWithoutHeaders(`${TEMPLATE_STORE_URL}preview/${templateId}`);
}
async function getTemplatePublicData(templateId) {
  return getWithoutHeaders(`${TEMPLATE_STORE_URL}templates/${templateId}/public-data`);
}
async function getTemplates(groupId) {
  // if we are not inside of group get all templates without groupId
  if (groupId === null) {
    return get(`${TEMPLATE_STORE_URL}templates/with-formats`);
  }
  // if we are inside of group get all templates without group assigned
  return get(`${TEMPLATE_STORE_URL}templates/with-formats/${groupId}`);
}
async function createTemplate(data, selectedGroupId = null) {
  return post(
    selectedGroupId
      ? `${TEMPLATE_STORE_URL}templates/create/${selectedGroupId}`
      : `${TEMPLATE_STORE_URL}templates/create`,
    data
  );
}
async function deleteTemplate(templateId) {
  return post(`${TEMPLATE_STORE_URL}templates/${templateId}/delete`);
}
async function duplicateTemplate(templateId) {
  return post(`${TEMPLATE_STORE_URL}templates/${templateId}/duplicate`);
}
async function moveTemplateIntoGroup(data) {
  return post(`${TEMPLATE_STORE_URL}groups/move-template`, data);
}

// Groups
async function getGroups(groupId) {
  // when we are not inside of group get groups without parent
  if (groupId === null) {
    return get(`${TEMPLATE_STORE_URL}groups`);
  }
  // when we are inside of group get nested groups
  return get(`${TEMPLATE_STORE_URL}groups/${groupId}`);
}

async function getGroupAncestors(groupId) {
  // when we are inside of group get parent groups
  if (groupId !== null) {
    return get(`${TEMPLATE_STORE_URL}groups/${groupId}/ancestors`);
  }
  return [];
}

async function createGroup(data) {
  return post(`${TEMPLATE_STORE_URL}groups/create`, data);
}
async function deleteGroup(groupId) {
  return post(`${TEMPLATE_STORE_URL}groups/${groupId}/delete`);
}
async function moveGroupIntoGroup(data) {
  return post(`${TEMPLATE_STORE_URL}groups/move-group`, data);
}

// Template Tags
async function getTemplateTags(accountId) {
  return get(`${TEMPLATE_STORE_URL}template-tag`, accountId);
}

export {
  templateStoreRequest,
  getTemplates,
  getTemplate,
  getGroups,
  getGroupAncestors,
  moveTemplateIntoGroup,
  moveGroupIntoGroup,
  deleteTemplate,
  deleteGroup,
  createTemplate,
  createGroup,
  getPreview,
  getTemplatePublicData,
  duplicateTemplate,
  getTemplateTags
};
