import React, { useEffect } from 'react';
import SentryWrapper from './containers/SentryWrapperContainer';
import StudioEditor from './containers/StudioEditorContainer';
import NewVersionChecker from './components/NewVersionChecker';
import StatusMessage from './components/StatusMessage';

function Editor(props) {
  const { editorType } = props;

  return (
    <>
      <StatusMessage />
      <NewVersionChecker />
      <SentryWrapper>
        <StudioEditor actualEditorType={editorType} />
      </SentryWrapper>
    </>
  );
}

export default Editor;
