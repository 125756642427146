/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import { takeEvery, select, call, takeLatest, put, fork } from 'redux-saga/effects';
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  processImage
} from 'pintura';
import imageCompression from 'browser-image-compression';

import toast from 'react-hot-toast';
import {
  loadImages,
  addImage,
  setOverwriteImageBase64Data,
  duplicateFormatImageBase64Data,
  duplicateLayerImageBase64Data,
  uploadImages,
  deleteImage,
  removeImage
} from '../slices/images';
import {
  addLayer,
  updateLayerImageState,
  updateLayerImageScale,
  updateLayerImage,
  addFormat,
  pasteLayer
} from '../slices/template';
import { selectFormat } from '../slices/editorSession';
import * as api from '../../api';
import { handleLoadBase64, addAssetLayerToCanvas } from './assetsHelper';
// import { handleLoadBase64, addAssetLayerToCanvas } from './assetsHelper';
// selectors need to be exported for testing
export const selectTemplate = (state) => state.template;
export const selectImages = (state) => state.images;
export const selectCampaignId = (state) => state.editorSession.campaignId;
export const selectFormatId = (state) => state.editorSession.selectedFormatId;
export const selectEditorType = (state) => state.editorSession.editorType;
export const selectPublishedTemplateId = (state) => state.template.publishedTemplateId;

function getTemplateImages(templateState) {
  const allImagesInTemplate = [];
  for (const format of templateState.formats) {
    if (format.object_data.images) {
      for (const formatImage of format.object_data.images) {
        if (!allImagesInTemplate.some((i) => i.uuid === formatImage.uuid)) {
          allImagesInTemplate.push(formatImage);
        }
      }
    }
  }
  return allImagesInTemplate;
}

export function* handleGetImages() {
  const templateState = yield select(selectTemplate);
  const campaignId = yield select(selectCampaignId);
  const publishedTemplateId = yield select(selectPublishedTemplateId);

  if (publishedTemplateId && publishedTemplateId !== null && publishedTemplateId !== undefined) {
    try {
      const { data } = yield call(
        api.imageService.getPublicImages,
        publishedTemplateId,
        campaignId
      );

      return data;
    } catch (e) {
      // TODO handle error
      console.error(e);
    }
  } else {
    try {
      const { data } = yield call(
        api.imageService.getImages,
        templateState.object_data.uuid,
        campaignId,
        templateState.client_id
      );
      return data;
    } catch (e) {
      // TODO handle error
      console.error(e);
    }
  }

  return null;
}

export function* handleLoadThumbnailAndAddImage(image) {
  // Adding some param to avoid Chrome caching CORS issue
  const base64 = yield call(handleLoadBase64, `${image.thumbnail_url}?chrome_cors=1`);
  if (base64) {
    try {
      image = JSON.parse(JSON.stringify(image));
      if (!image.thumbnail_base64) image.thumbnail_base64 = yield base64;
      yield put(addImage(image));
    } catch (e) {
      console.log(e);
    }
  }
}

export function* processImageData(image, imageState) {
  try {
    if ('targetSize' in imageState && imageState.targetSize !== undefined) {
      if ('scale' in imageState.targetSize && imageState.targetSize.scale !== undefined) {
        imageState = {
          ...imageState,
          targetSize: {
            ...imageState.targetSize,
            width: imageState.targetSize.width * imageState.targetSize.scale,
            height: imageState.targetSize.height * imageState.targetSize.scale
          }
        };
      }
    }

    const response = yield call(processImage, image, {
      imageReader: createDefaultImageReader(),
      imageWriter: createDefaultImageWriter({ canvasMemoryLimit: 5000 * 5000, quality: 0.9 }),
      shapePreprocessor: createDefaultShapePreprocessor(),
      ...imageState
    });

    // Compress the image
    const compressedImage = yield call(imageCompression, response.dest, { maxSizeMB: 4 });

    const imageSizeBytes = compressedImage.size;
    const url = URL.createObjectURL(compressedImage);
    const base64 = yield call(handleLoadBase64, url);
    URL.revokeObjectURL(url);
    return { base64, imageSizeBytes };
  } catch (e) {
    console.log(e);
    return null;
  }
}

export function* overwriteBase64Data(imageId, layerId, formatId, url, imageState) {
  const { base64, imageSizeBytes } = yield call(processImageData, url, imageState);
  yield put(setOverwriteImageBase64Data({ imageId, layerId, formatId, base64, imageSizeBytes }));
}

export function* overwriteBase64DataHelper(addedImage = null) {
  const selectedFormatId = yield select(selectFormatId);
  const templateState = yield select(selectTemplate);
  const images = getTemplateImages(templateState);
  if (!selectedFormatId || !templateState || !images) return;

  const selectedFormat = templateState.formats.find((format) => format.id === selectedFormatId);

  if (selectedFormat) {
    for (const [formatLayerId, formatLayer] of Object.entries(selectedFormat.object_data.layers)) {
      if ('settings' in formatLayer && 'source' in formatLayer.settings) {
        const imageId = formatLayer.settings.source;
        const image = images.find((image) => image.uuid === imageId);

        if (!image) continue;

        // Will only handle the addedImage if any, otherwise handle all images.
        const handleImage = !addedImage || image.uuid === addedImage;

        if (handleImage) {
          yield fork(
            overwriteBase64Data,
            imageId,
            formatLayerId,
            selectedFormatId,
            image.source,
            formatLayer.settings.imageState ? formatLayer.settings.imageState : {}
          );
        }
      }
    }
  }
}

export function* handleUpdateOverwrittenImageHelper(
  layers,
  formatId,
  layerId,
  imageState,
  imagesState
) {
  for (const [layerUuid, layer] of Object.entries(layers)) {
    if (layerUuid === layerId) {
      if ('settings' in layer && 'source' in layer.settings) {
        const imageId = layer.settings.source;
        const image = imagesState[imageId];

        if ('url' in image) {
          yield fork(overwriteBase64Data, imageId, layerId, formatId, image.url, imageState);
        }
      }
    }
    if ('layers' in layer) {
      yield fork(
        handleUpdateOverwrittenImageHelper,
        layer.layers,
        formatId,
        layerId,
        imageState,
        imagesState
      );
    }
  }
}

export function* handleUpdateOverwrittenImage(action) {
  const { formatId, layerId, imageState } = action.payload;

  const templateState = yield select(selectTemplate);
  const imagesState = yield select(selectImages);
  const format = templateState.formats.find((f) => f.id === formatId);

  if ('object_data' in templateState && 'layers' in templateState.object_data) {
    yield fork(
      handleUpdateOverwrittenImageHelper,
      format.object_data.layers,
      formatId,
      layerId,
      imageState,
      imagesState
    );
  }
}

export function* handleLoadUsedImages() {
  yield fork(overwriteBase64DataHelper);
}

export function* handleLoadImages() {
  const images = yield call(handleGetImages);
  if (images) {
    for (const image of images) {
      yield fork(handleLoadThumbnailAndAddImage, image);
    }
  }
}

export function* addImageLayer(action) {
  const imageToAdd = action.payload.layer.settings.source;

  if (action.payload.layer.type === 'image') {
    yield fork(overwriteBase64DataHelper, imageToAdd);
  }
}

export function* updateImageLayer(action) {
  const { layerId, newImage, formatTargetId } = action.payload;

  const imagesState = yield select(selectImages);
  const templateState = yield select(selectTemplate);

  const image = imagesState[newImage.uuid];
  if (!image || image.content_type === 'image/svg+xml') return;

  const format = templateState.formats.find((format) => format.id === formatTargetId);
  if (!format) return;

  for (const formatLayerId in format.object_data.layers) {
    if (formatLayerId === layerId) {
      const layer = format.object_data.layers[formatLayerId];

      if ('settings' in layer && 'url' in image) {
        yield fork(
          overwriteBase64Data,
          image.uuid,
          layerId,
          formatTargetId,
          image.url,
          layer.settings.imageState ? layer.settings.imageState : {}
        );
      }
    }
  }
}

export function* handleUploadImages(action) {
  const campaignId = yield select(selectCampaignId);
  const { formData, event, selectedFormat, scale } = action.payload;

  const toastId = yield toast.loading('Uploading image...', { id: 'uploading-image' });

  for (const formDataPair of formData.entries()) {
    if (formDataPair[0] === 'files') {
      const fileSize = (formDataPair[1].size / (1024 * 1024)).toFixed(2);
      if (fileSize > 4.0) {
        yield toast.error('Maximum file size is 4MB. Please minimize your image and try again.', {
          id: toastId
        });
        return;
      }
    }
  }

  try {
    const { data } = yield call(api.imageService.uploadImages, formData, campaignId);

    if (data.length === 0) {
      yield toast.error('Could not upload image, please try again.', {
        id: toastId
      });
      return;
    }

    for (const image of data) {
      yield call(handleLoadThumbnailAndAddImage, image);

      if (event && selectedFormat && scale) {
        yield call(addAssetLayerToCanvas, image, event, selectedFormat, scale, 'image');
      }
    }

    yield toast.success('Image uploaded successfully.', {
      id: toastId
    });
  } catch (error) {
    yield toast.error('Could not upload image, please try again.', {
      id: toastId
    });
  }
}

export function* handleDuplicatedFormatImages(action) {
  const { format, originalFormatId } = action.payload;
  if (format && originalFormatId) {
    yield put(duplicateFormatImageBase64Data({ format, originalFormatId }));
  } else {
    yield call(handleLoadImages);
  }
}

export function* handleDuplicatedLayerImages(action) {
  const { copied, uuid } = action.payload;
  if (copied && uuid) yield put(duplicateLayerImageBase64Data({ copied, uuid }));
}

export function* handleDeleteImage(action) {
  const imageId = action.payload;
  const templateState = yield select(selectTemplate);
  const editorType = yield select(selectEditorType);
  const isImageInUse = getTemplateImages(templateState).some((image) => image.uuid === imageId);

  if (isImageInUse) {
    yield toast.error("Image can't be deleted, when in use.", {
      id: 'delete-image-warning'
    });
  } else {
    try {
      let response;
      if (editorType === 'template') {
        response = yield call(api.imageService.deleteImageFromTemplate, imageId, templateState.id);
      } else {
        const campaignId = yield select(selectCampaignId);
        response = yield call(api.imageService.deleteImageFromCampaign, imageId, campaignId);
      }

      if (response.status >= 200 && response.status < 300) {
        yield put(removeImage(imageId));
        yield toast.success('Image deleted successfully.', {
          id: 'delete-image'
        });
      } else if (response.data.error_code && response.data.error_code === 1) {
        yield toast.error('Image is a shared image. It can not be deleted.', {
          id: 'delete-image-error'
        });
      } else if (response.data.error_code && response.data.error_code === 2) {
        yield toast.error('Image not found on this template.', {
          id: 'delete-image-error'
        });
      } else {
        yield toast.error('Could not deleted image, please try again.', {
          id: 'delete-image-error'
        });
      }
    } catch (e) {
      // TODO handle error
      yield toast.error('Could not deleted image, please try again.', {
        id: 'delete-image-error'
      });
      console.error(e);
    }
  }
}

function* watchImages() {
  yield takeLatest(loadImages, handleLoadImages);
  yield takeEvery(addLayer, addImageLayer);
  yield takeEvery(addFormat, handleDuplicatedFormatImages);
  yield takeEvery(pasteLayer, handleDuplicatedLayerImages);
  yield takeEvery(updateLayerImageState, handleUpdateOverwrittenImage);
  yield takeLatest(updateLayerImageScale, handleUpdateOverwrittenImage);
  yield takeEvery(updateLayerImage, updateImageLayer);
  yield takeEvery(uploadImages, handleUploadImages);
  yield takeEvery(selectFormat, handleLoadUsedImages);
  yield takeEvery(deleteImage, handleDeleteImage);
}

export default watchImages;
