import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.scss';
import './StudioEditor.scss';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling, { createVerticalStrength } from 'react-dnd-scrolling';
import { useParams } from 'react-router-dom';
import { setGroupId } from '../redux/slices/dashboard';
import LeftMenu from '../components/dashboard/leftMenu/LeftMenu';
import LoadSpinner from '../components/LoadSpinner';
import Breadcrumb from '../components/dashboard/Breadcrumb';
import GroupList from '../components/dashboard/GroupList';
import TemplateList from '../components/dashboard/TemplateList';
import CustomDragLayer from '../components/DragNDrop/CustomDragLayer';

const Header = styled.div`
  min-height: 85px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0px 40px;

  &:not(:last-child) {
    border-bottom: ${(props) => `1px solid ${props.theme.colors.borderColor}`};
  }
`;

function Dashboard() {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.dashboard.isLoading);
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const selectedAccountUser = user?.accounts.find(
    (userAccount) => userAccount.account.id === currentAccountId
  );

  const ScrollingComponent = withScrolling('div');
  const vStrength = createVerticalStrength(300);
  const { groupId } = useParams();

  useEffect(() => {
    if (groupId === undefined) {
      dispatch(setGroupId(null));
    } else dispatch(setGroupId(groupId));
  }, [groupId, currentAccountId]);

  return (
    <div id='studio-dashboard'>
      <div id='main-container'>
        <LeftMenu />
        <div id='content-wrapper'>
          <div id='templates-wrapper'>
            <Header>
              <h1>{`Templates - ${selectedAccountUser.account.accountName}`}</h1>
            </Header>
            <DndProvider backend={HTML5Backend}>
              <Breadcrumb />
              {!isLoading ? (
                <ScrollingComponent
                  verticalStrength={vStrength}
                  style={{
                    height: '100%',
                    overflow: 'auto'
                  }}>
                  <CustomDragLayer />

                  <GroupList />
                  <TemplateList />
                </ScrollingComponent>
              ) : (
                <LoadSpinner />
              )}
            </DndProvider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
