import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateTitle } from '../../redux/slices/template';

function TemplateTitle() {
  const dispatch = useDispatch();
  const { editorType, selectedFormat } = useSelector((state) => state.editorSession);
  const template = useSelector((state) => state.template);

  const [isFormatNameEditable, setIsFormatNameEditable] = useState(false);
  const [previousTitle, setPreviousTitle] = useState(template?.title ?? '');

  if (!selectedFormat) {
    return null;
  }

  const handleChange = (event) => {
    dispatch(setTemplateTitle(event.target.value));
  };

  return (
    <>
      {isFormatNameEditable ? (
        <input
          className='layer-name-input template-title'
          style={{ background: '#4792E2', fontSize: '13px', marginTop: '3px', width: '150px' }}
          type='text'
          value={template.title}
          onBlur={(e) => {
            if (e.target.value === '') {
              dispatch(setTemplateTitle(previousTitle));
              toast.error('Template must have a name');
            }
            setIsFormatNameEditable(false);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (e.target.value === '') {
                dispatch(setTemplateTitle(previousTitle));
                toast.error('Template must have a name');
              }
              setIsFormatNameEditable(false);
            }
          }}
          onChange={handleChange}
          autoFocus
        />
      ) : (
        <p
          onDoubleClick={() => {
            if (editorType !== 'banner') {
              setPreviousTitle(template.title);
              setIsFormatNameEditable(true);
            }
          }}
          className='text-medium template-title'>
          {editorType === 'banner' ? template.name : template.title} (
          {selectedFormat?.object_data?.width ?? 0}x{selectedFormat?.object_data?.height ?? 0})
        </p>
      )}
    </>
  );
}

TemplateTitle.propTypes = {};

export default TemplateTitle;
