import { takeEvery, call, put, select } from 'redux-saga/effects';
// eslint-disable-next-line import/no-cycle
import * as api from '../../api';
import { getTemplateHasAutomation, setTemplateHasAutomation } from '../slices/automation';
import { selectTemplate } from '../slices/template';

export function* handleHasAutomation() {
  const templateState = yield select(selectTemplate);

  try {
    const { data } = yield call(
      api.automationService.templateHasAutomationByTemplateId,
      templateState.id
    );

    if (data === undefined) {
      yield put(setTemplateHasAutomation(false));
    }

    yield put(setTemplateHasAutomation(data));
  } catch (e) {
    // TODO handle error
    console.error(e);
  }
}

function* watchAutomation() {
  yield takeEvery(getTemplateHasAutomation, handleHasAutomation);
}

export default watchAutomation;
