import React, { useState } from 'react';
import './AssetsMenu.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Checkboard } from 'react-color/lib/components/common';
import { addLayer, updateLayerVideo, applyVideoToAllFormats } from '../../redux/slices/template';
import { deleteVideo } from '../../redux/slices/videos';
import Draggable from '../DragNDrop/Draggable';
import { getDefaultLayerObject } from '../../objectsTypes';
import { getVideoLayer } from './AssetsHelper';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import OptionButton from '../common/OptionButton';
import { flat } from '../../../helpers';

function Video(props) {
  const { currentLayers, video, scale } = props;
  const isAuthorized = useAuthorization();

  const selectedFormat = useSelector((state) => state.editorSession.selectedFormat);
  const editorType = useSelector((state) => state.editorSession.editorType);
  const activeLayer = useSelector((state) => state.editorSession.activeLayer);
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const deleteAssetsFeatureEnabled = isAuthorized({ featureFlag: 'delete-assets' });

  // check if there are any layers using the image
  const layersWithVideo = flat(currentLayers).filter(
    (layer) => layer.settings?.source?.uuid === video.uuid
  );

  const videoStyle = {
    backgroundImage: `url(${video.thumbnail_base64})`,
    outline: layersWithVideo.length > 0 ? '1px solid #4792E2' : 'none'
  };

  function clickHandler() {
    const currentActiveLayer = activeLayer
      ? currentLayers.find((layer) => layer.uuid === activeLayer)
      : null;
    if (activeLayer && currentActiveLayer?.type === 'video') {
      capturePosthogData({
        event: 'update-source',
        type: 'asset',
        asset_type: 'video'
      });

      dispatch(updateLayerVideo(activeLayer, video, selectedFormat.id));
      if (editorType !== 'banner') {
        dispatch(applyVideoToAllFormats(activeLayer, selectedFormat.id));
      }
    } else if (editorType === 'template') {
      let layerWidth = video.width;
      let layerHeight = video.height;

      // Make sure the inserted video layer is not huge compared to the format.
      if (
        video.width > selectedFormat.object_data.width * 1.5 ||
        video.height > selectedFormat.object_data.height * 1.5
      ) {
        const videoSizeRatio = video.width / video.height;
        const widthRatio = video.width / selectedFormat.object_data.width;
        const heightRatio = video.height / selectedFormat.object_data.height;
        if (widthRatio > heightRatio) {
          layerWidth = selectedFormat.object_data.width * 1.5;
          layerHeight = (1 / videoSizeRatio) * layerWidth;
        } else {
          layerHeight = selectedFormat.object_data.height * 1.5;
          layerWidth = videoSizeRatio * layerHeight;
        }
      }
      capturePosthogData({
        event: 'add-asset',
        type: 'asset',
        asset_type: 'video',
        dragndrop: false
      });

      const finalVideo = getDefaultLayerObject('video');
      finalVideo.format.width = layerWidth;
      finalVideo.format.height = layerHeight;
      finalVideo.settings.source = video;
      finalVideo.animations = [
        {
          type: 'playVideo',
          time: 0,
          duration: parseFloat((Math.ceil((video.duration + 0.1) * 100) / 100).toFixed(2)),
          maxDuration: parseFloat((Math.ceil((video.duration + 0.1) * 100) / 100).toFixed(2))
        }
      ];

      dispatch(addLayer(finalVideo, selectedFormat.id));
    }
  }

  const options = [
    {
      text: 'Delete video',
      func: () => {
        capturePosthogData({
          event: 'delete-asset',
          type: 'asset',
          assetType: 'video'
        });
        dispatch(deleteVideo(video.uuid));
      },
      warning: true
    }
  ];

  return (
    <div
      className='image-assets-image-container'
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <Checkboard />
      {deleteAssetsFeatureEnabled && hover && (
        <OptionButton options={options} backgroundColor='#2a2a2a' />
      )}
      <Draggable
        key='abc123'
        dataItem={{ type: 'asset', name: 'video' }}
        scale={scale}
        draggedObject={getVideoLayer(video, selectedFormat)}
        dropEffect='copy'
        disabled={editorType === 'banner'}>
        <div className='bg-image' style={videoStyle} title={video.title} onClick={clickHandler} />
      </Draggable>
    </div>
  );
}

export default Video;
