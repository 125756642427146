import { useState, useEffect } from 'react';

export const useIntersection = (element, wrapper, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      { root: wrapper.current, rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => element.current && observer.unobserve(element.current);
  }, []);

  return isVisible;
};
