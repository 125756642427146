import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  templateHasAutomation: false
};

const automation = createSlice({
  name: 'automation',
  initialState,
  reducers: {
    getTemplateHasAutomation() {},
    setTemplateHasAutomation(state, action) {
      state.templateHasAutomation = action.payload;
    }
  }
});

export const { getTemplateHasAutomation, setTemplateHasAutomation } = automation.actions;

export const selectAutomation = (state) => state.automation;
export const selectTemplateHasAutomation = (state) => state.automation.templateHasAutomation;
export default automation.reducer;
