import React from 'react';

const ToggleButton = ({ offSymbol, onSymbol, initialtogleOffState, onToggleChange }) => (
  <div
    onClick={() => onToggleChange(!initialtogleOffState)}
    className={`wrg-toggle ${initialtogleOffState ? 'wrg-toggle--checked' : ''}`}>
    <div
      className='wrg-toggle-container'
      style={{ backgroundColor: initialtogleOffState ? ' #3E3E3E' : '#4792E2' }}>
      <div className='wrg-toggle-check'>
        <span>{offSymbol}</span>
      </div>
      <div className='wrg-toggle-uncheck'>
        <span>{onSymbol}</span>
      </div>
    </div>
    <div
      className='wrg-toggle-circle'
      style={{ backgroundColor: initialtogleOffState ? ' #2A2A2A' : '#084599' }}
    />
    <input className='wrg-toggle-input' type='checkbox' aria-label='Toggle Button' />
  </div>
);
export default ToggleButton;
