import { postWithArrayBufferResponse, getWithoutHeaders } from './basicCalls';

async function getFormatHtml(templateId, formatId) {
  return getWithoutHeaders(
    `${window._env_.REACT_ENV_LAMBDA_URL}qa/get_template_html/${templateId}/${formatId}`
  );
}

async function exportTemplate(templateId, exports, seekTime) {
  return postWithArrayBufferResponse(
    `${window._env_.REACT_ENV_QUICK_EXPORT_URL}/template/${templateId}`,
    {
      exports,
      seekTime
    }
  );
}

export { exportTemplate, getFormatHtml };
