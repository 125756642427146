function removeDcoTagFromTextByKey(objectList, dynamicKey) {
  const newObjectList = JSON.parse(JSON.stringify(objectList));

  for (let i = 0; i < newObjectList.length; i++) {
    if (newObjectList[i].dynamic_key && newObjectList[i].dynamic_uuid) {
      if (newObjectList[i].dynamic_key === dynamicKey) {
        const {
          dynamic_uuid: removedUuid,
          dynamic_key: removedKey,
          dynamic_color: removedColor,
          ...newObj
        } = newObjectList[i];
        newObjectList[i] = newObj;
      }
    }

    if (newObjectList[i].children && newObjectList[i].children !== undefined) {
      newObjectList[i].children = removeDcoTagFromTextByKey(newObjectList[i].children, dynamicKey);
    }
  }

  return newObjectList;
}

export default removeDcoTagFromTextByKey;
