/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef, createRef } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  getValidatedDpaColumnData,
  updateMappedColumnsData,
  getMappings,
  setAdId,
  useOptionalMapping,
  removeOptionalMapping,
  initialState,
  updateOptionalMappedColumnsData,
  addOptionalMapping
} from '../../redux/slices/dpa';
import {
  DropDownIcon,
  ColumnStatusDot,
  DpaInfoIcon,
  CloseIcon,
  PlusIcon
} from '../../assets/icons';
import { useClickOutside } from '../../assets/utils';
import ZuuviLoader from '../common/ZuuviLoader';

const MapAdditionalFieldsButton = styled.span`
  background-color: ${(props) => (props.environment === 'studio' ? '#464646' : '#D9D9D9')};
  width: 100%;
  height: 30px;
  color: ${(props) => (props.environment === 'studio' ? '#7a7a7a' : '#828282')};
  cursor: pointer;
  margin: 10px 0px;
  border-radius: 4px;
  text-align: center;

  :hover {
    background-color: ${(props) => (props.environment === 'studio' ? '#363636' : '#b1b1b1')};
  }
`;

const Row = styled.div`
  height: 47.5px;
  line-height: 47.5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid rgba(196, 196, 196, 0.25);
  column-gap: 0.875rem;
  align-items: center;

  &:first-child {
    position: sticky;
    background: ${(props) => (props.environment === 'studio' ? '#2b2b2b' : '#fff')};
    top: 0;
    height: 35px;
    border-bottom: none;
    padding-bottom: 5px;

    box-shadow: ${(props) => (!props.hitEdge ? '0px 1px 10px rgb(0 0 0 / 50%)' : 'none')};
  }

  &:last-child {
    position: sticky;
    background: ${(props) => (props.environment === 'studio' ? '#2b2b2b' : '#fff')};
    bottom: -1px;
    gap: 10px;
    height: 50px;
    justify-content: flex-start;
    border-bottom: none;

    box-shadow: ${(props) => (!props.hitEdge ? '0px -1px 10px rgb(0 0 0 / 50%)' : 'none')};
  }
`;

const RowsNameBox = styled.div`
  width: 146px;
  font-size: ${(props) => (props.environment === 'studio' ? '14px' : '15px')};
`;

const SelectedDataStatusBox = styled.div`
  width: 215px;
  align-items: center;
  font-size: 12px;
  margin: ${(props) => (props.environment === 'studio' ? 'auto 0px' : 'auto')};
  display: flex;
`;
const PreviewValueBox = styled.div`
  width: ${(props) => props.width}px;

  display: flex;
  flex-direction: column;

  font-size: 10px;
  line-height: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #828282;
`;

const InfoIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 7px;
`;

const InfoModal = styled.div`
  background: #4a90e2;
  position: absolute;
  top: 30px;
  margin-left: 10px;

  padding: 12px;
  > p {
    font-size: 12px;
    color: white;
    font-weight: 400;
    line-height: 16.8px;
    margin: 0;
  }
`;

const FakeScrollbarPadding = styled.div`
  width: 2px;
  max-height: 95px;
  background: ${(props) => (props.environment === 'studio' ? '#363636' : 'white')};
`;

const MappingsWrapper = styled.div`
  max-height: ${(props) => (props.environment === 'studio' ? '480px' : '450px')};

  ${(props) => {
    if (props.environment !== 'studio') {
      if (props.allowScroll) {
        return 'width: 790px;';
      } else {
        return 'width: 800px;';
      }
    }
  }}
  ${(props) => (props.environment === 'studio' ? 'margin: auto' : 'margin-left: 30px')};

  overflow-x: hidden;
  overflow-y: ${(props) => (props.allowScroll ? 'hidden' : 'scroll')};

  width: calc(100vw-10px);

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: ${(props) => (props.allowScroll ? '10px' : '0px')};

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-clip: content-box;
    border: 1px solid transparent;
  }
`;

const ColumnTitle = styled.div`
  font-weight: bold;
  font-size: ${(props) => (props.environment === 'studio' ? '14px' : '16px')};
  margin: ${(props) => (props.environment === 'studio' ? 'auto 0px' : 'auto')};
  min-width: 30px;
  display: flex;
  width: ${(props) => `${props.width}px`};

  > svg {
    margin-left: 7px;
    align-self: center;
    cursor: pointer;
  }
`;

const sharedDropdownInputStyle = css`
  overflow-y: scroll;
  overflow-x: hidden;

  background: ${(props) => (props.environment === 'studio' ? '#363636' : 'white')};

  border: 1px solid ${(props) => (props.environment === 'studio' ? '#494949' : '#D9D9D9')};
  margin: ${(props) => (props.environment === 'studio' ? 'auto 0px' : 'auto')};

  word-break: break-all;
  line-height: 14px;
  font-size: 14px;
  align-items: center;
`;

const DropDownDefaultBox = styled.div`
  ${sharedDropdownInputStyle};

  min-height: 25px;
  max-height: 25px;
  overflow: hidden;

  color: ${(props) => props.color};
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
`;

const DropDownDefaultInputBox = styled.input`
  ${sharedDropdownInputStyle};

  min-height: 25px;
  max-height: 25px;

  color: ${(props) => props.color};
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  outline: none;
  width: calc(100% - 22px);
`;

const DropDownListContainer = styled.div`
  position: relative;
  display: flex;

  height: 0;
  width: 101%;

  bottom: ${(props) => (props.bottom ? '125px' : '-4px')};
  z-index: 1000;
`;

const ListItem = styled.div`
  margin: 0;
  margin-right: 2px;
  padding: 5px 10px;
  &:hover {
    background-color: ${(props) => (props.environment === 'studio' ? '#3E3E3E' : '#4a90e2')};
    color: white;
  }
`;

const DropDownList = styled.div`
  ${sharedDropdownInputStyle};
  width: 100%;
  min-height: 25px;
  max-height: 93px;

  &::-webkit-scrollbar {
    width: 6px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) =>
      props.environment === 'studio' ? props.theme.colors.dcoPurple : '#4a90e2'};
    border-radius: 10px;
  }

  background: ${(props) => (props.environment === 'studio' ? '#363636' : 'white')};
`;

function MapColumnData({ environment = 'manager' }) {
  const { adId, templateId, publishedTemplateId } = useParams();
  const { mappings, optionalMappings, dpaFetchedData, errorMessage } = useSelector(
    (state) => state.dpa
  );

  const dispatch = useDispatch();

  const [elRefs, setElRefs] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [dropDownOpened, setDropDownOpened] = useState(null);
  const [optionalDropDownOpened, setOptionalDropDownOpened] = useState(null);
  const [hitEdgeTop, setHitEdgeTop] = useState(true);
  const [hitEdgeBottom, setHitEdgeBottom] = useState(true);
  const [infoIconHovered, setInfoIconHovered] = useState(null);

  const optionalMappingRef = useRef();
  const stepsComponentWrapperRef = useRef();
  const dropdownListContainerRef = useRef();
  const COLUMN_TITLES = [
    {
      title: 'FIELDS',
      tooltip: (
        <>
          <p>These fields display the naming for the data used in Meta.</p>
          <p>The first 8 rows are required by Meta to make sure your data feed is compliant.</p>
          <p>You can add more field to map by clicking the + button below.</p>
          <p>The data feed will still include all unmapped fields when you proceed.</p>
        </>
      ),
      width: 211,
      renderColumn: (column, i, width) => (
        <div style={{ width }}>
          {isOptionalField(column.dpa_key) ? (
            optionalDropDownOpened !== i ? (
              <DropDownDefaultBox
                className='select-input'
                ref={elRefs[i]}
                environment={environment}
                color={
                  column.dpa_key && environment !== 'studio'
                    ? 'black'
                    : column.dpa_key && environment === 'studio'
                    ? 'white'
                    : '#868686'
                }
                id={column.dpa_key}
                onClick={() => {
                  setOptionalDropDownOpened(i);
                  changeInput('');
                }}>
                {column.dpa_key ? column.dpa_key : 'Select field'}
                <DropDownIcon />
              </DropDownDefaultBox>
            ) : (
              <>
                <DropDownDefaultInputBox
                  autoFocus
                  value={inputValue || ''}
                  environment={environment}
                  color={
                    column.dco_key && environment !== 'studio'
                      ? 'black'
                      : column.dco_key && environment === 'studio'
                      ? 'white'
                      : '#868686'
                  }
                  onChange={(event) => changeInput(event.target.value)}
                />
                <DropDownListContainer
                  environment={environment}
                  marginTop={environment === 'studio' && i + 1 === mappings.length}
                  bottom={environment === 'studio' && i + 1 === mappings.length}
                  id='list-container'
                  ref={dropdownListContainerRef}>
                  <DropDownList environment={environment} id='list'>
                    {optionalMappings &&
                      optionalMappings.map(
                        (optionalMapping) =>
                          optionalMapping.dpa_key &&
                          optionalMapping.dpa_key.includes(inputValue) && (
                            <ListItem
                              environment={environment}
                              key={`${optionalMapping.dpa_key}-optional-mapping`}
                              id='list-item'
                              onClick={(e) => {
                                dispatch(
                                  updateOptionalMappedColumnsData({
                                    dpaKey: column.dpa_key,
                                    data: {
                                      dpa_key: optionalMapping.dpa_key,
                                      dco_key: column.dco_key,
                                      row_status: column.row_status
                                    }
                                  })
                                );
                                setOptionalDropDownOpened(null);
                              }}>
                              {optionalMapping.dpa_key}
                            </ListItem>
                          )
                      )}
                  </DropDownList>

                  <FakeScrollbarPadding environment={environment} />
                </DropDownListContainer>
              </>
            )
          ) : (
            <RowsNameBox environment={environment} className='row-name'>
              {column.dpa_key.charAt(0).toUpperCase() + column.dpa_key.slice(1)}
            </RowsNameBox>
          )}
        </div>
      )
    },
    {
      title: 'SELECT COLUMN',
      tooltip: (
        <>
          <p>These columns display the naming used in your data feed.</p>
          <p>The first 8 rows are required by Meta to make sure your data feed is compliant.</p>
          <p>You can add more columns to map by clicking the + button below.</p>
          <p>The data feed will still include all unmapped fields when you proceed.</p>
        </>
      ),
      width: 211,
      renderColumn: (column, i, width) => (
        <div style={{ width }}>
          {dropDownOpened !== i ? (
            <DropDownDefaultBox
              className='select-input'
              ref={elRefs[i]}
              environment={environment}
              color={
                column.dco_key && environment !== 'studio'
                  ? 'black'
                  : column.dco_key && environment === 'studio'
                  ? 'white'
                  : '#868686'
              }
              id={column.dpa_key}
              onClick={() => {
                if (column.dpa_key) {
                  setDropDownOpened(i);
                  changeInput('');
                }
              }}>
              <p>
                {column.dpa_key
                  ? column.dco_key
                    ? column.dco_key
                    : 'Select column'
                  : 'Please select a field'}
              </p>
              {column.dpa_key && <DropDownIcon />}
            </DropDownDefaultBox>
          ) : (
            <>
              <div style={{ height: '25px' }}>
                <DropDownDefaultInputBox
                  autoFocus
                  value={inputValue || ''}
                  environment={environment}
                  color={
                    column.dco_key && environment !== 'studio'
                      ? 'black'
                      : column.dco_key && environment === 'studio'
                      ? 'white'
                      : '#868686'
                  }
                  onChange={(event) => changeInput(event.target.value)}
                />
                <DropDownListContainer
                  environment={environment}
                  marginTop={environment === 'studio' && i + 1 === mappings.length}
                  bottom={environment === 'studio' && i + 1 === mappings.length}
                  id='list-container'
                  ref={dropdownListContainerRef}>
                  <DropDownList environment={environment} id='list'>
                    {dpaFetchedData.mappings[column.dpa_key] &&
                      dpaFetchedData.mappings[column.dpa_key].options.map((option) =>
                        Object.entries(option).map(
                          ([optionKey, rowStatusValue]) =>
                            optionKey.includes(inputValue) && (
                              <ListItem
                                environment={environment}
                                key={`${optionKey}-${rowStatusValue}`}
                                id='list-item'
                                onClick={(e) => {
                                  dispatch(
                                    updateMappedColumnsData({
                                      dpaKey: column.dpa_key,
                                      data: { dco_key: optionKey, row_status: rowStatusValue }
                                    })
                                  );
                                  setDropDownOpened(null);
                                }}>
                                {optionKey}
                              </ListItem>
                            )
                        )
                      )}
                  </DropDownList>
                  <FakeScrollbarPadding environment={environment} />
                </DropDownListContainer>
              </div>
            </>
          )}
        </div>
      )
    },
    {
      title: 'STATUS',
      width: 225,
      renderColumn: (column, i, width) => (
        <div style={{ width, overflow: 'hidden' }}>
          <RowStatusIndicator status={column} />
        </div>
      )
    },
    {
      title: 'PREVIEW',
      width: 125,
      renderColumn: (column, i, width) => (
        <PreviewValueBox
          className='preview'
          environment={environment}
          id={column.dpa_key}
          width={width}
          onBlur={(e) => {
            setDropDownOpened(null);
          }}>
          {dpaFetchedData?.preview_values[column.dco_key]
            ? dpaFetchedData?.preview_values[column.dco_key].map((value, idx) => (
                <span key={`${value}-${idx}`}>{value}</span>
              ))
            : ['-', '-', '-'].map((value, idx) => <span key={`${value}-${idx}`}>{value}</span>)}
        </PreviewValueBox>
      )
    },
    {
      title: '',
      width: 50,
      renderColumn: (column, i, width) => (
        <div style={{ width, overflow: 'hidden' }}>
          <span
            style={{
              float: 'right',
              width: '30px'
            }}>
            {isOptionalField(column.dpa_key) && (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch(removeOptionalMapping({ dpaKey: column.dpa_key }))}>
                <CloseIcon stroke={environment === 'studio' ? 'white' : 'black'} />
              </span>
            )}
          </span>
        </div>
      )
    }
  ];

  // assign refs to the mapped list selected items for clickoutside functionality
  useEffect(() => {
    if (environment === 'manager') {
      dispatch(setAdId(adId));
    }
    // add or remove refs
    setElRefs((r) =>
      Array(mappings.length)
        .fill()
        .map((_, i) => r[i] || createRef())
    );
  }, []);

  const onScroll = () => {
    if (stepsComponentWrapperRef.current) {
      setDropDownOpened(null);
      setOptionalDropDownOpened(null);

      const { scrollTop, scrollHeight, clientHeight } = stepsComponentWrapperRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setHitEdgeBottom(true);
      } else {
        setHitEdgeBottom(false);
      }

      if (scrollTop === 0) {
        setHitEdgeTop(true);
      } else {
        setHitEdgeTop(false);
      }
    }
  };

  useEffect(() => {
    const handleGetMappings = async () => {
      dispatch(getMappings({ adId }));
    };
    // call the function
    if (environment === 'manager') {
      handleGetMappings();

      if (!dpaFetchedData || !mappings) {
        dispatch(
          getValidatedDpaColumnData({
            templateId,
            publishedTemplateId
          })
        );
      }
    }
  }, [templateId, publishedTemplateId]);

  // autofill columna data if we have matching pairs
  useEffect(() => {
    if (mappings && dpaFetchedData && environment === 'studio') {
      mappings.map((column) => getMatchingMappings(column));
    }
  }, [mappings, dpaFetchedData]);

  useEffect(() => {
    if (stepsComponentWrapperRef.current)
      stepsComponentWrapperRef.current.scrollTop = stepsComponentWrapperRef.current.scrollHeight;
  }, [mappings.length]);

  // autofill columna data if we have matching pairs
  useEffect(() => {
    // Check if any optional mappings match, then we want to add them to the mappings.
    if (optionalMappings && dpaFetchedData && environment === 'studio') {
      optionalMappings.map((column) => {
        getMatchingOptionalMappings(column);
      });
    }
  }, [optionalMappings, dpaFetchedData]);

  useClickOutside(
    dropdownListContainerRef && dropdownListContainerRef,
    () => {
      setDropDownOpened(null);
      setOptionalDropDownOpened(null);
    },
    elRefs,
    optionalMappingRef
  );

  function getMatchingMappings(column) {
    if (dpaFetchedData.mappings[column.dpa_key]) {
      dpaFetchedData.mappings[column.dpa_key].options.map((option) =>
        Object.entries(option).map(([optionKey, rowStatusValue]) => {
          if (optionKey === column.dpa_key && !column.dco_key) {
            dispatch(
              updateMappedColumnsData({
                dpaKey: column.dpa_key,
                data: { dco_key: optionKey, row_status: rowStatusValue }
              })
            );
            return optionKey;
          }
        })
      );
    }
  }

  function getMatchingOptionalMappings(column) {
    if (dpaFetchedData.mappings[column.dpa_key]) {
      dpaFetchedData.mappings[column.dpa_key].options.map((option) =>
        Object.entries(option).map(([optionKey]) => {
          if (optionKey === column.dpa_key && !column.dco_key) {
            dispatch(
              useOptionalMapping({
                dpaKey: column.dpa_key
              })
            );
            return optionKey;
          }
        })
      );
    }
  }

  function percentageCalculator(amount, percent) {
    return ((percent * amount) / 100).toFixed(0);
  }

  function isOptionalField(field) {
    if (!field) return true;
    return initialState.optionalMappings.findIndex((mapping) => mapping.dpa_key === field) !== -1;
  }

  function getStatusColor(invalidRowsAmount, criticalPercentageAmount) {
    if (invalidRowsAmount > 0 && invalidRowsAmount < criticalPercentageAmount) {
      return '#F5CC5C';
    }
    if (invalidRowsAmount > criticalPercentageAmount) {
      return '#de6e4d';
    }
    return '#6ADAAE';
  }

  function changeInput(value) {
    setInputValue(value);
  }

  const RowStatusIndicator = ({ status }) => {
    let data = { text: 'Awaiting', color: '#828282' };

    if (dpaFetchedData && status.dpa_key && status.dco_key) {
      const { options } = dpaFetchedData.mappings[status.dpa_key];

      const option = options.find((o) => status.dco_key in o);

      if (option) {
        const { invalid_rows, matching_rows } = option[status.dco_key];
        const criticalPercentage = percentageCalculator(invalid_rows + matching_rows, 80);

        data = {
          text:
            invalid_rows > 0
              ? `Problem in ${invalid_rows} (out of ${invalid_rows + matching_rows}) rows`
              : 'Approved',
          color: getStatusColor(invalid_rows, criticalPercentage)
        };
      }
    }
    return (
      <SelectedDataStatusBox environment={environment} className='status'>
        <ColumnStatusDot
          style={{ margin: environment === 'studio' ? 'auto 6px auto 0px' : 'auto 6px auto 0px' }}
          fill={data.color}
        />
        <div>{data.text}</div>
      </SelectedDataStatusBox>
    );
  };

  const HoverInfoModal = (props) => (
    <InfoModal environment={environment}>{props.children}</InfoModal>
  );

  const MappingsLoader = () => (
    <div
      style={{
        left: '50%',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        marginTop: environment === 'studio' ? '10%' : '25%',
        marginBottom: '10%',
        color: '#DE6E4D',
        fontSize: '12px'
      }}>
      {errorMessage && environment === 'studio' ? (
        errorMessage
      ) : (
        <ZuuviLoader size='mini' color={environment === 'studio' ? 'white' : 'black'} />
      )}
    </div>
  );

  return (
    <MappingsWrapper
      environment={environment}
      ref={stepsComponentWrapperRef}
      onScroll={onScroll}
      allowScroll={dropDownOpened !== null || optionalDropDownOpened !== null}>
      {dpaFetchedData ? (
        <>
          {/* TOP ROW WITH COLUMN TITLES */}
          <Row environment={environment} hitEdge={hitEdgeTop}>
            {COLUMN_TITLES.map(({ title, tooltip, width }) => (
              <ColumnTitle key={title} environment={environment} width={width}>
                {title}
                {tooltip && (
                  <InfoIconWrapper
                    onMouseOver={() => setInfoIconHovered(title)}
                    onMouseOut={() => setInfoIconHovered(null)}>
                    <DpaInfoIcon />
                    {infoIconHovered === title && <HoverInfoModal>{tooltip}</HoverInfoModal>}
                  </InfoIconWrapper>
                )}
              </ColumnTitle>
            ))}
          </Row>

          {/* MAPPING ROWS */}
          {mappings.map((column, i) => (
            <Row environment={environment} key={column.dpa_key} id={`${column.dpa_key}-row`}>
              {COLUMN_TITLES.map(({ title, tooltip, width, renderColumn }) =>
                renderColumn(column, i, width)
              )}
            </Row>
          ))}

          {/* BOTTOM ROW */}
          <Row
            environment={environment}
            key='add-optional-mapping'
            id='add-optional-mapping-row'
            hitEdge={hitEdgeBottom}>
            <MapAdditionalFieldsButton environment={environment}>
              <span
                style={{
                  fontSize: '12px',
                  lineHeight: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
                onClick={() => dispatch(addOptionalMapping())}>
                <PlusIcon
                  fill={environment === 'studio' ? '#7a7a7a' : '#828282'}
                  style={{ paddingRight: '7px' }}
                />
                Map additional fields
              </span>
            </MapAdditionalFieldsButton>
          </Row>
        </>
      ) : (
        MappingsLoader()
      )}
    </MappingsWrapper>
  );
}
MapColumnData.defaultProps = {
  environment: 'manager'
};
MapColumnData.propTypes = {
  environment: PropTypes.string
};

export default MapColumnData;
