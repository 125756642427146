import { connect } from 'react-redux';
import RightMenu from '../components/RightMenu/RightMenu';
import { updateLayerFont } from '../redux/slices/template';

// const mapStateToProps = (state) => ({ });

const mapActionsToProps = {
  updateLayerFont
};

export default connect(null, mapActionsToProps)(RightMenu);
