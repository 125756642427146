const hasDcoTagInText = (objectList) => {
  if (typeof objectList !== 'object') return false;
  let hasDcoInText = false;

  for (const object of objectList) {
    if (object) {
      if (object.dynamic_key && object.dynamic_uuid) {
        hasDcoInText = true;
        break;
      }

      if (object.children) {
        hasDcoInText = hasDcoTagInText(object.children);
        if (hasDcoInText) break;
      }
    }
  }

  return hasDcoInText;
};

export default hasDcoTagInText;
