/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import PropTypes from 'prop-types';
import './AdFrame.scss';

import axios from 'axios';
import { useSelector } from 'react-redux';
import ZuuviLoader from '../common/ZuuviLoader';

export default function AdFrame(props) {
  const { item, iframeRefs } = props;
  const itemsExpanded = useSelector((state) => state.preview.itemsExpanded);
  const dynamicContent = useSelector((state) => state.preview.dynamicContent);

  function isItemExpanded(key) {
    return itemsExpanded.includes(key);
  }

  const [itemHtml, setItemHtml] = useState('');

  useEffect(async () => {
    // Fetch html to display in ad
    try {
      const response = await axios.get(item.src);
      setItemHtml(response.data);
    } catch (error) {
      // Error fetching
    }
  }, []);

  function resetAd(e) {
    if (
      e &&
      'target' in e &&
      'contentWindow' in e.target &&
      'zuuviTimeline' in e.target.contentWindow
    ) {
      e.target.contentWindow.zuuviTimeline.pause();
      e.target.contentWindow.zuuviTimeline.seek(0);
    }
  }

  return itemHtml !== '' ? (
    <iframe
      ref={iframeRefs}
      id={item.key}
      className='adFrame'
      onLoad={(e) => {
        // Post empty content in creatives for now
        e.target.contentWindow.postMessage(
          { type: 'dco-content', sources: dynamicContent, parameters: {} },
          '*'
        );
        resetAd(e); // Reset the ad
        setTimeout(() => {
          // Incase we have dco we need to do it again.
          // This is because the ad starts when the source is loaded
          resetAd(e);
        }, 400);
      }}
      title={item.key}
      style={{
        width: `${item.width}px`,
        height: `${item.height}px`,
        transform: `scale(${isItemExpanded(item.key) ? 1 : item.scale})`,
        transformOrigin: item.scale !== 1 && 'left top'
      }}
      srcDoc={itemHtml}
    />
  ) : (
    <div
      style={{
        border: '1px solid #353535',
        height: `${item.height * (isItemExpanded(item.key) ? 1 : item.scale)}px`
      }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <ZuuviLoader size='mini' color='white' />
      </div>
    </div>
  );
}

AdFrame.propTypes = {
  item: PropTypes.object.isRequired,
  iframeRefs: PropTypes.shape({
    current: PropTypes.object
  })
};

AdFrame.defaultProps = {
  iframeRefs: {}
};
