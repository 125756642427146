import posthog from 'posthog-js';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  AdformIcon,
  AdformLogo,
  CustomDCOIcon,
  DynamicLinkIcon,
  GoogleStudioLogo,
  LinkIcon,
  MainDpaIcon,
  RaptorRetargetingLogo,
  UploadFileIcon,
  URLParameterIcon,
  VirtualSourceIcon
} from '../../assets/icons';

import {
  getDynamicContent,
  parseDynamicCode,
  setActiveMenu,
  setParameterData,
  setUserInput
} from '../../redux/slices/dco';

import DCOParameterTable from './DCOParameterTable';
import DCOTable from './DCOTable';
import MapColumnData from '../DPA/MapColumnData';
import useAuthorization from '../../../utils/hooks/useAuthorization';

// Used for showing the dynamic possibilities
export function getDynamicSources() {
  const isAuthorized = useAuthorization();
  return [
    {
      id: 'file',
      name: 'Upload file',
      defaultSourceName: 'My DCO file',
      description:
        'Upload your own file that does not change and expose it as a data source in your templates.',
      icon: UploadFileIcon,
      posthogEventName: 'create-source-upload-file',
      active: isAuthorized({ featureFlag: 'dco-upload-file' })
    },
    {
      id: 'link',
      name: 'Dynamic link',
      defaultSourceName: 'My DCO link',
      description:
        'Upload your link that refreshes at regular intervals and expose it as a data source in your templates.',
      icon: DynamicLinkIcon,
      posthogEventName: 'create-source-dynamic-link',
      active: isAuthorized({ featureFlag: 'dco-dynamic-link' })
    },
    {
      id: 'raptor',
      name: 'Raptor retargeting link',
      defaultSourceName: 'My raptor retargeting link',
      description:
        'Upload your dynamic Raptor link. The content displayed in the ad will be based on the end viewers.',
      icon: RaptorRetargetingLogo,
      posthogEventName: 'create-source-raptor-retargeting-link',
      active: isAuthorized({ featureFlag: 'dco-raptor-link' })
    },
    {
      id: 'google-studio',
      name: 'Google Studio Integration',
      defaultSourceName: 'My Google Studio source',
      description:
        'Integrate with Google Studio. The content displayed in the ad will be based on the end viewers using the Google Studio engine.',
      icon: GoogleStudioLogo,
      posthogEventName: 'create-source-google-studio',
      active: isAuthorized({ featureFlag: 'dco-google-studio' })
    },
    {
      id: 'adform-dynamic-ads',
      name: 'Adform Dynamic Ads',
      defaultSourceName: 'My Adform Dynamic Ads source',
      description:
        'Integrate with Adform Dynamic Ads. The content displayed in the ad will be based on the end viewers using the Adform Dynamic Ads engine.',
      icon: AdformLogo,
      posthogEventName: 'create-source-adform-dynamic-ads',
      active: isAuthorized({ featureFlag: 'dco-adform-dynamic-ads' })
    },
    {
      id: 'adform-product-retargeting',
      name: 'Adform Retargeting',
      defaultSourceName: 'My Adform Product Retargeting source',
      description:
        'Integrate with Adform Product Retargeting. The content displayed in the ad will be based on the end viewers using the Adform Product Retargeting engine.',
      icon: AdformLogo,
      posthogEventName: 'create-source-adform-product-retargeting',
      active: isAuthorized({ featureFlag: 'dco-adform-product-retargeting' })
    },
    {
      id: 'parameter',
      name: 'URL parameter',
      defaultSourceName: 'My DCO URL parameter',
      description:
        'Create a set of url parameters that the template can read data from, on the page your assets are exposed.',
      posthogEventName: 'create-source-url-parameter',
      icon: URLParameterIcon,
      active: isAuthorized({ featureFlag: 'dco-url-parameter' })
    },
    {
      id: 'virtual',
      name: 'Virtual Source',
      defaultSourceName: 'My virtual source',
      description:
        'Create a filtered version of a file uploaded or a dynamic link, that is more specific to your end viewers. For instance to your all products with a discount greater than 50%.',
      posthogEventName: 'create-source-virtual-source',
      icon: VirtualSourceIcon,
      active: isAuthorized({ featureFlag: 'dco-virtual-source' })
    },
    {
      id: 'custom',
      name: 'Custom datasource',
      defaultSourceName: 'My custom source',
      description:
        'If you have special requirements - lets talk. We can create a custom solution for you.',
      icon: CustomDCOIcon,
      active: !!isAuthorized({ featureFlag: 'dco-custom-source' }),
      buttonText: 'Buy access now',
      posthogEventName: 'create-source-buy-access-custom-datasource',
      buttonClick: () => {
        window.open('https://zuuvenir.typeform.com/to/kr7x9MAz', '_blank').focus();
      },
      special: true
    },
    {
      id: 'dpa',
      name: 'Catalog Ads source',
      defaultSourceName: 'My Catalog Ads source',
      description: 'Catalog Ads source',
      posthogEventName: 'create-source-virtual-source',
      icon: MainDpaIcon,
      active: false
    }
  ];
}

export function getDynamicOptions() {
  const dispatch = useDispatch();
  const { activeMenu, userInput, sourceData, parameterData } = useSelector((state) => state.dco);

  function removeSpaces(text) {
    if (typeof text === 'string') {
      return text.replaceAll(' ', '+');
    }
    return '';
  }

  function changeOption(change) {
    dispatch(
      setActiveMenu({
        ...activeMenu,
        activeOptionNumber: activeMenu.activeOptionNumber + change,
        isError: false
      })
    );
  }

  const handleGetDynamicContent = (change) => {
    dispatch(getDynamicContent(change));
  };

  const handleParseDynamicCode = (change) => {
    dispatch(parseDynamicCode(change));
  };

  const updateParameterData = (rowIndex, columnId, value) => {
    const newParameterData = () =>
      parameterData.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...parameterData[rowIndex],
            [columnId]: value
          };
        }
        return row;
      });

    const data = newParameterData();
    dispatch(setParameterData(data));
  };

  const removeParameterRow = (index) => {
    if (parameterData.length > 1) {
      const dataCopy = [...parameterData];
      dataCopy.splice(index, 1);
      dispatch(setParameterData(dataCopy));
    }
  };

  const handleSetAllData = (data) => {
    dispatch(setUserInput(data));
  };

  const filterParamData = (change) => {
    const filteredArray = parameterData.filter(
      (param) => param.parameter !== '' && param.name !== ''
    );
    if (filteredArray.length > 0) {
      dispatch(setParameterData(filteredArray));
      changeOption(change);
    } else {
      dispatch(setActiveMenu({ ...activeMenu, isError: true }));
      toast.error('Please add at least one parameter.');
    }
  };

  const isRowSelected = (change) => {
    if (userInput.selected_rows.length > 0) {
      changeOption(change);
    } else {
      dispatch(setActiveMenu({ ...activeMenu, isError: true }));
      toast.error('Please choose at least one row.');
    }
  };

  const handleChange = (event) => {
    dispatch(
      setUserInput({
        ...userInput,
        [event.target.name]: event.target.files ? event.target.files[0] : event.target.value
      })
    );
  };

  return [
    {
      id: 'choose-name',
      name: 'Name your source',
      active: [
        'link',
        'file',
        'parameter',
        'dpa',
        'raptor',
        'google-studio',
        'adform-dynamic-ads',
        'adform-product-retargeting'
      ],
      nextFunction: () => {
        if (userInput.name === '') {
          dispatch(setActiveMenu({ ...activeMenu, isError: true }));
        } else {
          dispatch(
            setActiveMenu({
              ...activeMenu,
              isError: false,
              activeOptionNumber: 1
            })
          );
        }
      },
      content: (
        <ChooseSourceInput
          id='option-choose-source-name'
          type='text'
          value={userInput.name}
          name='name'
          autoComplete='off'
          placeholder='Source name...'
          onChange={handleChange}
          isError={activeMenu.isError}
        />
      )
    },
    {
      id: 'choose-link',
      name: 'Choose link',
      active: ['link', 'dpa', 'raptor', 'adform-product-retargeting'],
      nextFunction: handleGetDynamicContent,
      content: (
        <>
          <ChooseLinkIcon />
          <ChooseSourceInput
            id='option-upload-link-field'
            type='text'
            value={userInput.url}
            name='url'
            autoComplete='off'
            placeholder='Paste link...'
            onChange={handleChange}
            isError={activeMenu.isError}
          />
        </>
      )
    },
    {
      id: 'choose-file',
      name: 'Choose file',
      active: ['file', 'adform-dynamic-ads'],
      nextFunction: handleGetDynamicContent,
      content: (
        <>
          <ChooseFileInput
            type='file'
            name='file'
            id='drop_zone'
            accept='.csv,.tsv,.json,.xml,.xlsx'
            onChange={handleChange}
          />
          <ChooseFileInputOverlay file={userInput.file} isError={activeMenu.isError}>
            {userInput.file && userInput.file.name
              ? userInput.file.name
              : 'Click or drag a file here to upload.'}
          </ChooseFileInputOverlay>
        </>
      )
    },
    {
      id: 'choose-parameters',
      name: 'Choose parameters',
      active: ['parameter'],
      nextFunction: filterParamData,
      content: (
        <>
          <DCOParameterTable
            data={parameterData}
            size={parameterData.length}
            updateParameterData={updateParameterData}
            removeParameterRow={removeParameterRow}
            isError={activeMenu.isError}
          />
          <ParameterPreviewWrapper>
            www.example.com
            {parameterData.map((param, index) =>
              param.parameter !== ''
                ? `${index === 0 ? '?' : '&'}${removeSpaces(param.parameter)}=${removeSpaces(
                    param.example
                  )}`
                : ''
            )}
          </ParameterPreviewWrapper>
        </>
      )
    },
    {
      id: 'insert-code',
      name: 'Insert code',
      active: ['google-studio'],
      nextFunction: handleParseDynamicCode,
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px'
          }}>
          <span
            style={{
              textAlign: 'left',
              width: '92%',
              fontSize: '14px',
              color: 'white',
              fontWeight: 'normal'
            }}>
            Copy the code from Google Studio and insert it into the box below.
          </span>
          <ChooseCodeInput
            id='option-insert-code'
            type='text'
            value={userInput.code}
            name='code'
            autoComplete='off'
            spellcheck='false'
            data-gramm='false'
            rows='10'
            cols='10'
            placeholder={googleStudioExampleCode}
            onChange={handleChange}
            isError={activeMenu.isError}
          />
        </div>
      )
    },
    {
      id: 'example-parameter',
      name: 'Parameter example',
      active: ['parameter'],
      content: (
        <>
          <ParameterPreviewWrapper>
            www.example.com
            {parameterData.map((param, index) =>
              param.parameter !== ''
                ? `${index === 0 ? '?' : '&'}${removeSpaces(param.parameter)}=${removeSpaces(
                    param.example
                  )}`
                : ''
            )}
          </ParameterPreviewWrapper>
          <DCOTable
            data={parameterData}
            size={parameterData.length}
            isError={activeMenu.isError}
            type='parameter'
          />
        </>
      )
    },
    {
      id: 'read-code',
      name: 'Check integration',
      active: ['google-studio'],
      nextFunction: true,
      content: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: '10px'
          }}>
          <div>
            <p
              style={{
                fontSize: '14px',
                color: 'white',
                textAlign: 'left',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between'
              }}>
              Google Studio Profile ID:
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                {userInput.google_studio_profile_id}
              </span>
            </p>
          </div>

          <DCOTable
            data={sourceData.list}
            allData={userInput}
            setAllData={handleSetAllData}
            size={sourceData.length}
            isError={activeMenu.isError}
            type='content'
            googleStudio={true}
          />

          <p style={{ fontSize: '12px', color: 'grey', textAlign: 'left', width: '100%' }}>
            These are the values that we parsed from the code inserted at the previous step. <br />
            If you see any errors, please go back and check your code.
          </p>
        </div>
      )
    },
    {
      id: 'read-content',
      name: 'Read column and rows',
      active: ['link', 'file', 'raptor', 'adform-dynamic-ads', 'adform-product-retargeting'],
      nextFunction: isRowSelected,
      content: (
        <>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <span style={{ fontSize: '13px', color: 'grey', textAlign: 'left', width: '100%' }}>
              Select the keys you want to import and use in your creatives.
            </span>
          </div>

          {activeMenu.source === 'raptor' && (
            <div style={{ display: 'flex', marginBottom: '3px' }}>
              <span style={{ fontSize: '12px', color: 'grey', textAlign: 'left', width: '100%' }}>
                Values below are example values on does not represent all the values in your Raptor
                feed.
              </span>
            </div>
          )}

          <DCOTable
            data={sourceData.list}
            allData={userInput}
            setAllData={handleSetAllData}
            size={sourceData.length}
            isError={activeMenu.isError}
            type='content'
          />
        </>
      )
    },
    {
      id: 'map-data',
      name: 'Map data source',
      subtitle: 'Map columns in your files to match required columns',
      active: ['dpa'],
      content: (
        <>
          <MapColumnData environment='studio' />
        </>
      )
    },
    // We need to explain these better, can be added back by removing the comment.
    // Thinking just keep it for now and possibly add it back later with different design.
    // {
    //   id: 'configure-frequency',
    //   name: 'Update frequency',
    //   active: ['link', 'file'],
    //   content: (
    //     <>
    //       <div className='slider-wrapper'>
    //         <div className='frequency-wrapper'>
    //           <span>Time between rotations</span>
    //           <span>
    //             {userInput.time_between_rotations} minute
    //             {userInput.time_between_rotations !== 1 && 's'}
    //           </span>
    //         </div>
    //         <input
    //           type='range'
    //           min='30'
    //           max='120'
    //           value={userInput.time_between_rotations}
    //           name='time_between_rotations'
    //           className='option-slider'
    //           onChange={handleChange}
    //         />
    //       </div>

    //       <div className='slider-wrapper'>
    //         <div className='frequency-wrapper'>
    //           <span>Number of objects in rotation</span>
    //           <span>
    //             {userInput.number_of_objects} object{userInput.number_of_objects !== 1 && 's'}
    //           </span>
    //         </div>
    //         <input
    //           type='range'
    //           min='1'
    //           value={userInput.number_of_objects ? userInput.number_of_objects : 0}
    //           max={sourceData.totalObjects > 20 ? 20 : sourceData.totalObjects}
    //           name='number_of_objects'
    //           className='option-slider'
    //           onChange={handleChange}
    //         />
    //       </div>
    //     </>
    //   )
    // },
    {
      id: 'comming-soon',
      name: '',
      active: ['virtual'],
      content: (
        <>
          <h2>Comming soon to a studio near you</h2>
        </>
      )
    }
  ];
}

const ParameterPreviewWrapper = styled.div`
  background-color: #363636;
  padding: 10px 20px 10px;
  word-break: break-all;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const ChooseSourceInput = styled.input`
  width: 80%;
  height: 44px;

  background: #3e3e3e;
  border-radius: 101px;
  padding-left: 25px;
  padding-right: 45px;
  color: white;

  border: ${(props) => (props.isError ? '2px solid #ff4545' : 'none')};
  outline: none;
`;

const ChooseCodeInput = styled.textarea`
  width: 90%;
  height: 400px;

  background: #3e3e3e;
  border-radius: 8px;
  padding: 10px;
  color: white;

  border: ${(props) => (props.isError ? '2px solid #ff4545' : 'none')};
  outline: none;
  resize: none;
`;

const ChooseFileInput = styled.input`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  opacity: 0;
  position: relative;
  z-index: 1;

  cursor: pointer;
`;

const ChooseFileInputOverlay = styled.div`
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;

  border: ${(props) =>
    props.isError ? 'dashed 2px #ff4545' : 'dashed 2px rgba(255, 255, 255, 0.3)'};
  position: absolute;
  top: 0px;
  text-align: center;

  color: ${(props) => (props.file !== null ? 'white' : 'rgba(255, 255, 255, 0.3)')};

  cursor: pointer;
`;

const ChooseLinkIcon = styled(LinkIcon)`
  top: 15px;
  position: absolute;
  left: 90%;
`;

const googleStudioExampleCode = `<!--
 * Dynamic Content Enable code for Profile: 00000001
 *
 * The following code initializes the following dynamic variables for
 * development testing and live serving of Fields associated to the above profile
-->

<!-- DynamicContent Start: HTML5 invocation code. -->
<script type="text/javascript">

// Dynamic Content variables and sample values
    Enabler.setProfileId(00000001);
    var devDynamicContent = {};

    devDynamicContent.Zuuvi_Code_Example = [{}];
    devDynamicContent.Zuuvi_Code_Example[0].CTA = "Read More";
    devDynamicContent.Zuuvi_Code_Example[0].Headline = "This is a headline";
    devDynamicContent.Zuuvi_Code_Example[0].ExitUrl = "https://www.zuuvi.com/";
    Enabler.setDevDynamicContent(devDynamicContent);

</script>

<!--
 * You may access the variables in the following manner
 * AFTER the Studio Enabler is initialized.
 * var CTA = dynamicContent.Zuuvi_Code_Example[0].CTA;
 * Note: be sure to use "dynamicContent", not "devDynamicContent"
 * Note: be sure to use ExitOverride to create your exit URL dynamically; follow the instructions on our Help center: https://support.google.com/richmedia/answer/2664807
-->`;
