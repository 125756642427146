/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';
import toast, { Toaster } from 'react-hot-toast';
// import usePosthogCapture from '../../utils/hooks/usePosthogCapture';
import {
  ArrowLeftIcon,
  PauseIcon,
  PlayIcon,
  StopIcon,
  ZuuviLogo,
  ZuuviLogoSantaHat
} from '../assets/icons';
import './Preview.scss';
import { setWindowDimensions, getTemplate, getCampaign } from '../redux/slices/preview';
import Grid from '../components/preview/Grid';
import BaseButton from '../components/common/BaseButton';
import ZuuviLoader from '../components/common/ZuuviLoader';
import useAuthorization from '../../utils/hooks/useAuthorization';

function Preview(props) {
  const appUrl = window._env_.REACT_ENV_APP_URL.replace(/\/$/, '');
  const { templateId, campaignId, creativesIds } = props;
  const isAuthorized = useAuthorization();
  const dispatch = useDispatch();
  // const capturePosthogData = usePosthogCapture();

  const items = useSelector((state) => state.preview.items);
  const itemsExpanded = useSelector((state) => state.preview.itemsExpanded);
  const title = useSelector((state) => state.preview.title);
  const width = useSelector((state) => state.preview.windowDimensions.width);
  const extraWidth = width > 900 ? (width - 900) / 2 : 0;
  const isMobile = width <= 700;
  const validPreviewIds = !!(templateId || campaignId);

  const iframeRefs = useRef([]);
  const isIframeRefsLoaded = iframeRefs && iframeRefs.current.length > 0;

  const [isPlaying, setIsPlaying] = useState(false);
  const [hoverCopy, setHoverCopy] = useState(false);
  const [hoverLogin, setHoverLogin] = useState(false);
  const [zuuviTimeline, setZuuviTimeline] = useState(null);
  const [timelineProgress, setTimelineProgress] = useState('0.000');

  function handleResize() {
    dispatch(
      setWindowDimensions({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
      })
    );
  }

  // Add initial window dimensions and fetch the template or campaign
  useEffect(() => {
    handleResize();
    if (templateId) dispatch(getTemplate({ templateId }));
    if (campaignId) dispatch(getCampaign({ campaignId }));

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Make sure we grab one of the zuuvi timelines, to update our timeline progress
  useEffect(() => {
    if (!zuuviTimeline && isIframeRefsLoaded) {
      if (iframeRefs.current[0].current) {
        const zuuviAd = iframeRefs.current[0].current.contentWindow;
        if (zuuviAd && 'zuuviTimeline' in zuuviAd) {
          zuuviAd.zuuviTimeline.eventCallback('onUpdate', () => {
            setTimelineProgress(zuuviAd.zuuviTimeline.time().toFixed(3));
          });
          setZuuviTimeline(zuuviAd.zuuviTimeline);
        }
      }
    }
  }, [iframeRefs.current]);

  function executeOnIframes(callback) {
    if (isIframeRefsLoaded) {
      iframeRefs.current.forEach((ref) => {
        if (
          ref &&
          ref.current &&
          ref.current.contentWindow &&
          ref.current.contentWindow.zuuviTimeline
        ) {
          callback(ref.current.contentWindow.zuuviTimeline);
        }
      });
    }
  }

  const redirectLogin = () => {
    if (templateId) window.open(`/editor/template/${templateId}`, '_blank');
    if (campaignId) window.open(`${appUrl}`, '_blank');
  };

  const redirectZuuvi = () => {
    window.open('https://www.zuuvi.com/', '_blank');
  };

  const handlePlay = () => {
    executeOnIframes((timeline) => {
      if (isPlaying && timeline.isActive()) {
        timeline.pause();
        setIsPlaying(false);
      } else {
        if (timeline.totalDuration() === timeline.time()) {
          timeline.restart();
          timeline.repeat(3);
        } else {
          timeline.play();
        }
        setIsPlaying(true);
      }
    });
  };

  const handlePause = () => {
    executeOnIframes((timeline) => {
      timeline.pause();
      setIsPlaying(false);
    });
  };

  const handleStop = () => {
    executeOnIframes((timeline) => {
      timeline.restart();
      timeline.pause();
      timeline.repeat(3);
      setIsPlaying(false);
    });
    setTimelineProgress('0.000');
  };
  return (
    <>
      <div className='preview'>
        <div className='topbar'>
          <div className='left-bar'>
            <div
              style={{ display: 'flex', flexDirection: 'row', gap: '30px', alignItems: 'center' }}>
              <a style={{ cursor: 'pointer' }} onClick={redirectZuuvi}>
                {isAuthorized({ featureFlag: 'santa-hat-logo' }) ? (
                  <ZuuviLogoSantaHat className='zuuvi-logo' />
                ) : (
                  <ZuuviLogo className='zuuvi-logo' />
                )}
              </a>

              {!isMobile && (
                <span
                  style={{
                    fontSize: '16px',
                    width: `${140 + extraWidth}px`,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    color: 'white',
                    fontWeight: '700',
                    lineHeight: '16px'
                  }}>
                  {title !== '' ? title : 'Untitled'}
                </span>
              )}
            </div>
          </div>
          <div className='playBar'>
            <button onClick={handlePlay} type='button'>
              <PlayIcon className='animation-button' />
            </button>
            <button onClick={handlePause} type='button'>
              <PauseIcon className='animation-button' />
            </button>
            <button onClick={handleStop} type='button'>
              <StopIcon className='animation-button' />
            </button>

            <div
              className='timeline-progress-container'
              style={{ width: isMobile ? '50px' : '90px' }}>
              <p className='timeline-progress-container-text' style={{ marginLeft: '4px' }}>
                {timelineProgress < 10
                  ? `0${Number(timelineProgress).toFixed(2)}`
                  : timelineProgress}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '20px'
            }}>
            <button
              id='copy-link-preview-button'
              type='button'
              style={{
                margin: '0px',
                borderRadius: '4px',
                marginLeft: `${extraWidth}px`,
                backgroundColor: hoverCopy ? '#446BA5' : 'rgba(71, 146, 226, 1)',

                fontSize: isMobile ? '11px' : '12px',
                fontWeight: '700',
                height: '32px',
                padding: '0px 16px',
                transition: 'background-color 50ms ease-in-out',
                whiteSpace: 'nowrap'
              }}
              onClick={() => {
                // capturePosthogData({event: 'copy-link-preview-button', type: 'preview'});
                toast.success('Copied', { id: 'copy-link-preview-' });
                navigator.clipboard.writeText(window.location.href);
              }}
              onMouseEnter={() => setHoverCopy(true)}
              onMouseLeave={() => setHoverCopy(false)}>
              Copy Link
            </button>
            {!isMobile && validPreviewIds && (
              <button
                id='login-preview-button'
                type='button'
                style={{
                  margin: '0px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: 'white',
                  backgroundColor: hoverLogin ? 'rgba(71, 146, 226, 1)' : '#242424',
                  border: hoverLogin ? '1px solid rgba(71, 146, 226, 1)' : '1px solid #D8D8D8',
                  fontWeight: '700',
                  height: '32px',
                  padding: '0px 16px',
                  transition: 'all 50ms ease-in-out',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={redirectLogin}
                text='Login'
                onMouseEnter={() => setHoverLogin(true)}
                onMouseLeave={() => setHoverLogin(false)}>
                Login
                <ArrowLeftIcon
                  className='right-arrow'
                  fill='#ffffff'
                  style={{ marginTop: '10px' }}
                />
              </button>
            )}
          </div>
        </div>
        <div
          className='grid'
          style={{
            overflowX: itemsExpanded.length === 0 ? 'hidden' : 'scroll',
            overflowY: 'scroll'
          }}>
          {isMobile && <h1>{title !== '' ? title : 'Untitled'}</h1>}
          {validPreviewIds &&
            (items.length > 0 ? (
              <Grid iframeRefs={iframeRefs} creativesIds={creativesIds} />
            ) : (
              <div
                style={{
                  color: '#cbcbcb',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  justifyContent: 'center',
                  textAlign: 'center',
                  margin: '20px',
                  marginTop: '50px'
                }}>
                Loading preview
                <ZuuviLoader size='mini' color='white' />
              </div>
            ))}
          {!validPreviewIds && (
            <div
              style={{
                color: '#cbcbcb',
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                justifyContent: 'center',
                textAlign: 'center',
                margin: '20px',
                marginTop: '50px'
              }}>
              Could not find preview, please try again with another id.
            </div>
          )}
        </div>
      </div>
      <Toaster
        position='top-center'
        reverseOrder
        toastOptions={{
          // Define default options
          className: '',
          style: {
            margin: '40px',
            background: '#363636',
            color: '#fff',
            zIndex: 1,
            maxWidth: '10000px'
          },
          // Default options for specific types
          loading: {
            duration: 30000,
            icon: <ZuuviLoader size='mini' color='white' />
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black'
            }
          },
          error: {
            duration: 3000
          }
        }}
      />
    </>
  );
}

export default Preview;
