import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import { moveGroupIntoGroup, moveTemplateIntoGroup } from '../../redux/slices/dashboard';
import ItemTypes from '../DragNDrop/ItemTypes';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import BreadcrumbItemStyled from '../common/styled/BreadcrumbItem';

function BreadcrumItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const capturePosthogData = usePosthogCapture();
  const { ancestor, ancestors } = props;
  const isLastChild = ancestors.indexOf(ancestor) === ancestors.length - 1;

  const onDrop = (item, itemType) => {
    switch (itemType.getItemType()) {
      case ItemTypes.GROUP:
        if (item.id !== ancestor.id) {
          const data = {
            groupId: item.id,
            destinationGroupId: ancestor.id
          };
          capturePosthogData({ event: 'move-group-into-group', type: 'group' });
          dispatch(moveGroupIntoGroup(data));
        }
        break;
      case ItemTypes.TEMPLATE:
        if (item.id) {
          const data = { templateId: item.id, groupId: ancestor ? ancestor.id : null };
          capturePosthogData({ event: 'move-template-into-group', type: 'template' });
          dispatch(moveTemplateIntoGroup(data));
        }
        break;
      default:
    }
  };

  const [{ isOver, canDrop }, dropAncestor] = useDrop(() => ({
    accept: [ItemTypes.GROUP, ItemTypes.TEMPLATE],
    // eslint-disable-next-line no-use-before-define
    drop: (item, itemType) => {
      onDrop(item, itemType);
    },
    collect: (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  const isActive = isOver && canDrop && !isLastChild;

  return (
    <BreadcrumbItemStyled
      ref={dropAncestor}
      data-cy='breadcrumb-item'
      isActive={isActive}
      onClick={() => {
        navigate(`/${ancestor.id}`);
      }}>
      <span style={{ paddingRight: '10px' }}>/</span>
      {ancestor.title}
    </BreadcrumbItemStyled>
  );
}

BreadcrumItem.defaultProps = {
  ancestors: []
};

BreadcrumItem.propTypes = {
  ancestor: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  ancestors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string
    })
  )
};

export default BreadcrumItem;
