import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  currentAccountId: null
};
const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentAccountId(state, action) {
      state.currentAccountId = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    }
  }
});

export const { setCurrentAccountId, setUser } = auth.actions;
export default auth.reducer;
