import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CirclePlusIcon, CircleMinusIcon } from '../../assets/icons';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { setScale } from '../../redux/slices/editorSession';

function Zoom() {
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();

  const { scale } = useSelector((state) => state.editorSession.studio);

  return (
    <div className='top-menu-right-section-box margin-right-pointer'>
      <button
        type='button'
        className='zoom-button'
        title='zoom out'
        id='top-menu-zoom-out'
        onClick={() => {
          capturePosthogData({ event: 'zoom-out', type: 'zoom', menu: 'top' });
          if (scale >= 20) dispatch(setScale(scale - 10));
        }}>
        <CircleMinusIcon width={16} height={16} className='icon-hover' />
      </button>
      <button
        type='button'
        className='button-margin-left-small-pointer zoom-button'
        title='zoom in'
        id='top-menu-zoom-in'
        onClick={() => {
          capturePosthogData({ event: 'zoom-in', type: 'zoom', menu: 'top' });
          if (scale <= 390) dispatch(setScale(scale + 10));
        }}>
        <CirclePlusIcon width={16} height={16} className='icon-hover' />
      </button>
      <p className='text-medium zoom-text'>{scale}%</p>
    </div>
  );
}

export default Zoom;
