/* eslint-disable no-shadow */
import React, { useState } from 'react';
import './AssetsMenu.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Checkboard } from 'react-color/lib/components/common';
import { addLayer, updateLayerImage, applyImageToAllFormats } from '../../redux/slices/template';
import { deleteImage } from '../../redux/slices/images';
import Draggable from '../DragNDrop/Draggable';
import { getDefaultLayerObject } from '../../objectsTypes';
import { getImageLayer } from './AssetsHelper';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import calculateImageSize from '../../../utils/calculateImageSize';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import OptionButton from '../common/OptionButton';
import { flat } from '../../../helpers';

function Image(props) {
  const { currentLayers, image, scale } = props;
  const isAuthorized = useAuthorization();
  const selectedFormat = useSelector((state) => state.editorSession.selectedFormat);
  const editorType = useSelector((state) => state.editorSession.editorType);
  const activeLayer = useSelector((state) => state.editorSession.activeLayer);
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const deleteAssetsFeatureEnabled = isAuthorized({ featureFlag: 'delete-assets' });
  // check if there are any layers using the image
  const layersWithImage = flat(currentLayers).filter(
    (layer) => layer.settings?.source?.uuid === image.uuid
  );
  // check if layers using the image are also dco enabled
  const isDco = layersWithImage.some((layer) => layer.dynamic?.dynamic_uuid !== '');
  const imageStyle = {
    backgroundImage: `url(${image.thumbnail_url})`,
    outline:
      layersWithImage.length > 0 && isDco
        ? '1px solid purple'
        : layersWithImage.length > 0 && !isDco
        ? '1px solid #4792E2'
        : 'none'
  };

  const getCurrentActiveLayer = () => {
    let imageLayer = null;

    if (activeLayer) {
      if (currentLayers.some((layer) => layer.uuid === activeLayer)) {
        imageLayer = currentLayers.find((layer) => layer.uuid === activeLayer);
      } else if (currentLayers.some((layer) => layer.type === 'group')) {
        currentLayers.forEach((layer) => {
          if (layer.type === 'group') {
            if (layer.layers.some((layer) => layer.uuid === activeLayer)) {
              imageLayer = layer.layers.find((layer) => layer.uuid === activeLayer);
              return;
            }
          }
        });
      }
    }

    return imageLayer;
  };

  function clickHandler() {
    const currentActiveLayer = getCurrentActiveLayer();

    if (activeLayer && currentActiveLayer?.type === 'image') {
      capturePosthogData({
        event: 'update-source',
        type: 'asset',
        assetType: 'image'
      });

      dispatch(updateLayerImage(activeLayer, image, selectedFormat.id));

      if (editorType !== 'banner') {
        dispatch(applyImageToAllFormats(activeLayer, selectedFormat.id, image));
      }
    } else if (editorType === 'template') {
      // Make sure the inserted image layer is not huge compared to the format.
      const { layerWidth, layerHeight } = calculateImageSize(
        image.width,
        image.height,
        selectedFormat.object_data.width,
        selectedFormat.object_data.height
      );

      const finalImage = getDefaultLayerObject('image');
      finalImage.format.width = layerWidth;
      finalImage.format.height = layerHeight;
      finalImage.settings.source = image;

      /* if (finalImage.settings.source.content_type !== 'image/svg+xml') { */
      finalImage.settings.imageState = {
        crop: {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height
        },
        cropAspectRatio: layerWidth / layerHeight,
        targetSize: {
          width: layerWidth,
          height: layerHeight
        }
      };
      /* } */

      capturePosthogData({
        event: 'add-asset',
        type: 'asset',
        asset_type: 'image',
        dragndrop: false
      });
      dispatch(addLayer(finalImage, selectedFormat.id));
    }
  }

  const options = [
    {
      text: 'Delete image',
      func: () => {
        capturePosthogData({
          event: 'delete-asset',
          type: 'asset',
          assetType: 'image'
        });
        dispatch(deleteImage(image.uuid));
      },
      warning: true
    }
  ];

  return (
    <div
      className='image-assets-image-container'
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}>
      <Checkboard />
      {deleteAssetsFeatureEnabled && hover && (
        <OptionButton options={options} backgroundColor='#2a2a2a' />
      )}
      <Draggable
        key='abc123'
        dataItem={{ type: 'asset', name: 'image' }}
        scale={scale}
        draggedObject={getImageLayer(image, selectedFormat)}
        dropEffect='copy'
        disabled={editorType === 'banner'}>
        <div className='bg-image' style={imageStyle} title={image.title} onClick={clickHandler} />
      </Draggable>
    </div>
  );
}

export default Image;
