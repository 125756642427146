export const defaultFonts = [
  { value: "Arial,'Helvetica Neue',Helvetica,sans-serif", name: 'Arial' },
  {
    value: "'Arial Black','Arial Bold',Gadget,sans-serif",
    name: 'Arial Black'
  },
  { value: "'Arial Narrow',Arial,sans-serif", name: 'Arial Narrow' },
  {
    value: "'Arial Rounded MT Bold','Helvetica Rounded',Arial,sans-serif",
    name: 'Arial Rounded MT Bold'
  },
  {
    value: "'Avant Garde',Avantgarde,'Century Gothic',CenturyGothic,AppleGothic,sans-serif",
    name: 'Avant Garde'
  },
  {
    value: "Calibri,Candara,Segoe,'Segoe UI',Optima,Arial,sans-serif",
    name: 'Calibri'
  },
  {
    value: "Candara,Calibri,Segoe,'Segoe UI',Optima,Arial,sans-serif",
    name: 'Candara'
  },
  {
    value: "'Century Gothic',CenturyGothic,AppleGothic,sans-serif",
    name: 'Century Gothic'
  },
  {
    value: "'Franklin Gothic Medium','Franklin Gothic','ITC Franklin Gothic',Arial,sans-serif",
    name: 'Franklin Gothic Medium'
  },
  { value: "Futura,'Trebuchet MS',Arial,sans-serif", name: 'Futura' },
  { value: 'Geneva,Tahoma,Verdana,sans-serif', name: 'Geneva' },
  {
    value: "'Gill Sans','Gill Sans MT',Calibri,sans-serif",
    name: 'Gill Sans'
  },
  {
    value: "'Helvetica Neue',Helvetica,Arial,sans-serif",
    name: 'Helvetica Neue'
  },
  { value: 'Tahoma,Verdana,Segoe,sans-serif', name: 'Tahoma' },
  {
    value: "'Trebuchet MS','Lucida Grande','Lucida Sans Unicode','Lucida Sans',Tahoma,sans-serif",
    name: 'Trebuchet MS'
  },
  { value: 'Verdana,Geneva,sans-serif', name: 'Verdana' },
  { value: "Georgia,Times,'Times New Roman',serif", name: 'Georgia' },
  {
    value: "Palatino,'Palatino Linotype','Palatino LT STD','Book Antiqua',Georgia,serif",
    name: 'Palatino'
  },
  {
    value: "TimesNewRoman,'Times New Roman',Times,Baskerville,Georgia,serif",
    name: 'TimesNewRoman'
  },
  {
    value: "'Courier New',Courier,'Lucida Sans Typewriter','Lucida Typewriter',monospace",
    name: 'Courier New'
  }
];
