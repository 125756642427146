/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import { select, call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import {
  addVideo,
  loadVideos,
  loadUsedVideos,
  uploadVideos,
  removeVideo,
  deleteVideo
} from '../slices/videos';
import * as api from '../../api';
import { handleLoadBase64, addAssetLayerToCanvas } from './assetsHelper';

// selectors need to be exported for testing
export const selectTemplate = (state) => state.template;
export const selectVideos = (state) => state.videos;
export const selectCampaignId = (state) => state.editorSession.campaignId;
export const selectPublishedTemplateId = (state) => state.template.publishedTemplateId;
export const selectEditorType = (state) => state.editorSession.editorType;

export function* handleGetVideos() {
  const templateState = yield select(selectTemplate);
  const campaignId = yield select(selectCampaignId);
  const publishedTemplateId = yield select(selectPublishedTemplateId);

  if (publishedTemplateId && publishedTemplateId !== null && publishedTemplateId !== undefined) {
    try {
      const { data } = yield call(
        api.videoService.getPublicVideos,
        publishedTemplateId,
        campaignId
      );
      return data;
    } catch (e) {
      // TOOD handle error
      console.error(e);
    }
  } else {
    try {
      const { data } = yield call(
        api.videoService.getVideos,
        templateState.object_data.uuid,
        campaignId,
        templateState.client_id
      );
      return data;
    } catch (e) {
      //TODO handle error
      console.error(e);
    }
    return null;
  }
}

export function getTemplateVideos(templateState) {
  const allVideosInTemplate = [];
  for (const format of templateState.formats) {
    if (format.object_data.videos) {
      for (const formatVideo of format.object_data.videos) {
        if (!allVideosInTemplate.some((i) => i?.uuid === formatVideo?.uuid)) {
          allVideosInTemplate.push(formatVideo);
        }
      }
    }
  }
  return allVideosInTemplate;
}

export function* handleLoadThumbnailAndAddVideo(video) {
  const base64 = yield call(handleLoadBase64, video.thumbnail_url);
  if (base64) {
    try {
      if (!video.thumbnail_base64) video.thumbnail_base64 = yield base64;
      yield put(addVideo(video));
    } catch (e) {}
  }
}

export function* handleLoadUsedVideos() {
  const templateState = yield select(selectTemplate);
  const videos = yield call(handleGetVideos);
  const templateVideos = getTemplateVideos(templateState);
  // loop over used videos and load thumbnails
  for (const templateVideo of templateVideos) {
    if (videos && videos.some((video) => video.uuid === templateVideo.uuid)) {
      const video = yield videos.find((video) => video.uuid === templateVideo.uuid);
      yield call(handleLoadThumbnailAndAddVideo, video);
    }
  }
}

export function* handleLoadVideos() {
  const videosState = yield select(selectVideos);
  const videos = yield call(handleGetVideos);

  // loop over all videos and add if not already loaded
  if (videos) {
    for (const video of videos) {
      if (!(video.uuid in videosState)) {
        yield call(handleLoadThumbnailAndAddVideo, video);
      }
    }
  }
}

export function* handleUploadVideos(action) {
  const campaignId = yield select(selectCampaignId);
  const { formData, event, selectedFormat, scale } = action.payload;

  const toastId = yield toast.loading('Uploading video...', { id: 'uploading-video' });

  for (const formDataPair of formData.entries()) {
    if (formDataPair[0] === 'files') {
      const fileSize = (formDataPair[1].size / (1024 * 1024)).toFixed(2);
      if (fileSize > 4.0) {
        yield toast.error('Maximum file size is 4MB. Please minimize your video and try again.', {
          id: toastId
        });
        return;
      }
    }
  }

  try {
    const { data } = yield call(api.videoService.uploadVideos, formData, campaignId);
    for (const video of data) {
      yield call(handleLoadThumbnailAndAddVideo, video);

      if (event && selectedFormat && scale) {
        yield call(addAssetLayerToCanvas, video, event, selectedFormat, scale, 'video');
      }
    }

    yield toast.success('Video uploaded successfully.', {
      id: toastId
    });
  } catch (error) {
    yield toast.error('Could not upload video, try again.', {
      id: toastId
    });
  }
}

export function* handleDeleteVideo(action) {
  const videoId = action.payload;
  const templateState = yield select(selectTemplate);
  const editorType = yield select(selectEditorType);
  const templateVideos = getTemplateVideos(templateState);
  const isVideoInUse = templateVideos.some((videos) => videos.uuid === videoId);

  if (isVideoInUse) {
    yield toast.error("Video can't be deleted, when in use.", {
      id: 'delete-video-warning'
    });
  } else {
    try {
      let response;
      if (editorType === 'template') {
        response = yield call(api.videoService.deleteVideoFromTemplate, videoId, templateState.id);
      } else {
        const campaignId = yield select(selectCampaignId);
        response = yield call(api.videoService.deleteVideoFromCampaign, videoId, campaignId);
      }
      if (response.status >= 200 && response.status < 300) {
        yield put(removeVideo(videoId));
        yield toast.success('Video deleted successfully.', {
          id: 'delete-video'
        });
      } else {
        yield toast.error('Could not deleted video, please try again.', {
          id: 'delete-video-error'
        });
      }
    } catch (e) {
      //TODO handle error
      yield toast.error('Could not deleted video, please try again.', {
        id: 'delete-video-error'
      });
      console.error(e);
    }
  }
}

function* watchVideos() {
  yield takeLatest(loadUsedVideos, handleLoadUsedVideos);
  yield takeLatest(loadVideos, handleLoadVideos);
  yield takeEvery(uploadVideos, handleUploadVideos);
  yield takeEvery(deleteVideo, handleDeleteVideo);
}

export default watchVideos;
