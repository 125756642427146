const getDcoButtonColor = (objectList, dcoTag) => {
  let dcoButtonColor = '';

  if (dcoTag) {
    for (const object of objectList) {
      if (
        object.dynamic_key &&
        object.dynamic_uuid &&
        object.dynamic_color &&
        object.dynamic_key === dcoTag
      ) {
        dcoButtonColor = object.dynamic_color;
        break;
      }

      if (object.children) {
        dcoButtonColor = getDcoButtonColor(object.children, dcoTag);
      }
    }
  }

  return dcoButtonColor;
};

export default getDcoButtonColor;
