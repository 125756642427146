import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import SaveTimeView from '../../containers/SaveTimeViewContainer';
import TemplateTitle from './TemplateTitle';
import Shortcuts from './Shortcuts';
import UserSettings from './UserSettings';
import AnimationControl from './AnimationControl';
import { ArrowLeftIcon } from '../../assets/icons';
import './TopMenuContainer.scss';
import { UseKeyboardShortcut } from '../../../HotkeyHandler';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import BaseButton from '../common/BaseButton';
import {
  saveCreativeSet,
  publishCreativeSet,
  saveAd,
  saveTemplate
} from '../../redux/slices/template';
import { setDirtySaveState } from '../../redux/slices/editorSession';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import Zoom from './Zoom';

function TopMenuContainer(props) {
  const { topHeaderRef } = props;

  const capturePosthogData = usePosthogCapture();
  const dispatch = useDispatch();
  const { editorType, selectedFormat } = useSelector((state) => state.editorSession);
  const template = useSelector((state) => state.template);
  const { adId, creativeSetId } = useParams();

  const isAuthorized = useAuthorization();

  // eslint-disable-next-line prettier/prettier
  const appUrl = window._env_.REACT_ENV_APP_URL.replace(/\/$/, '');

  async function saveBannerAction(callback = undefined, failCallback = undefined) {
    dispatch(saveAd(adId, callback, failCallback));
  }

  async function onSaveAndPreviewBanner() {
    if (editorType === 'banner' && adId) {
      saveBannerAction(() => {
        const previewUrl = `${window._env_.REACT_ENV_PREVIEW_AD_URL}/${template.id}/${template.formats[0].id}/${adId}`;
        window.open(previewUrl, 'blank');
      });
    } else if (editorType === 'banner') {
      const toastId = toast.loading('Saving banner ...');
      const requestData = {
        format: selectedFormat.object_data,
        template: template.object_data
      };
      // sendAppMessage({
      //   action: 'save',
      //   data: requestData,
      //   toast: toastId
      // });
    }
  }

  async function onSaveCreativeSet() {
    if (editorType === 'banner' && creativeSetId) {
      dispatch(
        saveCreativeSet(creativeSetId, () => {
          dispatch(setDirtySaveState(false));
        })
      );
    }
  }

  async function onSaveAndPublishCreativeSet() {
    if (editorType === 'banner' && creativeSetId) {
      dispatch(
        publishCreativeSet(creativeSetId, () => {
          dispatch(setDirtySaveState(false));
        })
      );
    }
  }

  // Save ad
  UseKeyboardShortcut(
    ['S'],
    () => {
      onSaveCreativeSet();
    },
    {
      overrideSystem: true,
      metaKeyPressed: true,
      overrideContentEditable: true
    }
  );

  // const MemorizedUserSettings = memo(UserSettings);

  return (
    <div className='top-header' ref={topHeaderRef}>
      <div className='top-menu-left-section'>
        <div className='margin-right-pointer flex-center'>
          {editorType !== 'banner' && (
            <button
              data-cy='return-to-dashboard-button'
              type='button'
              className='dashboard-link text-medium flex-center'
              onClick={() => {
                capturePosthogData({
                  event: 'go-to-template-overview',
                  type: 'redirect',
                  menu: 'top'
                });
                if (template.group_id) {
                  window.location.pathname = `/${template.group_id}`;
                } else window.location.pathname = '/';
              }}>
              <ArrowLeftIcon className='margin-right-pointer icon-hover' />
              <p className='text-medium'>Templates</p>
            </button>
          )}
          {/* {editorType === 'banner' && (
            <button type='button' className='dashboard-link text-medium'>
              <a
                className='flex-center'
                href={`${appUrl}/campaign/${campaignId}`}
                onClick={() => {
                  dispatch(resetRightMenu());
                  capturePosthogData({
                    event: 'go-to-banner-overview',
                    type: 'redirect',
                    menu: 'top'
                  });
                }}>
                <ArrowLeftIcon className='margin-right-pointer icon-hover' />
                <p className='text-medium'>Campaign</p>
              </a>
            </button>
          )} */}
        </div>
        <TemplateTitle />
        {editorType === 'banner' && template.id && (
          <BaseButton
            text='Save'
            id='top-menu-save-ad-button'
            style={{
              backgroundColor: '#4792E2',
              color: 'white',
              width: '100px',
              marginLeft: '20px'
            }}
            onClick={() => {
              capturePosthogData({
                event: 'save',
                type: 'ad',
                menu: 'top'
              });
              onSaveCreativeSet();
            }}
          />
        )}

        {editorType === 'banner' && template.id && adId && (
          <BaseButton
            text='Save and Preview'
            id='top-menu-save-and-preview-ad-button'
            style={{
              backgroundColor: '#4792E2',
              color: 'white',
              width: '150px',
              marginLeft: '10px'
            }}
            onClick={() => {
              capturePosthogData({
                event: 'save-and-preview',
                type: 'ad',
                menu: 'top'
              });
              onSaveAndPreviewBanner();
            }}
          />
        )}

        {editorType === 'banner' && isAuthorized({ feature: 'hosting' }) && template.id && (
          <BaseButton
            text='Save and Publish'
            id='top-menu-save-and-publish-ad-button'
            style={{
              backgroundColor: '#4792E2',
              color: 'white',
              width: '150px',
              marginLeft: '10px'
            }}
            onClick={() => {
              capturePosthogData({
                event: 'save-and-publish',
                type: 'ad',
                menu: 'top'
              });
              onSaveAndPublishCreativeSet();
            }}
          />
        )}
        {editorType !== 'banner' && (
          <BaseButton
            text='Save'
            style={{
              backgroundColor: '#4792E2',
              width: '100px',
              marginLeft: '20px'
            }}
            id='save-template'
            onClick={() => {
              capturePosthogData({
                event: 'save',
                type: 'template',
                menu: 'top'
              });
              dispatch(saveTemplate());
            }}
          />
        )}
        {editorType !== 'banner' && <SaveTimeView />}
      </div>

      <div className='flex-display align-center'>
        <AnimationControl />
      </div>

      <div className='top-menu-right-section'>
        <div style={{ paddingLeft: '100px' }}>
          {/* div for undo redo buttons,
                    remove padding(it's only a placeholder)  */}
        </div>

        <Zoom />
        <Shortcuts />
        <UserSettings />
      </div>
    </div>
  );
}

TopMenuContainer.propTypes = {
  topHeaderRef: PropTypes.object.isRequired
};

export default TopMenuContainer;
