import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  EyeIcon,
  TrashcanIcon,
  MainDcoIcon,
  MainDcoIconLocked,
  MainDpaIcon,
  MainDpaIconLocked,
  HiddenEyeIcon
} from '../../assets/icons';
import { updateLayerDCO, updateLayerSettings } from '../../redux/slices/template';
import { rerenderSlate, rerenderSlateEditor, setLeftMenu } from '../../redux/slices/editorSession';
import { setDcoSelection } from '../../redux/slices/dco';
import { selectTemplateHasAutomation } from '../../redux/slices/automation';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import DynamicTypeCard from '../dynamic/DynamicTypeCard';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import useAuthorization from '../../../utils/hooks/useAuthorization';
import { useClickOutside } from '../../assets/utils';
import removeDcoTagFromTextById from '../../../utils/removeDcoTagFromTextById';
import DeleteWarning from '../common/styled/DeleteWarning';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import theme from '../../assets/theme';
import PreviewDynamicProducts from '../dynamic/PreviewDynamicProducts';
import DCOMiniTable from '../DCO/DCOMiniTable';

function DynamicMenu() {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const capturePosthogData = usePosthogCapture();
  const { editorType, menus } = useSelector((state) => state.editorSession);
  const { dcoSelection } = useSelector((state) => state.dco);
  const { leftMenu } = menus;
  const template = useSelector((state) => state.template);
  const templateHasAutomation = useSelector(selectTemplateHasAutomation);

  const [showWarning, setShowWarning] = useState('');
  const [dcoMenuOpened, setDCOMenuOpened] = useState({
    dynamic: true,
    parameter: true,
    custom: true
  });

  const deleteWarningRef = useRef();
  const deleteDynamicButtonRef = useRef();
  const deleteParameterButtonRef = useRef();
  const deleteCustomButtonRef = useRef();

  useClickOutside(deleteWarningRef, () => setShowWarning(''), [
    deleteDynamicButtonRef,
    deleteParameterButtonRef,
    deleteCustomButtonRef
  ]);

  const StyledDynamicFeedOverviewTitle = styled.p`
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
  `;

  const DCO_DATA = isAuthorized({ feature: 'dco' })
    ? {
        id: 'add-source-dco',
        title: 'DCO - Display',
        icon: <MainDcoIcon />,
        onButtonClick: () => {
          capturePosthogData({ event: 'show-add-source-large-menu', type: 'dco' });
          dispatch(
            setLeftMenu({
              ...leftMenu,
              dynamicLargeMenu: 'dco'
            })
          );
        },
        isLocked: false
      }
    : {
        id: 'add-source-dco-locked',
        title: 'DCO - Display',
        icon: <MainDcoIconLocked />,
        onLockedBoxClick: () => {
          capturePosthogData({ event: 'show-unlock-dco-popup-modal', type: 'dco' });
          window.open('https://www.zuuvi.com/book-dynamic-demo', '_blank');
        },
        isLocked: true
      };

  const DPA_DATA = isAuthorized({ feature: 'dpa' })
    ? {
        id: 'add-source-dpa',
        title: 'Catalog Ads',
        icon: <MainDpaIcon />,
        onButtonClick: () => {
          capturePosthogData({ event: 'show-add-source-large-menu', type: 'dpa' });
          dispatch(
            setLeftMenu({
              ...leftMenu,
              dynamicLargeMenu: 'dpa'
            })
          );
        },
        isLocked: false
      }
    : {
        id: 'add-source-dpa-locked',
        title: 'Catalog Ads',
        icon: <MainDpaIconLocked />,
        onLockedBoxClick: () => {
          capturePosthogData({ event: 'show-unlock-dpa-popup-modal', type: 'dpa' });
          window.open('https://www.zuuvi.com/book-dynamic-demo', '_blank');
        },
        isLocked: true
      };

  const DYNAMIC_MENU_DATA = [DCO_DATA, DPA_DATA];

  function removeDcoFromLayers(dynamicId, resetState) {
    //  Reset dco source on layers

    template.formats.forEach((format) => {
      Object.entries(format.object_data.layers).forEach(([layerId, layer]) => {
        if (JSON.stringify(layer).includes(dynamicId)) {
          if ('text' in layer.settings) {
            dispatch(
              updateLayerSettings(format.id, layerId, {
                text: removeDcoTagFromTextById(layer.settings.text, dynamicId)
              })
            );
          } else {
            dispatch(
              updateLayerDCO(layerId, {
                dynamic_key: 'None',
                dynamic_uuid: ''
              })
            );
          }
        }
      });
    });

    dispatch(rerenderSlateEditor());
    dispatch(rerenderSlate());
    dispatch(
      setDcoSelection({
        ...dcoSelection,
        ...resetState
      })
    );
  }

  const tooltipText = "Dynamic source can't be removed, while being used by an Automation";

  return (
    <div>
      {editorType !== 'banner' &&
        !dcoSelection.dynamicSourceIncluded &&
        !dcoSelection.parameterSourceIncluded &&
        !dcoSelection.customSourceIncluded && (
          <StyledSectionWrap>
            {DYNAMIC_MENU_DATA.map((dynamicType) => (
              <DynamicTypeCard
                key={dynamicType.id}
                id={dynamicType.id}
                onLockedBoxClick={dynamicType.onLockedBoxClick}
                title={dynamicType.title}
                icon={dynamicType.icon}
                onButtonClick={dynamicType.onButtonClick}
                isLocked={dynamicType.isLocked}
              />
            ))}
          </StyledSectionWrap>
        )}

      {editorType !== 'banner' &&
        dcoSelection.dynamicSource.export !== 'dpa' &&
        (dcoSelection.dynamicSourceIncluded ||
          dcoSelection.parameterSourceIncluded ||
          dcoSelection.customSourceIncluded) && (
          <StyledSectionWrap>
            <button
              type='button'
              title={
                (dcoSelection.dynamicSourceIncluded && dcoSelection.parameterSourceIncluded) ||
                dcoSelection.customSourceIncluded
                  ? 'The maximum of allowed source type is already selected, remove it to select another.'
                  : 'Add source'
              }
              id='left-menu-add-dco'
              className={
                (dcoSelection.dynamicSourceIncluded && dcoSelection.parameterSourceIncluded) ||
                dcoSelection.customSourceIncluded
                  ? 'add-dco-button-disabled'
                  : 'add-dco-button'
              }
              disabled={
                (dcoSelection.dynamicSourceIncluded && dcoSelection.parameterSourceIncluded) ||
                dcoSelection.customSourceIncluded
              }
              onClick={() => {
                capturePosthogData({
                  event: 'show-add-source-large-menu',
                  type: 'dco'
                });
                dispatch(
                  setLeftMenu({
                    ...leftMenu,
                    dynamicLargeMenu: 'dco'
                  })
                );
              }}>
              <p>Add source</p>
            </button>
          </StyledSectionWrap>
        )}
      {dcoSelection.dynamicSourceIncluded && (
        <StyledSectionWrap>
          <div className='dco-select-icon-name-wrapper'>
            {dcoSelection.dynamicSource.export !== 'dpa' ? (
              <MainDcoIcon className='dco-select-icon' />
            ) : (
              <MainDpaIcon className='dco-select-icon' />
            )}
            <StyledDynamicFeedOverviewTitle>
              {dcoSelection.dynamicSource.name}
            </StyledDynamicFeedOverviewTitle>
          </div>
          <div>
            <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
              <button
                type='button'
                id='dco-preview-dynamic-source'
                onClick={() => {
                  dispatch(
                    setDcoSelection({
                      ...dcoSelection,
                      dynamicSourcePreview: !dcoSelection.dynamicSourcePreview
                    })
                  );
                }}>
                {dcoSelection.dynamicSourcePreview ? (
                  <EyeIcon
                    height={14}
                    width={21}
                    className='eye-icon-dco'
                    fill={theme.colors.dcoPurple}
                  />
                ) : (
                  <HiddenEyeIcon height={17} width={21} />
                )}
              </button>
              {editorType !== 'banner' && (
                <button
                  type='button'
                  style={{ height: 17 }}
                  ref={deleteDynamicButtonRef}
                  id='dco-remove-dynamic-source'
                  className='dco-select-remove'
                  disabled={templateHasAutomation}
                  title={templateHasAutomation ? tooltipText : 'Removed dynamic source'}
                  onClick={(e) => {
                    showWarning === 'dynamic-source'
                      ? setShowWarning('')
                      : setShowWarning('dynamic-source');
                    e.stopPropagation();
                  }}>
                  <TrashcanIcon
                    className='trash-icon-dco'
                    fill={templateHasAutomation ? 'grey' : 'white'}
                  />
                </button>
              )}
            </div>
            {showWarning === 'dynamic-source' && (
              <DeleteWarning
                ref={deleteWarningRef}
                right='10px'
                data-cy='option-delete-warning'
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowWarning('');
                  removeDcoFromLayers(dcoSelection.dynamicSource.id, {
                    dynamicSource: {},
                    dynamicSourceFull: {},
                    dynamicSourceIncluded: false,
                    dynamicSourcePreview: false,
                    dynamicSourceSelectedRow: 0
                  });
                }}>
                <b>DELETE</b>
              </DeleteWarning>
            )}
          </div>
        </StyledSectionWrap>
      )}
      {dcoSelection.parameterSourceIncluded && (
        <StyledSectionWrap>
          <div className='dco-select-icon-name-wrapper'>
            <MainDcoIcon className='dco-select-icon' />
            <StyledDynamicFeedOverviewTitle>
              {dcoSelection.parameterSource.name}
            </StyledDynamicFeedOverviewTitle>
          </div>
          <div>
            <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
              <button
                type='button'
                id='dco-preview-parameter-source'
                onClick={() => {
                  dispatch(
                    setDcoSelection({
                      ...dcoSelection,
                      parameterSourcePreview: !dcoSelection.parameterSourcePreview
                    })
                  );
                }}>
                {dcoSelection.parameterSourcePreview ? (
                  <EyeIcon
                    height={14}
                    width={21}
                    className='eye-icon-dco'
                    fill={theme.colors.dcoPurple}
                  />
                ) : (
                  <HiddenEyeIcon height={17} width={21} />
                )}
              </button>
              {editorType !== 'banner' && (
                <button
                  type='button'
                  style={{ height: 17 }}
                  id='dco-remove-parameter-source'
                  className='dco-select-remove'
                  ref={deleteParameterButtonRef}
                  disabled={false}
                  onClick={(e) => {
                    showWarning === 'parameter-source'
                      ? setShowWarning('')
                      : setShowWarning('parameter-source');
                    e.stopPropagation();
                  }}>
                  <TrashcanIcon className='trash-icon-dco' />
                </button>
              )}
            </div>
            {showWarning === 'parameter-source' && (
              <DeleteWarning
                ref={deleteWarningRef}
                right='10px'
                data-cy='option-delete-warning'
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowWarning('');
                  removeDcoFromLayers(dcoSelection.parameterSource.id, {
                    parameterSource: {},
                    parameterSourceIncluded: false,
                    parameterSourcePreview: false
                  });
                }}>
                <b>DELETE</b>
              </DeleteWarning>
            )}
          </div>
        </StyledSectionWrap>
      )}
      {dcoSelection.customSourceIncluded && (
        <StyledSectionWrap>
          <div className='dco-select-icon-name-wrapper'>
            <MainDcoIcon className='dco-select-icon' />
            <StyledDynamicFeedOverviewTitle>
              {dcoSelection.customSource.name}
            </StyledDynamicFeedOverviewTitle>
          </div>
          <div>
            <div style={{ display: 'flex', height: 17, alignItems: 'center' }}>
              <button
                type='button'
                id='dco-preview-custom-source'
                onClick={() => {
                  dispatch(
                    setDcoSelection({
                      ...dcoSelection,
                      customSourcePreview: !dcoSelection.customSourcePreview
                    })
                  );
                }}>
                {dcoSelection.customSourcePreview ? (
                  <EyeIcon
                    height={14}
                    width={21}
                    className='eye-icon-dco'
                    fill={theme.colors.dcoPurple}
                  />
                ) : (
                  <HiddenEyeIcon height={17} width={21} />
                )}
              </button>
              {editorType !== 'banner' && (
                <button
                  type='button'
                  id='dco-remove-custom-source'
                  style={{ height: 17 }}
                  className='dco-select-remove'
                  ref={deleteCustomButtonRef}
                  onClick={(e) => {
                    showWarning === 'custom-source'
                      ? setShowWarning('')
                      : setShowWarning('custom-source');
                    e.stopPropagation();
                  }}>
                  <TrashcanIcon className='trash-icon-dco' />
                </button>
              )}
            </div>
            {showWarning === 'custom-source' && (
              <DeleteWarning
                ref={deleteWarningRef}
                right='10px'
                data-cy='option-delete-warning'
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowWarning('');
                  removeDcoFromLayers(dcoSelection.customSource.id, {
                    customSource: {},
                    customSourceIncluded: false,
                    customSourcePreview: false
                  });
                }}>
                <b>DELETE</b>
              </DeleteWarning>
            )}
          </div>
        </StyledSectionWrap>
      )}
      {/* Preview content */}
      {dcoSelection.dynamicSourceIncluded && (
        <>
          <ToggleRevealSectionButton
            id='dco-open-dynamic-source-content-preview'
            handleOnClick={() => {
              capturePosthogData({
                event: 'open-preview',
                type: 'dco',
                content_origin: 'source',
                is_open: !dcoMenuOpened.dynamic
              });
              setDCOMenuOpened({
                ...dcoMenuOpened,
                dynamic: !dcoMenuOpened.dynamic
              });
            }}
            isOpened={dcoMenuOpened.dynamic}>
            <p>Preview Your Products</p>
          </ToggleRevealSectionButton>
          <StyledSectionWrap>
            {dcoMenuOpened.dynamic && <PreviewDynamicProducts />}
          </StyledSectionWrap>
        </>
      )}
      {dcoSelection.parameterSourceIncluded && (
        <>
          <ToggleRevealSectionButton
            id='dco-open-dynamic-parameter-content-preview'
            handleOnClick={() => {
              capturePosthogData({
                event: 'open-preview',
                type: 'dco',
                content_origin: 'parameter',
                is_open: !dcoMenuOpened.parameter
              });
              setDCOMenuOpened({
                ...dcoMenuOpened,
                parameter: !dcoMenuOpened.parameter
              });
            }}
            isOpened={dcoMenuOpened.parameter}>
            <p>Preview Your Products</p>
          </ToggleRevealSectionButton>

          {dcoMenuOpened.parameter && (
            <DCOMiniTable
              data={dcoSelection.parameterSource.keys}
              size={dcoSelection.parameterSource.keys.length}
              type='parameter'
            />
          )}
        </>
      )}
      {dcoSelection.customSourceIncluded && (
        <>
          <ToggleRevealSectionButton
            id='dco-open-dynamic-custom-content-preview'
            handleOnClick={() => {
              capturePosthogData({
                event: 'open-preview',
                type: 'dco',
                content_origin: 'custom',
                is_open: !dcoMenuOpened.custom
              });
              setDCOMenuOpened({
                ...dcoMenuOpened,
                custom: !dcoMenuOpened.custom
              });
            }}
            isOpened={dcoMenuOpened.parameter}>
            <p className='text-small'>Preview Your Products</p>
          </ToggleRevealSectionButton>
          {dcoMenuOpened.custom && (
            <DCOMiniTable
              data={dcoSelection.customSource.json.keys}
              size={dcoSelection.customSource.json.keys.length}
              type='custom'
            />
          )}
        </>
      )}
    </div>
  );
}

export default DynamicMenu;
