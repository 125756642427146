import React, { useState } from 'react';
import styled from 'styled-components';

import { InformationIcon } from '../../assets/icons';
import ShortcutsMenu from './ShortcutsMenu';

const ShortcutsButton = styled.button`
  padding: 0px 10px;
`;

function Shortcuts() {
  const [hover, setHover] = useState(false);

  return (
    <>
      <ShortcutsButton
        type='button'
        id='top-bar-shortcuts-tooltip-button'
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        className='top-menu-right-section-box margin-right-pointer settings-button'>
        <InformationIcon className='margin-right-small-pointer icon-hover' />
        <p className='text-medium'>Shortcuts</p>
      </ShortcutsButton>
      <ShortcutsMenu show={hover} />
    </>
  );
}

export default Shortcuts;
