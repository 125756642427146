import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useEventListener from '../../../utils/hooks/useEventListener';

import OptionButtonStyled, { OptionItem } from './styled/OptionButton';
import { ExtraDots } from '../../assets/icons';

function OptionButton(props) {
  const { options, backgroundColor } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [showWarning, setShowWarning] = useState(null);
  const showOptionsReference = useRef();
  const optionsListReference = useRef();
  const optionsReference = useRef();

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && optionsReference && optionsReference.current) {
        if (!event.path.includes(optionsReference.current)) {
          setShowOptions(false);
          setShowWarning(null);
        }
      }
    },
    document
  );

  const Option = (props) => {
    const { text, func, warning = false } = props.option;

    const clickHandler = (event) => {
      event.preventDefault();
      event.stopPropagation();
      func();
      setShowWarning(null);
      setShowOptions(false);
    };

    const clickWarning = (event) => {
      event.preventDefault();
      event.stopPropagation();
      setShowWarning(text);
    };

    // if warning return a button with exstra warning step.
    if (warning) {
      return (
        <>
          {showWarning !== text ? (
            <OptionItem warning onClick={clickWarning}>
              {text}
            </OptionItem>
          ) : (
            <OptionItem warning onClick={clickHandler}>
              <b>ARE YOU SURE?</b>
            </OptionItem>
          )}
        </>
      );
    }

    return (
      <OptionItem type='button' onClick={clickHandler}>
        {text}
      </OptionItem>
    );
  };

  return (
    <OptionButtonStyled styles={{ backgroundColor }} ref={optionsReference}>
      <button
        type='button'
        className='options-button'
        onClick={() => {
          setShowOptions(!showOptions);
        }}
        ref={showOptionsReference}>
        <ExtraDots />
      </button>
      {showOptions && (
        <div className='options-list' ref={optionsListReference}>
          {options.map((option) => (
            <Option key={`${option.text}`} option={option} />
          ))}
        </div>
      )}
    </OptionButtonStyled>
  );
}

OptionButton.defaultProps = {
  backgroundColor: 'none'
};

OptionButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, func: PropTypes.func, warning: PropTypes.bool })
  ).isRequired,
  backgroundColor: PropTypes.string
};

export default OptionButton;
