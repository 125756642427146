/* eslint-disable import/prefer-default-export */
import { get } from './basicCalls';

const USER_SETTINGS_URL =
  window._env_.REACT_ENV_USER_SETTINGS_URL !== ''
    ? window._env_.REACT_ENV_USER_SETTINGS_URL
    : '/user';

async function getUserSettings(userId) {
  return get(`${USER_SETTINGS_URL}/get-settings?user_id=${userId}`);
}

async function updateUserSettings(userId, settingType, changeFrom, changeTo) {
  try {
    const req = await fetch(`${USER_SETTINGS_URL}/update-settings`, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        user_id: userId,
        setting_type: settingType,
        change_from: changeFrom,
        change_to: changeTo
      })
    });

    if (req.status === 200) {
      const newSettings = await req.json();
      return newSettings;
    }
  } catch (e) {
    console.log(e);
  }
}

export { getUserSettings, updateUserSettings };
