/* eslint-disable dot-notation */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const images = createSlice({
  name: 'images',
  initialState,
  reducers: {
    loadImages() {},
    uploadImages() {},
    deleteImage() {},
    addImage(state, action) {
      const image = action.payload;
      state[image.uuid] = { ...state[image.uuid], ...image };
    },
    removeImage(state, action) {
      const imageId = action.payload;
      delete state[imageId];
    },
    setImageBase64Data(state, action) {
      state[action.payload.uuid].base64 = action.payload.base64;
    },
    setOverwriteImageBase64Data(state, action) {
      const { imageId, layerId, formatId, base64, imageSizeBytes } = action.payload;

      if (!(imageId in state)) state[imageId] = {};

      if (!('overwrittenBase64' in state[imageId])) state[imageId]['overwrittenBase64'] = {};

      if (!(formatId in state[imageId]['overwrittenBase64']))
        state[imageId]['overwrittenBase64'][formatId] = {};

      if (!(layerId in state[imageId]['overwrittenBase64'][formatId]))
        state[imageId]['overwrittenBase64'][formatId][layerId] = {};

      state[imageId]['overwrittenBase64'][formatId][layerId] = {
        image: base64,
        size: imageSizeBytes
      };
    },
    duplicateFormatImageBase64Data(state, action) {
      const { format, originalFormatId } = action.payload;

      for (const imageId in state) {
        const image = state[imageId];
        if ('overwrittenBase64' in image) {
          if (originalFormatId in image.overwrittenBase64) {
            const copiedBase64 = JSON.parse(
              JSON.stringify(image.overwrittenBase64[originalFormatId])
            );
            image.overwrittenBase64[format.id] = copiedBase64;
          }
        }
      }
    },
    duplicateLayerImageBase64Data(state, action) {
      const { copied, uuid } = action.payload;
      if (copied.layer.type === 'image') {
        for (const imageId in state) {
          if (imageId === copied.layer.settings.source) {
            const image = state[imageId];
            if ('overwrittenBase64' in image) {
              for (const formatId in image.overwrittenBase64) {
                const copiedBase64 = JSON.parse(
                  JSON.stringify(image.overwrittenBase64[formatId][copied.layer.id])
                );
                image.overwrittenBase64[formatId][uuid] = copiedBase64;
              }
            }
          }
        }
      }
    },
    clearImages() {
      return initialState;
    }
  }
});

export const {
  loadImages,
  addImage,
  uploadImages,
  clearImages,
  setImageBase64Data,
  setOverwriteImageBase64Data,
  duplicateFormatImageBase64Data,
  duplicateLayerImageBase64Data,
  removeImage,
  deleteImage
} = images.actions;
export default images.reducer;
