import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';
import {
  clearAncestors,
  moveGroupIntoGroup,
  moveTemplateIntoGroup
} from '../../redux/slices/dashboard';
import ItemTypes from '../DragNDrop/ItemTypes';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

import BreadcrumbList from '../common/styled/BreadcrumbList';
import BreadcrumbItemStyled from '../common/styled/BreadcrumbItem';
import BreadcrumbItem from './BreadcrumbItem';

function Breadcrum() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const capturePosthogData = usePosthogCapture();
  const ancestors = useSelector((state) => state.dashboard.ancestors);
  const onDrop = (item, itemType, ancestor) => {
    switch (itemType.getItemType()) {
      case ItemTypes.GROUP:
        if (ancestor === null) {
          const data = {
            groupId: item.id,
            destinationGroupId: null
          };
          capturePosthogData({ event: 'move-group-into-group', type: 'group' });
          dispatch(moveGroupIntoGroup(data));
        }
        break;
      case ItemTypes.TEMPLATE:
        if (item.id) {
          const data = { templateId: item.id, groupId: ancestor ? ancestor.id : null };
          capturePosthogData({ event: 'move-template-into-group', type: 'template' });
          dispatch(moveTemplateIntoGroup(data));
        }
        break;
      default:
    }
  };

  const [{ isOver, canDrop }, dropHome] = useDrop(() => ({
    accept: [ItemTypes.GROUP, ItemTypes.TEMPLATE],
    // eslint-disable-next-line no-use-before-define
    drop: (item, itemType) => {
      onDrop(item, itemType, null);
    },
    collect: (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    })
  }));

  const isActive = isOver && canDrop && ancestors.length !== 0;

  return (
    <div
      className='content-section templates-container'
      style={{ paddingBottom: '20px', paddingTop: '20px' }}>
      <BreadcrumbList>
        <BreadcrumbItemStyled
          ref={dropHome}
          data-cy='breadcrumb-item-home'
          isActive={isActive}
          onClick={() => {
            navigate('/');
            dispatch(clearAncestors());
          }}>
          Home
        </BreadcrumbItemStyled>

        {ancestors.map((ancestor) => (
          <BreadcrumbItem key={ancestor.id} ancestor={ancestor} ancestors={ancestors} />
        ))}
      </BreadcrumbList>
    </div>
  );
}

export default Breadcrum;
