/* eslint-disable no-restricted-syntax */
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentAccountId } from '../../../redux/slices/auth';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';
import styled from 'styled-components';

import { getUserAccounts } from '../../../../utils/userAccounts';
import { useClickOutside } from '../../../assets/utils';

const SwitchClientMenuStyle = styled.div`
  position: absolute;
  left: 100%;
  bottom: calc(100% - 44px);
  z-index: 100;
  min-width: 200px;
  padding: 0px;
  margin: 0 0 0 10px;
  background: ${(props) => props.theme.colors.backgroundColorSecondary};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  max-height: 500px;
  overflow: auto;
  text-align: left;
`;

const SwitchClientMenu = (props) => {
  const { closeFunc } = props;
  const accounts = [];
  for (const account of getUserAccounts()) {
    accounts.push(
      <SwitchClientMenuButton
        key={account.account.id}
        userAccount={account}
        closeFunc={closeFunc}
      />
    );
  }
  const componentRef = useRef();
  useClickOutside(componentRef && componentRef, () => {
    closeFunc();
  });
  return (
    <SwitchClientMenuStyle ref={componentRef} data-cy='switch-client-menu'>
      {accounts}
    </SwitchClientMenuStyle>
  );
};

const SwitchClientMenuButtonStyle = styled.div`
  margin: 4px;
  padding: 8px 20px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;

  &.current {
    background: #313131;
  }

  &.disabled {
    color: #5e5e5e;
    cursor: default;
  }

  &:hover {
    background: #5e5e5e;
  }

  &:hover.disabled {
    background: transparent;
  }
`;

const SwitchClientMenuButton = ({ userAccount, closeFunc }) => {
  const capturePosthogData = usePosthogCapture();
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const selectedAccountUser = user?.accounts.find(
    (usrAcc) => usrAcc.account.id === currentAccountId
  );
  const currentAccount = selectedAccountUser.account;
  const dispatch = useDispatch();
  const selectAccount = useCallback(() => {
    if (userAccount.account.id !== currentAccount.id) {
      dispatch(setCurrentAccountId(userAccount.account.id));
      capturePosthogData({
        event: 'switch-client',
        type: 'auth',
        new_client_id: userAccount.account.id
      });
    }
    closeFunc();
  }, [userAccount]);

  const classes = [];
  if (userAccount.account.id === currentAccount.id) {
    classes.push('current');
  }

  if (!userAccount.role.permissions.includes('studio')) {
    classes.push('disabled');
  }

  return (
    <SwitchClientMenuButtonStyle
      data-cy='switch-client-menu-button'
      className={classes.join(' ')}
      style={{ ...(userAccount.account.parentAccountId ? { marginLeft: 20 } : {}) }}
      onClick={() => {
        if (userAccount.role.permissions.includes('studio')) {
          selectAccount();
        }
      }}>
      {userAccount.account.accountName}
    </SwitchClientMenuButtonStyle>
  );
};

export default SwitchClientMenu;
