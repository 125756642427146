import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as dropEffects from './Dropeffects';
import useUploadAssets from '../../../utils/hooks/useUploadAssets';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

const Dropzone = (props) => {
  const { disabled, dropEffect, onItemDropped, children } = props;
  const [isOver, setIsOver] = useState(false);
  const uploadAssets = useUploadAssets();

  const { selectedFormat } = useSelector((state) => state.editorSession);
  const { scale } = useSelector((state) => state.editorSession.studio);

  const capturePosthogData = usePosthogCapture();

  const insideStyle = {
    backgroundColor: 'rgba(0,0,0,0.5)'
  };

  const dragOver = (ev) => {
    ev.preventDefault();
    if (!disabled) ev.dataTransfer.dropEffect = dropEffect;
  };

  const drop = (event) => {
    const droppedObject = event.dataTransfer.getData('drag-object');

    if (droppedObject) {
      onItemDropped(event, JSON.parse(droppedObject));
    } else {
      event.preventDefault();

      capturePosthogData({
        event: 'upload',
        type: 'asset',
        dragndrop: true,
        drop_on_canvas: true
      });

      if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
        return;
      }

      uploadAssets(event.dataTransfer.files, event, selectedFormat, scale);
    }

    setIsOver(false);
  };

  const dropIgnore = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const dragEnter = (ev) => {
    ev.dataTransfer.dropEffect = dropEffect;
    setIsOver(true);
  };

  const dragLeave = () => setIsOver(false);

  if (disabled) {
    return (
      <div
        onDragOver={dragOver}
        onDrop={dropIgnore}
        style={{ width: '100%', height: '100%', ...(isOver ? insideStyle : {}) }}>
        {children}
      </div>
    );
  }
  return (
    <div
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      style={{ width: '100%', height: '100%', ...(isOver ? insideStyle : {}) }}>
      {children}
    </div>
  );
};

Dropzone.propTypes = {
  onItemDropped: PropTypes.func.isRequired,
  dropEffect: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  disabled: PropTypes.bool
};

Dropzone.defaultProps = {
  dropEffect: dropEffects.All,
  disabled: false
};

export default Dropzone;
