/* eslint-disable import/prefer-default-export */
import store from '../redux/store';
import { deleteCall, get, postAsFormdata, putAsFormdata } from './basicCalls';

const DCO_SERVICE_URL =
  window._env_.REACT_ENV_DCO_SERVICE_URL !== '' ? window._env_.REACT_ENV_DCO_SERVICE_URL : '/dco';

async function getOverviewData() {
  return get(`${DCO_SERVICE_URL}/dynamic`);
}

async function getDynamicContent(data) {
  // Incase it's an XML feed, it will use second version of the xml/rss parser
  data.xml_paser_version = 'v2';
  return postAsFormdata(`${DCO_SERVICE_URL}/url-json-content`, data);
}

async function parseDynamicCode(data) {
  return postAsFormdata(`${DCO_SERVICE_URL}/parse-dynamic-code`, data);
}

async function getFullData(sourceId) {
  return get(`${DCO_SERVICE_URL}/${sourceId}/full`);
}

async function createDynamicSource(data) {
  return postAsFormdata(`${DCO_SERVICE_URL}/dynamic/create`, data);
}

async function createParameterSource(data) {
  return postAsFormdata(`${DCO_SERVICE_URL}/parameter/create`, data);
}

async function updateDynamicSource(data) {
  const storeState = store.getState();
  const accountId = storeState.auth.currentAccountId;
  return putAsFormdata(`${DCO_SERVICE_URL}/dynamic/${accountId}/${data.id}`, data);
}

async function deleteDatasource(dynamicId) {
  const storeState = store.getState();
  const accountId = storeState.auth.currentAccountId;
  return deleteCall(`${DCO_SERVICE_URL}/dynamic/${accountId}/${dynamicId}`);
}

export {
  getDynamicContent,
  createDynamicSource,
  updateDynamicSource,
  createParameterSource,
  getOverviewData,
  getFullData,
  deleteDatasource,
  parseDynamicCode
};
