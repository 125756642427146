/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import useEventListener from '../../../utils/hooks/useEventListener';
import { duplicateTemplate, deleteTemplate } from '../../redux/slices/dashboard';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import ItemTypes from '../DragNDrop/ItemTypes';

import { ExtraDots } from '../../assets/icons';

function TemplateCard(props) {
  const { template } = props;
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const templateShowOptionsReference = useRef();
  const templateOptionsListReference = useRef();
  const templateOptionsReference = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const showtemplateOptions = () => {
    setShowOptions(!showOptions);
  };

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && templateOptionsReference && templateOptionsReference.current) {
        if (!event.path.includes(templateOptionsReference.current)) {
          setShowOptions(false);
          setShowDeleteOption(false);
        }
      }
    },
    document
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.TEMPLATE,
      item: template,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    []
  );

  const handleDuplicateTemplate = () => {
    capturePosthogData({ event: 'duplicate-template', type: 'template', template_id: template.id });
    dispatch(duplicateTemplate(template));
  };

  const handleDeleteTemplate = () => {
    capturePosthogData({ event: 'delete-template', type: 'template', template_id: template.id });
    dispatch(deleteTemplate(template.id));
  };

  const formatCountText =
    (template.formats && template.formats.length) === 1
      ? `1 format`
      : `${template.formats.length} formats`;

  const link = `/editor/template/${template.id}`;
  const ConditionalLink = ({ children, to, condition }) =>
    !!condition && to ? (
      <Link
        to={to}
        onClick={() =>
          capturePosthogData({
            event: 'select-template',
            type: 'template',
            template_id: template.id
          })
        }>
        {children}
      </Link>
    ) : (
      <>{children}</>
    );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, []);

  let localTimeZone = moment.utc(template.updated_at);
  localTimeZone = moment(localTimeZone).local();
  return (
    <div
      className='template card'
      data-cy='template-card'
      ref={drag}
      style={{ opacity: isDragging ? 0 : 1 }}>
      <div className='format-options' ref={templateOptionsReference}>
        <div
          className='format-options-button'
          onClick={() => {
            setShowDeleteOption(false);
            showtemplateOptions();
          }}
          ref={templateShowOptionsReference}>
          <ExtraDots />
        </div>
        {showOptions && (
          <div className='format-options-list' ref={templateOptionsListReference}>
            <div
              data-cy='option-duplicate'
              className='format-option-item'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleDuplicateTemplate();
                setShowOptions(false);
              }}>
              <p> Duplicate template</p>
            </div>
            {!showDeleteOption ? (
              <div
                data-cy='option-delete'
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowDeleteOption(true);
                }}>
                <p> Delete template</p>
              </div>
            ) : (
              <div
                data-cy='option-delete-warning'
                className='format-option-item delete warning'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleDeleteTemplate();
                  setShowOptions(false);
                  setShowDeleteOption(false);
                }}>
                <b>ARE YOU SURE?</b>
              </div>
            )}
          </div>
        )}
      </div>
      <ConditionalLink to={link} condition={!showOptions}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            overflowWrap: 'break-word'
          }}
          className='template-wrapper'>
          {/* <div className="template-preview"></div> */}
          <div className='template-title'>{template.title}</div>
          <div className='template-format-count'>{formatCountText}</div>
          <div className='template-format-count'>
            Last updated {localTimeZone.format('D MMM YYYY HH:mm')}
          </div>
          {/* <div className="template-actions"></div> */}
        </div>
      </ConditionalLink>
    </div>
  );
}

TemplateCard.propTypes = {
  template: PropTypes.object.isRequired
};

export default TemplateCard;
