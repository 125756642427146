import React, { useState, useRef } from 'react';
import useEventListener from '../../../../utils/hooks/useEventListener';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';

import { AddGroupIcon } from '../../../assets/icons';
import MediumIconButton from '../../common/MediumIconButton';
import CreateGroupForm from './CreateGroupForm';

const AddGroupButton = () => {
  const [addGroupFormShown, setAddGroupFormShown] = useState(false);
  const capturePosthogData = usePosthogCapture();
  const addGroupRef = useRef();

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && addGroupRef && addGroupRef.current) {
        if (!event.path.includes(addGroupRef.current)) {
          setAddGroupFormShown(false);
        }
      }
    },
    document
  );

  const createGroupButtonData = {
    title: 'Group',
    tooltip: 'Create group',
    icon: (
      <AddGroupIcon
        fill={addGroupFormShown ? '#4792E2' : 'white'}
        className='icon-hover'
        width='26px'
        height='19px'
      />
    ),
    id: 'show-create-template-group'
  };

  return (
    <div ref={addGroupRef} style={{ textAlign: 'center' }}>
      <MediumIconButton
        text={createGroupButtonData.title}
        tooltip={createGroupButtonData.tooltip}
        id={createGroupButtonData.id}
        icon={createGroupButtonData.icon}
        key={createGroupButtonData.id}
        onHandleClick={() => {
          capturePosthogData({ event: 'show-create-template-group-form', type: 'group' });
          setAddGroupFormShown(!addGroupFormShown);
        }}
      />
      {addGroupFormShown && <CreateGroupForm closeFunc={() => setAddGroupFormShown(false)} />}
    </div>
  );
};

export default AddGroupButton;
