import { useDispatch, useSelector } from 'react-redux';
import {
  StyledContentContainer,
  StyledIconBox,
  StyledNameBox,
  StyledButton
} from './styled/StyledQuickExports';
import {
  LeftSmallArrowIcon,
  AdformIcon,
  AdnamiIcon,
  AdtechIcon,
  BidtheatreIcon,
  DeltaProjectsIcon,
  MainDpaIcon,
  GoogleAdsIcon,
  GMPIcon,
  IframeIcon,
  MP4Icon,
  PdfIcon,
  ImagesIcon,
  XandrIcon
} from '../../assets/icons';
import SelectedQuickExportType from './SelectedQuickExportType';
import {
  resetSelectedQuickExportType,
  exportTemplate,
  setIsBeingExported
} from '../../redux/slices/quickExport';

const SelectedQuickExportTypeContainer = () => {
  const dispatch = useDispatch();
  const templateId = useSelector((state) => state.template.id);

  const selectedQuickExportData = [
    {
      id: 'adform',
      icon: <AdformIcon height='19' />,
      options: [
        {
          id: 'adformExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export adform');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-adform',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'adnami',
      icon: <AdnamiIcon width='24' />,
      options: [
        {
          id: 'adnamiExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export adnami');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-adform',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'adtech',
      icon: <AdtechIcon width='24' />,
      options: [
        {
          id: 'adtechExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export adtech');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-adtech',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'bidtheatre',
      icon: <BidtheatreIcon height='18' style={{ marginTop: 2 }} />,
      options: [
        {
          id: 'bidtheatreExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export bidtheatre');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-bidtheatre',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'deltaProjects',
      icon: <DeltaProjectsIcon height='18' />,
      options: [
        {
          id: 'deltaProjectsExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export deltaProjects');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-deltaProjects',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'dpa',
      icon: <MainDpaIcon height='18' lightningFill='white' style={{ marginTop: 2 }} />,
      options: [
        {
          id: 'dpaExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export dpa');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-dpa',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'googleAds',
      icon: <GoogleAdsIcon height='18' />,
      options: [
        {
          id: 'googleAdsExport',
          title: 'Download creatives',
          subtitle:
            'You creative is ready for Google Ads. Make sure your creatives must be no more than 150kb.',
          buttonText: 'Download',
          onButtonClick: () => dispatch(exportTemplate({ templateId, exports: ['GOOGLE_ADS'] })),
          buttonId: 'quick-export-google-ads',
          simple: true
        }
      ]
    },
    {
      id: 'gmp',
      icon: <GMPIcon height='18' />,
      options: [
        {
          id: 'gmpExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export gmp');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-gmp',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'iframe',
      icon: <IframeIcon width='20' />,
      options: [
        {
          id: 'iframeExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export iframe');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-iframe',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'jpg',
      icon: <ImagesIcon height='16' />,
      options: [
        {
          id: 'jpgExport',
          title: 'Choose a frame to export',
          subtitle:
            'Use the slider or the Start/Pause buttons below to choose the desired frame to export.',
          buttonText: 'Download',
          onButtonClick: () => {
            dispatch(setIsBeingExported(true));
            dispatch(
              exportTemplate({
                templateId,
                exports: ['JPG']
              })
            );
          },
          buttonId: 'quick-export-jpg',
          simple: false
        }
      ]
    },
    {
      id: 'mp4',
      icon: <MP4Icon height='16' />,
      options: [
        {
          id: 'mp4Export',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export mp4');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-mp4',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'pdf',
      icon: <PdfIcon height='18' />,
      options: [
        {
          id: 'pdfExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export pdf');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-pdf',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'png',
      icon: <ImagesIcon height='16' />,
      options: [
        {
          id: 'pngExport',
          title: 'Choose a frame to export',
          subtitle:
            'Use the slider or the Start/Pause buttons below to choose the desired frame to export.',
          onButtonClick: () => {
            dispatch(setIsBeingExported(true));
            dispatch(
              exportTemplate({
                templateId,
                exports: ['PNG']
              })
            );
          },
          buttonText: 'Download',
          buttonId: 'quick-export-png',
          bulkOption: true,
          simple: false
        }
      ]
    },
    {
      id: 'xandr',
      icon: <XandrIcon height='18' />,
      options: [
        {
          id: 'xandrExport',
          title: 'Download creatives',
          subtitle: 'some subtitle',
          onButtonClick: () => {
            // TODO: remove placeholder
            console.log('export xandr');
          },
          buttonText: 'Download',
          buttonId: 'quick-export-xandr',
          bulkOption: true,
          simple: false
        }
      ]
    }
  ];

  const { id, title } = useSelector((state) => state.quickExport.selectedQuickExportType);
  const getActiveOption = () => {
    const selectedOption = selectedQuickExportData.find((element) => element.id === id);
    return selectedOption;
  };
  return (
    <>
      <StyledContentContainer padding='10px 30px 10px 30px' lineBottom>
        <StyledButton
          enabled
          small={id !== 'gmp'}
          medium={id === 'gmp'}
          onClick={() => dispatch(resetSelectedQuickExportType())}
          data-cy='dpa-invalid-rows-checkbox'>
          <LeftSmallArrowIcon style={{ marginRight: 12, height: 9, width: 5, cursor: 'pointer' }} />
          <StyledIconBox className='export-icon' small>
            {getActiveOption()?.icon}
          </StyledIconBox>
          <StyledNameBox>
            <p>{title}</p>
          </StyledNameBox>
        </StyledButton>
      </StyledContentContainer>
      <StyledContentContainer regularPadding>
        <StyledContentContainer border padding='15px'>
          {getActiveOption()?.options?.map((option) => (
            <SelectedQuickExportType
              id={option.id}
              key={option.id}
              title={option.title}
              subtitle={option.subtitle}
              buttonText={option.buttonText}
              onButtonClick={option.onButtonClick}
              buttonId={option.buttonId}
              bulkOption={option.bulkOption}
              simple={option.simple}
            />
          ))}
        </StyledContentContainer>
      </StyledContentContainer>
    </>
  );
};

export default SelectedQuickExportTypeContainer;
