/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-use-before-define */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';

import '../../../../node_modules/react-grid-layout/css/styles.css';
import '../../../../node_modules/react-resizable/css/styles.css';

import './Grid.scss';
import { useDispatch, useSelector } from 'react-redux';
import AdFrame from './AdFrame';
import { setItemExpanded } from '../../redux/slices/preview';

export default function Grid(props) {
  const dispatch = useDispatch();
  const { iframeRefs, creativesIds } = props;
  const items = useSelector((state) => state.preview.items);

  const getFilteredItems = () => {
    if (creativesIds) {
      const itemsIdsArr = creativesIds.split('_&_');
      const arr = items.filter((obj) => itemsIdsArr.includes(obj.key));
      return arr;
    }
    return items;
  };

  const windowDimensions = useSelector((state) => state.preview.windowDimensions);
  const isMobile = windowDimensions.width <= 700;
  const itemsExpanded = useSelector((state) => state.preview.itemsExpanded);
  const gridMaxSize = useSelector((state) => state.preview.gridMaxSize);
  const gridWidth = windowDimensions.width < gridMaxSize ? windowDimensions.width : gridMaxSize;

  iframeRefs.current = items.map((item, index) => (iframeRefs.current[index] = useRef(null)));

  function isItemExpanded(key) {
    return itemsExpanded.includes(key);
  }
  function getBannerTitle(item) {
    if (item.title !== undefined) {
      if (`${item.width}x${item.height}` === item.title) {
        return item.title;
      }
      return `${item.width}x${item.height} - ${item.title}`;
    }
    return `${item.width}x${item.height}`;
  }
  return (
    <GridLayout
      className='layout'
      cols={gridWidth}
      layout={items.map((item) => ({
        i: item.key,
        x: item.x,
        y: item.y,
        w: isItemExpanded(item.key) ? item.width + 20 : item.width * item.scale + 20,
        h: isItemExpanded(item.key)
          ? item.height / 10 - item.height * 0.01 + 3
          : (item.height * item.scale) / 10 - item.height * item.scale * 0.01 + 3
      }))}
      isResizable={false}
      isDraggable={!isMobile}
      rowHeight={1.5}
      width={gridWidth}
      style={{
        width: `${gridWidth + (isMobile ? 0 : 20)}px`,
        paddingBottom: '50px',
        paddingLeft: isMobile ? '10px' : '0px'
        // paddingRight: '20px'
      }}>
      {getFilteredItems().map((item, index) => (
        <div key={item.key} className='grid-wrapper'>
          <div className='ad'>
            <span className='banner-size-text'>{getBannerTitle(item)}</span>
            {item.scale !== 1 && (
              <>
                <span className='banner-size-text'>{`(${
                  isItemExpanded(item.key) ? '1.0' : item.scale.toFixed(1)
                }x)`}</span>
                {!isMobile && (
                  <button
                    type='button'
                    className='resize-button'
                    onClick={() =>
                      dispatch(
                        setItemExpanded({ key: item.key, expand: !isItemExpanded(item.key) })
                      )
                    }>
                    {isItemExpanded(item.key) ? 'Collapse' : 'Expand'}
                  </button>
                )}
              </>
            )}

            <AdFrame item={item} iframeRefs={iframeRefs.current[index]} />
          </div>
        </div>
      ))}
    </GridLayout>
  );
}

Grid.propTypes = {
  iframeRefs: PropTypes.shape({
    current: PropTypes.arrayOf(PropTypes.object)
  })
};

Grid.defaultProps = {
  iframeRefs: {}
};
