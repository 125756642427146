const removeDcoTagFromTextById = (objectList, dynamicId) => {
  const newObjectList = JSON.parse(JSON.stringify(objectList));

  for (let i = 0; i < newObjectList.length; i++) {
    if (newObjectList[i].dynamic_key && newObjectList[i].dynamic_uuid) {
      if (newObjectList[i].dynamic_uuid === dynamicId) {
        const {
          dynamic_uuid: removedUuid,
          dynamic_key: removedKey,
          dynamic_color: removedColor,
          ...newObj
        } = newObjectList[i];
        newObjectList[i] = newObj;
      }
    }
    if (newObjectList[i].children) {
      newObjectList[i].children = removeDcoTagFromTextById(newObjectList[i].children, dynamicId);
    }
  }

  return newObjectList;
};

export default removeDcoTagFromTextById;
