import styled from 'styled-components';

export default styled.span`
  display: flex;
  margin: 0 5px;
  font-size: 14px;
  cursor: pointer;
  :not(:last-child):hover {
    color: ${(props) => props.theme.colors.zuuviBlue};
  }

  color: ${(props) => {
    if (props.isActive) {
      return props.theme.colors.zuuviBlue;
    }
  }};

  :last-child {
    font-weight: bold;
  }

  flex-direction: row;
  justify-content: flex-start;
`;
