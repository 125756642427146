import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { StyledInputLabelWrapper } from '../common/styled/AttributeInputs';
import BaseButton from '../common/BaseButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { setQuickExportBigMenuOpened } from '../../redux/slices/quickExport';

function QuickExportButton() {
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();

  const onSetQuickExportOpenMenu = async () => {
    capturePosthogData({ event: 'quick-export-open-menu', type: 'quick-export', menu: 'right' });
    dispatch(setQuickExportBigMenuOpened(true));
  };

  return (
    <StyledInputLabelWrapper>
      <p>Quick export:</p>
      <BaseButton
        id='quick-export-open-menu'
        text='Export'
        style={{ backgroundColor: '#4792E2', color: 'white', marginTop: 7 }}
        onClick={onSetQuickExportOpenMenu}
      />
    </StyledInputLabelWrapper>
  );
}

export default QuickExportButton;
