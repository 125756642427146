import React from 'react';
import { useSelector } from 'react-redux';
import BaseButton from '../common/BaseButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { StyledInputLabelWrapper } from '../common/styled/AttributeInputs';

function PreviewTemplateButton() {
  const template = useSelector((state) => state.template);

  const isDisabled = template === undefined || template.id === undefined;
  const capturePosthogData = usePosthogCapture();

  const handlePreviewTemplate = async () => {
    capturePosthogData({ event: 'preview-template', type: 'template', menu: 'right' });
    window.open(`/preview/template/${template.id}`, '_blank');
  };

  return (
    <StyledInputLabelWrapper removedBottomMargin>
      <p>Create shareable preview link:</p>
      <BaseButton
        id='preview-template'
        secondary
        text='Preview Template'
        style={{ color: 'white', marginTop: 7 }}
        disabled={isDisabled}
        onClick={handlePreviewTemplate}
      />
    </StyledInputLabelWrapper>
  );
}

export default PreviewTemplateButton;
