import styled from 'styled-components';

export default styled.div`
  position: absolute;
  width: 82px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  line-height: 13px;
  margin-top: 2px;
  right: ${(props) => (props.right ? props.right : '5px')};
  border-radius: 4px;
  z-index: 20;
  background-color: #df6d4e;
  cursor: pointer;
`;
