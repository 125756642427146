import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const videos = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    loadVideos() {},
    loadUsedVideos() {},
    uploadVideos() {},
    deleteVideo() {},
    addVideo(state, action) {
      const video = action.payload;
      state[video.uuid] = video;
    },
    removeVideo(state, action) {
      const videoId = action.payload;
      delete state[videoId];
    },
    clearVideos() {
      return initialState;
    }
  }
});

export const {
  addVideo,
  clearVideos,
  loadVideos,
  loadUsedVideos,
  uploadVideos,
  deleteVideo,
  removeVideo
} = videos.actions;
export default videos.reducer;
