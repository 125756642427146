import React from 'react';
import PropTypes from 'prop-types';
import './AssetsMenu.scss';
import { connect } from 'react-redux';

import Image from './Image';
import Video from './Video';

function AssetsList(props) {
  const { images, videos, activeTab, activeLayer, currentLayers } = props;

  const sortFunc = (a, b) => {
    const usedA = currentLayers.some(
      (layer) =>
        layer.settings.source && layer.settings.source.uuid && layer.settings.source.uuid === a.uuid
    );
    const usedB = currentLayers.some(
      (layer) =>
        layer.settings.source && layer.settings.source.uuid && layer.settings.source.uuid === b.uuid
    );

    if (usedA === usedB) {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }
    if (usedA > usedB) {
      return -1;
    }
    if (usedA < usedB) {
      return 1;
    }

    return 0;
  };

  images.sort(sortFunc);
  videos.sort(sortFunc);

  let dataToShow = [];
  if (activeTab === 'vectors') {
    images.forEach((image) => {
      if (image.content_type === 'image/svg+xml') {
        dataToShow = [
          ...dataToShow,
          <Image key={image.uuid} image={image} currentLayers={currentLayers} />
        ];
      }
    });
  } else if (activeTab === 'images') {
    images.forEach((image) => {
      if (image.content_type !== 'image/svg+xml') {
        dataToShow = [
          ...dataToShow,
          <Image key={image.uuid} image={image} currentLayers={currentLayers} />
        ];
      }
    });
  } else if (activeTab === 'video') {
    videos.forEach((video) => {
      dataToShow = [
        ...dataToShow,
        <Video
          key={video.uuid}
          video={video}
          activeLayer={activeLayer}
          currentLayers={currentLayers}
        />
      ];
    });
  }

  return <div className='image-assets-images-wrapper'>{dataToShow}</div>;
}

AssetsList.propTypes = {
  template: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  videos: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeTab: PropTypes.string.isRequired,
  currentLayers: PropTypes.arrayOf(PropTypes.object).isRequired
};

AssetsList = connect((state) => {
  const images = [];
  for (const i in state.images) {
    images.push(state.images[i]);
  }
  const videos = [];
  for (const i in state.videos) {
    videos.push(state.videos[i]);
  }
  return {
    template: state.template,
    images,
    videos
  };
})(AssetsList);

export default AssetsList;
