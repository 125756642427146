import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import InputNumber from '../common/InputNumber';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import {
  StyledInputWrapperBig,
  StyledInputLabelWrapper,
  StyledOpacityInputWrapper,
  StyledSpan
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import { RadiusIcon, RotationIcon } from '../../assets/icons';
import ColorPicker from '../common/ColorPicker';

function BasicAttributes(props) {
  const { layer, onUpdateLayerFormat, shiftPressed } = props;
  const capturePosthogData = usePosthogCapture();

  const [basicOpen, setBasicOpen] = useState(true);

  const BASIC_INPUTS_DATA = [
    {
      id: 'width',
      inputText: 'W',
      posthogInputId: 'right-submenu-edit-width',
      glyph: 'px'
    },
    {
      id: 'height',
      inputText: 'H',
      posthogInputId: 'right-submenu-edit-height',
      glyph: 'px'
    },
    {
      id: 'x',
      inputText: 'X',
      posthogInputId: 'right-submenu-edit-X',
      glyph: 'px'
    },
    {
      id: 'y',
      inputText: 'Y',
      posthogInputId: 'right-submenu-edit-Y',
      glyph: ' px'
    },
    {
      id: 'rotation',
      inputText: <RotationIcon />,
      posthogInputId: 'right-submenu-edit-rotation',
      removedBottomMargin: true,
      glyph: '°'
    },
    {
      id: 'radius',
      inputText: <RadiusIcon />,
      posthogInputId: 'right-submenu-edit-radius',
      removedBottomMargin: true,
      glyph: 'px'
    }
  ];
  const applyOpacityStep = (previousValue, newValue) => {
    const bigStep = 10;
    const smallStep = 1;
    previousValue = parseInt(previousValue);
    newValue = newValue === '' ? 0 : parseInt(newValue);

    if (previousValue < newValue) {
      newValue = shiftPressed ? newValue + bigStep - smallStep : newValue;
    } else {
      newValue = shiftPressed ? newValue - bigStep + smallStep : newValue;
    }

    // make sure we dont exceed min and max values:
    newValue = newValue < 0 ? 0 : newValue;
    newValue = newValue > 100 ? 100 : newValue;

    return newValue;
  };

  const handleColorPickerDataChange = (data) => {
    const currentColorType = layer.format.background_color?.colorType || 'solid';
    if (
      data.colorType &&
      data.colorType !== 'solid' &&
      currentColorType === 'solid' &&
      layer.animations.some((element) => element.type === 'background')
    ) {
      toast('Background animation is not compatible with gradient background color', {
        duration: 6000,
        icon: '⚠️'
      });
    }
    // in group, text, image and video the background color doesn't exist so we create it
    if (!layer.format.background_color || !('angle' in layer.format.background_color)) {
      onUpdateLayerFormat(layer.uuid, {
        background_color: {
          ...layer.format.background_color,
          ...data,
          angle: 90
        }
      });
    } else {
      onUpdateLayerFormat(layer.uuid, {
        background_color: { ...layer.format.background_color, ...data }
      });
    }
  };
  return (
    <>
      <ToggleRevealSectionButton
        removedBorderTop
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'edit',
            menu: 'right',
            menu_type: 'edit',
            submenu: 'basic-attributes',
            is_open: !basicOpen
          });
          setBasicOpen(!basicOpen);
        }}
        isOpened={basicOpen}
        id='right-submenu-edit-open-close-basic-attributes-section'>
        <p className='color-white'>Transform</p>
      </ToggleRevealSectionButton>
      {basicOpen && (
        <StyledSectionWrap key={layer.uuid}>
          <StyledInputLabelWrapper>
            {' '}
            <p>Opacity</p>
            <StyledOpacityInputWrapper>
              <input
                type='range'
                value={layer.format.opacity.toFixed(0)}
                step='1'
                id='right-submenu-edit-opacity'
                onChange={(e) => {
                  onUpdateLayerFormat(layer.uuid, {
                    opacity: applyOpacityStep(layer.format.opacity, e.target.value)
                  });
                }}
              />
              <div>
                <p>{layer.format.opacity.toFixed(0)}%</p>
              </div>
            </StyledOpacityInputWrapper>
          </StyledInputLabelWrapper>
          {BASIC_INPUTS_DATA.map((input) => {
            const isDisabled =
              (layer.type === 'group' ||
                (layer.type === 'image' &&
                  layer.settings.source.content_type !== 'image/svg+xml')) &&
              (input.id === 'height' || input.id === 'width');
            return (
              <StyledInputWrapperBig key={input.id} disabled={!!isDisabled}>
                {input.inputText}
                {input.id === 'width' || input.id === 'height' || input.id === 'radius' ? (
                  <InputNumber
                    key={input.id}
                    id={input.posthogInputId}
                    disabled={!!isDisabled}
                    value={layer.format[`${input.id}`]}
                    min={0}
                    onUpdate={(value) => {
                      onUpdateLayerFormat(layer.uuid, { [input.id]: value });
                    }}
                  />
                ) : (
                  <InputNumber
                    key={input.id}
                    id={input.posthogInputId}
                    className={`attributes-input ${input.id}`}
                    value={layer.format[`${input.id}`]}
                    onUpdate={(value) => {
                      onUpdateLayerFormat(layer.uuid, { [input.id]: value });
                    }}
                  />
                )}{' '}
                <StyledSpan>{input.glyph}</StyledSpan>
              </StyledInputWrapperBig>
            );
          })}

          {layer.type !== 'image' && (
            <ColorPicker
              color={layer.format.background_color}
              onChange={handleColorPickerDataChange}
            />
          )}
        </StyledSectionWrap>
      )}
    </>
  );
}
BasicAttributes.propTypes = {
  layer: PropTypes.object.isRequired,
  shiftPressed: PropTypes.bool.isRequired,
  onUpdateLayerFormat: PropTypes.func.isRequired
};

export default BasicAttributes;
