/* eslint-disable import/prefer-default-export */
import { get } from './basicCalls';

async function templateHasAutomationByTemplateId(templateId) {
  try {
    return get(
      `${window._env_.REACT_ENV_AUTOMATION_SERVICE_URL}/template-has-automation/${templateId}`
    );
  } catch (e) {
    console.log(e);
  }
  return null;
}

export { templateHasAutomationByTemplateId };
