import ZuuviLogo from '../assets/zuuvi_logo_big.png';

export const NoStudioPermission = () => (
  <div
    style={{
      backgroundColor: '#1b1b1b',
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      left: 0
    }}>
    <div style={{ position: 'absolute', top: '35%', width: '100%' }}>
      <center>
        <img
          style={{
            height: '100px'
          }}
          alt='Zuuvi loader icon'
          src={ZuuviLogo}
        />
        <br />
        <br />
        <span
          style={{
            fontSize: '30px',
            fontWeight: '700',
            color: '#4a90e2'
          }}>
          You don't have the permission to access Studio
        </span>
        <br />
        <br />
        <br />
        <span style={{ color: 'white' }}>
          To get access please contact the owner of your account or go back to the{' '}
          <a style={{ color: 'white' }} href={window._env_.REACT_ENV_APP_URL}>
            Creative Manager
          </a>
        </span>
        <br />
        <br />
      </center>
    </div>
  </div>
);
