import { connect } from 'react-redux';
import SaveTimeView from '../components/SaveTimeView';

const mapStateToProps = (state) => ({
  lastTemplateSave: state.template.updated_at ? new Date(state.template.updated_at) : false,
  isTemplateSaving: state.autosave.isSaving,
  autosaveHasError: state.autosave.hasError
});

export default connect(mapStateToProps)(SaveTimeView);
