import React, { useState, useEffect } from 'react';
import { ChromePicker } from 'react-color';
import { Editor } from 'slate';
import styled from 'styled-components';
import { ReactEditor, useSlate } from 'slate-react';
import { isValidHex } from 'react-color/lib/helpers/color';
import useEventListener from '../../../utils/hooks/useEventListener';
import convertRGBAToHex from '../../../utils/convertRGBAToHex';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

const Wrapper = styled.div`
  position: absolute;
  top: 42px;
  left: ${(props) =>
    props.isEditorMenu
      ? props.format === 'bgColor'
        ? '-70px'
        : '-100px'
      : props.format === 'bgColor'
      ? '126px'
      : '96px'};
  z-index: 10000 !important;
  border-radius: 4px;
`;

function TextColorPicker(props) {
  const { format, isEditorMenu } = props;
  const capturePosthogData = usePosthogCapture();
  const editor = useSlate();
  // set state for color picker display purposes only (when used for slate it makes it be step behind)
  const [selectedColor, setSelectedColor] = useState();

  const getHexColorValue = (colorTgba) => {
    const { r, g, b, a } = colorTgba;
    return convertRGBAToHex(`rgba(${r}, ${g}, ${b}, ${a})`);
  };

  useEffect(() => {
    setSelectedColor(
      format === 'color' ? Editor?.marks(editor)?.color : Editor?.marks(editor)?.bgColor
    );
  }, [format]);

  const addMarkData = (color, event) => {
    capturePosthogData({
      event: 'add-text-inline-style',
      type: format,
      color
    });
    Editor.addMark(editor, format, color);
    ReactEditor.focus(editor);
  };

  const handleChangeComplete = (color, event) => {
    // if the user is not typing to input
    if (event.type !== 'change') {
      addMarkData(getHexColorValue(color.rgb), event);
    }
    // if the user paster hex valuen that is valid
    if (
      event.type === 'change' &&
      event.nativeEvent.inputType === 'insertFromPaste' &&
      isValidHex(event.target.value)
    ) {
      addMarkData(getHexColorValue(color.rgb), event);
    }
  };
  // when user types in hex or rgb value he has to press tab to push it to slate
  useEventListener('keydown', (e) => {
    if (e.keyCode === 9) {
      Editor.addMark(editor, format, selectedColor);
      ReactEditor.focus(editor);
    }
  });
  const hangleChange = (color, event) => {
    setSelectedColor(getHexColorValue(color.rgb));
  };

  return (
    <Wrapper format={format} isEditorMenu={isEditorMenu}>
      <ChromePicker
        color={selectedColor}
        onChange={hangleChange}
        onChangeComplete={handleChangeComplete}
      />
    </Wrapper>
  );
}

export default TextColorPicker;
