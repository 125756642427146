import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { uploadImages } from '../../studioEditor/redux/slices/images';
import { uploadVideos } from '../../studioEditor/redux/slices/videos';

const useUploadAssets = () => {
  const templateId = useSelector((state) => state.template.object_data.uuid);
  const dispatch = useDispatch();

  const createFormData = (assets) => {
    const formData = new FormData();
    for (let i = 0; i < assets.length; i += 1) {
      formData.append('files', assets[i]);
      formData.append('template_id', templateId);
    }
    return formData;
  };

  const uploadAssets = useCallback((files, event = null, selectedFormat = null, scale = null) => {
    const images = [...files].filter((file) => file.type.startsWith('image/'));
    const videos = [...files].filter((file) => file.type.startsWith('video/mp4'));

    const containsIllegalVideo = [...files].some((file) => {
      if (file.type.startsWith('video/')) {
        return !file.type.startsWith('video/mp4');
      }

      return false;
    });

    if (containsIllegalVideo) {
      toast.error('Only MP4 format is supported as video.', {
        id: 'only-mp4-as-video'
      });
    }

    if (images && images.length > 0) {
      const formData = createFormData(images);
      dispatch(uploadImages({ formData, event, selectedFormat, scale }));
    }

    if (videos && videos.length > 0) {
      const formData = createFormData(videos);
      dispatch(uploadVideos({ formData, event, selectedFormat, scale }));
    }
  });

  return uploadAssets;
};

export default useUploadAssets;
