import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Pagination from './Pagination';
import { StyledInputLabelWrapper, StyledInputWrapperBig } from '../common/styled/AttributeInputs';
import { useDispatch, useSelector } from 'react-redux';
import { setDcoSelection } from '../../redux/slices/dco';

const StyledExtendedInputWrapperBig = styled(StyledInputWrapperBig)`
  background: #313131;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledLabel = styled.p`
  font-weight: 700;
  margin-bottom: 5px !important;
`;

const StyledValue = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  color: #c4c4c4;
`;

export default function PreviewDynamicProducts() {
  const dispatch = useDispatch();
  const { dcoSelection } = useSelector((state) => state.dco);
  const currentDynamicData = dcoSelection.dynamicSourceFull
    ? dcoSelection.dynamicSourceFull[dcoSelection.dynamicSourceSelectedRow]
    : [];

  const getCorrectKey = (keyText) => {
    let keyToReturn = <span>{keyText}</span>;

    if (dcoSelection.dynamicSource && dcoSelection.dynamicSource.addon === 'google-studio') {
      const splitKey = keyText.split(/\[\d\]\./i);

      if (splitKey.length > 1) {
        const feed = splitKey[0];
        const key = splitKey[1];
        keyToReturn = (
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              overflow: 'hidden',
              gap: '3px'
            }}>
            <span>{key}</span>
            <span style={{ fontSize: '10px', color: 'grey' }}>{feed}</span>
          </span>
        );
      }
    }

    return keyToReturn;
  };

  return (
    <div style={{ width: '235px' }}>
      {dcoSelection.dynamicSource && dcoSelection.dynamicSource.addon === 'google-studio' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            marginBottom: '12px',
            gap: '5px'
          }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span>Google Studio Profile ID:</span>
            <span style={{ fontWeight: 'bold', maxWidth: '190px', textOverflow: 'ellipsis' }}>
              {dcoSelection.dynamicSource.keys.google_studio_profile_id ?? ''}
            </span>
          </div>
        </div>
      ) : (
        <Pagination
          currentPage={dcoSelection.dynamicSourceSelectedRow}
          totalCount={(dcoSelection.dynamicSourceFull || ['']).length}
          onPageChange={(page) =>
            dispatch(
              setDcoSelection({
                ...dcoSelection,
                dynamicSourceSelectedRow: page
              })
            )
          }
        />
      )}

      {Object.entries(currentDynamicData).map(([key, value]) => (
        <StyledInputLabelWrapper key={key}>
          <StyledLabel>{getCorrectKey(key)}</StyledLabel>
          <StyledExtendedInputWrapperBig fullWidth>
            <StyledValue>{value}</StyledValue>
          </StyledExtendedInputWrapperBig>
        </StyledInputLabelWrapper>
      ))}
    </div>
  );
}
