/* eslint-disable import/no-cycle */
import { call, put } from 'redux-saga/effects';
import * as api from '../../api';
import { addLayer } from '../slices/template';
import { getImageLayer, getVideoLayer } from '../../components/assetsMenu/AssetsHelper';
import { handleLoadImages } from './imageSaga';
import { handleLoadUsedVideos, handleLoadVideos, getTemplateVideos } from './videoSaga';

function* readAsBase64(blob) {
  return yield new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

function* loadUsedAssets(template) {
  // Images now fetched when format loads.

  const videos = getTemplateVideos(template);
  if (videos.length > 0) {
    yield call(handleLoadUsedVideos);
  }
}

function* loadAssets() {
  yield call(handleLoadImages);
  yield call(handleLoadVideos);
}

function* handleLoadBase64(url) {
  try {
    const { data } = yield call(api.basicCalls.getBlob, url);
    const base64 = yield call(readAsBase64, data);
    return base64;
  } catch (error) {
    // Better error handling
    // console.error(error);
  }

  return null;
}

function* addAssetLayerToCanvas(asset, event, selectedFormat, scale, type) {
  let assetObject = {};

  if (type === 'image') {
    assetObject = yield call(getImageLayer, asset, selectedFormat);
  } else if (type === 'video') {
    assetObject = yield call(getVideoLayer, asset, selectedFormat);
  }

  const banner = document.getElementById('banner').getBoundingClientRect();

  yield (assetObject.format.x =
    event.pageX - banner.left - ((event.pageX - banner.left) / scale) * (scale - 100));
  yield (assetObject.format.y =
    event.pageY - banner.top - ((event.pageY - banner.top) / scale) * (scale - 100));

  yield put(addLayer(assetObject, selectedFormat.id));
}

export { readAsBase64, handleLoadBase64, addAssetLayerToCanvas, loadUsedAssets, loadAssets };
