import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const toasts = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    newVersionToast() {},
    statusMessageToast() {}
  }
});

export const { newVersionToast, statusMessageToast } = toasts.actions;
export default toasts.reducer;
