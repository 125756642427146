const hasSpecificDcoTagInText = (objectList, dcoTag) => {
  let hasSpecificDcoInText = false;

  if (dcoTag) {
    for (const object of objectList) {
      if (object.dynamic_key && object.dynamic_uuid && object.dynamic_key === dcoTag) {
        hasSpecificDcoInText = true;
        break;
      }

      if (object.children) {
        hasSpecificDcoInText = hasSpecificDcoTagInText(object.children, dcoTag);
      }
    }
  }

  return hasSpecificDcoInText;
};

export default hasSpecificDcoTagInText;
