export default function prettyImageType(name) {
  if (name === 'image/png') {
    name = 'png';
  } else if (name === 'image/jpeg') {
    name = 'jpeg';
  } else if (name === 'image/jpg') {
    name = 'jpg';
  } else if (name === 'image/svg+xml') {
    name = 'svg';
  }

  return name;
}
