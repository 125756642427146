import React, { forwardRef } from 'react';
import styled from 'styled-components';

const StyledMenuDiv = styled.div`
  z-index: 30;
  position: absolute;
  display: flex;
  background-color: #242424;
  border: 2px solid #353535;
  top: -40px;
  left: 0px;
  margin-top: -10px;
  border-radius: 4px;
  padding: 0 5px;
  opacity: 0;
  transition: opacity 0.75s;
`;

const Menu = forwardRef(({ className, ...props }, ref) => (
  <StyledMenuDiv {...props} ref={ref} className={className} />
));

export default Menu;
