export default {
  colors: {
    backgroundColorPrimary: 'rgb(27, 27, 27)',
    backgroundColorSecondary: '#242424',
    dcoPurple: '#b049ef',
    textColorPrimary: '#fff',
    textColorSecondary: '#b9b9b9',
    borderColor: '#353535',
    zuuviBlue: '#4792E2',
    zuuviRed: '#df6d4e',
    lightMediumGray: '#3e3e3e'
  }
};
