/* eslint-disable import/prefer-default-export */
import store from '../studioEditor/redux/store';

export function getUserAccounts() {
  const { user } = store.getState().auth;
  const rearrangedArray = [];

  if (user) {
    // make sure the children follow parents in the array
    user.accounts.forEach((accountUser) => {
      // first push parents
      if (accountUser.account.parentAccountId === undefined) {
        rearrangedArray.push(accountUser);
      } else {
        // then push children after parents
        const parentId = accountUser.account.parentAccountId;
        const index = rearrangedArray.findIndex((item) => item.account.id === parentId);
        rearrangedArray.splice(index + 1, 0, accountUser);
      }
    });
    return rearrangedArray;
  }
  return [];
}
