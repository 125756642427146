import styled from 'styled-components';

export default styled.div`
  padding: 17px;
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  flex-wrap: wrap;
  & p {
    margin: 0;
  }
`;
