import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as dropEffects from './Dropeffects';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

const draggingStyle = {
  opacity: 0.25
};

const Draggable = (props) => {
  const { dataItem, draggedObject, disabled, children, dropEffect } = props;
  const { scale } = useSelector((state) => state.editorSession.studio);

  const [isDragging, setIsDragging] = useState(false);
  const [dragPreview, setDragPreview] = useState(null);
  const capturePosthogData = usePosthogCapture();

  const startDrag = (e) => {
    setIsDragging(true);

    if (dataItem.type !== 'dashboard') {
      const dragPreview = document.createElement('div');

      // Handling the change of style for the preview image

      dragPreview.style.width = `${(draggedObject.format?.width * scale) / 100}px`;
      dragPreview.style.height = `${(draggedObject.format?.height * scale) / 100}px`;

      dragPreview.style.left = '0px';
      dragPreview.style.top = '0px';
      dragPreview.style.position = 'absolute';
      dragPreview.style.transform = 'translateX(-1000px)';
      dragPreview.style.zIndex = 0;

      if (dataItem.type === 'asset') {
        if (dataItem.name === 'image') {
          dragPreview.style.backgroundSize = 'contain';
          dragPreview.style.backgroundImage = `url('${draggedObject.settings.source.thumbnail_url}')`;
        }

        if (dataItem.name === 'video') {
          dragPreview.style.backgroundSize = 'contain';
          dragPreview.style.backgroundImage = `url('${draggedObject.settings.source.thumbnail_url}')`;
        }
      }

      if (dataItem.type === 'element') {
        dragPreview.style.backgroundColor = draggedObject.format.background_color;
        dragPreview.style.borderRadius = `${(draggedObject.format.radius * scale) / 100}px`;

        if (dataItem.name === 'text') {
          dragPreview.innerHTML = 'Write something';
          dragPreview.style.fontFamily = draggedObject.settings.font.name;
          dragPreview.style.fontSize = `${(draggedObject.format.font_size * scale) / 100}px`;
        }
      }

      document.body.appendChild(dragPreview);

      // Setting the dataTransfer data
      e.dataTransfer.setDragImage(dragPreview, 0, 0);
      e.dataTransfer.effectAllowed = dropEffect;
      e.dataTransfer.setData('drag-item', JSON.stringify(dataItem));
      e.dataTransfer.setData('drag-object', JSON.stringify(draggedObject));

      setDragPreview(dragPreview);
    } else {
      e.dataTransfer.effectAllowed = dropEffect;
      e.dataTransfer.setData('drag-object', JSON.stringify(draggedObject));
      e.dataTransfer.setData('drag-item', JSON.stringify(dataItem));
    }
  };

  const dragEnd = () => {
    if (dataItem.type === 'dashboard') {
      capturePosthogData({
        event: 'dashboard-drag',
        type: 'dashboard-card',
        element_type: dataItem.name,
        dragndrop: true
      });
      setIsDragging(false);
    } else {
      capturePosthogData({
        event: 'add-element',
        type: 'element',
        element_type: draggedObject.type,
        dragndrop: true,
        menu: 'left'
      });
      dragPreview.remove();
      setIsDragging(false);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <div
      style={isDragging ? draggingStyle : {}}
      draggable
      onDragStart={startDrag}
      onDragEnd={dragEnd}
      onDragOver={dragOver}>
      {children}
    </div>
  );
};

Draggable.propTypes = {
  dataItem: PropTypes.object.isRequired,
  dropEffect: PropTypes.string,
  disabled: PropTypes.bool,
  draggedObject: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

Draggable.defaultProps = {
  dropEffect: dropEffects.All,
  disabled: false
};

export default Draggable;
