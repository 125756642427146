import React, { useState } from 'react';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';

import { AccountsIcon } from '../../../assets/icons';
import MediumIconButton from '../../common/MediumIconButton';
import SwitchClientMenu from './SwitchClientMenu';

const SwitchClientButton = () => {
  const [showMenu, setShowMenu] = useState(false);
  const capturePosthogData = usePosthogCapture();
  const buttonData = {
    title: 'Accounts',
    tooltip: 'Switch account',
    icon: <AccountsIcon fill={showMenu ? '#4792E2' : 'white'} className='icon-hover' />,
    id: 'show-switch-account'
  };

  return (
    <div>
      <MediumIconButton
        text={buttonData.title}
        tooltip={buttonData.tooltip}
        id={buttonData.id}
        icon={buttonData.icon}
        key={buttonData.id}
        onHandleClick={() => {
          capturePosthogData({ event: 'show-switch-account', type: 'account' });
          setShowMenu(!showMenu);
        }}
      />
      {showMenu && <SwitchClientMenu closeFunc={() => setShowMenu(false)} />}
    </div>
  );
};

export default SwitchClientButton;
