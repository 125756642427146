import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Card.Module.scss';

function Card(props) {
  // Deconstruct props.
  // eslint-disable-next-line react/prop-types
  const {
    flex,
    icon,
    title,
    description,
    subDescription,
    updatedAt,
    hover,
    footer,
    special = false,
    disabled = false,
    active = false
  } = props;

  const WrapperStyle = {
    width: `${100 / flex - flex}%`,
    margin: '1%',
    height: 'auto',
    background: !special ? 'rgba(62, 62, 62, 1)' : 'rgb(36, 36, 36)',
    border: '1px solid rgba(82, 82, 82, 1)',
    boxSizing: 'border-box',
    boxShadow: '6px 0px 5px rgba(0, 0, 0, 0.07)',
    borderRadius: '4px',
    color: 'rgba(255, 255, 255, 1)',
    padding: '30px 15px 15px 15px',
    textAlign: 'center'
  };

  const HeaderStyle = {
    marginBottom: '15px'
  };

  const FooterStyle = {
    marginTop: updatedAt ? '10px' : '20px'
  };

  const TitleStyle = {
    fontSize: '16px',
    lineHeight: '16px',
    margin: '10px 0px 0px 0px',
    color: '#ffffff',
    overflow: 'hidden',
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap'
  };

  const UpdatedAtStyle = {
    fontSize: '9px',
    lineHeight: '9px',
    margin: '25px 0px 0px 0px',
    alignItems: 'center',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)'
  };

  const TextStyle = {
    fontSize: '11px',
    lineHeight: '11px',
    margin: '0px',
    color: 'rgba(255, 255, 255, 0.7)',
    overflow: 'hidden',
    wordBreak: 'keep-all'
  };

  const parentRef = useRef();

  const [hovering, setHovering] = useState(false);
  const [hoverHeight, setHoverHeight] = useState(0);

  function calculateHeight() {
    if (parentRef.current) {
      setHoverHeight(parentRef.current.clientHeight / 5);
    }
  }

  return (
    <div
      id='card-wrapper'
      data-cy='dco-card'
      ref={parentRef}
      style={
        (hovering && hover) || disabled
          ? {
              ...WrapperStyle,
              background: 'rgba(62, 62, 62, 0.2)',
              border: active ? '1px solid #b049ef' : '1px solid rgba(82, 82, 82, 0.5)'
            }
          : { ...WrapperStyle }
      }
      onMouseEnter={() => {
        setHovering(true);
        calculateHeight();
      }}
      onMouseLeave={() => {
        setHovering(false);
      }}>
      {/* HOVER CONTENT */}
      {hovering && hover && (
        <div
          style={{
            position: 'relative',
            height: 0,
            width: '80%',
            top: `${hoverHeight - 15}px`,
            marginLeft: '10%'
          }}>
          {hover}
        </div>
      )}

      {/* CARD CONTENT */}
      <div
        id='card'
        className={styles.card}
        style={(hovering && hover) || disabled ? { opacity: 0.1, pointerEvents: 'none' } : {}}>
        {/* CARD HEADER CONTENT */}
        <div id='card-header' style={HeaderStyle}>
          <div id='card-header-icon'>{icon != null && icon}</div>
          <p id='card-header-title' style={TitleStyle}>
            {title}
          </p>
        </div>

        {/* CARDBODY CONTENT */}
        <div id='card-body'>
          {description && <p style={TextStyle}>{description}</p>}
          {subDescription && <p style={{ ...TextStyle, marginTop: '10px' }}>{subDescription}</p>}
          {updatedAt && <p style={UpdatedAtStyle}>Last updated {updatedAt}</p>}
        </div>

        {/* CARD FOOTER CONTENT */}
        <div id='card-footer' style={FooterStyle}>
          {footer}
        </div>
      </div>
    </div>
  );
}

Card.defaultProps = {
  flex: 1,
  title: null,
  icon: null,
  description: null,
  subDescription: null,
  updatedAt: null,
  footer: null,
  hover: null
};

Card.propTypes = {
  flex: PropTypes.number,
  icon: PropTypes.element,
  title: PropTypes.string,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  updatedAt: PropTypes.string,
  footer: PropTypes.element,
  hover: PropTypes.element
};

export default Card;
