/* eslint-disable import/order */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './StudioEditor.scss';
import '../assets/global.scss';
import { v4 as uuidv4 } from 'uuid';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { Style } from 'react-style-tag';
import { clearImages } from '../redux/slices/images';
import { clearVideos } from '../redux/slices/videos';
import { clearError } from '../redux/slices/error';
import {
  addAnimation,
  addLayer,
  getCreativeSet,
  getTemplate,
  setTemplateDCO,
  setTemplateDuration
} from '../redux/slices/template';
import {} from '../redux/slices/auth';
import Banner from '../components/EditorCanvas/Banner';
import TopMenuContainer from '../components/topMenu/TopMenuContainer';
import AnimationSelection from '../components/RightMenu/RightMenuAnimationSelection';
import RightMenu from '../containers/RightMenuContainer';
import RightMenuTemplateClicktag from '../containers/RightMenuTemplateClicktagContainer';
import RightMenuTemplateSettings from '../components/RightMenu/RightMenuTemplateSettings';
import RightMenuBannerClicktag from '../containers/RightMenuBannerClicktagContainer';
import MediumIconButton from '../components/common/MediumIconButton';
import { getPathById } from '../assets/utils';
import { flat } from '../../helpers';
import Dropzone from '../components/DragNDrop/Dropzone';
import useEventListener from '../../utils/hooks/useEventListener';
import HotkeyActions from '../../HotkeyActions';

import Timeline from '../components/timeline/Timeline';
import usePosthogCapture from '../../utils/hooks/usePosthogCapture';
import RightMenuFinalise from '../components/RightMenu/RightMenuFinalise';
import QuickExportTypeSelection from '../components/quickExport/QuickExportTypeSelection';
import useAuthorization from '../../utils/hooks/useAuthorization';
import {
  AnimateIcon,
  EditIcon,
  TemplateSettingsIcon,
  EffectsIcon,
  FinaliseIcon,
  ClicktagIcon
} from '../assets/icons';
import '@zuuvi/zuuvi-nestable/dist/index.css';
import {
  buildAnimations,
  resetActiveAnimation,
  setActiveAnimation,
  setEditorType,
  setIframeIsOpened,
  setRightMenu,
  stopAnimations
} from '../redux/slices/editorSession';
import {
  setQuickExportBigMenuOpened,
  resetSelectedQuickExportType
} from '../redux/slices/quickExport';
import useBeforeunload from '../../utils/hooks/useBeforeunload';
import EditDatasourceModal from '../components/common/EditDatasourceModal';
import GoogleFontLoader from 'react-google-font-loader';
import Ruler from '../components/EditorCanvas/Ruler';
import CustomGuide from '../components/EditorCanvas/CustomGuide';
import LeftMenu from '../components/leftMenu/LeftMenu';
import CanvasOverlay from '../components/EditorCanvas/CanvasOverlay';
import LayerOverlay from '../components/EditorCanvas/LayerOverlay';

function StudioEditor({ actualEditorType }) {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const capturePosthogData = usePosthogCapture();
  gsap.registerPlugin(CustomEase);
  const errorState = useSelector((state) => state.error);
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const userSettings = useSelector((state) => state.userSettings);
  const template = useSelector((state) => state.template);
  const { scale, presentLayers, selectedFonts, iframeIsOpened } = useSelector(
    (state) => state.editorSession.studio
  );
  const { selectedFormatId, selectedFormat, dirtySaveState, editorType } = useSelector(
    (state) => state.editorSession
  );
  const { dcoSelection, editDatasourceModalOpened } = useSelector((state) => state.dco);

  const { quickExportBigMenuOpened, selectedQuickExportType } = useSelector(
    (state) => state.quickExport
  );
  const isModalOpenened = editDatasourceModalOpened || quickExportBigMenuOpened;

  // chrome popup is behind feature flag because of test user
  if (isAuthorized({ featureFlag: 'chrome-popup' })) {
    useBeforeunload(() => dirtySaveState && 'stopunload');
  }

  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const [userFonts, setUserFonts] = useState([]);
  const [googleFonts, setGoogleFonts] = useState([]);
  const [rulerPosition, setRulerPosition] = useState({ x: 0, y: 0 });
  const [copiedData, setCopiedData] = useState(null);

  const leftMenuTabRef = useRef();
  const leftNavRef = useRef();

  // Load data from template store if in Studio workspace
  const { templateId, creativeSetId } = useParams();

  useEffect(() => {
    dispatch(setEditorType(actualEditorType));
    if (actualEditorType === 'banner' && creativeSetId) {
      dispatch(getCreativeSet(creativeSetId));
    } else {
      dispatch(getTemplate(templateId));
      setCurrentTemplateId(templateId);
    }
  }, []);

  // fetch customfonts and googlefonts
  useEffect(() => {
    if (currentAccountId && currentTemplateId) {
      dispatch(clearImages());
      dispatch(clearVideos());
    }
  }, [currentAccountId, currentTemplateId]);

  useEffect(() => {
    if (currentAccountId) {
      getFonts(currentAccountId);
    }
  }, [currentAccountId]);

  useEffect(() => {
    if (errorState && errorState.status) {
      if (errorState.status === 401) {
        keycloak.login();
      } else if (errorState.status === 404) {
        navigate('/');
      }
      dispatch(clearError());
    }
  }, [errorState]);

  useEffect(() => {
    if (editorType !== 'banner') {
      if (
        selectedFormat &&
        selectedFormat.object_data &&
        selectedFormat.object_data.width &&
        selectedFormat.object_data.height
      ) {
        navigate(
          `/editor/template/${template.id}/format/width/${selectedFormat.object_data.width}/height/${selectedFormat.object_data.height}`
        );
      }
    }
  }, [selectedFormat]);

  useEffect(() => {
    dispatch(
      setTemplateDCO({
        dynamic_uuid: dcoSelection.dynamicSourceIncluded ? dcoSelection.dynamicSource.id : '',
        parameter_uuid: dcoSelection.parameterSourceIncluded ? dcoSelection.parameterSource.id : '',
        custom: dcoSelection.customSourceIncluded ? dcoSelection.customSource : {},
        client_id: currentAccountId,
        number_of_objects: dcoSelection.dynamicSourceIncluded
          ? dcoSelection.dynamicSource.number_of_objects
          : 1
      })
    );
  }, [dcoSelection]);

  function getFonts(clientId) {
    fetch(window._env_.REACT_ENV_GET_GOOGLE_FONTS_URL)
      .then((gFontsRes) => gFontsRes.json())
      .then((googleFonts) => {
        setGoogleFonts(googleFonts.items);
      })
      .catch((e) => {
        console.log(e);
      });
    fetch(window._env_.REACT_ENV_GET_ALL_CUSTOM_FONTS_URL + clientId)
      .then((uFontsRes) => uFontsRes.json())
      .then((userFonts) => {
        setUserFonts(userFonts.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getFontsData(fontType) {
    if (fontType === 'google') {
      return selectedFonts
        .filter((fonts) => fonts.reference_type === fontType)
        .map((font) => ({ font: font.name, weights: font.weights }));
    }
    if (fontType === 'external') {
      return selectedFonts
        .filter((fonts) => fonts.reference_type === fontType)
        .map((font) => ({ name: font.name, source: font.source }));
    }
    return [];
  }

  // ****** LEFT MENU ****** //
  const changeDcoSourceNameRef = useRef();
  const quickExportRef = useRef();

  const [openedAnimations, setOpenedAnimations] = useState([]);

  const [openedEffects, setOpenedEffects] = useState([]);

  const [isEditingTextEnabled, setIsEditingTextEnabled] = useState(null);
  const [isReadonlyTextEnabled, setIsReadonlyTextEnabled] = useState(null);

  const onIsTextEditingEnabled = (status) => {
    setIsEditingTextEnabled(status);
  };

  const rightMenuTabRef = useRef();

  // RIGHT TABS
  const [canvasStyle, setCanvasStyle] = useState({
    cursor: 'default',
    transform: 'translate(0px, 0px)'
  });
  const [canvasPosition, setCanvasPosition] = useState({ x: 0, y: 0 });
  const [canvasStartPosition, setCanvasStartPosition] = useState({ x: 0, y: 0 });
  const [canvasStartDrag, setCanvasStartDrag] = useState({ x: 0, y: 0 });
  const [canvasIsMovable, setCanvasIsMovable] = useState({
    mouse: false,
    space: false
  });

  const keydownHandler = useCallback((event) => {
    if (!isModalOpenened) {
      if (!canvasIsMovable.space && event.code === 'Space') {
        setCanvasIsMovable({
          ...canvasIsMovable,
          space: true
        });
      }
    }
  });

  const keyupHandler = useCallback((event) => {
    if (!isModalOpenened) {
      if (event.code === 'Space') {
        setCanvasIsMovable({
          ...canvasIsMovable,
          space: false
        });
      }
    }
  });

  const handelUpdateRulerPosition = () => {
    const banner = document.getElementById('banner');
    if (banner && banner.getBoundingClientRect()) {
      const clientRect = banner.getBoundingClientRect();
      if (clientRect.left !== rulerPosition.x || clientRect.top !== rulerPosition.y) {
        setRulerPosition(getRulerPosition());
      }
    }
  };

  useEffect(() => {
    // used for updating ruler on canvas move
    handelUpdateRulerPosition();
  });

  useEffect(() => {
    // used for updating ruler on window resizing
    window.addEventListener('resize', handelUpdateRulerPosition);
    return () => {
      window.removeEventListener('resize', handelUpdateRulerPosition);
    };
  }, []);

  useEventListener('keydown', keydownHandler);
  useEventListener('keyup', keyupHandler);

  function mouseupCanvasHandler(event) {
    event.preventDefault();
    setCanvasIsMovable({
      ...canvasIsMovable,
      mouse: false
    });
  }

  function mousemoveCanvasHandler(event) {
    if (canvasIsMovable.space && canvasIsMovable.mouse) {
      setCanvasPosition({
        x: canvasStartPosition.x + (event.pageX - canvasStartDrag.x),
        y: canvasStartPosition.y + (event.pageY - canvasStartDrag.y)
      });
      setCanvasStyle({
        ...canvasStyle,
        transform: `translate(${canvasPosition.x}px, ${canvasPosition.y}px)`
      });
    }
  }
  function mousedownCanvasHandler(event) {
    event.preventDefault();
    setCanvasStartDrag({
      x: event.pageX,
      y: event.pageY
    });
    setCanvasStartPosition({
      x: canvasPosition.x,
      y: canvasPosition.y
    });
    setCanvasIsMovable({
      ...canvasIsMovable,
      mouse: true
    });
  }

  let canvasCursor = 'default';
  if (canvasIsMovable.space && !canvasIsMovable.mouse) canvasCursor = 'grab';
  if (canvasIsMovable.space && canvasIsMovable.mouse) canvasCursor = 'grabbing';

  const canvasRealStyle = {
    ...canvasStyle,
    cursor: canvasCursor,
    minWidth: `${selectedFormat?.object_data?.width ?? 0}px`
  };

  const topHeaderRef = useRef();
  const timelineContainerRef = useRef();

  const handleDropzoneDrop = (e, object) => {
    // Change to use the banner ref to get it
    const banner = document.getElementById('banner').getBoundingClientRect();

    object.format.x = e.pageX - banner.left - ((e.pageX - banner.left) / scale) * (scale - 100);
    object.format.y = e.pageY - banner.top - ((e.pageY - banner.top) / scale) * (scale - 100);
    dispatch(addLayer(object, selectedFormatId));
  };

  // SHORTCUTS
  HotkeyActions({
    copiedData,
    setCopiedData
  });

  const toolbarRef = useRef();

  const getRulerPosition = () => {
    const banner = document.getElementById('banner');

    if (banner && banner.getBoundingClientRect()) {
      const clientRect = banner.getBoundingClientRect();

      return {
        x: clientRect.left,
        y: clientRect.top
      };
    }

    return { x: 0, y: 0 };
  };
  // after font is uploaded, pass it to parent and close iframe
  const onMessageIframeAddUploadedFonts = useCallback(
    (event) => {
      if (event.data.message === 'onEventUpload') {
        setUserFonts(userFonts.concat(event.data.fonts));
        dispatch(setIframeIsOpened(false));
      }
    },
    [userFonts]
  );
  // listen to message and clean up after
  useEffect(() => {
    window.addEventListener('message', onMessageIframeAddUploadedFonts);
    return () => window.removeEventListener('message', onMessageIframeAddUploadedFonts);
  }, [onMessageIframeAddUploadedFonts]);

  const onMessageCloseIframe = useCallback((event) => {
    if (event.data.message === 'onIframeClose') {
      dispatch(setIframeIsOpened(false));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessageCloseIframe);
    return () => window.removeEventListener('message', onMessageCloseIframe);
  }, [onMessageCloseIframe]);

  return (
    <div id='studio-editor'>
      {/* LOAD GOOGLE FONTS CHOOSEN BY USER */}
      {getFontsData('google')?.length > 0 && (
        <GoogleFontLoader fonts={getFontsData('google')} subsets={['cyrillic-ext', 'greek']} />
      )}
      {getFontsData('external')?.length > 0 &&
        getFontsData('external').map((externalFont) => (
          <Style hasSourceMap={false} key={externalFont.name}>{`
         @font-face {
            font-family: ${externalFont.name};
            src: url(${externalFont.source})
          }
        `}</Style>
        ))}
      {iframeIsOpened && (
        <iframe
          id='fontupload-iframe'
          src={window._env_.REACT_ENV_FONT_UPLOAD_IFRAME_URL + currentAccountId}
          title='fontUploader'
          style={{
            zIndex: 9999,
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            border: 'none'
          }}
        />
      )}
      {/* TOP MENU */}
      <TopMenuContainer topHeaderRef={topHeaderRef} />
      {/* LEFT MENU  */}
      <LeftMenu
        leftMenuTabRef={leftMenuTabRef}
        leftNavRef={leftNavRef}
        changeDcoSourceNameRef={changeDcoSourceNameRef}
      />
      <div
        className='canvas'
        style={{
          backgroundColor: userSettings.background,
          pointerEvents: editDatasourceModalOpened ? 'none' : 'auto'
        }}
        onMouseDown={canvasIsMovable.space ? mousedownCanvasHandler : undefined}
        onMouseUp={canvasIsMovable.space ? mouseupCanvasHandler : undefined}
        onMouseMove={canvasIsMovable.space ? mousemoveCanvasHandler : undefined}>
        {editDatasourceModalOpened && (
          <EditDatasourceModal
            modalId='dco-source-name-change'
            id='dco-source-name-change'
            featureRef={changeDcoSourceNameRef}
          />
        )}

        {quickExportBigMenuOpened && (
          <QuickExportTypeSelection
            featureRef={quickExportRef}
            allOptionsEnabled={isAuthorized({ featureFlag: 'quick-export-extended-access' })}
            onCloseIconClick={(e) => {
              dispatch(resetSelectedQuickExportType());
              dispatch(setQuickExportBigMenuOpened(false));
              capturePosthogData({
                event: 'close-quick-export-type-selection',
                type: 'quick-export',
                selectedExport: selectedQuickExportType.id,
                menu: 'right'
              });
            }}
          />
        )}
        {selectedFormat && (
          <Dropzone
            onItemDropped={(e, object) => handleDropzoneDrop(e, object)}
            dropEffect='copy'
            disabled={editorType === 'banner'}>
            <div className='canvas-actions' style={canvasRealStyle}>
              <Banner
                isEditingTextEnabled={isEditingTextEnabled}
                isReadonlyTextEnabled={isReadonlyTextEnabled}
                toolbarRef={toolbarRef}
                onIsTextEditingEnabled={(status) => onIsTextEditingEnabled(status)}
                excludedRefs={[
                  topHeaderRef,
                  leftNavRef,
                  leftMenuTabRef,
                  rightMenuTabRef,
                  timelineContainerRef,
                  quickExportRef,
                  changeDcoSourceNameRef
                ]}
                excludedContentEditableRefs={[]}
                onStopTimeline={() => dispatch(stopAnimations())}
                canvasIsMovable={canvasIsMovable.space}
                userSettings={userSettings}
              />
            </div>
            <CanvasOverlay canvasPosition={canvasPosition} />
            <LayerOverlay canvasPosition={canvasPosition} />
          </Dropzone>
        )}
      </div>
      {/* RIGHT MENU  */}
      <StudioRightMenu
        rightMenuTabRef={rightMenuTabRef}
        userFonts={userFonts}
        setUserFonts={setUserFonts}
        googleFonts={googleFonts}
        onIsTextEditingEnabled={onIsTextEditingEnabled}
        isReadonlyTextEnabled={isReadonlyTextEnabled}
        setIsReadonlyTextEnabled={setIsReadonlyTextEnabled}
        toolbarRef={toolbarRef}
        isEditingTextEnabled={isEditingTextEnabled}
        stopAnimations={() => dispatch(stopAnimations())}
        openedEffects={openedEffects}
        setOpenedEffects={setOpenedEffects}
        openedAnimations={openedAnimations}
        setOpenedAnimations={setOpenedAnimations}
      />
      {/* TIMELINE */}
      <Timeline
        timelineContainerRef={timelineContainerRef}
        stopAnimations={() => stopAnimations()}
        buildAnimations={() => dispatch(buildAnimations())}
        setTemplateDuration={(length) => {
          dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers));
        }}
        setOpenedAnimations={setOpenedAnimations}
        setOpenedEffects={setOpenedEffects}
        openedAnimations={openedAnimations}
        openedEffects={openedEffects}
      />

      {isAuthorized({ featureFlag: 'banner-ruler' }) && userSettings.ruler && (
        <>
          <Ruler axi='x' zeroPosition={rulerPosition.x} />
          <Ruler axi='y' zeroPosition={rulerPosition.y} />
          {template.formats
            .find((format) => format.id === selectedFormatId)
            ?.guidelines?.customGuides.map((customGuide) => (
              <CustomGuide
                key={customGuide.uuid}
                customGuide={customGuide}
                rulerPosition={rulerPosition}
              />
            ))}
        </>
      )}
    </div>
  );
}

function StudioRightMenu({
  rightMenuTabRef,
  userFonts,
  setUserFonts,
  googleFonts,
  onIsTextEditingEnabled,
  isReadonlyTextEnabled,
  setIsReadonlyTextEnabled,
  toolbarRef,
  isEditingTextEnabled,
  openedEffects,
  setOpenedEffects,
  openedAnimations,
  setOpenedAnimations
}) {
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const { template } = useSelector((state) => state);
  const { selectedFormatId, activeLayer, selectedFormat, editorType, menus, studio } = useSelector(
    (state) => state.editorSession
  );
  const { presentLayers } = studio;
  const { rightMenu } = menus;

  const [showAnimationList, setShowAnimationList] = useState(false);
  const [showEffectsList, setShowEffectsList] = useState(false);

  const isDisabled = selectedFormat === null || editorType === 'banner';

  const onIsReadonlyTextEnabled = (status) => {
    setIsReadonlyTextEnabled(status);
  };

  function addAnimationEditor(layerId, path, type, settings, ease, direction) {
    const animationId = uuidv4();
    const newAnimation = {
      uuid: animationId,
      type,
      direction: direction || 'to',
      time: '0.0',
      ease,
      easeType: 'inOut',
      ...(settings && { settings: { ...settings } }),
      // settings: {...settings && settings},
      only_on_repeat: false,
      duration: 0.5,
      target: layerId
    };
    dispatch(addAnimation(selectedFormatId, layerId, newAnimation));

    setOpenedEffects([...openedEffects, animationId]);
    setOpenedAnimations([...openedAnimations, animationId]);

    dispatch(
      setActiveAnimation({
        uuid: animationId,
        target: layerId,
        index: 0
      })
    );
  }

  const RIGHT_BUTTONS_DATA = [
    {
      text: 'Edit',
      tooltip: 'Editing of elements',
      buttonStyle: { marginTop: 20 },
      icon: (
        <EditIcon
          fill={rightMenu === 'edit' ? '#4792E2' : selectedFormat === null ? 'gray' : 'white'}
          className={selectedFormat === null ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-edit',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'edit'
      },
      id: 'edit',
      disabled: selectedFormat === null,
      hidden: false
    },
    {
      text: 'Animate',
      tooltip: 'Animate elements',
      icon: (
        <AnimateIcon
          fill={rightMenu === 'animate' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-animate',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'animate'
      },
      id: 'animate',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Effects',
      tooltip: 'Element effects',
      icon: (
        <EffectsIcon
          fill={rightMenu === 'effects' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-effects',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'effects'
      },
      id: 'effects',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Clicktag',
      tooltip: 'Clicktag',
      icon: (
        <ClicktagIcon
          fill={rightMenu === 'clicktag' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-clicktag',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'clicktag'
      },
      id: 'clicktag',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Template Settings',
      tooltip: 'Settings for template',
      icon: (
        <TemplateSettingsIcon
          fill={rightMenu === 'template_settings' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      buttonStyle: { marginTop: 7 },
      posthogData: {
        event: 'open-template-settings',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'template-settings'
      },
      id: 'template_settings',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Finalise',
      tooltip: 'Finalise template',
      icon: (
        <FinaliseIcon
          fill={rightMenu === 'finalise' ? '#4792E2' : isDisabled ? 'gray' : 'white'}
          className={isDisabled ? '' : 'icon-hover'}
        />
      ),
      buttonStyle: { marginTop: 9 },
      posthogData: {
        event: 'open-finalise',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'finalise'
      },
      id: 'finalise',
      disabled: isDisabled,
      hidden: editorType === 'banner'
    },
    {
      text: 'Clicktag',
      tooltip: 'Clicktags for banner',
      icon: (
        <ClicktagIcon
          fill={
            rightMenu === 'banner_clicktag_settings'
              ? '#4792E2'
              : selectedFormat === null || editorType !== 'banner'
              ? 'gray'
              : 'white'
          }
          className={selectedFormat === null || editorType !== 'banner' ? '' : 'icon-hover'}
        />
      ),
      posthogData: {
        event: 'open-banner-clicktag',
        type: 'open-submenu',
        menu: 'right',
        submenu: 'banner_clicktag-settings'
      },
      id: 'banner_clicktag_settings',
      disabled: selectedFormat === null || editorType !== 'banner',
      hidden: editorType !== 'banner'
    }
  ];

  const animationAndEffectIncluded = () => {
    let isIncluded = { animationsIncluded: false, effectsIncluded: false };
    flat(presentLayers).forEach((layer) => {
      if (activeLayer === layer.uuid) {
        if (layer.animations && layer.animations.length > 0) {
          const effects = layer.animations.filter(
            (an) =>
              an.type === 'fadeTop' ||
              an.type === 'fadeBottom' ||
              an.type === 'pulse' ||
              an.type === 'makeVisible'
          );
          if (effects.length === 0) {
            isIncluded = { animationsIncluded: true, effectsIncluded: false };
          }
          if (effects.length > 0 && effects.length < layer.animations.length) {
            isIncluded = { animationsIncluded: true, effectsIncluded: true };
          }
          if (effects.length === layer.animations.length) {
            isIncluded = { animationsIncluded: false, effectsIncluded: true };
          }
        }
      }
    });
    return isIncluded;
  };
  return (
    <div className='right-nav-wrapper' ref={rightMenuTabRef}>
      <div className='main-nav right-nav'>
        {RIGHT_BUTTONS_DATA.map((button) => (
          <MediumIconButton
            id={button.id}
            disabled={button.disabled}
            buttonStyle={button.buttonStyle}
            hidden={button.hidden}
            text={button.text}
            tooltip={button.tooltip}
            icon={button.icon}
            key={button.id}
            onHandleClick={() => {
              capturePosthogData(button.posthogData);
              dispatch(resetActiveAnimation());
              dispatch(setRightMenu(rightMenu !== button.id ? button.id : ''));
            }}
          />
        ))}
      </div>
      {rightMenu && (
        <div className='right-nav-opened'>
          <div className='scroll-container'>
            {!activeLayer &&
              rightMenu !== 'template_settings' &&
              rightMenu !== 'banner_clicktag_settings' &&
              rightMenu !== 'finalise' &&
              rightMenu !== 'clicktag' && (
                <div className='template-settings-section'>
                  <div className='menu-title'> Please select layer or group.</div>
                </div>
              )}
            {rightMenu === 'edit' && activeLayer && (
              <RightMenu
                isReadonlyTextEnabled={isReadonlyTextEnabled}
                toolbarRef={toolbarRef}
                onIsTextEditingEnabled={(status) => onIsTextEditingEnabled(status)}
                onIsReadonlyTextEnabled={(status) => onIsReadonlyTextEnabled(status)}
                isEditingTextEnabled={isEditingTextEnabled}
                userFonts={userFonts}
                onsetUserFonts={(newFonts) => setUserFonts(newFonts)}
                googleFonts={googleFonts}
              />
            )}
            {rightMenu === 'animate' && editorType !== 'banner' && activeLayer && (
              <>
                <AnimationSelection
                  isIncluded={animationAndEffectIncluded().animationsIncluded}
                  showList={showAnimationList}
                  onSetShowList={(data) => setShowAnimationList(data)}
                  animationTypes='animation'
                  openedAnimations={openedAnimations}
                  onSetOpenedAnimations={(data) => setOpenedAnimations(data)}
                  onAddAnimationEditor={(animationId, settings, ease, direction) => {
                    addAnimationEditor(
                      activeLayer,
                      getPathById(
                        activeLayer,
                        template.formats.find((format) => format.id === selectedFormatId)?.layers ||
                          []
                      ),
                      animationId,
                      settings,
                      ease,
                      direction
                    );
                  }}
                />
              </>
            )}
            {rightMenu === 'effects' && editorType !== 'banner' && activeLayer && (
              <AnimationSelection
                isIncluded={animationAndEffectIncluded().effectsIncluded}
                showList={showEffectsList}
                onSetShowList={(data) => setShowEffectsList(data)}
                animationTypes='effect'
                openedAnimations={openedEffects}
                onSetOpenedAnimations={(data) => setOpenedEffects(data)}
                onAddAnimationEditor={(animationId, settings, ease, direction) => {
                  addAnimationEditor(
                    activeLayer,
                    getPathById(
                      activeLayer,
                      template.formats.find((format) => format.id === selectedFormatId)?.layers ||
                        []
                    ),
                    animationId,
                    settings,
                    ease,
                    direction
                  );
                }}
              />
            )}
            {rightMenu === 'clicktag' && (
              <RightMenuTemplateClicktag
                onSetTemplateDuration={(length) =>
                  dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers))
                }
                onStopAnimations={() => {
                  dispatch(stopAnimations());
                }}
              />
            )}
            {rightMenu === 'template_settings' && (
              <RightMenuTemplateSettings
                onSetTemplateDuration={(length) =>
                  dispatch(setTemplateDuration(parseFloat(length).toFixed(1), presentLayers))
                }
              />
            )}
            {rightMenu === 'banner_clicktag_settings' && (
              <RightMenuBannerClicktag onStopAnimations={() => dispatch(stopAnimations())} />
            )}
            {rightMenu === 'finalise' && <RightMenuFinalise />}
          </div>
        </div>
      )}
    </div>
  );
}

export default StudioEditor;
