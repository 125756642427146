import styled from 'styled-components';

export default styled.div`
  max-height: 467px;

  overflow-x: hidden;
  overflow-y: hidden;
  width: ${(props) => (props.environment === 'studio' ? '100%' : '800px')};
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
