/* eslint-disable import/prefer-default-export */
import { get, post, getWithoutHeaders } from './basicCalls';

const CREATIVES_SERVICE_URL = `${window._env_.REACT_ENV_TEMPLATE_STORE_URL}data/creative-set`;
const CAMPAIGN_SERVICE_URL = window._env_.REACT_ENV_CAMPAIGN_SERVICE_URL;

async function getCreativeSet(creativeSetId) {
  return get(`${CREATIVES_SERVICE_URL}/${creativeSetId}`);
}

async function saveCreativeSet(creativeSetId, creativeSet) {
  return post(`${CREATIVES_SERVICE_URL}/${creativeSetId}`, {
    creativeSet,
    publish: false
  });
}

async function publishCreativeSet(creativeSetId, creativeSet) {
  return post(`${CREATIVES_SERVICE_URL}/${creativeSetId}`, {
    creativeSet,
    publish: true
  });
}

async function getCreativeSetPublicData(creativeSetId) {
  return getWithoutHeaders(`${CAMPAIGN_SERVICE_URL}/creative-set/${creativeSetId}/public-data`);
}

export { getCreativeSet, saveCreativeSet, publishCreativeSet, getCreativeSetPublicData };
