import posthog from 'posthog-js';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

// import { useGetUserQuery } from 'creative/api/accountApi/userEndpoints';
// import { useAppSelector } from 'creative/store/hooks';

// import { AuthUser, selectAuthUser, selectSelectedAccountId } from 'creative/store/slices/authSlice';
// import getSelectedAccountUser from 'creative/utils/helpers/getSelectedAccountUser';

// export interface AuthorizationProps {
//   featureFlag?: FeatureFlagType;
//   feature?: FeatureType;
//   permission?: PermissionType;
//   billingType?: BillingType;
// }

const useAuthorization = () => {
  const user = useSelector((state) => state.auth.user);
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const selectedAccountUser = user?.accounts.find(
    (userAccount) => userAccount.account.id === currentAccountId
  );
  const { permissions, features } = useMemo(() => {
    let permissionsList = [];
    let featuresList = [];

    if (selectedAccountUser) {
      permissionsList = selectedAccountUser.role?.permissions;
      featuresList = selectedAccountUser.account?.features;
    }

    return { permissions: permissionsList, features: featuresList };
  }, [selectedAccountUser]);

  const isAuthorized = useCallback(
    (props) => {
      const { feature = null, featureFlag = null, permission = null, billingType = null } = props;

      let isFeatureEnabled = true; // For account features.
      let isFeatureFlagEnabled = true; // For feature flags.
      let isPermissionEnabled = true; // For user permissions.
      let isBillingTypeEnabled = true; // For account billing types (stripe, other, both, none).

      if (featureFlag) {
        // For the new version of posthog, this can now be undefined, so we are using the nullish coalescing operator
        isFeatureFlagEnabled = posthog.isFeatureEnabled(featureFlag) ?? false;
      }

      if (feature && features) {
        isFeatureEnabled = features.some((p) => p === feature);
      }

      if (permission && permissions) {
        isPermissionEnabled = permissions.some((p) => p === permission);
      }

      if (billingType) {
        const haveStripeSubscription =
          selectedAccountUser?.account.planType !== null &&
          selectedAccountUser?.account.stripeCustomer !== null;
        const haveOtherSubscription =
          selectedAccountUser?.account.planType !== null &&
          selectedAccountUser?.account.stripeCustomer === null;

        if (billingType === 'stripe') {
          isBillingTypeEnabled = haveStripeSubscription;
        } else if (billingType === 'other') {
          isBillingTypeEnabled = haveOtherSubscription;
        } else if (billingType === 'both') {
          isBillingTypeEnabled = haveStripeSubscription || haveOtherSubscription;
        } else {
          isBillingTypeEnabled = selectedAccountUser?.account.planType === null; // billingType === BillingType.NoBilling
        }
      }

      return (
        isFeatureEnabled && isFeatureFlagEnabled && isPermissionEnabled && isBillingTypeEnabled
      );
    },
    [features, permissions, selectedAccountUser]
  );

  return isAuthorized;
};

export default useAuthorization;
