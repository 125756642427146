/* eslint-disable no-restricted-syntax */
import { takeEvery, call, put, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { getFormatHtml, setFormatHtml, exportTemplate } from '../slices/quickExport';
import * as api from '../../api';

export const selectQuickExport = (state) => state.quickExport;

export function* handleGetFormatHtml(action) {
  const { templateId, formatId } = action.payload;
  try {
    const response = yield call(api.quickExportService.getFormatHtml, templateId, formatId);
    yield put(setFormatHtml(response.data));
  } catch (e) {
    console.log(e);
  }
}

export function* handleExportTemplate(action) {
  const { templateId, exports } = action.payload;
  const { seekTime } = yield select(selectQuickExport);

  const selectTemplate = (state) => state.template;
  const templateState = yield select(selectTemplate);
  try {
    const response = yield call(
      api.quickExportService.exportTemplate,
      templateId,
      exports,
      parseFloat(seekTime) * 1000
    );
    const successResponseRegex = /2\d{2}/g;
    const matches = successResponseRegex.exec(response.status);
    if (matches) {
      const blob = new Blob([response.data], {
        type: 'application/octet-stream'
      });
      saveAs(blob, `${templateState.title.replace(/ /g, '_')}.zip`);
    }
  } catch (e) {
    console.log('error', e);
  }
}

function* watchQuickExport() {
  yield takeEvery(getFormatHtml, handleGetFormatHtml);
  yield takeEvery(exportTemplate, handleExportTemplate);
}

export default watchQuickExport;
