import { useEffect } from 'react';
import { TextIcon, ElementsIcon, FolderIcon, ImagesIcon } from './icons';

export const useClickOutside = (ref, handler, excludedRefsArr) => {
  function handleClickOutside(event) {
    // check if it's refs array
    if (Array.isArray(ref.current)) {
      ref.current.forEach((item) => {
        // do nothing if the ref is in focus
        if (
          !item ||
          item?.contains(event.target) ||
          excludedRefsArr?.some((reference) => reference.current?.contains(event.target))
        ) {
          return;
        }
        // fire event when it's not
        handler(event);
      });
    }
    // if ref is not array
    else {
      if (
        !ref.current ||
        ref.current?.contains(event.target) ||
        excludedRefsArr?.some((reference) => reference.current?.contains(event.target))
      ) {
        return;
      }
      // fire event when it's not
      handler(event);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

export const getPathById = (uuid, layers) => {
  let path = [];
  layers.every((layer, i) => {
    if (layer.uuid === uuid) {
      path.push(i);
    } else if (layer.layers) {
      const childrenPath = getPathById(uuid, layer.layers);
      if (childrenPath.length) {
        path = path.concat(i).concat(childrenPath);
      }
    } else if (layer.children) {
      const childrenPath = getPathById(uuid, layer.children);
      if (childrenPath.length) {
        path = path.concat(i).concat(childrenPath);
      }
    }
    return path.length === 0;
  });
  return path;
};
export const renderIcon = (layerType, pointer = true) => {
  if (layerType === 'group') {
    return (
      <FolderIcon
        fill='#7C7C7C'
        className={pointer ? 'margin-right-pointer' : 'margin-right'}
        width='13'
        height='13'
      />
    );
  }
  if (layerType === 'text') {
    return (
      <TextIcon
        fill='#7C7C7C'
        className={pointer ? 'margin-right-pointer' : 'margin-right'}
        width='13'
        height='13'
      />
    );
  }
  if (layerType === 'image' || layerType === 'video') {
    return (
      <ImagesIcon
        fill='#7C7C7C'
        className={pointer ? 'margin-right-pointer' : 'margin-right'}
        width='13'
        height='13'
      />
    );
  }
  return (
    <ElementsIcon
      fill='#7C7C7C'
      className={pointer ? 'margin-right-pointer' : 'margin-right'}
      width='13'
      height='13'
    />
  );
};
