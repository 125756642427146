/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector } from 'react-redux';

import GroupCard from './GroupCard';

function GroupList() {
  const groups = useSelector((state) => state.dashboard.groups);

  const groupsCount = () => {
    if (groups) {
      if (groups.length === 1) {
        return `${groups.length} group`;
      }
      if (groups.length !== 1) {
        return `${groups.length} groups`;
      }
    }

    return null;
  };

  if (!groups || groups.length === 0) {
    return null;
  }

  const sortedGroups = [...groups];
  sortedGroups.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  return (
    <>
      <div className='content-section templates-container' data-cy='group-list'>
        <div className='templates-count-text'>
          <span style={{ display: 'flex', width: '90px' }}>{groupsCount()}</span>
        </div>
        <div id='templates' className='card-container'>
          {sortedGroups.length !== 0 &&
            sortedGroups.map((group) => <GroupCard key={group.id} group={group} />)}
        </div>
      </div>
    </>
  );
}

export default GroupList;
