import { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import posthog from 'posthog-js';

const usePosthogCapture = () => {
  const currentAccountId = useSelector((state) => state.auth.currentAccountId);
  const user = useSelector((state) => state.auth.user);
  const { keycloak } = useKeycloak();
  const selectedAccountUser = user?.accounts.find(
    (userAccount) => userAccount.account.id === currentAccountId
  );
  const selectedAccount = selectedAccountUser?.account;

  const getEditorType = () => {
    if (window.location.href.indexOf('template') > -1) {
      return 'studio';
    }
    if (window.location.href.indexOf('banner') > -1) {
      return 'app-editor';
    }
    return 'studio';
  };

  const getUserImpersonatedData = () => {
    if (keycloak?.tokenParsed?.impersonator !== undefined) {
      return {
        impersonated: true,
        impersonated_user_id: keycloak.tokenParsed.impersonator.id,
        impersonated_username: keycloak.tokenParsed.impersonator.username
      };
    }

    return { impersonated: false };
  };

  const capturePosthogData = useCallback((posthogData) => {
    const data = {
      ...posthogData,
      ...getUserImpersonatedData(),
      platform: 'studio',
      editor_type: getEditorType(),
      client_id: selectedAccount?.id,
      account_id: selectedAccount?.id,
      plan_type: selectedAccount?.planType,
      user_id: user.id
    };
    posthog.capture(posthogData.event, {
      ...data
    });
  });

  return capturePosthogData;
};

export default usePosthogCapture;
