export const zuuviColors = [
  { color: '#F5CC5C', rgb: 'rgba(245, 204, 92)', name: 'Zuuvi Lemon' },
  { color: '#6ADAAE', rgb: 'rgba(106, 218, 174)', name: 'Zuuvi Mint' },
  { color: '#DE6E4D', rgb: 'rgba(222, 110, 77)', name: 'Zuuvi Ruby' },
  { color: '#4A90E2', rgb: 'rgba(74, 144, 226)', name: 'Zuuvi Blue' },
  { color: '#39AA7D', rgb: 'rgb(57,170,125)', name: 'Zuuvi Blue' },
  { color: '#249066', rgb: 'rgb(36,144,102)', name: 'Zuuvi Blue' },
  { color: '#D8AC33', rgb: 'rgb(216,172,51)', name: 'Zuuvi Lemon Darker' },
  { color: '#C99A1C', rgb: 'rgb(201,154,28)', name: 'Zuuvi Lemon Dark' },
  { color: '#B45134', rgb: 'rgb(180,81,52)', name: 'Zuuvi Ruby Darker' },
  { color: '#AF3916', rgb: 'rgb(175,57,22)', name: 'Zuuvi Ruby Dark' },
  { color: '#B049EF', rgb: 'rgba(176, 73, 239)', name: 'Dynamic Purple' }
];
