import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const error = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      const newError = action.payload;
      return newError;
    },
    clearError() {
      return initialState;
    }
  }
});

export const { setError, clearError } = error.actions;
export default error.reducer;
