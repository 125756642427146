import { useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import bytesToSize from '../../../utils/bytesToSize';
import { addFormat, removeFormat, setFormatName } from '../../redux/slices/template';
import {
  resetActiveAnimation,
  selectFormat,
  setActiveLayer
} from '../../redux/slices/editorSession';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import './Formats.scss';
import * as api from '../../api';
import useEventListener from '../../../utils/hooks/useEventListener';

import { ExtraDots } from '../../assets/icons';

const EstimateSize = styled.span`
  font-family: Roboto;
  font-style: italic;
  font-weight: normal;
  font-size: 11px;
  color: ${(props) => {
    if (props.size > 350000) {
      return 'red';
    }
    return '#b9b9b9';
  }};
`;
const FormatBox = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
`;
const FormatTitleInput = styled.input`
  background: #4792e2;
  font-size: 11px;
  width: 150px;
  border: none;
  margin-left: 15px;
  color: white;
  outline: none;
  height: 13px;
  align-self: center;
`;
const FormatTitle = styled.p`
  max-width: 150px;
  display: inline-block;
  margin: 0px 0px 0px 15px;
  align-self: center;
`;

function Format(props) {
  const { format } = props;
  const [isTitleEditable, setIsTitleEditable] = useState(false);
  const editorType = useSelector((state) => state.editorSession.editorType);
  const templateId = useSelector((state) => state.template.id);
  const selectedFormatId = useSelector((state) => state.editorSession.selectedFormatId);
  const clientId = useSelector((state) => state.template.client_id);

  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const formatShowOptionsReference = useRef();
  const formatOptionsListReference = useRef();
  const formatOptionsReference = useRef();
  const formatEditTitleInputReference = useRef();

  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const navigate = useNavigate();

  const clickFormatHandler = (event) => {
    const path = event.path || event.nativeEvent.composedPath();

    if (
      path &&
      //TODO: commmented out because we commented out the options for now
      // formatShowOptionsReference.current &&
      !(
        path.includes(formatEditTitleInputReference.current) ||
        path.includes(formatShowOptionsReference.current) ||
        path.includes(formatOptionsListReference.current)
      )
    ) {
      dispatch(selectFormat(format));
      dispatch(setActiveLayer(null));
      dispatch(resetActiveAnimation());

      capturePosthogData({
        event: 'select-format',
        type: 'format',
        selected_format: format.id,
        menu: 'left',
        menu_type: 'formats'
      });

      toast('Switched format', {
        id: 'switched-format',
        duration: 1000
      });
    }
    if (editorType !== 'banner') {
      navigate(
        `/editor/template/${templateId}/format/width/${format.width}/height/${format.height}`
      );
   
    } else {
      // navigate to the creative path
      navigate(`/set/${format.creativeSetId}/${format.id}`)
    }
  };

  const showFormatOptions = () => {
    setShowOptions(!showOptions);
  };

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && formatOptionsReference && formatOptionsReference.current) {
        if (!event.path.includes(formatOptionsReference.current)) {
          setShowOptions(false);
          setShowDeleteOption(false);
        }
      }
    },
    document
  );

  const duplicateFormat = async () => {
    toast.loading('Duplicating format ...', {
      id: 'duplicate-format'
    });
    capturePosthogData({
      event: 'duplicate-format',
      type: 'format',
      format_id: format.id,
      menu: 'left',
      menu_type: 'formats'
    });

    const response = await api.templateStore.templateStoreRequest(
      `formats/${format.id}/duplicate`,
      'POST',
      null,
      clientId
    );
    const duplicatedFormat = await response.json();

    dispatch(addFormat({ format: duplicatedFormat, originalFormatId: format.id }));
    dispatch(selectFormat(duplicatedFormat));
    dispatch(setActiveLayer(null));
    dispatch(resetActiveAnimation());

    toast.success('Format duplicated.', {
      id: 'duplicate-format'
    });
  };

  const deleteFormat = () => {
    toast.loading('Deleting format ...', {
      id: 'delete-format'
    });
    const deleteRequest = api.templateStore.templateStoreRequest(
      `formats/${format.id}/delete`,
      'POST',
      null,
      clientId
    );
    capturePosthogData({
      event: 'delete-format',
      type: 'format',
      format_id: format.id,
      menu: 'left',
      menu_type: 'formats'
    });
    deleteRequest.then(() => {
      dispatch(removeFormat(format.id));
      toast.success('Format deleted.', {
        id: 'delete-format'
      });
    });
  };

  return (
    <FormatBox
      className='format-button'
      backgroundColor={selectedFormatId === format.id ? '#3E3E3E' : 'transparent'}
      onClick={(e) => {
        if (selectedFormatId !== format.id) {
          clickFormatHandler(e);
        }
      }}>
      {!isTitleEditable ? (
        <FormatTitle
          onDoubleClick={() => {
            setIsTitleEditable(true);
          }}>
          {format.title}
        </FormatTitle>
      ) : (
        <FormatTitleInput
          autoFocus
          ref={formatEditTitleInputReference}
          onBlur={(e) => {
            dispatch(setFormatName({ formatId: format.id, title: e.target.value }));
            setIsTitleEditable(false);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              dispatch(setFormatName({ formatId: format.id, title: e.target.value }));
              setIsTitleEditable(false);
            }
          }}
          defaultValue={format.title}
          type='text'
        />
      )}
      {format.size && <EstimateSize size={format.size}>{bytesToSize(format.size)}</EstimateSize>}
      <div className='format-options' ref={formatOptionsReference}>
        {/* TODO: add the format options back once new manager is released and we have time to fix the functionality */}
        {editorType !== 'banner' && (
          <div
            className='format-options-button'
            onClick={() => {
              showFormatOptions();
            }}
            ref={formatShowOptionsReference}>
            <ExtraDots />
          </div>
        )}
        {showOptions && (
          <div className='format-options-list' ref={formatOptionsListReference}>
            <div
              className='format-option-item'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                duplicateFormat();
                setShowOptions(false);
              }}>
              <p> Duplicate format</p>
            </div>
            <div
              className='format-option-item'
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setIsTitleEditable(true);
                setShowOptions(false);
              }}>
              <p>Rename format</p>
            </div>
            {!showDeleteOption ? (
              <div
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setShowDeleteOption(true);
                }}>
                <p>Delete format</p>
              </div>
            ) : (
              <div
                className='format-option-item delete'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  deleteFormat();
                }}>
                <b>ARE YOU SURE?</b>
              </div>
            )}
          </div>
        )}
      </div>
    </FormatBox>
  );
}

export default Format;
