import {
  AUTOSAVE_TEMPLATE_START,
  AUTOSAVE_TEMPLATE_SUCCESS,
  AUTOSAVE_TEMPLATE_ERROR,
  AUTOSAVE_TEMPLATE_DISABLE,
  AUTOSAVE_TEMPLATE_ENABLE
} from '../actionTypeConstants';

import { setActiveLayer, selectFormat } from '../slices/editorSession';
import { addImage, clearImages } from '../slices/images';
import { addVideo, clearVideos } from '../slices/videos';
import { setTemplate, setTemplateDCO, resetUndoHistory } from '../slices/template';

const initialState = {
  saveState: false,
  isSaving: false,
  hasError: false,
  enabled: true
};

export default function reducer(state = initialState, action) {
  // Action that should not trigger a save.
  const noneSaveActions = [
    resetUndoHistory.type,
    setTemplate.type,
    setTemplateDCO.type,
    selectFormat.type,
    addImage.type,
    clearImages.type,
    addVideo.type,
    clearVideos.type,
    setActiveLayer.type
  ];
  if (action.type === AUTOSAVE_TEMPLATE_START) {
    return {
      ...state,
      saveState: false,
      isSaving: true
    };
  } else if (action.type === AUTOSAVE_TEMPLATE_SUCCESS) {
    return {
      ...state,
      saveState: false,
      isSaving: false,
      hasError: false
    };
  } else if (action.type === AUTOSAVE_TEMPLATE_ERROR) {
    return {
      ...state,
      saveState: false,
      isSaving: false,
      hasError: true
    };
  } else if (action.type === AUTOSAVE_TEMPLATE_DISABLE) {
    return {
      ...state,
      enabled: false
    };
  } else if (action.type === AUTOSAVE_TEMPLATE_ENABLE) {
    return {
      ...state,
      enabled: true
    };
  } else if (noneSaveActions.includes(action.type) || action.type.startsWith('@@redux')) {
    return state;
  } else {
    // Autosave per default ?
    return {
      ...state,
      saveState: true
    };
  }
}
