import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkboard } from 'react-color/lib/components/common';
import { ChromePicker } from 'react-color';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import InputNumber from '../common/InputNumber';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import {
  StyledInputWrapperBig,
  StyledSpan,
  StyledColorPickerBackground,
  StyledColorPickerButton,
  StyledFlexSpaceBetween,
  StyledChromePickerWrapper
} from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';
import { useClickOutside } from '../../assets/utils';
import convertRGBAToHex from '../../../utils/convertRGBAToHex';

function BorderAttribute(props) {
  const { layer, onUpdateLayerFormat } = props;
  const capturePosthogData = usePosthogCapture();
  const [borderOpen, setBorderOpen] = useState(true);
  const [borderColorPickerOpened, setBorderColorPickerOpened] = useState(false);
  const borderColorPickerRef = useRef();
  const borderColorPickerButtonRef = useRef();

  useClickOutside(
    borderColorPickerRef,
    () => {
      setBorderColorPickerOpened(false);
    },
    [borderColorPickerButtonRef]
  );

  const BORDER_INPUTS_DATA = [
    {
      id: 'top',
      inputText: 'Top',
      posthogInputId: 'right-submenu-edit-border-top'
    },
    {
      id: 'right',
      inputText: 'Right',
      posthogInputId: 'right-submenu-edit-border-right'
    },
    {
      id: 'bottom',
      inputText: 'Bottom',
      posthogInputId: 'right-submenu-edit-border-bottom'
    },
    {
      id: 'left',
      inputText: 'Left',
      posthogInputId: 'right-submenu-edit-border-left'
    }
  ];

  const handleChange = (input, value) => {
    const diff = value - layer.format.border[input.id];
    const newHeight = parseInt(layer.format.height) + parseInt(diff);
    const newWidth = parseInt(layer.format.width) + parseInt(diff);

    onUpdateLayerFormat(layer.uuid, {
      x: layer.format.x,
      y: layer.format.y,
      ...(input.id === 'top' || input.id === 'bottom'
        ? { height: newHeight }
        : { height: layer.format.height }),
      ...(input.id === 'left' || input.id === 'right'
        ? { width: newWidth }
        : { width: layer.format.width })
    });

    onUpdateLayerFormat(layer.uuid, {
      border: {
        ...layer.format.border,
        [input.id]: value
      }
    });
  };

  return (
    <>
      <ToggleRevealSectionButton
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'edit',
            menu: 'right',
            menu_type: 'edit',
            submenu: 'border',
            is_open: !borderOpen
          });
          setBorderOpen(!borderOpen);
        }}
        id='right-submenu-edit-open-close-border-section'
        isOpened={borderOpen}>
        <p className='color-white'>Border</p>
      </ToggleRevealSectionButton>
      {borderOpen && (
        <StyledSectionWrap>
          <>
            {BORDER_INPUTS_DATA.map((input) => (
              <StyledInputWrapperBig key={input.id}>
                <p className='input-text'>{input.inputText}</p>
                <InputNumber
                  key={input.id}
                  id={input.posthogInputId}
                  className={`attributes-input ${input.id}`}
                  min={0}
                  value={layer.format.border[input.id]}
                  onUpdate={(value) => {
                    handleChange(input, value);
                  }}
                />
                <StyledSpan>px</StyledSpan>
              </StyledInputWrapperBig>
            ))}
            <StyledFlexSpaceBetween>
              <StyledColorPickerBackground>
                <StyledColorPickerButton
                  ref={borderColorPickerButtonRef}
                  type='button'
                  style={{
                    backgroundColor: layer.format.border.color
                  }}
                  id='right-submenu-edit-open-background-colorpicker-font-color'
                  onClick={() => setBorderColorPickerOpened(!borderColorPickerOpened)}>
                  <Checkboard />
                </StyledColorPickerButton>
              </StyledColorPickerBackground>
              <div>
                <p className='input-text'>
                  {layer.format.border.color ? convertRGBAToHex(layer.format.border.color) : ''}
                </p>
              </div>
            </StyledFlexSpaceBetween>
          </>
          {borderColorPickerOpened && (
            <StyledChromePickerWrapper ref={borderColorPickerRef}>
              <ChromePicker
                color={layer.format.border.color}
                onChange={(color) => {
                  const { r, g, b, a } = color.rgb;
                  return onUpdateLayerFormat(layer.uuid, {
                    border: {
                      ...layer.format.border,
                      color: `rgba(${r}, ${g}, ${b}, ${a})`
                    }
                  });
                }}
              />
            </StyledChromePickerWrapper>
          )}
        </StyledSectionWrap>
      )}
    </>
  );
}

BorderAttribute.propTypes = {
  layer: PropTypes.object.isRequired,
  onUpdateLayerFormat: PropTypes.func.isRequired
};

export default BorderAttribute;
