import ZuuviLogo from '../assets/zuuvi_logo_big.png';
import ZuuviLoader from './common/ZuuviLoader';

export const PageLoader = () => (
  <div id='loader-wrapper'>
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <ZuuviLoader size='large' />
    </div>
  </div>
);
