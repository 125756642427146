import styled from 'styled-components';
import React, { useState } from 'react';

const StyledButton = styled.div`
  background-color: ${(props) => (props.secondary ? 'white' : '#4a90e2')};
  color: ${(props) => (props.secondary ? ' #4a90e2' : 'white')};
  display: inline-block;
  border-radius: 8px;
  padding: 11px 0;
  width: 140px;
  border: ${(props) => (props.secondary ? '1px solid #4a90e2' : 'none')};
  text-align: center;
  &:first-child {
    margin-left: 30px;
  }
  &:last-child {
    margin-right: 30px;
  }
  cursor: pointer;
`;

const StyledButtonText = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin: 0;
`;

function NavigateButton(props) {
  const { text, hoverColor, style, id, onClick, secondary } = props;
  const [hover, setHover] = useState(false);

  const handleClick = (e) => {
    onClick(e);
  };

  return (
    <StyledButton
      secondary={secondary}
      id={`base-button-${id}`}
      style={hover && hoverColor ? { ...style, backgroundColor: hoverColor } : { ...style }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={(e) => handleClick(e)}>
      <StyledButtonText>{text}</StyledButtonText>
    </StyledButton>
  );
}

export default NavigateButton;
