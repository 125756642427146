import toast from 'react-hot-toast';
import { takeLeading, takeEvery, call, put, select } from 'redux-saga/effects';
import * as api from '../../api';
import {
  getValidatedDpaColumnData,
  getValidateDpaColumnDataStudio,
  setValidatedDpaColumnData,
  getFeedImagesStatus,
  generateDpaFeed,
  setDcoId,
  setDpaUrl,
  setNumberOfImages,
  setImagesReadyCount,
  setImageRows,
  setErrorMessage,
  createDpaSource,
  getMappings,
  updateMappedColumnsData,
  resetDpaState,
  setRemoveInvalidRowsChecked,
  updateDpaSeekTime
} from '../slices/dpa';
import { handleCreateDynamicSource, handleGetOverviewData } from './dcoSaga';

export const selectDpa = (state) => state.dpa;

export function* handleGetValidatedDpaColumnData(action) {
  // in future we will get the data through adId
  const { templateId, publishedTemplateId } = action.payload;
  try {
    const response = yield call(
      api.dpaService.getValidatedDpaColumnData,
      templateId,
      publishedTemplateId
    );

    yield put(setDcoId(response.dynamic_id));
    yield put(setValidatedDpaColumnData(response));
  } catch (e) {
    yield put(setErrorMessage('An error has occurred. Please try again.'));
  }
}
export function* handleGetValidateDpaColumnDataStudio(action) {
  // in future we will get the data through adId
  const feedUrl = action.payload;

  try {
    const response = yield call(api.dpaService.getValidatedDpaColumnData, null, null, feedUrl);

    yield put(setDcoId(response.dynamic_id));
    yield put(setValidatedDpaColumnData(response));
  } catch (e) {
    yield put(setErrorMessage('An error has occurred. Please try again.'));
  }
}

export function* handleGenerateDpaFeed() {
  // Create dpa mappings
  const dpaState = yield select(selectDpa);

  try {
    yield call(
      api.dpaService.createDpaMappings,
      dpaState.adId,
      dpaState.seekTime * 1000,
      dpaState.dcoId,
      dpaState.mappings,
      dpaState.removeInvalidRowsChecked
    );

    const response = yield call(api.dpaService.generateDpaFeed, dpaState.adId);
    yield put(setDpaUrl(response.aws_dpa_url));
    yield put(setNumberOfImages(response.number_of_images));
    yield put(setImageRows(response.image_rows));
  } catch (e) {
    yield put(setErrorMessage('Your feed could not be loaded. Please try again.'));
  }
}

export function* handleGetFeedImagesStatus() {
  // Create dpa mappings
  const dpaState = yield select(selectDpa);

  try {
    const response = yield call(api.dpaService.getImagesReadyCount, dpaState.adId);
    yield put(setImagesReadyCount(response.current_image_count));
  } catch (e) {
    yield put(setErrorMessage('Status could not be fetched. Please try again.'));
  }
}

export function* handleCreateDpaSource() {
  // We need this to delete the dynamic source if something goes wrong
  let dynamicId = '';

  try {
    // Loading toast
    yield toast.loading(`Creating Catalog Ads source ...`, {
      id: 'create-dynamic-dpa-source'
    });

    // Get the dpa state
    const dpaState = yield select(selectDpa);

    // First we create the dco source.
    const { dynamic_id } = yield call(handleCreateDynamicSource);
    dynamicId = dynamic_id;
    // Then we create the dpa source mappings, using the dco source.
    const success = yield call(api.dpaService.createDcoMappings, dynamic_id, dpaState.mappings);

    if (success) {
      // IF we successfully create the dpa.
      yield put(resetDpaState());
      yield toast.success(`Successfully created Catalog Ads source!`, {
        id: 'create-dynamic-dpa-source'
      });
    } else {
      // If we failed creating the dpa.
      throw new Error(`Failed to create Catalog Ads source`);
    }
  } catch (e) {
    // Delete the dynamic if somethigng causes an exception
    yield call(api.dcoService.deleteDatasource, dynamicId);
    yield call(handleGetOverviewData);
    yield toast.error('Error happened creating the source!', {
      id: 'create-dynamic-dpa-source'
    });
  }
}

export function* handleGetMappings(action) {
  const { adId } = action.payload;

  const { mappings, remove_invalid_rows, seek_time } = yield call(api.dpaService.getMappings, adId);
  yield put(updateDpaSeekTime((seek_time / 1000).toFixed(2)));
  yield put(setRemoveInvalidRowsChecked(remove_invalid_rows));
  for (const mapping of mappings) {
    yield put(
      updateMappedColumnsData({
        dpaKey: mapping.dpa_key,
        data: { dco_key: mapping.dco_key }
      })
    );
  }
}

function* watchDpa() {
  yield takeEvery(getValidatedDpaColumnData, handleGetValidatedDpaColumnData);
  yield takeEvery(getValidateDpaColumnDataStudio, handleGetValidateDpaColumnDataStudio);
  yield takeEvery(generateDpaFeed, handleGenerateDpaFeed);
  yield takeEvery(createDpaSource, handleCreateDpaSource);
  yield takeEvery(getMappings, handleGetMappings);
  yield takeLeading(getFeedImagesStatus, handleGetFeedImagesStatus);
}

export default watchDpa;
