import styled from 'styled-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigateButton from './NavigateStepsButton';
import {
  FirstStepIcon,
  SecondStepIcon,
  GoToPageLinkIcon,
  FinishedStepIcon
} from '../../assets/icons';
import {
  clearErrorMessage,
  setRemoveInvalidRowsChecked,
  resetUrlAndImageCount
} from '../../redux/slices/dpa';
import { StyledCheckbox } from '../common/styled/AttributeInputs';

const StyledStep = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
`;

const StepsWrapper = styled.div`
  display: flex;
  flexdirection: row;
  margin-top: 25px;
  padding: 0px 100px;
`;

const FlexButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 30px;
  height: 41px;
  justify-content: space-between;
  width: 100%;
`;

const ConnectorLine = styled.div`
  flex: 1 1 auto;
  position: absolute;
  top: 12px;
  left: calc(-50% + 20px);
  right: calc(50% + 20px);
`;
const StyledLink = styled.a`
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #4a90e2;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  font-size: 12px;
  right: 100px;
  align-self: center;
`;
const FlexWrapper = styled.div`
  display: flex;
`;
function Connector() {
  return (
    <ConnectorLine>
      <span style={{ display: 'block', borderTop: '1px solid rgb(117, 117, 117)' }} />
    </ConnectorLine>
  );
}

function Steps(props) {
  const { activeStep, onUpdateActiveStep, children } = props;

  const dispatch = useDispatch();

  const steps = [
    { label: 'Map datasheet', icon: FirstStepIcon },
    { label: 'Preview', icon: SecondStepIcon }
  ];
  const { removeInvalidRowsChecked, errorMessage } = useSelector((state) => state.dpa);

  const handleNext = () => {
    dispatch(clearErrorMessage());
    onUpdateActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    dispatch(clearErrorMessage());
    dispatch(resetUrlAndImageCount());
    onUpdateActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    dispatch(clearErrorMessage());
    window.parent.postMessage({ message: 'close' }, '*');
  };

  const Step = (props) => {
    const { step, index } = props;
    return (
      <StyledStep>
        {index !== 0 && <Connector />}
        <span style={{ flexDirection: 'column', alignItems: 'center', display: 'flex' }}>
          <span>
            {activeStep > index ? (
              <FinishedStepIcon />
            ) : (
              <step.icon fill={index <= activeStep ? '#4A90E2' : '#828282'} />
            )}
          </span>
          <span style={{ width: '100%' }}>
            <p style={{ textAlign: 'center', fontSize: 12, marginTop: 8 }}>{step.label}</p>
          </span>
        </span>
      </StyledStep>
    );
  };

  return (
    <>
      <StepsWrapper>
        {steps.map((step, index) => (
          <React.Fragment key={step.label}>
            <Step key={index} step={step} index={index} />
          </React.Fragment>
        ))}
      </StepsWrapper>
      {children}
      <FlexButtonWrapper>
        {activeStep === 0 ? (
          <div
            style={{
              marginLeft: '51px',
              alignSelf: 'center'
            }}>
            <StyledLink
              href='https://intercom.help/zuuvi/en/articles/8135874-how-do-i-export-my-catalog-ads-campaign'
              target='_blank'
              rel='noreferrer'>
              View export guide
              <GoToPageLinkIcon style={{ marginLeft: 7 }} />
            </StyledLink>
          </div>
        ) : (
          <NavigateButton
            secondary
            onClick={handleBack}
            text='Previous'
            id='navigate-button-timeline-next'
          />
        )}
        <FlexWrapper>
          {activeStep === 0 && errorMessage === '' && (
            <CheckboxWrapper>
              <div>Remove rows with errors from the data feed </div>
              <StyledCheckbox
                style={{ marginRight: 20, marginLeft: 6 }}
                id='dpa-invalid-rows-checkbox'
                data-cy='dpa-invalid-rows-checkbox'
                type='checkbox'
                checked={removeInvalidRowsChecked}
                onChange={() => {
                  dispatch(setRemoveInvalidRowsChecked(!removeInvalidRowsChecked));
                }}
              />
            </CheckboxWrapper>
          )}
          <NavigateButton
            onClick={activeStep === steps.length - 1 ? handleComplete : handleNext}
            text={activeStep === 0 ? 'Next' : 'Close'}
            id='navigate-button-timeline-next'
            style={{ marginLeft: 'auto' }}
          />
        </FlexWrapper>
      </FlexButtonWrapper>
    </>
  );
}

export default Steps;
