import { useDispatch } from 'react-redux';
import { useUpdateCheck } from 'react-update-notification';
import { newVersionToast } from '../redux/slices/toasts';
import useAuthorization from '../../utils/hooks/useAuthorization';

const NewVersionChecker = () => {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const show = isAuthorized({ featureFlag: 'new-version-notification' });

  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 10000
  });

  if (!show) {
    return null;
  }

  if (status === 'checking' || status === 'current') {
    // dispatch(newVersionToast());
  }

  if (status === 'available') {
    dispatch(newVersionToast());
  }

  return null;
};

export default NewVersionChecker;
