import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AttributeOpenedIcon, AttributeClosedIcon } from '../../assets/icons';
import './ToggleRevealSectionButton.scss';

const StyledToggleButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  font-size: 14px;
  border-top: ${(props) => (props.removedBorderTop ? 'none' : '1px solid #353535')};
  border-bottom: 1px solid #353535;
  width: 100%;
  background: #2b2b2b;
  padding: 0px 10px;
  font-weight: 600;
`;

function ToggleRevealSectionButton(props) {
  const {
    backgroundColor,
    handleOnClick,
    isOpened,
    iconFill,
    children,
    deleteIcon,
    aditionalIcon,
    id,
    removedBorderTop
  } = props;
  const handleClick = (e) => {
    // only if production or staging
    handleOnClick(e);
  };

  return (
    <StyledToggleButton
      removedBorderTop={removedBorderTop}
      id={`toggle-reveal-selection-button-${id}`}
      type='button'
      style={{
        backgroundColor
      }}
      onClick={(e) => handleClick(e)}>
      {children}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {aditionalIcon}
        {deleteIcon}
        {isOpened ? (
          <AttributeOpenedIcon fill={iconFill} />
        ) : (
          <AttributeClosedIcon fill={iconFill} />
        )}
      </div>
    </StyledToggleButton>
  );
}
ToggleRevealSectionButton.defaultProps = {
  iconFill: '#C0C0C0',
  backgroundColor: '#2b2b2b',
  deleteIcon: '',
  aditionalIcon: '',
  removedBorderTop: false
};

ToggleRevealSectionButton.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  id: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  iconFill: PropTypes.string,
  deleteIcon: PropTypes.node,
  removedBorderTop: PropTypes.bool,
  aditionalIcon: PropTypes.node
};

export default ToggleRevealSectionButton;
