import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import InputNumber from '../common/InputNumber';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { StyledInputWrapperBig, StyledSpan } from '../common/styled/AttributeInputs';
import StyledSectionWrap from '../common/styled/StyledSectionWrap';

function TextPaddingAttribute(props) {
  const { layer, onUpdateLayerFormat } = props;
  const capturePosthogData = usePosthogCapture();

  const [attributeOpen, setAttributeOpen] = useState(true);

  const TEXT_PADDING_DATA = [
    {
      id: 'top',
      inputText: 'Top',
      posthogInputId: 'right-submenu-edit-text-padding-top'
    },
    {
      id: 'left',
      inputText: 'Left',
      posthogInputId: 'right-submenu-edit-text-padding-left'
    },
    {
      id: 'bottom',
      inputText: 'Bottom',
      posthogInputId: 'right-submenu-edit-text-padding-bottom',
      removedBottomMargin: true
    },
    {
      id: 'right',
      inputText: 'Right',
      posthogInputId: 'right-submenu-edit-text-padding-right',
      removedBottomMargin: true
    }
  ];

  const handleChange = (input, value) => {
    const diff = value - layer.format.padding[input.id];
    const newHeight = parseInt(layer.format.height) + parseInt(diff);
    const newWidth = parseInt(layer.format.width) + parseInt(diff);

    onUpdateLayerFormat(layer.uuid, {
      x: layer.format.x,
      y: layer.format.y,
      ...(input.id === 'top' || input.id === 'bottom'
        ? { height: newHeight }
        : { height: layer.format.height }),
      ...(input.id === 'left' || input.id === 'right'
        ? { width: newWidth }
        : { width: layer.format.width })
    });

    onUpdateLayerFormat(layer.uuid, {
      padding: {
        ...layer.format.padding,
        [input.id]: value
      }
    });
  };

  return (
    <>
      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-text-padding-section'
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'edit',
            menu: 'right',
            menu_type: 'edit',
            submenu: 'text-padding',
            is_open: !attributeOpen
          });
          setAttributeOpen(!attributeOpen);
        }}
        isOpened={attributeOpen}>
        <p className='color-white'>Text Padding</p>
      </ToggleRevealSectionButton>
      {attributeOpen && (
        <StyledSectionWrap>
          {TEXT_PADDING_DATA.map((input) => (
            <StyledInputWrapperBig key={input.id} removedBottomMargin={input.removedBottomMargin}>
              <p className='input-text'>{input.inputText}</p>
              <InputNumber
                key={input.id}
                id={input.posthogInputId}
                className={`attributes-input ${input.id}`}
                min={0}
                value={layer.format.padding[input.id]}
                onUpdate={(value) => {
                  handleChange(input, value);
                }}
              />
              <StyledSpan>px</StyledSpan>
            </StyledInputWrapperBig>
          ))}
        </StyledSectionWrap>
      )}
    </>
  );
}

TextPaddingAttribute.propTypes = {
  layer: PropTypes.object.isRequired,
  onUpdateLayerFormat: PropTypes.func.isRequired
};

export default TextPaddingAttribute;
