import React from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor, Transforms } from 'slate';
import styled from 'styled-components';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

const StyledButton = styled.button`
  background-color: ${(props) => {
    const { disabled, format, isMarkActive } = props;

    if (isMarkActive) {
      return 'rgb(81, 81, 81)';
    }

    return 'rgba(0,0,0,.1)';
  }};
  height: 28px;
  width: 28px;
  margin: 6px 1px;
  align-items: 'center';
  display: 'flex';
`;

const FormatButton = (props) => {
  const {
    format,
    icon,
    func,
    onColorPickerSet,
    onDcoSelection,
    disabled,
    colorPickerOpen,
    isDcoSelectionOpen
  } = props;

  const capturePosthogData = usePosthogCapture();
  const editor = useSlate();

  function isMarkActive() {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  }

  function clearMarks() {
    const markObj = {};
    // get all the marks used within nodes
    Transforms.unwrapNodes(editor, {
      match: (node) => {
        if (node.hasOwnProperty('text')) {
          Object.keys(node).forEach((prop) => {
            if (prop !== 'text') markObj[prop] = null;
          });
        }
        return false;
      }
    });
    // remove all the marks that are used in the selection
    for (const [key, value] of Object.entries(markObj)) {
      Editor.removeMark(editor, key);
    }
  }

  function toggleFormat(e) {
    e.stopPropagation();
    e.preventDefault();
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      capturePosthogData({
        event: 'remove-text-inline-style',
        type: format
      });
      Editor.removeMark(editor, format);
    } else {
      capturePosthogData({
        event: 'add-text-inline-style',
        type: format
      });
      Editor.addMark(editor, format, true);
    }
    ReactEditor.focus(editor);
  }

  function handleOnMouseDown(e) {
    if (func === 'clear') {
      capturePosthogData({
        event: 'clear-all-text-inline-styles',
        type: format
      });
      clearMarks(editor);
      onColorPickerSet('');
      onDcoSelection('');
    }
    if (func === 'toggle') {
      toggleFormat(e);
      onColorPickerSet('');
      onDcoSelection('');
    }
    if (func === 'picker') {
      ReactEditor.focus(editor);
      onColorPickerSet(format);
      onDcoSelection('');
    }
    if (func === 'addDco') {
      ReactEditor.focus(editor);
      onDcoSelection(format);
      onColorPickerSet('');
    }
  }
  const isActive =
    format !== 'color' && format !== 'bgColor' && format !== 'dcoText'
      ? isMarkActive(editor, format)
      : (colorPickerOpen.type === format && colorPickerOpen.isOpened) ||
        (format === 'dcoText' && isDcoSelectionOpen);

  return (
    <StyledButton
      data-cy={format}
      format={format}
      isMarkActive={isActive}
      disabled={disabled}
      // on click would turn the selection to null
      onMouseDown={(event) => {
        handleOnMouseDown(event);
      }}>
      {icon}
    </StyledButton>
  );
};

export default FormatButton;
