import { createSlice } from '@reduxjs/toolkit';

// move to slice
export const initialState = {
  mappings: [
    { dpa_key: 'id', dco_key: null, row_status: null },
    { dpa_key: 'title', dco_key: null, row_status: null },
    { dpa_key: 'description', dco_key: null, row_status: null },
    { dpa_key: 'availability', dco_key: null, row_status: null },
    { dpa_key: 'condition', dco_key: null, row_status: null },
    { dpa_key: 'price', dco_key: null, row_status: null },
    { dpa_key: 'link', dco_key: null, row_status: null },
    { dpa_key: 'brand', dco_key: null, row_status: null }
  ],
  optionalMappings: [
    { dpa_key: 'size', dco_key: null, row_status: null },
    { dpa_key: 'color', dco_key: null, row_status: null },
    { dpa_key: 'gender', dco_key: null, row_status: null },
    { dpa_key: 'status', dco_key: null, row_status: null },
    { dpa_key: 'age_group', dco_key: null, row_status: null },
    { dpa_key: 'material', dco_key: null, row_status: null },
    { dpa_key: 'pattern', dco_key: null, row_status: null },
    { dpa_key: 'sale_price', dco_key: null, row_status: null },
    { dpa_key: 'sale_price_effective_date', dco_key: null, row_status: null },
    { dpa_key: 'item_group_id', dco_key: null, row_status: null },
    { dpa_key: 'custom_label_0', dco_key: null, row_status: null },
    { dpa_key: 'custom_label_1', dco_key: null, row_status: null },
    { dpa_key: 'custom_label_2', dco_key: null, row_status: null },
    { dpa_key: 'custom_label_3', dco_key: null, row_status: null },
    { dpa_key: 'custom_label_4', dco_key: null, row_status: null },
    { dpa_key: 'custom_number_0', dco_key: null, row_status: null },
    { dpa_key: 'custom_number_1', dco_key: null, row_status: null },
    { dpa_key: 'custom_number_2', dco_key: null, row_status: null },
    { dpa_key: 'custom_number_3', dco_key: null, row_status: null },
    { dpa_key: 'custom_number_4', dco_key: null, row_status: null }
  ],
  seekTime: '0.00',
  adId: '',
  dpaFetchedData: null,
  dcoId: '',
  dpaUrl: '',
  numberOfImages: 0,
  imagesReadyCount: 0,
  imageRows: [],
  errorMessage: '',
  continueWithInvalidRowsChecked: false,
  removeInvalidRowsChecked: false,
  enlargedImage: null
};

const dpa = createSlice({
  name: 'dpa',
  initialState,
  reducers: {
    generateDpaFeed() {},
    getFeedImagesStatus() {},
    getMappings() {},
    getValidatedDpaColumnData() {},
    getValidateDpaColumnDataStudio() {},
    setAdId(state, action) {
      state.adId = action.payload;
    },
    setDpaUrl(state, action) {
      state.dpaUrl = action.payload;
    },
    setNumberOfImages(state, action) {
      state.numberOfImages = action.payload;
    },
    setImageRows(state, action) {
      state.imageRows = action.payload;
    },
    setImagesReadyCount(state, action) {
      state.imagesReadyCount = action.payload;
    },
    resetUrlAndImageCount(state, action) {
      state.numberOfImages = 0;
      state.dpaUrl = '';
      state.imageRows = [];
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    clearErrorMessage(state, action) {
      state.errorMessage = '';
    },
    setDcoId(state, action) {
      state.dcoId = action.payload;
    },
    setValidatedDpaColumnData(state, action) {
      state.dpaFetchedData = action.payload;
    },
    updateMappedColumnsData(state, action) {
      const { dpaKey, data } = action.payload;
      const index = state.mappings.findIndex((group) => group.dpa_key === dpaKey);
      state.mappings[index] = { dpa_key: dpaKey, ...data };
    },
    updateOptionalMappedColumnsData(state, action) {
      const { dpaKey, data } = action.payload;
      const mappingsIndex = state.mappings.findIndex((mapping) => mapping.dpa_key === dpaKey);
      const optionalIndex = state.optionalMappings.findIndex(
        (mapping) => mapping.dpa_key === data.dpa_key
      );
      // Put the replaced optional mapping back in the optionalMappings list.
      // And put the new optional mapping in its place
      if (mappingsIndex !== -1) {
        const mapping = state.mappings.splice(mappingsIndex, 1, data)[0];
        state.optionalMappings.push(mapping);
      }

      if (optionalIndex !== -1) {
        state.optionalMappings.splice(optionalIndex, 1)[0];
      }
    },
    addOptionalMapping(state, action) {
      const index = state.mappings.findIndex((mapping) => !mapping.dpa_key);
      if (index === -1) {
        state.mappings.push({ dpa_key: null, dco_key: null, row_status: null });
      }
    },
    useOptionalMapping(state, action) {
      const { dpaKey } = action.payload;
      const index = state.optionalMappings.findIndex((mapping) => mapping.dpa_key === dpaKey);
      if (index !== -1) {
        const mapping = state.optionalMappings.splice(index, 1)[0];
        state.mappings.push(mapping);
      }
    },
    removeOptionalMapping(state, action) {
      const { dpaKey } = action.payload;

      const index = state.mappings.findIndex((mapping) => mapping.dpa_key === dpaKey);
      if (index !== -1) {
        const mapping = state.mappings.splice(index, 1)[0];
        state.optionalMappings.push(mapping);
      }
    },
    updateDpaSeekTime(state, action) {
      state.seekTime = action.payload;
    },
    createDpaSource(state, action) {},
    resetDpaState(state, action) {
      state.mappings = initialState.mappings;
      state.optionalMappings = initialState.optionalMappings;
    },
    setContinueWithInvalidRowsChecked(state, action) {
      state.continueWithInvalidRowsChecked = action.payload;
    },
    setRemoveInvalidRowsChecked(state, action) {
      state.removeInvalidRowsChecked = action.payload;
    },
    setEnlargedImage(state, action) {
      state.enlargedImage = action.payload;
    },
    resetEnlargedImage(state, action) {
      state.enlargedImage = null;
    }
  }
});

export const {
  setAdId,
  setDcoId,
  setDpaUrl,
  setErrorMessage,
  setNumberOfImages,
  setImageRows,
  setImagesReadyCount,
  resetUrlAndImageCount,
  clearErrorMessage,
  getValidatedDpaColumnData,
  getValidateDpaColumnDataStudio,
  setValidatedDpaColumnData,
  updateMappedColumnsData,
  addOptionalMapping,
  updateOptionalMappedColumnsData,
  useOptionalMapping,
  removeOptionalMapping,
  updateDpaSeekTime,
  generateDpaFeed,
  getFeedImagesStatus,
  createDpaSource,
  getMappings,
  resetDpaState,
  setContinueWithInvalidRowsChecked,
  setRemoveInvalidRowsChecked,
  setEnlargedImage,
  resetEnlargedImage
} = dpa.actions;
export default dpa.reducer;
