/* eslint-disable import/prefer-default-export */
import keycloak from '../../keycloak';
import { getWithoutHeaders } from './basicCalls';
import store from '../redux/store';

const LAMBDA_URL = window._env_.REACT_ENV_LAMBDA_URL;

async function lambdaRequest(path, method, data) {
  method = method || 'GET';

  const storeState = store.getState();
  const accountId = storeState.auth.currentAccountId;

  var headers = new Headers();
  headers.append('zuuvi-account-id', accountId);
  headers.append('Authorization', `Bearer ${keycloak.token}`);

  const requestOptions = {
    method: 'POST',
    headers: headers,
    mode: 'cors'
  };

  if (data) {
    requestOptions.body = JSON.stringify(data);
    requestOptions.headers['Content-Type'] = 'application/json';
  }

  const responseValue = await fetch(LAMBDA_URL + path, requestOptions);
  return responseValue;
}

async function getStatusMessage() {
  return getWithoutHeaders(`${LAMBDA_URL}zuuvi/get_status`);
}

async function getCampaignPreviewData(campaignId) {
  return getWithoutHeaders(`${LAMBDA_URL}preview/${campaignId}`);
}

export { lambdaRequest, getStatusMessage, getCampaignPreviewData };
