import React, { useState, useRef } from 'react';
import useEventListener from '../../../../utils/hooks/useEventListener';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';

import { CirclePlusIcon } from '../../../assets/icons';
import MediumIconButton from '../../common/MediumIconButton';
import CreateTemplateForm from './CreateTemplateForm';

const AddTemplateButton = () => {
  const [showTemplateForm, setShowTemplateForm] = useState(false);
  const capturePosthogData = usePosthogCapture();
  const addTemplateRef = useRef();

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && addTemplateRef && addTemplateRef.current) {
        if (!event.path.includes(addTemplateRef.current)) {
          setShowTemplateForm(false);
        }
      }
    },
    document
  );

  const createTemplateButtonData = {
    title: 'Template',
    tooltip: 'Create template',
    icon: (
      <CirclePlusIcon
        fill={showTemplateForm ? '#4792E2' : 'white'}
        className='icon-hover'
        width='20px'
        height='20px'
      />
    ),
    id: 'show-create-template'
  };

  return (
    <div ref={addTemplateRef} style={{ textAlign: 'center', marginTop: 23 }}>
      <MediumIconButton
        text={createTemplateButtonData.title}
        tooltip={createTemplateButtonData.tooltip}
        id={createTemplateButtonData.id}
        icon={createTemplateButtonData.icon}
        key={createTemplateButtonData.id}
        onHandleClick={() => {
          capturePosthogData({ event: 'show-create-template-form', type: 'template' });
          setShowTemplateForm(!showTemplateForm);
        }}
      />
      {showTemplateForm && <CreateTemplateForm closeFunc={() => setShowTemplateForm(false)} />}
    </div>
  );
};

export default AddTemplateButton;
