import { useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { flat, getBoundingClientRect, useWindowDimensions } from '../../../helpers';

export default function LayerOverlay({ canvasPosition }) {
  const { presentLayers, scale, animation } = useSelector((state) => state.editorSession.studio);
  const { activeLayer, isTextEditable, isNestedTextEditable } = useSelector(
    (state) => state.editorSession
  );
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const flatLayers = useMemo(() => flat(presentLayers), [presentLayers]);
  const activeLayerObj = flatLayers.find((item) => item.uuid === activeLayer);

  const parentSelector = '[id^="layer-"]';
  const defaultLayerSettings = {
    rotation: 0,
    extraRotation: 0,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    transformX: 'center',
    transformY: 'center'
  };

  const [layerOverlaySettings, setLayerOverlaySettings] = useState(defaultLayerSettings);

  const selectedLayerElement =
    activeLayerObj && document.querySelector(`[id^="layer-${activeLayerObj.uuid}"]`)
      ? document.querySelector(`[id^="layer-${activeLayerObj.uuid}"]`).parentElement
      : null;

  const StyledMarker = ({ left, top, right, bottom }) => (
    <div
      style={{
        position: 'absolute',
        left,
        top,
        right,
        bottom,
        width: 20,
        height: 20,
        pointerEvents: 'none'
      }}>
      <span className='styledMarkers' />
    </div>
  );

  function getRotationDegrees(element) {
    const rotateMatch = element.style.transform.match(/rotate3d\([^)]+, ([^)]+)deg\)/);

    if (!rotateMatch) {
      const matrix = window.getComputedStyle(element).transform;
      const matrixMatch = matrix.match(/matrix\(([^)]+)\)/);

      if (!matrixMatch) {
        return 0;
      }

      const values = matrixMatch[1].split(', ');
      const a = parseFloat(values[0]);
      const b = parseFloat(values[1]);
      const angle = Math.atan2(b, a);
      return angle * (180 / Math.PI);
    }

    return parseFloat(rotateMatch[1]);
  }

  useLayoutEffect(() => {
    if (selectedLayerElement) {
      if (selectedLayerElement.closest(parentSelector)) {
        if (selectedLayerElement.closest(parentSelector).style.display !== 'none') {
          const computedStyle = window.getComputedStyle(selectedLayerElement.parentElement);

          const rect = getBoundingClientRect(
            selectedLayerElement.closest(parentSelector).parentElement.parentElement
          );

          setLayerOverlaySettings({
            top: rect.top + activeLayerObj.format.y * (scale / 100),
            left: rect.left + activeLayerObj.format.x * (scale / 100),
            width: parseInt(computedStyle.width) * (scale / 100), // Child width
            height: parseInt(computedStyle.height) * (scale / 100), // Child height
            transformX: rect.width / 2 - activeLayerObj.format.x, // Center of rotation X
            transformY: rect.height / 2 - activeLayerObj.format.y, // Center of rotation Y
            rotation: getRotationDegrees(
              selectedLayerElement.closest(parentSelector).parentElement
            ), // Rotation of parent
            extraRotation: getRotationDegrees(selectedLayerElement) // Rotation of child
          });
        }
      } else {
        setLayerOverlaySettings({
          ...getBoundingClientRect(selectedLayerElement.parentElement),
          rotation: activeLayerObj.format.rotation,
          extraRotation: 0
        });
      }
    }
  }, [selectedLayerElement, scale, windowHeight, windowWidth, canvasPosition]);

  useLayoutEffect(() => {
    if (selectedLayerElement) {
      const observer = new MutationObserver((mutations) => {
        const mutation = mutations[0]; // We always just take the first since we get all attributes anyways

        const isChild = mutation.target.style.transform.includes('rotate3d');
        const selectedElement = isChild
          ? mutation.target
          : mutation.target.querySelector('[style*="rotate3d"]');
        if (selectedElement) {
          if (selectedElement.closest(parentSelector)) {
            if (selectedElement.closest(parentSelector).style.display !== 'none') {
              const computedStyle = window.getComputedStyle(selectedElement.parentElement);

              const rect = getBoundingClientRect(
                selectedElement.closest(parentSelector).parentElement.parentElement
              );

              setLayerOverlaySettings({
                top: rect.top + parseInt(selectedElement.parentElement.style.top) * (scale / 100),
                left:
                  rect.left + parseInt(selectedElement.parentElement.style.left) * (scale / 100),
                width: parseInt(computedStyle.width) * (scale / 100), // Child width
                height: parseInt(computedStyle.height) * (scale / 100), // Child height
                transformX: rect.width / 2 - parseInt(selectedElement.parentElement.style.left), // Center of rotation X
                transformY: rect.height / 2 - parseInt(selectedElement.parentElement.style.top), // Center of rotation Y
                rotation: getRotationDegrees(selectedElement.closest(parentSelector).parentElement), // Rotation of parent
                extraRotation: getRotationDegrees(selectedElement) // Rotation of child
              });
            }
          } else if (selectedElement.childNodes[0].style.display !== 'none') {
            setLayerOverlaySettings({
              ...getBoundingClientRect(selectedElement.parentElement),
              rotation: getRotationDegrees(selectedElement)
            });
          }
        }
      });

      const options = {
        attributes: true,
        attributeFilter: ['style']
      };

      // Since the rotation is applied to one element, and position/size another, we need to observe both
      observer.observe(selectedLayerElement, options);
      observer.observe(selectedLayerElement.parentElement, options);

      return () => observer.disconnect();
    }
  }, [selectedLayerElement, scale]);

  if (!activeLayerObj) return null; // No layer is selected
  if (animation.isPlaying) return null; // Animations is playing
  if (
    activeLayerObj.editor_settings?.hidden ||
    activeLayerObj.editor_settings?.locked ||
    activeLayerObj.settings?.ignored
  )
    return null;

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        pointerEvents: 'none',
        width: layerOverlaySettings.width,
        height: layerOverlaySettings.height,
        left: layerOverlaySettings.left,
        top: layerOverlaySettings.top,
        transform: `rotate3d(0,0,1,${layerOverlaySettings.rotation}deg)`,
        transformOrigin: `${
          layerOverlaySettings.transformX ? `${layerOverlaySettings.transformX}px` : 'center'
        } ${layerOverlaySettings.transformY ? `${layerOverlaySettings.transformY}px` : 'center'}`
      }}>
      <div
        style={{
          outline: 'rgb(71, 146, 226) dashed 1px',
          width: layerOverlaySettings.width,
          height: layerOverlaySettings.height,
          transform: `rotate3d(0,0,1,${
            layerOverlaySettings.extraRotation ? layerOverlaySettings.extraRotation : 0
          }deg)`,
          transformOrigin: 'center center'
        }}>
        {!isTextEditable && !isNestedTextEditable && (
          <>
            <StyledMarker id='top left' left='-10px' top='-10px' />
            <StyledMarker id='middle left' left='-10px' top='calc(50% - 10px)' />
            <StyledMarker id='bottom left' left='-10px' bottom='-10px' />
            <StyledMarker id='top middle' right='calc(50% - 10px)' top='-10px' />
            <StyledMarker id='bottom middle' right='calc(50% - 10px)' bottom='-10px' />
            <StyledMarker id='top right' right='-10px' top='-10px' />
            <StyledMarker id='middle right' right='-10px' top='calc(50% - 10px)' />
            <StyledMarker id='bottom right' right='-10px' bottom='-10px' />
          </>
        )}
      </div>
    </div>
  );
}
