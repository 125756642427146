import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './BaseButton.scss';

function BaseButton(props) {
  const { text, onClick, disabled, hoverColor, children, style, type, id, secondary, className } =
    props;

  const [hover, setHover] = useState(false);

  const handleClick = (e) => {
    // only if production or staging
    onClick(e);
  };

  return (
    <button
      type={type === 'submit' ? 'submit' : 'button'}
      className={`${'add-button'} ${className}`}
      disabled={disabled}
      id={`base-button-${id}`}
      style={{
        ...(secondary && { backgroundColor: '#242424', border: '1px solid #4792E2' }),
        ...(hover && hoverColor
          ? { ...style, border: 'none', backgroundColor: hoverColor }
          : { ...style })
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={(e) => handleClick(e)}>
      <p>{text}</p>
      {children != null && children}
    </button>
  );
}

BaseButton.defaultProps = {
  disabled: false,
  children: null,
  hoverColor: null,
  style: {},
  type: 'button',
  secondary: false,
  onClick: undefined,
  className: 'add-button'
};

BaseButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hoverColor: PropTypes.string,
  children: PropTypes.element,
  style: PropTypes.object,
  type: PropTypes.string,
  secondary: PropTypes.bool,
  className: PropTypes.string
};

export default BaseButton;
