import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dcoSelection: {
    parameterSource: {},
    parameterSourceIncluded: false,
    parameterSourcePreview: false,
    customSource: {},
    customSourceIncluded: false,
    customSourcePreview: false,
    dynamicSource: {},
    dynamicSourceFull: {},
    dynamicSourceIncluded: false,
    dynamicSourcePreview: false,
    dynamicSourceSelectedRow: 0
  },
  activeMenu: {
    menu: 'overview',
    source: '',
    activeOptionNumber: 0,
    activeOptionID: '',
    isError: false
  },
  userInput: {
    name: '',
    description: '',
    url: null,
    file: null,
    code: '',
    selected_rows: [],
    parameter: null,
    time_between_rotations: 60,
    time_between_reparsings: 120,
    number_of_objects: 1,
    export: 'dco',
    addon: null
  },
  sourceData: {
    length: 0,
    totalObjects: 0,
    list: []
  },
  parameterData: [
    {
      parameter: '',
      name: '',
      description: ''
    }
  ],
  overviewData: [],
  editDatasourceModalOpened: false,
  editingDatasource: {},
  editingDatasourceScroll: 0
};

const dco = createSlice({
  name: 'dco',
  initialState,
  reducers: {
    getDynamicContent() {},
    parseDynamicCode() {},
    createDynamicSource() {},
    updateDynamicSource() {},
    createParameterSource() {},
    getOverviewData() {},
    fetchDcoFromTemplate() {},
    chooseDco() {},

    setDcoSelection(state, action) {
      state.dcoSelection = action.payload;
    },
    resetDcoSelection(state) {
      state.dcoSelection = initialState.dcoSelection;
    },
    setActiveMenu(state, action) {
      state.activeMenu = action.payload;
    },
    resetActiveMenu(state) {
      state.activeMenu = initialState.activeMenu;
    },
    setUserInput(state, action) {
      state.userInput = action.payload;
    },
    resetUserInput(state) {
      state.userInput = initialState.userInput;
    },
    setSourceData(state, action) {
      state.sourceData = action.payload;
    },
    resetSourceData(state) {
      state.sourceData = initialState.sourceData;
    },
    setParameterData(state, action) {
      state.parameterData = action.payload;
    },
    resetParameterData(state) {
      state.parameterData = initialState.parameterData;
    },
    addParameterRow(state) {
      state.parameterData = [...state.parameterData, initialState.parameterData[0]];
    },
    setOverviewData(state, action) {
      state.overviewData = action.payload;
    },
    increaseSelectedRow(state, action) {
      if (
        state.dcoSelection.dynamicSourceFull.length - 1 >
        state.dcoSelection.dynamicSourceSelectedRow
      ) {
        state.dcoSelection = {
          ...state.dcoSelection,
          dynamicSourceSelectedRow: state.dcoSelection.dynamicSourceSelectedRow + 1
        };
      } else {
        state.dcoSelection = {
          ...state.dcoSelection,
          dynamicSourceSelectedRow: 0
        };
      }
    },
    resetSelectedRow(state, action) {
      state.dcoSelection = {
        ...state.dcoSelection,
        dynamicSourceSelectedRow: 0
      };
    },
    setEditDatasourceModalOpened(state, action) {
      state.editDatasourceModalOpened = action.payload;
    },
    setEditingDatasource(state, action) {
      state.editingDatasource = action.payload;
    },
    setEditingDatasourceScroll(state, action) {
      state.editingDatasourceScroll = action.payload;
    }
  }
});

export const {
  getDynamicContent,
  parseDynamicCode,
  createDynamicSource,
  updateDynamicSource,
  createParameterSource,
  getOverviewData,
  setDcoSelection,
  resetDcoSelection,
  setActiveMenu,
  resetActiveMenu,
  setUserInput,
  resetUserInput,
  setSourceData,
  resetSourceData,
  setParameterData,
  resetParameterData,
  addParameterRow,
  setOverviewData,
  fetchDcoFromTemplate,
  chooseDco,
  resetSelectedRow,
  increaseSelectedRow,
  setEditDatasourceModalOpened,
  setEditingDatasource,
  setEditingDatasourceScroll
} = dco.actions;
export default dco.reducer;
