import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledModal = styled.div`
  border: 1px solid #747474;
  color: white;
  padding: 30px;
  margin: 220px auto 0;
  border-radius: 4px;
  background: rgba(49, 49, 49, 1);
  z-index: 10000;
  ${(props) =>
    props.exportModal &&
    ` @media (max-width: 1700px) {
      margin: 85px auto 0;
  }`}
`;
const ModalWrapper = (props) => {
  const { children, modalRef, style, id, exportModal } = props;
  return (
    <StyledModal ref={modalRef} style={style} id={id} exportModal={exportModal}>
      {children}
    </StyledModal>
  );
};

ModalWrapper.defaultProps = {
  style: {},
  exportModal: false
};

ModalWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  exportModal: PropTypes.bool,
  modalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  children: PropTypes.node.isRequired
};

export default ModalWrapper;
