import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { useSelector } from 'react-redux';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor } from 'slate';
import posthog from 'posthog-js';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import TextColorPicker from './TextColorPicker';
import {
  BoldTextIcon,
  ItalicTextIcon,
  UnderlineTextIcon,
  BackgroundColorTextIcon,
  ColorTextIcon,
  TextFormatClearIcon,
  ToolbarDynamicIcon,
  StrikeThroughTextIcon
} from '../../assets/icons';
import hasDcoTagInText from '../../../utils/hasDcoTagInText';

import FormatButton from './FormatButton';
import Menu from './Menu';
import DcoSelector from './DcoSelector';

const HoveringToolbar = (props) => {
  const { toolbarRef, layerObject, value, isEditorMenu } = props;

  const dcoSelection = useSelector((state) => state.dco.dcoSelection);
  const [selectedColorPicker, setSelectedColorPicker] = useState(null);
  const [colorPickerOpen, setColorPickerOpen] = useState({ isOpened: false, type: '' });
  const [isDcoSelectionOpen, setIsDcoSelectionOpen] = useState(false);

  const ref = useRef();
  const editor = useSlate();
  // maybe get rid of the use effect completely
  useEffect(() => {
    editor.selection && showToolbar(editor.selection);
  });

  useEffect(() => {
    if (editor.selection && Editor.string(editor, editor.selection) === '') {
      setSelectedColorPicker(null);
      setColorPickerOpen({ isOpened: false, type: '' });
      setIsDcoSelectionOpen(false);
    }
  }, [editor.selection]);

  const regularButtonData = [];

  function getMarkValue(mark, list) {
    let markValue = '#8B8B8B';

    function checkChildren(children) {
      let childMarkValue = '#8B8B8B';

      if ('children' in children) {
        childMarkValue = checkChildren(children.children);
      } else {
        children.forEach((child) => {
          if (mark in child) childMarkValue = child[mark];
        });
      }
      return childMarkValue;
    }

    if (list && list.length > 0) markValue = checkChildren(list[0]);

    return markValue;
  }

  const textFunctions = [
    { format: 'bold', icon: <BoldTextIcon />, func: 'toggle' },
    { format: 'italic', icon: <ItalicTextIcon />, func: 'toggle' },
    { format: 'strikethrough', icon: <StrikeThroughTextIcon />, func: 'toggle' },
    { format: 'underline', icon: <UnderlineTextIcon />, func: 'toggle' },
    {
      format: 'color',
      icon: (
        <ColorTextIcon
          fill={editor.selection ? getMarkValue('color', editor.getFragment()) : '#8B8B8B'}
        />
      ),
      func: 'picker'
    },
    {
      format: 'bgColor',
      icon: (
        <BackgroundColorTextIcon
          fill={editor.selection ? getMarkValue('bgColor', editor.getFragment()) : '#8B8B8B'}
        />
      ),
      func: 'picker'
    },
    { format: 'clearAllMarks', icon: <TextFormatClearIcon />, func: 'clear' }
  ];

  let showTextButton = false;
  try {
    showTextButton = editor.selection && Editor.string(editor, editor.selection) !== '';
  } catch (e) {
    showTextButton = false;
  }

  if (showTextButton) {
    if (!hasDcoTagInText(editor.getFragment())) {
      regularButtonData.push(...textFunctions);
    }

    if (
      dcoSelection.parameterSourceIncluded ||
      dcoSelection.dynamicSourceIncluded ||
      dcoSelection.customSourceIncluded
    ) {
      // push dco button to second last
      // so the last one is dco
      regularButtonData.splice(6, 0, {
        format: 'dcoText',
        icon: <ToolbarDynamicIcon />,
        func: 'addDco'
      });
    }
  }

  function showToolbar(selection) {
    const el = ref.current;
    if (!el) {
      return;
    }

    if (!selection || !ReactEditor.isFocused(editor)) {
      el.removeAttribute('style');
      return;
    }

    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();

    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
    el.style.opacity = '1';
  }

  return (
    regularButtonData.length !== 0 && (
      <Portal toolbarRef={toolbarRef}>
        <Menu className='hovering-toolbar-menu' ref={ref}>
          {regularButtonData.map((data) => (
            <FormatButton
              key={data.format}
              format={data.format}
              icon={data.icon}
              func={data.func}
              disabled={false}
              colorPickerOpen={colorPickerOpen}
              onColorPickerSet={(type) => {
                if (type === '') {
                  setSelectedColorPicker(null);
                  setColorPickerOpen({ isOpened: false, type: '' });
                } else {
                  setSelectedColorPicker(type);
                  setColorPickerOpen(
                    type === selectedColorPicker
                      ? { isOpened: !colorPickerOpen.isOpened, type }
                      : { isOpened: true, type }
                  );
                }
              }}
              isDcoSelectionOpen={isDcoSelectionOpen}
              onDcoSelection={(type) => {
                type === ''
                  ? setIsDcoSelectionOpen(false)
                  : setIsDcoSelectionOpen(!isDcoSelectionOpen);
              }}
            />
          ))}
          {colorPickerOpen.isOpened && colorPickerOpen.type === selectedColorPicker && (
            <TextColorPicker format={selectedColorPicker} isEditorMenu={isEditorMenu} />
          )}
          {isDcoSelectionOpen && (
            <DcoSelector
              isEditorMenu={isEditorMenu}
              isPositionExtended={showTextButton && hasDcoTagInText(editor.getFragment())}
              setIsDcoSelectionOpen={setIsDcoSelectionOpen}
              value={value}
            />
          )}
        </Menu>
      </Portal>
    )
  );
};
export default HoveringToolbar;
export const Portal = ({ children, toolbarRef }) =>
  typeof document === 'object'
    ? ReactDOM.createPortal(<div ref={toolbarRef}>{children}</div>, document.body)
    : null;
