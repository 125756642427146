import PropTypes from 'prop-types';
import * as SentryBrowser from '@sentry/browser';

function SentryWrapper(props) {
  const { children, template } = props;

  const attachmentUrlFromDsn = (dsn, eventId) => {
    const { host, path, projectId, port, protocol, user } = dsn;
    return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
      path !== '' ? `/${path}` : ''
    }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
  };

  SentryBrowser.addGlobalEventProcessor((event) => {
    if (
      event.message === 'savingError' ||
      event.message === 'tokenError' ||
      (event.message && event.message.includes('fallBackError'))
    ) {
      try {
        const client = SentryBrowser.getCurrentHub().getClient();
        const endpoint = attachmentUrlFromDsn(client.getDsn(), event.event_id);
        const templateAttachment = JSON.parse(JSON.stringify(template));
        delete templateAttachment._history;

        const formData = new FormData();

        formData.append(
          'my-attachment',
          new Blob([JSON.stringify(templateAttachment)], {
            type: 'application/json'
          }),
          'template.json'
        );
        // remove the extra form data if tokenError is being sent
        if (event.message === 'tokenError') {
          formData.delete('my-attachment');
        }
        // TODO: sentry getting called to much by error, fix error.
        fetch(endpoint, {
          method: 'POST',
          body: formData
        }).catch((ex) => {
          // we have to catch this otherwise it throws an infinite loop in Sentry
          console.error(ex);
        });
        return event;
      } catch (ex) {
        console.error(ex);
      }
    }
  });

  return { ...children };
}

SentryWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  template: PropTypes.object.isRequired
};

export default SentryWrapper;
