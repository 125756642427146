import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBoundingClientRect, useWindowDimensions } from '../../../helpers';

export default function CanvasOverlay({ canvasPosition }) {
  const { background, overflow } = useSelector((state) => state.userSettings);
  const { scale } = useSelector((state) => state.editorSession.studio);
  const { selectedFormatId } = useSelector((state) => state.editorSession);
  const [bannerPosition, setBannerPosition] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const banner = document.getElementById('banner');

  useLayoutEffect(() => {
    if (banner) {
      const { left, top, width, height } = getBoundingClientRect(banner);

      setBannerPosition({
        left,
        top,
        width: windowWidth - left - width,
        height: windowHeight - top - height
      });
    }
  }, [banner, canvasPosition, windowHeight, windowWidth, scale, selectedFormatId]);

  const StyledOverlay = ({ id, height, width, left, right, top, bottom }) => (
    <div
      id={id}
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        backgroundColor: background,
        width,
        height,
        left,
        top,
        right,
        bottom
      }}
    />
  );

  if (overflow === 'visible') return null;
  return (
    <div id='canvas-overlay'>
      <StyledOverlay
        id='canvas-overlay-left'
        height='100%'
        width={bannerPosition.left}
        left={0}
        top={0}
      />
      <StyledOverlay
        id='canvas-overlay-top'
        width='100%'
        height={bannerPosition.top}
        left={0}
        top={0}
      />
      <StyledOverlay
        id='canvas-overlay-right'
        height='100%'
        width={bannerPosition.width}
        right={0}
        bottom={0}
      />
      <StyledOverlay
        id='canvas-overlay-bottom'
        width='100%'
        height={bannerPosition.height}
        left={0}
        bottom={0}
      />
    </div>
  );
}
