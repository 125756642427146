/* eslint-disable no-shadow */
import React, { useState, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './InputNumber.scss';
import { StyledInput } from './styled/AttributeInputs';

function InputNumber(props) {
  const {
    id,
    className,
    value,
    onUpdate,
    min,
    max,
    withDecimal,
    step,
    defaultValue,
    disabled,
    leftAligned,
    style,
    autoFocus,
    overlapingChange,
    overlaping,
    isMulticlicktag,
    dataCy
  } = props;
  const parseValue = (value) => (withDecimal ? parseFloat(value).toFixed(1) : parseInt(value));
  const [shiftPressed, onShiftPressed] = useState(false);
  const [localValue, setLocalValue] = useState(parseValue(value));
  const inputRef = createRef();
  const smallStep = step;
  const bigStep = step * 10;

  useEffect(() => {
    setLocalValue(parseValue(value));
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      inputRef.current.blur();
    } else if (e.key === 'Shift') {
      onShiftPressed(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Shift') {
      onShiftPressed(false);
    }
  };

  const validate = (value) => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }

    return parseValue(value);
  };

  const handleStep = (value) => {
    if (shiftPressed) {
      const newValue = withDecimal ? parseFloat(value) : parseInt(value);
      if (value < localValue) {
        return newValue - bigStep + smallStep;
      }
      if (value > localValue) {
        return newValue + bigStep - smallStep;
      }
    }

    return value;
  };

  const handleChange = (e) => {
    if (isMulticlicktag) {
      // set overlaping prop to true if multiclicktag intervals overlap
      overlapingChange(e);
    }
    const { value } = e.target;
    const isChangedbySmallStep =
      parseFloat(value) === parseFloat(localValue) + smallStep ||
      parseFloat(value) === parseFloat(localValue) - smallStep;
    setLocalValue(handleStep(value));

    if (shiftPressed || isChangedbySmallStep) {
      onUpdate(validate(handleStep(value)));
    }
  };

  const handleBlur = () => {
    // overlaping is for multipleClicktag intervals
    if (!localValue || localValue === '' || localValue === '-' || overlaping) {
      onUpdate(defaultValue);
      setLocalValue(defaultValue);
    } else {
      onUpdate(validate(localValue));
      setLocalValue(validate(localValue));
    }
  };

  return (
    <StyledInput
      autoFocus={autoFocus}
      id={id}
      leftAligned={leftAligned}
      className={className}
      type='number'
      step={smallStep}
      ref={inputRef}
      min={min}
      max={max}
      value={localValue}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      // temporary until we redesign animation
      style={{ ...style }}
      overlapingChange={overlapingChange}
      overlaping={overlaping}
      isMulticlicktag={isMulticlicktag}
      data-cy={dataCy}
    />
  );
}

InputNumber.defaultProps = {
  min: -99999,
  max: 99999,
  withDecimal: false,
  step: 1,
  defaultValue: 0,
  disabled: false,
  className: '',
  leftAligned: false,
  overlapingChange: null,
  overlaping: false,
  isMulticlicktag: false,
  dataCy: ''
};

InputNumber.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onUpdate: PropTypes.func.isRequired,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  withDecimal: PropTypes.bool,
  step: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  leftAligned: PropTypes.bool,
  overlapingChange: PropTypes.func,
  overlaping: PropTypes.bool,
  isMulticlicktag: PropTypes.bool,
  dataCy: PropTypes.string
};

export default InputNumber;
