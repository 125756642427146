/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-use-before-define */
/* eslint-disable no-restricted-syntax */
import { takeEvery, call, put } from 'redux-saga/effects';
import * as api from '../../api';
import { getCampaign, getTemplate, setDynamicContent, setItems, setTitle } from '../slices/preview';

const PREVIEW_URL = `${window._env_.REACT_ENV_TEMPLATE_STORE_URL}preview`;

export function* handleGetTemplate(action) {
  const { templateId } = action.payload;
  const { data } = yield call(api.templateStore.getTemplatePublicData, templateId);
  const items = [];

  if ('formats' in data) {
    for (const format of data.formats) {
      items.push({
        title: format.title,
        key: format.id,
        height: format.height,
        width: format.width,
        x: 0,
        y: 0,
        scale: 1,
        src: `${PREVIEW_URL}/format/${format.id}`
      });
    }
  }

  yield put(setItems(items));
  yield put(setTitle(data.title));
  yield put(setDynamicContent(data.dynamicContent));
}

export function* handleGetCampaign(action) {
  const { campaignId } = action.payload;
  const { data } = yield call(api.creativeSetService.getCreativeSetPublicData, campaignId);

  const items = [];
  const { title } = data;

  for (const creative of data.creatives) {
    items.push({
      title: creative.title,
      key: creative.id,
      height: creative.height,
      width: creative.width,
      x: 0,
      y: 0,
      scale: 1,
      src: `${PREVIEW_URL}/creative/${creative.id}`
    });
  }

  yield put(setItems(items));
  yield put(setTitle(title));
  yield put(setDynamicContent(data.dynamicContent));
}

function* watchPreviewSaga() {
  yield takeEvery(getTemplate, handleGetTemplate);
  yield takeEvery(getCampaign, handleGetCampaign);
}

export default watchPreviewSaga;
