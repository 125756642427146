import './Tabs.scss';

function Tabs(props) {
  const { items, activeId, onClick, containerStyle, itemStyle } = props;

  return (
    <div
      className='tabs-container'
      style={{
        ...containerStyle
      }}>
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`tab-item ${activeId === item.id ? 'tab-item-active' : ''}`}
          style={{
            ...itemStyle
          }}
          onClick={() => {
            onClick(item, index);
          }}>
          <p style={{ width: '100%', margin: 0 }}> {item.label}</p>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
