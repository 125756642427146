import { useDispatch } from 'react-redux';
import { statusMessageToast } from '../redux/slices/toasts';
import useAuthorization from '../../utils/hooks/useAuthorization';

const StatusMessage = () => {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const show = isAuthorized({ featureFlag: 'do-not-delete-status-message' });

  if (show) {
    dispatch(statusMessageToast());
  }

  return null;
};

export default StatusMessage;
