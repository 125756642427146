/* eslint-disable no-restricted-syntax */
import { takeEvery, call, put, select, fork } from 'redux-saga/effects';
import toast from 'react-hot-toast';
import * as api from '../../api';
import {
  getDynamicContent,
  setActiveMenu,
  setUserInput,
  setSourceData,
  createDynamicSource,
  resetActiveMenu,
  resetUserInput,
  resetSourceData,
  createParameterSource,
  resetParameterData,
  getOverviewData,
  setOverviewData,
  fetchDcoFromTemplate,
  setDcoSelection,
  resetDcoSelection,
  chooseDco,
  updateDynamicSource,
  parseDynamicCode
} from '../slices/dco';
import { updateLayerSettings } from '../slices/template';
import { rerenderSlate, rerenderSlateEditor } from '../slices/editorSession';
import fixDcoTagInText from '../../../utils/fixDcoTagInText';
import {
  resetDpaState,
  setContinueWithInvalidRowsChecked,
  setDcoId,
  setValidatedDpaColumnData
} from '../slices/dpa';
// selectors need to be exported for testing
export const selectDco = (state) => state.dco;
export const selectDcoSelection = (state) => state.dco.dcoSelection;
export const selectUserInput = (state) => state.dco.userInput;
export const selectActiveMenu = (state) => state.dco.activeMenu;
export const selectParameterData = (state) => state.dco.parameterData;
export const selectTemplate = (state) => state.template;
export const selectDpa = (state) => state.dpa;

export function* handleGetDynamicContent(action) {
  const change = action.payload;
  const userInput = yield select(selectUserInput);
  const activeMenu = yield select(selectActiveMenu);

  yield put(resetDpaState());
  yield put(setContinueWithInvalidRowsChecked(false));
  yield toast.loading(`Parsing ${userInput.file ? 'file...' : 'link...'}`, {
    id: 'get-dynamic-content'
  });
  const request = {};
  if (userInput.file) {
    request.file = userInput.file;
  } else {
    request.url = userInput.url;
  }

  try {
    if (activeMenu.source === 'raptor') {
      const raptorRegex =
        /^https:\/\/api\.raptorsmartadvisor\.com\/v1\/[0-9]+\/[a-zA-Z]*\/[0-9]+\/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-(:?8|9|A|B)[a-f0-9]{3}-[a-f0-9]{12})/i;
      const matchesRegex = userInput.url.match(raptorRegex);
      if (!matchesRegex) {
        yield put(setActiveMenu({ ...activeMenu, isError: true }));
        yield toast.error(`Provided link is not a valid Raptor URL`, {
          id: 'get-dynamic-content'
        });
        return;
      }
    }

    if (activeMenu.source !== 'dpa') {
      const { data } = yield call(api.dcoService.getDynamicContent, request);

      // Make a check if the data returned a valid Array
      if (!Array.isArray(data)) {
        yield put(setActiveMenu({ ...activeMenu, isError: true }));
        yield toast.error(`We could not parse your ${userInput.file ? 'file.' : 'link.'}`, {
          id: 'get-dynamic-content'
        });
        return;
      }

      const { length } = Object.keys(data[0]);
      const list = [];

      for (const object of data) {
        for (const [key, value] of Object.entries(object)) {
          list.push({ key, value });
        }
      }

      yield put(
        setSourceData({
          length,
          totalObjects: data.length,
          list
        })
      );

      yield put(
        setUserInput({
          ...userInput,
          number_of_objects: data.length > 10 ? 10 : data.length
        })
      );
    }

    if (activeMenu.source === 'dpa') {
      // If DPA: We validate the fetched DATA.
      const data = yield call(api.dpaService.getValidatedDpaColumnData, null, null, userInput.url);
      yield put(setDcoId(data.dynamic_id));
      yield put(setValidatedDpaColumnData(data));
      yield put(setUserInput({ ...userInput, export: 'dpa' }));
    }

    yield put(
      setActiveMenu({
        ...activeMenu,
        activeOptionNumber: activeMenu.activeOptionNumber + change,
        isError: false
      })
    );

    yield toast.success(`${userInput.file ? 'File' : 'Link'} parsed.`, {
      id: 'get-dynamic-content'
    });
  } catch (e) {
    yield put(setActiveMenu({ ...activeMenu, isError: true }));
    yield toast.error(`We could not parse your ${userInput.file ? 'file.' : 'link.'}`, {
      id: 'get-dynamic-content'
    });
  }
}

export function* handleParseDynamicCode(action) {
  yield toast.loading(`Parsing the inserted code`, {
    id: 'parse-dynamic-code'
  });

  const change = action.payload;
  const userInput = yield select(selectUserInput);
  const activeMenu = yield select(selectActiveMenu);

  const { data } = yield call(api.dcoService.parseDynamicCode, { code: userInput.code });

  const { profileId, dynamicContent } = data;

  if (!data || !dynamicContent || !dynamicContent.length || !profileId) {
    yield put(setActiveMenu({ ...activeMenu, isError: true }));
    yield toast.error(`We could not parse your Google Studio code.`, {
      id: 'parse-dynamic-code'
    });
    return;
  }

  yield put(
    setSourceData({
      length: dynamicContent.length,
      totalObjects: dynamicContent.length,
      list: dynamicContent
    })
  );

  yield put(
    setUserInput({
      ...userInput,
      number_of_objects: dynamicContent.length > 10 ? 10 : dynamicContent.length,
      selected_rows: dynamicContent.map((_, index) => ({ row: index })),
      addon: 'google-studio',
      google_studio_profile_id: profileId
    })
  );

  yield put(
    setActiveMenu({
      ...activeMenu,
      activeOptionNumber: activeMenu.activeOptionNumber + change,
      isError: false
    })
  );

  yield toast.success(`Code parsed.`, {
    id: 'parse-dynamic-code'
  });
}

export function* handleCreateDynamicSource() {
  const userInput = yield select(selectUserInput);
  const activeMenu = yield select(selectActiveMenu);

  try {
    if (activeMenu.source !== 'dpa') {
      yield toast.loading(`Creating DCO source ...`, {
        id: 'create-dynamic-source'
      });
    }

    const { data } = yield call(api.dcoService.createDynamicSource, userInput);

    yield put(resetActiveMenu());
    yield put(resetUserInput());
    yield put(resetSourceData());

    if (activeMenu.source !== 'dpa') {
      yield toast.success(`Successfully created DCO source!`, {
        id: 'create-dynamic-source'
      });
    }

    return data;
  } catch (e) {
    if (activeMenu.source !== 'dpa') {
      yield toast.error('Error happened creating the source!', {
        id: 'create-dynamic-source'
      });
    }
  }
}

export function* handleCreateParameterSource() {
  const userInput = yield select(selectUserInput);
  const parameterData = yield select(selectParameterData);

  yield toast.loading('Creating parameter source ...', {
    id: 'create-parameter-source'
  });

  const formdata = {};
  formdata.keys = JSON.stringify(parameterData);
  formdata.name = userInput.name;
  formdata.description = userInput.description;

  try {
    yield call(api.dcoService.createParameterSource, formdata);

    yield put(resetActiveMenu());
    yield put(resetUserInput());
    yield put(resetSourceData());
    yield put(resetParameterData());

    yield toast.success('Successfully created parameter source!', {
      id: 'create-parameter-source'
    });
  } catch (e) {
    yield toast.error('Error happened creating the parameter source!', {
      id: 'create-parameter-source'
    });
  }
}

export function* handleGetOverviewData() {
  try {
    const { data } = yield call(api.dcoService.getOverviewData);
    yield put(setOverviewData(data));
  } catch (e) {
    yield toast.error('Error happened while getting data!', { id: 'get-overview-data' });
  }
}

export function* handleChooseDco(action) {
  const source = action.payload;
  const dcoSelection = yield select(selectDcoSelection);

  if (source.type === 'file' || source.type === 'link') {
    try {
      const { data } = yield call(api.dcoService.getFullData, source.id);
      yield put(
        setDcoSelection({
          ...dcoSelection,
          dynamicSourceFull: data,
          dynamicSource: source,
          dynamicSourceIncluded: true,
          dynamicSourceSelectedRow: 0
        })
      );
    } catch (e) {
      yield toast.error('Error happened while getting data!', { id: 'get-full-dco-data' });
    }
  } else if (source.type === 'parameter') {
    yield put(
      setDcoSelection({
        ...dcoSelection,
        parameterSource: source,
        parameterSourceIncluded: true
      })
    );
  } else if (source.type === 'custom') {
    yield put(
      setDcoSelection({
        ...dcoSelection,
        customSource: source,
        customSourceIncluded: true
      })
    );
  }
}

export function* handleFetchDcoFromTemplate() {
  const templateState = yield select(selectTemplate);
  const { dynamic } = templateState.object_data;

  yield put(resetDcoSelection());

  try {
    const { data } = yield call(api.dcoService.getOverviewData);

    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      if (
        obj.id === dynamic.dynamic_uuid ||
        obj.id === dynamic.parameter_uuid ||
        ('custom' in dynamic &&
          'id' in dynamic.custom &&
          dynamic.custom.id &&
          obj.id === dynamic.custom.id)
      ) {
        yield call(handleChooseDco, { payload: obj });
        yield call(handleFixDcoForOldTextLayers);
        yield put(rerenderSlateEditor());
        yield put(rerenderSlate());
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* handleFixDcoForOldTextLayers() {
  const templateState = yield select(selectTemplate);
  const dcoSelection = yield select(selectDcoSelection);

  for (const templateLayer of templateState.object_data.layers) {
    if ('dynamic' in templateLayer && templateLayer.dynamic.dynamic_key === 'dco_inserted') {
      for (const format of templateState.formats) {
        if (!('object_data' in format)) break;
        if (!('layers' in format.object_data)) break;

        const layer = format.object_data.layers[templateLayer.id];

        if (layer) {
          if (templateLayer.type === 'text') {
            if (!('settings' in layer)) break;
            if (!('text' in layer.settings)) break;
            yield fork(handleFixDCOForLayer, layer, templateLayer.id, format, dcoSelection);
          }
        }
      }
    }
  }
}

export function* handleFixDCOForLayer(layer, layerId, format, dcoSelection) {
  const newText = yield call(fixDcoTagInText, layer.settings.text, dcoSelection);
  yield put(updateLayerSettings(format.id, layerId, { text: newText }));
}

export function* handleUpdateDynamicSource(action) {
  yield toast.loading('Updating data source ...', { id: 'update-dynamic-source' });

  const datasource = action.payload;
  const response = yield call(api.dcoService.updateDynamicSource, datasource);

  if (response.status === 200) {
    yield fork(handleGetOverviewData);
    yield toast.success('Successfully updated the datasource!', { id: 'update-dynamic-source' });
  } else {
    yield toast.error('Error happened while updating the datasource!', {
      id: 'update-dynamic-source'
    });
  }
}

function* watchDco() {
  yield takeEvery(getDynamicContent, handleGetDynamicContent);
  yield takeEvery(parseDynamicCode, handleParseDynamicCode);
  yield takeEvery(createDynamicSource, handleCreateDynamicSource);
  yield takeEvery(createParameterSource, handleCreateParameterSource);
  yield takeEvery(getOverviewData, handleGetOverviewData);
  yield takeEvery(chooseDco, handleChooseDco);
  yield takeEvery(fetchDcoFromTemplate, handleFetchDcoFromTemplate);
  yield takeEvery(updateDynamicSource, handleUpdateDynamicSource);
}

export default watchDco;
