import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BaseButton from '../common/BaseButton';
import theme from '../../assets/theme';

const StyledBox = styled.div`
  width: 228px;
  height: 100px;
  background: #313131;
  border: 1px solid #747474;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 13px 0px 17px 0px;
  cursor: ${(props) => (props.isLocked ? 'pointer' : 'auto')};
`;
const StyledTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
`;
const StyledIconWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 2px;
`;

function DynamicTypeCard(props) {
  const { title, icon, onButtonClick, isLocked, onLockedBoxClick, id } = props;
  return (
    <StyledBox isLocked={isLocked} onClick={isLocked ? onLockedBoxClick : undefined}>
      <StyledTitle>{title}</StyledTitle>
      <StyledIconWrapper> {icon}</StyledIconWrapper>
      <BaseButton
        id={id}
        text='Add source'
        onClick={onButtonClick}
        hoverColor={theme.colors.dcoPurple}
        disabled={isLocked}
        style={{
          height: 25,
          width: 106,
          borderRadius: 4,
          border: '1px solid #747474',
          ...(isLocked ? { color: '#989898', cursor: 'auto' } : { cursor: 'pointer' })
        }}
      />
    </StyledBox>
  );
}
DynamicTypeCard.defaultProps = {
  onButtonClick: undefined,
  onLockedBoxClick: undefined
};

DynamicTypeCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  onButtonClick: PropTypes.func,
  isLocked: PropTypes.bool.isRequired,
  onLockedBoxClick: PropTypes.func
};
export default DynamicTypeCard;
