import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UnlockedLockIcon, LockedLockIcon, EyeIcon, HiddenEyeIcon } from '../../assets/icons';
import { getPathById, renderIcon } from '../../assets/utils';
import TimelineAnimation from './TimelineAnimation';

import './TimelineLayer.scss';

import {
  resetActiveAnimation,
  setActiveLayer,
  setRightMenu,
  updateLayersBelowClick
} from '../../redux/slices/editorSession';
import { updateLayerEditorSettings } from '../../redux/slices/template';

function TimelineLayer({
  layerId,
  layer,
  onSetIsNestableDragDisabled,
  onUpdateLayerName,
  collapseIcon,
  layers,
  onSetOpenedAnimations,
  onSetOpenedEffects,
  openedEffects,
  openedAnimations
}) {
  const dispatch = useDispatch();
  const path = getPathById(layerId, layers);
  const parent = path.length - 1 > 0 ? layers[path.slice(0, -1)[0]] : null;
  const [isLayerNameEditable, setIsLayerNameEditable] = useState(false);
  const { activeLayer, selectedLayers, selectedFormatId, editorType } = useSelector(
    (state) => state.editorSession
  );

  const isMultiSelected = selectedLayers.includes(layer.uuid);
  const rowHeight = layer.animations?.length > 1 ? layer.animations.length * 50 : 50;
  const rowWidth = path.length > 1 ? 186 : 196;

  const style = {
    width: rowWidth,
    backgroundColor: layer.uuid === activeLayer ? 'rgba(71, 146, 226,0.2)' : '#242424',
    height: rowHeight
  };

  const isOverlap = (a, b) =>
    !(
      a.format.x + a.format.width < b.format.x ||
      a.format.x > b.format.x + b.format.width ||
      a.format.y + a.format.height < b.format.y ||
      a.format.y > b.format.y + b.format.height
    );

  const overlappingLayers = (clickedLayerId) => {
    const selectedLayer = layers.find((layer) => layer.id === clickedLayerId);

    const overlapLayers = layers.filter((layer) => {
      if (!layer || !selectedLayer) return false;
      return (
        layer.format.z_index >= selectedLayer.format.z_index && isOverlap(selectedLayer, layer)
      );
    });
    dispatch(updateLayersBelowClick(overlapLayers));
  };

  const styleWithBlueBorder = {
    width: rowWidth - 9,
    backgroundColor: layer.uuid === activeLayer ? 'rgba(71, 146, 226, 0.2)' : '#242424',
    height: rowHeight - 9,
    border: '5px solid rgba(71, 146, 226, 0.2)'
  };

  return (
    <div key={`nestable-list-item-${layer.uuid}`} className='nestable-list-item'>
      <div
        id={`timeline-layer-container-${layer.uuid}`}
        className='timeline-layer-name'
        onClick={() => {
          overlappingLayers(layerId);
          dispatch(resetActiveAnimation());
          dispatch(setActiveLayer(layerId));
          dispatch(setRightMenu('edit'));
        }}
        style={isMultiSelected ? styleWithBlueBorder : style}>
        {collapseIcon}
        <div
          className='timeline-layer-name-icon-left'
          onDoubleClick={() => {
            setIsLayerNameEditable(true);
            onSetIsNestableDragDisabled(true);
          }}>
          {renderIcon(layer.type)}
          {isLayerNameEditable ? (
            <input
              className='layer-name-input'
              type='text'
              defaultValue={layer.name}
              onBlur={() => {
                setIsLayerNameEditable(false);
                onSetIsNestableDragDisabled(false);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setIsLayerNameEditable(false);
                  onSetIsNestableDragDisabled(false);
                }
              }}
              onChange={(e) => onUpdateLayerName(e.target.value, layer.uuid)}
              autoFocus
            />
          ) : (
            <div className='text-small'> {layer.name}</div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '100%',
            alignItems: 'center',
            flexBasis: '51px'
          }}>
          <button
            style={{
              height: '30px',
              cursor: editorType === 'banner' ? 'not-allowed' : 'pointer'
            }}
            type='button'
            id={`timeline-layer-container-lock-unlock-layer-${layer.uuid}`}
            onClick={() => {
              if (editorType !== 'banner') {
                dispatch(
                  updateLayerEditorSettings(selectedFormatId, layer.uuid, {
                    locked: !layer.editor_settings?.locked
                  })
                );
              }
            }}>
            {layer.editor_settings?.locked ||
            (editorType === 'banner' && parent?.editor_settings?.locked) ? (
              <LockedLockIcon className='margin-right' fill='#4792E2' />
            ) : (
              <UnlockedLockIcon className='margin-right' />
            )}
          </button>
          <button
            style={{ height: '30px', marginTop: layer.editor_settings?.hidden ? 2 : 0 }}
            type='button'
            id={`timeline-layer-container-hide-show-layer-${layer.uuid}`}
            onClick={() =>
              dispatch(
                updateLayerEditorSettings(selectedFormatId, layer.uuid, {
                  hidden: !layer.editor_settings?.hidden
                })
              )
            }>
            {layer.editor_settings?.hidden ? (
              <HiddenEyeIcon className='margin-right-pointer' height={19} fill='#4792E2' />
            ) : (
              <EyeIcon className='margin-right-pointer' />
            )}
          </button>
        </div>
      </div>
      <div
        className='timeline-layer-animations-container'
        key={`timeline-layer-animations-container-${layer.uuid}`}
        style={{ width: path.length > 1 ? 'calc(100% - 186px)' : 'calc(100% - 196px)' }}>
        {!('animations' in layer) || layer.animations.length === 0 ? (
          <div className='animation-wrapper'>
            <div className='timeline-layer-light-line' />
          </div>
        ) : (
          layer.animations?.map((animation, animationIndex) => (
            <TimelineAnimation
              key={`timeline-layer-animation-${animation.uuid}`}
              animation={animation}
              animationIndex={animationIndex}
              onSetOpenedAnimations={onSetOpenedAnimations}
              onSetOpenedEffects={onSetOpenedEffects}
              openedEffects={openedEffects}
              openedAnimations={openedAnimations}
              onSetIsNestableDragDisabled={onSetIsNestableDragDisabled}
              layer={layer}
            />
          ))
        )}
      </div>
    </div>
  );
}
TimelineLayer.defaultProps = { collapseIcon: null };

TimelineLayer.propTypes = {
  layerId: PropTypes.string.isRequired,
  layer: PropTypes.object.isRequired,
  onSetIsNestableDragDisabled: PropTypes.func.isRequired,
  onUpdateLayerName: PropTypes.func.isRequired,
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSetOpenedAnimations: PropTypes.func.isRequired,
  onSetOpenedEffects: PropTypes.func.isRequired,
  openedEffects: PropTypes.arrayOf(PropTypes.string).isRequired,
  openedAnimations: PropTypes.arrayOf(PropTypes.string).isRequired,
  collapseIcon: PropTypes.object
};
export default TimelineLayer;
