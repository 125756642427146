/* eslint-disable no-console */
import React from 'react';
import { useDispatch } from 'react-redux';
import { setTemplate } from '../redux/slices/template';
import usePosthogCapture from '../../utils/hooks/usePosthogCapture';
import BaseButton from './common/BaseButton';
import useAuthorization from '../../utils/hooks/useAuthorization';

function BreakTheWorld() {
  const dispatch = useDispatch();
  const isAuthorized = useAuthorization();
  const show = isAuthorized({ featureFlag: 'break-the-world' });
  const capturePosthogData = usePosthogCapture();

  const handleBreak = () => {
    try {
      capturePosthogData({
        event: 'break-the-world',
        type: 'break-the-world',
        menu: 'left'
      });
      throw new Error('Whoops! - You broke the world');
    } catch (e) {
      dispatch(setTemplate(null));
      console.error(`${e.name}: ${e.message}`);
    }
  };
  return (
    <>
      {show && (
        <BaseButton
          text='Break the world'
          id='break-the-world'
          style={{ position: 'absolute', bottom: '10px' }}
          onClick={handleBreak}
        />
      )}
    </>
  );
}

export default BreakTheWorld;
