import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedQuickExportType } from '../../redux/slices/quickExport';
import { useClickOutside } from '../../assets/utils';

import {
  BlueCircleArrowUpIcon,
  CloseIcon,
  AdformIcon,
  AdnamiIcon,
  AdtechIcon,
  BidtheatreIcon,
  DeltaProjectsIcon,
  MainDpaIcon,
  GoogleAdsIcon,
  GMPIcon,
  IframeIcon,
  MP4Icon,
  PdfIcon,
  ImagesIcon,
  XandrIcon
} from '../../assets/icons';
import ModalOverlayPortal from '../common/ModalOverlayPortal';
import ModalWrapper from '../common/ModalWrapper';
import {
  StyledContentContainer,
  StyledTitle,
  StyledButton,
  StyledNameBox,
  StyledIconBox,
  StyledMediumText,
  StyledSmallText
} from './styled/StyledQuickExports';
import SelectedQuickExportTypeContainer from './SelectedQuickExportTypeContainer';

const StyledCloseIcon = styled.div`
  position: absolute;
  margin-top: 23px;
  margin-left: 594px;
  cursor: pointer;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '3px')};
  justify-content: space-between;
  &::after {
    content: '';
    flex: auto;
  }
`;

const StyledLink = styled.span`
  color: #4a90e2;
  text-decoration: underline;
  cursor: pointer;
`;

const QuickExportOption = (props) => {
  const { icon, title, enabled, id } = props;
  const dispatch = useDispatch();
  return (
    <StyledButton
      enabled={enabled}
      onClick={(e) => {
        e.preventDefault();
        if (enabled) {
          dispatch(setSelectedQuickExportType({ id, title }));
        }
      }}>
      <StyledIconBox className='export-icon' id={id} enabled={enabled}>
        {icon}
      </StyledIconBox>
      <StyledNameBox limitedWidth>
        <p>{title}</p>
      </StyledNameBox>
    </StyledButton>
  );
};

const QuickExportTypeSelection = (props) => {
  const { onCloseIconClick, featureRef, allOptionsEnabled } = props;
  const selectedQuickExportType = useSelector((state) => state.quickExport.selectedQuickExportType);
  // enabled will be handled based on subscription and feature flags in future

  useClickOutside(
    featureRef,
    () => {
      onCloseIconClick();
    },
    []
  );

  const quickExportOptions = [
    {
      title: 'Adform',
      id: 'adform',
      icon: <AdformIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Adnami',
      id: 'adnami',
      icon: <AdnamiIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Adtech',
      id: 'adtech',
      icon: <AdtechIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Bidtheatre',
      id: 'bidtheatre',
      icon: <BidtheatreIcon style={{ marginTop: 6 }} />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Delta Projects',
      id: 'deltaProjects',
      icon: <DeltaProjectsIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Catalog Ads',
      id: 'dpa',
      icon: <MainDpaIcon lightningFill='white' style={{ marginTop: 6 }} />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Google Ads',
      id: 'googleAds',
      icon: <GoogleAdsIcon />,
      enabled: true
    },
    {
      title: 'Google Marketing Platform',
      id: 'gmp',
      icon: <GMPIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'Iframe',
      id: 'iframe',
      icon: <IframeIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'JPG',
      id: 'jpg',
      icon: <ImagesIcon width='32' height='32' />,
      enabled: true
    },
    {
      title: 'MP4',
      id: 'mp4',
      icon: <MP4Icon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'PDF',
      id: 'pdf',
      icon: <PdfIcon />,
      enabled: allOptionsEnabled
    },
    {
      title: 'PNG',
      id: 'png',
      icon: <ImagesIcon width='32' height='32' />,
      enabled: true
    },
    {
      title: 'Xandr',
      id: 'xandr',
      icon: <XandrIcon />,
      enabled: allOptionsEnabled
    }
  ];

  return (
    <ModalOverlayPortal>
      <ModalWrapper
        exportModal
        modalRef={featureRef}
        style={{ width: 630, padding: 0 }}
        id='quick-export-modal'>
        <StyledCloseIcon onClick={onCloseIconClick} data-cy='export-modal-close-icon'>
          <CloseIcon stroke='white' width={13} height={13} />
        </StyledCloseIcon>
        {selectedQuickExportType.id === '' &&
          (allOptionsEnabled ? (
            <StyledContentContainer regularPadding padding='30px 30px  23px'>
              <StyledTitle fontWeigh='500'>Pick desired format to export</StyledTitle>
              <StyledSmallText>Maybe write something about our cool formats</StyledSmallText>
              <StyledButtonsContainer>
                {quickExportOptions.map((option) => (
                  <QuickExportOption
                    id={option.id}
                    icon={option.icon}
                    title={option.title}
                    enabled={option.enabled}
                    key={option.id}
                  />
                ))}
              </StyledButtonsContainer>
            </StyledContentContainer>
          ) : (
            <>
              <StyledContentContainer lineBottom padding='30px 30px 15px 30px'>
                <StyledTitle fontWeigh='500'>Choose export destination</StyledTitle>
                <StyledSmallText>
                  The Quick export allows you to choose one of these three export options
                </StyledSmallText>
                <StyledButtonsContainer marginTop='14px'>
                  {quickExportOptions.map(
                    (option) =>
                      option.enabled === true && (
                        <QuickExportOption
                          id={option.id}
                          icon={option.icon}
                          title={option.title}
                          enabled={option.enabled}
                          key={option.id}
                        />
                      )
                  )}
                </StyledButtonsContainer>
              </StyledContentContainer>
              {/* TODO: this will change a bit whe we have a cler decision about text for limited options */}
              <StyledContentContainer limitedDisabled padding='15px 30px 23px'>
                <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'baseline' }}>
                  <BlueCircleArrowUpIcon />
                  <StyledMediumText marginLeft='5px'>
                    Do you want access to all export destinations?
                  </StyledMediumText>
                </div>
                <StyledSmallText padding='15px 30px 30px 30px'>
                  {`Upgrade your account to access premium display networks, MP4 and dynamic content. `}
                  <StyledLink>Contact sales</StyledLink> for more information.
                </StyledSmallText>
                <StyledButtonsContainer>
                  {quickExportOptions.map(
                    (option) =>
                      option.enabled === false && (
                        <QuickExportOption
                          id={option.id}
                          icon={option.icon}
                          title={option.title}
                          enabled={option.enabled}
                          key={option.id}
                        />
                      )
                  )}
                </StyledButtonsContainer>
              </StyledContentContainer>
            </>
          ))}
        {selectedQuickExportType.id !== '' && <SelectedQuickExportTypeContainer />}
      </ModalWrapper>
    </ModalOverlayPortal>
  );
};
QuickExportTypeSelection.propTypes = {
  onCloseIconClick: PropTypes.func.isRequired,
  allOptionsEnabled: PropTypes.bool.isRequired,
  featureRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default QuickExportTypeSelection;
