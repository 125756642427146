import styled from 'styled-components';

export default styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  min-width: 110px;
  color: #fff;
  z-index: 99999;

  .options-button {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 4px;
    padding: 0 3px 5px 3px;
    background-color: ${(props) => props.styles.backgroundColor};
  }

  .options-list {
    position: absolute;
    top: 32px;
    min-width: 110px;
    background: #2a2a2a;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.4);
    z-index: 9;
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const OptionItem = styled.button`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 22px;
  text-align: left;
  font-size: 11px;

  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:last-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  &:hover {
    background-color: ${(props) => {
      if (props.warning) {
        return props.theme.colors.zuuviRed;
      }
      return props.theme.colors.zuuviBlue;
    }};
  }
`;
