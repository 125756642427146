/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */

// This utility function is only temporary.
// We will not be needing this in the future.
// It is just to convert the old text layer dco (${}) to the new one (outline)
// Remove when enough time has past, or try to query database to see if we can remove (could be looking for dco_key = "dco_inserted" etc.)

const dcoColorArray = [
  { color: '#F5CC5C', name: 'Zuuvi Lemon' },
  { color: '#6ADAAE', name: 'Zuuvi Mint' },
  { color: '#DE6E4D', name: 'Zuuvi Ruby ' },
  { color: '#4A90E2', name: 'Zuuvi Blue' },
  { color: '#FAE6AD', name: 'Zuuvi 50% Lemon' },
  { color: '#BBE3D2', name: 'Zuuvi 50% Mint' },
  { color: '#EFB6A6', name: 'Zuuvi 50% Ruby' },
  { color: '#A8C6E5', name: 'Zuuvi 50% Blue' }
];

function findPosition(matchList, match, leafIndex) {
  return match.index + leafIndex;
}

const fixDcoTagInText = (leafList, dcoSelection, counter = 0) => {
  const newLeafList = JSON.parse(JSON.stringify(leafList));
  for (const [leafIndex, leaf] of newLeafList.entries()) {
    if (!leaf.dynamic_key && !leaf.dynamic_uuid && leaf.text) {
      const matchList = [...leaf.text.matchAll(/\${([^}]+)}/gm)];

      if (!matchList.length !== 0) {
        matchList.forEach((match) => {
          // Handle dynamic sources
          if (dcoSelection.dynamicSourceIncluded) {
            dcoSelection.dynamicSource.keys.forEach((dcoKey) => {
              if (match[1] === dcoKey) {
                leaf.text = leaf.text.replace(match[0], '');
                const { text: notUsedValue, ...oldValues } = leaf;
                const newLeaf = {
                  text: match[0],
                  dynamic_key: dcoKey,
                  dynamic_uuid: dcoSelection.dynamicSource.id,
                  dynamic_color: dcoColorArray[counter].color,
                  ...oldValues
                };
                newLeafList.splice(findPosition(matchList, match, leafIndex), 0, newLeaf);
              }
            });
          }

          // Handle parameter sources
          if (dcoSelection.parameterSourceIncluded) {
            dcoSelection.parameterSource.keys.forEach((dcoKey) => {
              if (match[1] === dcoKey.parameter) {
                leaf.text = leaf.text.replace(match[0], '');
                const { text: notUsedValue, ...oldValues } = leaf;
                const newLeaf = {
                  text: match[0],
                  dynamic_key: dcoKey.parameter,
                  dynamic_uuid: dcoSelection.parameterSource.id,
                  dynamic_color: dcoColorArray[counter].color,
                  ...oldValues
                };
                newLeafList.splice(findPosition(matchList, match, leafIndex), 0, newLeaf);
              }
            });
          }

          // Handle custom sources
          if (dcoSelection.customSourceIncluded) {
            dcoSelection.customSource.json.keys.forEach((dcoKey) => {
              if (match[1] === dcoKey.parameter) {
                leaf.text = leaf.text.replace(match[0], '');
                const { text: notUsedValue, ...oldValues } = leaf;
                const newLeaf = {
                  text: match[0],
                  dynamic_key: dcoKey.parameter,
                  dynamic_uuid: dcoSelection.customSource.id,
                  dynamic_color: dcoColorArray[counter].color,
                  ...oldValues
                };
                newLeafList.splice(findPosition(matchList, match, leafIndex), 0, newLeaf);
              }
            });
          }
          counter += 1;
        });
      }
    }

    if (leaf.children) {
      leaf.children = fixDcoTagInText(leaf.children, dcoSelection, counter);
    }
  }
  return newLeafList;
};

export default fixDcoTagInText;
