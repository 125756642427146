/* eslint-disable react/jsx-props-no-spreading */

import styled from 'styled-components';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import zuuviMiniLoaderWhite from '../../assets/zuuviMiniLoaderWhite.json';
import zuuviMiniLoaderBlack from '../../assets/zuuviMiniLoaderBlack.json';
import zuuviLargeLoader from '../../assets/zuuviLargeLoader.json';

const ZuuviLoader = (props) => {
  const { size, color } = props;

  const getLoaderStyle = {
    height: size === 'mini' ? '20px' : '200px',
    width: size === 'mini' ? '20px' : '200px'
  };

  const getAnimationData = () => {
    if (size === 'mini') {
      if (color === 'white') {
        return zuuviMiniLoaderWhite;
      }
      return zuuviMiniLoaderBlack;
    }
    return zuuviLargeLoader;
  };

  return (
    <Lottie
      style={getLoaderStyle}
      loop
      autoplay
      animationData={getAnimationData()}
      initialSegment={size === 'mini' ? null : [20, 220]}
    />
  );
};
ZuuviLoader.defaultProps = {
  size: 'mini',
  color: 'black'
};

ZuuviLoader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string
};
export default ZuuviLoader;
