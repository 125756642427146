import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  TimelineSelectTimePlayIcon,
  TimelineSelectTimePauseIcon,
  TimelineSelectTimeStopIcon
} from '../../assets/icons';
import DpaStepsComponentWrapper from './styled/DpaStepsComponentWrapper';
import ZuuviLoader from './ZuuviLoader';

const TimelineButton = styled.div`
  margin: 0 10px;
  cursor: pointer;
`;
const SeekTimeSpan = styled.span`
  width: 20px;
`;
const TimelineButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
`;
const Iframe = styled.iframe`
  transform-origin: left top;
  margin: 0 auto;
  display: block;
`;
const TimelineBarWrapper = styled.div`
  width: 100%;
  text-align: center;
  height: 30px;
`;
const TimelineBar = styled.input`
  -webkit-appearance: none;
  width: ${(props) => (props.barWidth ? `${props.barWidth};` : '400px;')}
  margin: 0;
  height: 1px;
  background: #717171;
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 11px;
    width: 11px;
    background-color: #4a90e2;
    border-radius: 50%;
  }
`;
const LoaderWrapper = styled.div`
  ${(props) => (props.environment === 'studio' ? 'margin:150px 0px;' : 'margintop: 25%;')}
  left: 50%;
  display: flex;
  justify-content: center;
  height: 100%;
`;

function BannerTimelineSelector(props) {
  const {
    adHtml,
    seekTime,
    environment,
    adWidth,
    adHeight,
    onHandleTimelineChange,
    barWidth,
    stopButtonHidden,
    exportTimeline
  } = props;

  const iframeRef = useRef(null);

  const [timeline, setTimeline] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  // get timeline function from current banner
  useEffect(() => {
    if (
      iframeRef &&
      iframeRef.current &&
      iframeRef.current.contentWindow.document.readyState === 'complete'
    ) {
      iframeRef.current.onload = () => {
        const { zuuviTimeline } = iframeRef.current.contentWindow;
        setTimeline(zuuviTimeline);
        zuuviTimeline.repeat(0);
        zuuviTimeline.restart();
        zuuviTimeline.pause();
      };
    }
  }, [adHtml]);

  function stopAnimation() {
    if (timeline) {
      timeline.restart();
      timeline.pause();
      setIsPlaying(false);
      onHandleTimelineChange('0.00');
    }
  }

  useEffect(() => {
    if (timeline) {
      timeline.eventCallback('onUpdate', () => {
        if (isPlaying) {
          onHandleTimelineChange(timeline.time().toFixed(2));
          if (timeline.time() === timeline.duration()) {
            timeline.repeat(0);
            stopAnimation();
          }
        } else {
          timeline.pause();
        }
      });
    }
  }, [timeline, isPlaying]);

  function playAnimation() {
    if (isPlaying && timeline.isActive()) {
      timeline.pause();
      setIsPlaying(false);
    } else {
      timeline.play();
      setIsPlaying(true);
    }
  }

  function pauseAnimation() {
    if (isPlaying && timeline.isActive()) {
      timeline.pause();
      setIsPlaying(false);
    }
  }
  function handleTimelineChange(value) {
    timeline.seek(value);
    onHandleTimelineChange(value);
  }

  const iframeMaxHeight = 350;
  const iframeMaxWidth = 350;

  const seekTimeString = seekTime < 10 ? `0${seekTime}` : seekTime;

  const [hoverPlay, setHoverPlay] = useState(false);
  const [hoverPause, setHoverPause] = useState(false);
  const [hoverStop, setHoverStop] = useState(false);
  return (
    <DpaStepsComponentWrapper environment={environment} exportTimeline>
      {adHtml ? (
        <>
          <div
            style={{
              marginTop: 30,
              width: iframeMaxWidth,
              height: iframeMaxHeight
            }}>
            <Iframe
              title='bannerIframe'
              ref={iframeRef}
              srcDoc={adHtml}
              width={adWidth}
              height={adHeight}
              style={{
                transform: `scale(${Math.min(
                  iframeMaxHeight / adHeight,
                  iframeMaxWidth / adWidth
                )})`,
                transformOrigin: `${iframeMaxWidth / adWidth > 1 ? 'top' : ''} ${
                  iframeMaxHeight / adHeight > 1 ? 'left' : ''
                }`,
                margin: '0 0',
                pointerEvents: 'none'
              }}
              frameBorder='0'
            />
          </div>
          <TimelineButtonWrapper id='timeline'>
            <div style={{ display: 'flex' }}>
              <TimelineButton
                isPlaying={isPlaying}
                onClick={() => playAnimation()}
                onMouseEnter={() => setHoverPlay(true)}
                onMouseLeave={() => setHoverPlay(false)}>
                <TimelineSelectTimePlayIcon fill={isPlaying || hoverPlay ? '#4A90E2' : '#828282'} />
              </TimelineButton>
              <TimelineButton
                onClick={() => pauseAnimation()}
                onMouseEnter={() => setHoverPause(true)}
                onMouseLeave={() => setHoverPause(false)}>
                <TimelineSelectTimePauseIcon fill={hoverPause ? '#4A90E2' : '#828282'} />
              </TimelineButton>
              {!stopButtonHidden && (
                <TimelineButton
                  onClick={() => stopAnimation()}
                  onMouseEnter={() => setHoverStop(true)}
                  onMouseLeave={() => setHoverStop(false)}>
                  <TimelineSelectTimeStopIcon fill={hoverStop ? '#4A90E2' : '#828282'} />
                </TimelineButton>
              )}
            </div>
            <TimelineBar
              barWidth={barWidth}
              type='range'
              id='timeline'
              value={seekTimeString}
              onChange={(e) => handleTimelineChange(e.target.value)}
              name='timeline'
              min='0'
              step='0.01'
              max={timeline && timeline.duration()}
            />
            <div
              style={{
                width: '45px',
                textAlign: 'center',
                display: 'flex',
                color: '#4792E2',
                fontWeight: '600'
              }}>
              <SeekTimeSpan>{seekTimeString.charAt(0)}</SeekTimeSpan>
              <SeekTimeSpan>{seekTimeString.charAt(1)}</SeekTimeSpan>
              <span>:</span>
              <SeekTimeSpan>{seekTimeString.charAt(3)}</SeekTimeSpan>
              <SeekTimeSpan>{seekTimeString.charAt(4)}</SeekTimeSpan>
            </div>
          </TimelineButtonWrapper>
          <TimelineBarWrapper />
        </>
      ) : (
        <LoaderWrapper environment={environment}>
          <ZuuviLoader size='mini' color={environment === 'studio' ? 'white' : 'default'} />
        </LoaderWrapper>
      )}
    </DpaStepsComponentWrapper>
  );
}
BannerTimelineSelector.defaultProps = {
  adHtml: null,
  barWidth: null,
  stopButtonHidden: false,
  exportTimeline: false
};

BannerTimelineSelector.propTypes = {
  adHtml: PropTypes.string,
  barWidth: PropTypes.string,
  stopButtonHidden: PropTypes.bool,
  exportTimeline: PropTypes.bool,
  seekTime: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  adWidth: PropTypes.number.isRequired,
  adHeight: PropTypes.number.isRequired,
  onHandleTimelineChange: PropTypes.func.isRequired
};
export default BannerTimelineSelector;
