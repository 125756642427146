import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setUserInput } from '../../redux/slices/dco';
import Menu from './Menu';

import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';

import Card from '../common/Card';
import BaseButton from '../common/BaseButton';
import { getDynamicSources } from './Helpers';
import theme from '../../assets/theme';

const Headline = styled.span`
  display: block;
  text-align: center;
  font-size: 25px;
  line-height: 25px;
  color: #fff;
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  place-content: space-between;
  flex-flow: wrap;
  overflow: auto;
`;

function CreateMenu(props) {
  const { modalRef } = props;
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();

  const { activeMenu, userInput } = useSelector((state) => state.dco);

  const Header = () => <Headline>Create new data source</Headline>;

  const Content = () => (
    <ContentWrapper id='dynamic-create-menu-content'>
      {getDynamicSources().map(
        (data) =>
          data.active && (
            <Card
              key={data.id}
              flex={2}
              title={data.name}
              description={data.description}
              icon={<data.icon />}
              special={data.special ? data.special : false}
              footer={
                <BaseButton
                  id={data.id}
                  style={{
                    border: '1px solid #525252',
                    marginBottom: '0px',
                    ...(data.special && {
                      border: 0,
                      color: theme.colors.dcoPurple,
                      textDecoration: 'underline'
                    })
                  }}
                  text={data.buttonText ? data.buttonText : 'Create new'}
                  onClick={() => {
                    capturePosthogData({
                      event: data.posthogEventName,
                      type: 'dco'
                    });
                    if (data.buttonClick) {
                      data.buttonClick();
                    } else {
                      dispatch(
                        setActiveMenu({
                          ...activeMenu,
                          menu: 'configure',
                          source: data.id,
                          activeOptionNumber: 0
                        })
                      );
                      dispatch(setUserInput({ ...userInput, name: data.defaultSourceName }));
                    }
                  }}
                  hoverColor={!data.special && theme.colors.dcoPurple}
                />
              }
            />
          )
      )}
    </ContentWrapper>
  );

  return <Menu Header={Header} Content={Content} modalRef={modalRef} />;
}

CreateMenu.defaultProps = {};

CreateMenu.propTypes = {
  modalRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired
};

export default CreateMenu;
