import React, { useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import posthog from 'posthog-js';
import usePosthogCapture from '../../../../utils/hooks/usePosthogCapture';

import {
  ZuuviLogo,
  ZuuviLogoSantaHat,
  LogOutIcon,
  GoToStudioOrCreativeManagerIcon
} from '../../../assets/icons';
import MediumIconButton from '../../common/MediumIconButton';
import AddTemplateButton from './AddTemplateButton';
import AddGroupButton from './AddGroupButton';
import SwitchClientButton from './SwitchClientButton';
import { getUserAccounts } from '../../../../utils/userAccounts';
import '../../../assets/global.scss';
import useAuthorization from '../../../../utils/hooks/useAuthorization';

const LeftMenu = () => {
  const { keycloak } = useKeycloak();
  const isAuthorized = useAuthorization();
  const capturePosthogData = usePosthogCapture();
  const showTemplateGroups = isAuthorized({ featureFlag: 'template-groups' });
  const userClients = getUserAccounts();

  const logOutButtonData = {
    title: 'Logout',
    tooltip: 'Logout',
    icon: <LogOutIcon className='icon-hover' />,
    posthogData: {
      event: 'log-out',
      type: 'log-out',
      menu: 'left'
    },
    id: 'logout'
  };

  const goToCreativeManagerButtonData = {
    title: 'Manager',
    tooltip: 'Go to Zuuvi Creative Manager',
    icon: <GoToStudioOrCreativeManagerIcon className='icon-hover' />,
    posthogData: {
      event: 'redirect-to-creative-manager',
      type: 'redirect',
      menu: 'left'
    },
    id: 'goToCreativeManager',
    href: window._env_.REACT_ENV_APP_URL
  };

  const LEFT_BOTTOM_BUTTONS_NAV_DATA = [goToCreativeManagerButtonData, logOutButtonData];

  return (
    <div id='left-menu-wrapper'>
      {isAuthorized({ featureFlag: 'santa-hat-logo' }) ? (
        <ZuuviLogoSantaHat className='zuuvi-logo' />
      ) : (
        <ZuuviLogo className='zuuvi-logo' />
      )}
      <div>
        <AddTemplateButton />
        {showTemplateGroups && <AddGroupButton />}
      </div>
      <div className='left-nav-bottom-buttons'>
        {userClients.length > 1 && <SwitchClientButton />}
        {LEFT_BOTTOM_BUTTONS_NAV_DATA.map((button) =>
          button.id === 'logout' ? (
            <MediumIconButton
              text={button.title}
              tooltip={button.tooltip}
              id={button.id}
              icon={button.icon}
              key={button.id}
              onHandleClick={() => {
                capturePosthogData(button.posthogData);
                if (button.id === 'logout') {
                  keycloak.logout();
                  posthog.reset();
                }
              }}
            />
          ) : (
            <a
              className='icon-link'
              href={button.href}
              target='_blank'
              rel='noreferrer'
              key={button.id}>
              <MediumIconButton
                text={button.title}
                tooltip={button.tooltip}
                icon={button.icon}
                id={button.id}
                onHandleClick={() => capturePosthogData(button.posthogData)}
              />
            </a>
          )
        )}
      </div>
    </div>
  );
};

export default LeftMenu;
