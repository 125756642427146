const replaceDcoTagInText = (leaf, dcoSelection) => {
  const newLeaf = JSON.parse(JSON.stringify(leaf));

  if (newLeaf.dynamic_key && newLeaf.dynamic_uuid) {
    // Handle dynamic sources
    if (dcoSelection.dynamicSourcePreview && dcoSelection.dynamicSourceIncluded) {
      if (newLeaf.dynamic_uuid === dcoSelection.dynamicSource.id) {
        newLeaf.text =
          dcoSelection.dynamicSourceFull[dcoSelection.dynamicSourceSelectedRow][
            newLeaf.dynamic_key
          ].toString();
      }
    }

    // Handle parameter sources
    if (dcoSelection.parameterSourcePreview && dcoSelection.parameterSourceIncluded) {
      if (newLeaf.dynamic_uuid === dcoSelection.parameterSource.id) {
        for (let i = 0; i < dcoSelection.parameterSource.keys.length; i++) {
          if (newLeaf.dynamic_key === dcoSelection.parameterSource.keys[i].parameter) {
            newLeaf.text = dcoSelection.parameterSource.keys[i].example.toString();
          }
        }
      }
    }

    // Handle custom sources
    if (dcoSelection.customSourcePreview && dcoSelection.customSourceIncluded) {
      if (newLeaf.dynamic_uuid === dcoSelection.customSource.id) {
        for (let i = 0; i < dcoSelection.customSource.json.keys.length; i++) {
          if (newLeaf.dynamic_key === dcoSelection.customSource.json.keys[i].parameter) {
            newLeaf.text = dcoSelection.customSource.json.keys[i].example.toString();
          }
        }
      }
    }
  }

  return newLeaf;
};

export default replaceDcoTagInText;
