import React from 'react';
import styled, { css } from 'styled-components';
import { LeftSmallArrowIcon, RightSmallArrowIcon } from '../../assets/icons';

const sharedStyle = css`
  display: flex;
`;
const StyledPaginationWrapper = styled.div`
  ${sharedStyle}
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
`;
const StyledArrowsWrapper = styled.div`
  ${sharedStyle}
`;
const StyledArrowBox = styled.div`
  height: 15px;
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? '4px' : '0px')};
  width: 15px;
  border: 1px solid #353535;
  border-radius: 2px;
  text-align: center;
`;

const Pagination = (props) => {
  const { onPageChange, totalCount, currentPage } = props;

  const canGoPrevious = currentPage !== 0;
  const canGoNexts = currentPage !== totalCount - 1;
  const onNext = () => {
    if (canGoNexts) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (canGoPrevious) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <StyledPaginationWrapper>
      <div>{`Product ${currentPage + 1} of ${totalCount}`}</div>
      <StyledArrowsWrapper>
        <StyledArrowBox onClick={onPrevious} marginRight>
          <LeftSmallArrowIcon opacity={!canGoPrevious ? 0.2 : 1} />
        </StyledArrowBox>
        <StyledArrowBox onClick={onNext}>
          <RightSmallArrowIcon opacity={!canGoNexts ? 0.2 : 1} />
        </StyledArrowBox>
      </StyledArrowsWrapper>
    </StyledPaginationWrapper>
  );
};

export default Pagination;
