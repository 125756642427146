import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getTemplateTags } from '../../redux/slices/editorSession';
import { setPublic, setTags } from '../../redux/slices/template';
import PublishTemplateButton from './PublishTemplateButton';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import PreviewTemplateButton from './PreviewTemplateButton';
import QuickExportButton from './QuickExportButton';
import useAuthorization from '../../../utils/hooks/useAuthorization';

import { StyledInputLabelWrapper, StyledCheckbox } from '../common/styled/AttributeInputs';

function RightMenuFinalise() {
  const accountId = useSelector((state) => state.auth.currentAccountId);
  const { templateTags } = useSelector((state) => state.editorSession);
  const { public: isPublic, tags } = useSelector((state) => state.template);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplateTags(accountId));
  }, []);

  const options = useMemo(
    () =>
      templateTags.map((tag) => ({
        value: tag.id,
        label: tag.name
      })),
    [templateTags]
  );

  const selectedTags = useMemo(
    () =>
      tags.map((tag) => ({
        value: tag.id,
        label: tag.name
      })),
    [tags]
  );

  const isAuthorized = useAuthorization();
  const allowMakePublic = isAuthorized({ featureFlag: 'make-public' });

  const [formSection, setFormSection] = useState({
    finaliseOpened: true
  });
  const [publishSettings, setPublishSettings] = useState(false);
  const capturePosthogData = usePosthogCapture();

  const handleMakePublic = (checked) => {
    dispatch(setPublic(checked));
  };

  const handleTag = (selectedOptions) => {
    const selectedTagIds = selectedOptions.map((option) => option.value);
    const newSelectedTags = templateTags.filter((tag) => selectedTagIds.includes(tag.id));
    dispatch(setTags(newSelectedTags));
  };

  return (
    <>
      {allowMakePublic && (
        <>
          <ToggleRevealSectionButton handleOnClick={() => setPublishSettings(!publishSettings)}>
            <p className='color-white'>Publish Settings</p>
          </ToggleRevealSectionButton>

          {publishSettings && (
            <div className='template-settings-section'>
              <StyledInputLabelWrapper makepublic>
                <p>Make Public:</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <StyledCheckbox
                    className='center'
                    id='make-public'
                    type='checkbox'
                    checked={isPublic}
                    onChange={(e) => {
                      handleMakePublic(e.target.checked);
                    }}
                  />
                </div>

                <p>Select Tags:</p>
                <Select
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: '#313131',
                      color: 'white',
                      border: '1px solid #313131',
                      borderRadius: '4px',
                      height: 'auto',
                      padding: '0px',
                      width: '100%'
                    }),
                    multiValue: (styles) => ({
                      ...styles,
                      backgroundColor: '#313131',
                      color: 'white'
                    }),
                    multiValueLabel: (styles) => ({
                      ...styles,
                      color: 'white'
                    }),
                    multiValueRemove: (styles) => ({
                      ...styles,
                      color: 'white'
                    }),
                    option: (styles) => ({
                      ...styles,
                      backgroundColor: '#313131',
                      color: 'white'
                    }),
                    menu: (styles) => ({
                      ...styles,
                      backgroundColor: '#313131',
                      color: 'white'
                    }),
                    menuList: (styles) => ({
                      ...styles,
                      backgroundColor: '#313131',
                      color: 'white'
                    }),
                    input: (styles) => ({
                      ...styles,
                      color: 'white'
                    })
                  }}
                  isMulti
                  options={options}
                  value={selectedTags}
                  onChange={(selectedOptions) => {
                    handleTag(selectedOptions);
                  }}
                />
              </StyledInputLabelWrapper>
            </div>
          )}
        </>
      )}

      <ToggleRevealSectionButton
        id='right-submenu-edit-open-close-template-finalise-section'
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'template',
            menu: 'right',
            menu_type: 'template',
            submenu: 'finalise',
            is_open: !formSection.finaliseOpened
          });
          setFormSection({
            ...formSection,
            finaliseOpened: !formSection.finaliseOpened
          });
        }}
        isOpened={formSection.finaliseOpened}>
        <p className='color-white'>Finalise</p>
      </ToggleRevealSectionButton>

      {formSection.finaliseOpened && (
        <div className='template-settings-section'>
          <PublishTemplateButton />
          {isAuthorized({ featureFlag: 'quick-export-access' }) && <QuickExportButton />}
          <PreviewTemplateButton />
        </div>
      )}
    </>
  );
}

export default RightMenuFinalise;
