import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  quickExportBigMenuOpened: false,
  selectedQuickExportType: { id: '', title: '', icon: null },
  seekTime: '0.00',
  formatHtml: null,
  isBeingExported: false
};

const quickExport = createSlice({
  name: 'quickExport',
  initialState,
  reducers: {
    setQuickExportBigMenuOpened(state, action) {
      state.quickExportBigMenuOpened = action.payload;
    },
    setSelectedQuickExportType(state, action) {
      state.selectedQuickExportType = action.payload;
    },
    resetSelectedQuickExportType(state, action) {
      state.selectedQuickExportType = { id: '', title: '', icon: null };
    },
    setAdHtml(state, action) {
      state.adHtml = action.payload;
    },
    updateQuickExportSeekTime(state, action) {
      state.seekTime = action.payload;
    },
    resetQuickExportSeekTime(state, action) {
      state.seekTime = '0.00';
    },
    getFormatHtml() {},
    setFormatHtml(state, action) {
      state.formatHtml = action.payload;
    },
    setIsBeingExported(state, action) {
      state.isBeingExported = action.payload;
    },
    exportTemplate() {}
  }
});
export const {
  setQuickExportBigMenuOpened,
  setSelectedQuickExportType,
  resetSelectedQuickExportType,
  setAdHtml,
  updateQuickExportSeekTime,
  resetQuickExportSeekTime,
  getFormatHtml,
  setFormatHtml,
  exportTemplate,
  setIsBeingExported
} = quickExport.actions;
export default quickExport.reducer;
