import { useDragLayer } from 'react-dnd';
import ItemTypes from './ItemTypes';
import { FolderIcon } from '../../assets/icons';

const CustomDragLayer = () => {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '0',
    height: '0'
  };

  const getItemStyles = (currentOffset) => {
    if (!currentOffset) {
      return {
        display: 'none'
      };
    }
    const { x, y } = currentOffset;

    const transform = `translate(${x - 20}px, ${y - 15}px)`;
    return {
      transform,
      WebkitTransform: transform
    };
  };

  const { itemType, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging()
  }));

  function renderItem() {
    switch (itemType) {
      case ItemTypes.GROUP:
        return (
          <div
            style={{
              width: '45px',
              height: '40px',
              border: '1px solid #353535',
              background: '#242424',
              borderRadius: '4px',
              padding: '4px 2px',
              opacity: 0.7,
              ...getItemStyles(currentOffset)
            }}>
            <FolderIcon width='38px' height='30' fill='#4792E2' />
          </div>
        );
      case ItemTypes.TEMPLATE:
        return (
          <div
            style={{
              width: '45px',
              height: '40px',
              border: '1px solid #353535',
              background: '#242424',
              borderRadius: '4px',
              padding: '4px 2px',
              opacity: 0.7,
              ...getItemStyles(currentOffset)
            }}
          />
        );
      default:
        return null;
    }
  }
  if (!isDragging) {
    return null;
  }
  return <div style={layerStyles}>{renderItem()}</div>;
};

export default CustomDragLayer;
