import React, { useMemo } from 'react';
import './DCOMiniTable.scss';
import { useTable, usePagination } from 'react-table';

import { LeftSmallArrowIcon, RightSmallArrowIcon } from '../../assets/icons';

export default function DCOMiniTable(props) {
  const { data, size, type, currentPage = 0, onDCOSelection } = props;

  const columns = useMemo(
    () => [
      {
        Header: 'Parameter',
        accessor: 'key'
      },
      {
        Header: 'Content',
        accessor: 'value'
      }
    ],
    []
  );

  const parameterColumns = useMemo(
    () => [
      {
        Header: 'Parameter',
        accessor: 'parameter'
      },
      {
        Header: 'Example',
        accessor: 'example'
      }
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns: type === 'dynamic' ? columns : parameterColumns,
      data,
      initialState: { pageIndex: currentPage, pageSize: size },
      autoResetPage: false
    },
    usePagination
  );

  function handlePrevious() {
    onDCOSelection(pageIndex - 1);
    previousPage();
  }

  function handleNext() {
    onDCOSelection(pageIndex + 1);
    nextPage();
  }

  return (
    <>
      <div id={type === 'custom' ? 'dco-mini-table-custom' : 'dco-mini-table'}>
        {type === 'dynamic' && (
          <div className='arrow-wrapper'>
            <button
              type='button'
              onClick={handlePrevious}
              disabled={!canPreviousPage}
              style={{
                background: '#2A2A2A',
                border: '1px solid #353535',
                boxSizing: 'border-box',
                borderRadius: '3px',
                width: '21px',
                height: '21px',
                marginRight: '5px'
              }}>
              <LeftSmallArrowIcon className='left-arrow' opacity={!canPreviousPage ? 0.2 : 1.0} />
            </button>

            <button
              type='button'
              onClick={handleNext}
              disabled={!canNextPage}
              style={{
                background: '#2A2A2A',
                border: '1px solid #353535',
                boxSizing: 'border-box',
                borderRadius: '3px',
                width: '21px',
                height: '21px'
              }}>
              <RightSmallArrowIcon className='right-arrow' opacity={!canNextPage ? 0.2 : 1.0} />
            </button>
          </div>
        )}

        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) => (
                  <th {...column.getHeaderProps()} key={i}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, i) => (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
