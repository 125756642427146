/* eslint-disable import/prefer-default-export */
import axios from 'axios';

const ACCOUNT_SERVICE_URL = window._env_.REACT_ENV_ACCOUNT_SERVICE_URL;

async function getUser(userId, accessToken) {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`
  };
  return axios.get(`${ACCOUNT_SERVICE_URL}/users/${userId}`, { headers });
}

export { getUser };
