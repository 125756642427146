/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { updateCustomGuide, removeCustomGuide } from '../../redux/slices/template';

function CustomGuide(props) {
  const { customGuide, rulerPosition } = props;
  const [isDragging, setDragging] = useState(false);
  const [remove, setRemove] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const { selectedFormatId, editorType } = useSelector((state) => state.editorSession);
  const { scale } = useSelector((state) => state.editorSession.studio);
  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const xEdge = 93;
  const yEdge = 80;

  const isBanner = editorType === 'banner';

  const getBorder = () => {
    if (isDragging === true && remove === true) {
      return '1px solid #df6d4e';
    }

    if (isDragging === true && remove === false) {
      return '1px solid #4a90e2';
    }

    return '1px solid rgba(74, 144, 226, .5)';
  };

  const style = () => {
    if (customGuide && customGuide.x !== undefined) {
      return {
        position: 'relative',
        cursor: isBanner ? '' : 'col-resize',
        borderLeft: getBorder(),
        height: '100%',
        width: '1px'
      };
    }
    if (customGuide && customGuide.y !== undefined) {
      return {
        position: 'relative',
        cursor: isBanner ? '' : 'row-resize',
        borderTop: getBorder(),
        width: '100%',
        height: '1px'
      };
    }
    return {};
  };

  const getGuideSize = useCallback(
    () => ({ x: window.innerWidth - 162, y: window.innerHeight - 106 }),
    [window.innerWidth, window.innerHeight]
  );

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ x: clientX - yEdge - 25, y: clientY - xEdge - 1 });
  };

  const getPositions = () => {
    let xPosition = xEdge;
    let yPosition = yEdge;

    if (customGuide.x !== undefined) {
      xPosition = (customGuide.x / 100) * scale + rulerPosition.x;
    }

    if (customGuide.y !== undefined) {
      yPosition = (customGuide.y / 100) * scale + rulerPosition.y;
    }

    return {
      x: xPosition,
      y: yPosition
    };
  };

  const getTooltipText = () =>
    `${customGuide.x !== undefined ? 'X' : 'Y'}: ${
      customGuide.x !== undefined ? customGuide.x : customGuide.y
    } px`;

  return (
    <Rnd
      data-cy='customGuide'
      scale={1}
      enableResizing={false}
      size={{
        height: customGuide.x !== undefined ? getGuideSize().y : '6px',
        width: customGuide.y !== undefined ? getGuideSize().x : '6px'
      }}
      position={getPositions()}
      dragAxis={customGuide.y !== undefined ? 'y' : 'x'}
      onDragStart={(e, d) => {
        setDragging(true);
      }}
      disableDragging={isBanner}
      onDrag={(e, d) => {
        handleMouseMove(e);
        if (!isBanner) {
          if (customGuide.x !== undefined) {
            if (d.x <= xEdge) {
              setRemove(true);
            } else {
              setRemove(false);
              dispatch(
                updateCustomGuide({
                  formatId: selectedFormatId,
                  customGuide: {
                    uuid: customGuide.uuid,
                    x: Math.round(((d.x - rulerPosition.x) / scale) * 100)
                  }
                })
              );
            }
          }
          if (customGuide.y !== undefined) {
            if (d.y <= yEdge) {
              setRemove(true);
            } else {
              setRemove(false);
              dispatch(
                updateCustomGuide({
                  formatId: selectedFormatId,
                  customGuide: {
                    uuid: customGuide.uuid,
                    y: Math.round(((d.y - rulerPosition.y) / scale) * 100)
                  }
                })
              );
            }
          }
        }
      }}
      onDragStop={(e, d) => {
        setDragging(false);
        if (customGuide.x !== undefined) {
          if (d.x <= xEdge) {
            dispatch(
              removeCustomGuide({ formatId: selectedFormatId, customGuideId: customGuide.uuid })
            );
            capturePosthogData({ event: 'remove-guideline', type: 'guideline' });
          }
        }
        if (customGuide.y !== undefined) {
          if (d.y <= yEdge) {
            dispatch(
              removeCustomGuide({ formatId: selectedFormatId, customGuideId: customGuide.uuid })
            );
            capturePosthogData({ event: 'remove-guideline', type: 'guideline' });
          }
        }
      }}>
      <div
        style={style()}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onMouseMove={handleMouseMove}>
        <span
          style={{
            padding: '4px 6px 4px 6px',
            position: 'absolute',
            top: customGuide.y !== undefined ? '16px' : tooltipPosition.y,
            left: customGuide.x !== undefined ? '16px' : tooltipPosition.x,
            visibility: showTooltip ? 'visible' : 'hidden',
            backgroundColor: 'rgba(36, 36, 36, .75)',
            border: '1px solid #444444',
            borderRadius: '4px',
            fontSize: '10px',
            width: 'max-content',
            height: '12px'
          }}>
          {getTooltipText()}
        </span>
      </div>
    </Rnd>
  );
}

CustomGuide.propTypes = {
  customGuide: PropTypes.object.isRequired,
  rulerPosition: PropTypes.object.isRequired
};

export default CustomGuide;
