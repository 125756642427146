import styled from 'styled-components';

export default styled.a`
  cursor: pointer;
  position: absolute;
  right: 26px;
  top: 6px;
  width: 0px;
  height: 0px;
`;
