/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteGroup,
  moveGroupIntoGroup,
  moveTemplateIntoGroup,
  setGroupId
} from '../../redux/slices/dashboard';
import ItemTypes from '../DragNDrop/ItemTypes';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import { FolderIcon, ExtraDots } from '../../assets/icons';
import useEventListener from '../../../utils/hooks/useEventListener';

const GroupCard = (props) => {
  const { group } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const capturePosthogData = usePosthogCapture();
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleteOption, setShowDeleteOption] = useState(false);
  const groupOptionsReference = useRef();
  const groupShowOptionsReference = useRef();
  const groupOptionsListReference = useRef();

  const showtemplateOptions = () => {
    setShowOptions(!showOptions);
  };

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.GROUP,
      item: group,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    []
  );

  const onDrop = (item, itemType) => {
    switch (itemType.getItemType()) {
      case ItemTypes.GROUP:
        if (item.id !== group.id) {
          const data = {
            groupId: item.id,
            destinationGroupId: group.id
          };
          capturePosthogData({ event: 'move-group-into-group', type: 'group' });
          dispatch(moveGroupIntoGroup(data));
        }
        break;
      case ItemTypes.TEMPLATE:
        if (item.id) {
          const data = {
            templateId: item.id,
            groupId: group.id
          };
          capturePosthogData({ event: 'move-template-into-group', type: 'template' });
          dispatch(moveTemplateIntoGroup(data));
        }
        break;
      default:
    }
  };

  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: [ItemTypes.GROUP, ItemTypes.TEMPLATE],
    // eslint-disable-next-line no-use-before-define
    drop: (item, itemType) => {
      onDrop(item, itemType);
    },
    collect: (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }));

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && groupOptionsReference && groupOptionsReference.current) {
        if (!event.path.includes(groupOptionsReference.current)) {
          setShowOptions(false);
          setShowDeleteOption(false);
        }
      }
    },
    document
  );

  const isActive = canDrop && isOver;
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, []);

  return (
    <div ref={dropRef} role='GroupCard' style={{ opacity: isActive ? 0.5 : 1 }} key={group.id}>
      <div style={{ opacity: isDragging ? 0 : 1 }}>
        <div
          className='template card'
          data-cy='group-card'
          onClick={() => {
            navigate(`/${group.id}`);
            dispatch(setGroupId(group.id));
          }}
          ref={drag}>
          <div className='format-options' ref={groupOptionsReference}>
            <div
              data-cy='format-options-button'
              style={{
                position: 'absolute',
                top: '2px',
                right: '9px',
                padding: '12px',
                zIndex: '5'
              }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setShowDeleteOption(false);
                showtemplateOptions();
              }}
              ref={groupShowOptionsReference}>
              <ExtraDots />
            </div>
            {showOptions && (
              <div className='format-options-list' ref={groupOptionsListReference}>
                {!showDeleteOption ? (
                  <div
                    data-cy='option-delete'
                    className='format-option-item delete'
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      setShowDeleteOption(true);
                    }}>
                    <p> Delete group</p>
                  </div>
                ) : (
                  <div
                    data-cy='option-delete-warning'
                    className='format-option-item delete warning'
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      dispatch(deleteGroup(group.id));
                      setShowOptions(false);
                      setShowDeleteOption(false);
                    }}>
                    <b>ARE YOU SURE?</b>
                    This action will delete the group and its contents
                  </div>
                )}
              </div>
            )}
          </div>
          <div className='format-options'>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  right: -110,
                  top: 103,
                  width: 30
                }}>
                <FolderIcon width='38px' height='30' fill='#4792E2' />
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
                overflowWrap: 'break-word'
              }}
              className='template-wrapper'>
              <div
                className='template-title'
                style={{ paddingTop: '', margin: '20px 20px', fontSize: '16px' }}>
                {group.title}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GroupCard.propTypes = {
  group: PropTypes.object.isRequired
};

export default GroupCard;
