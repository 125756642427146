import React from 'react';
import { useSelector } from 'react-redux';

import Draggable from '../DragNDrop/Draggable';
import TemplateCard from './TemplateCard';

function TemplateList() {
  const templates = useSelector((state) => state.dashboard.templates);

  const templateCount = () => {
    if (templates) {
      if (templates.length === 1) {
        return `${templates.length} template`;
      }
      if (templates.length !== 1) {
        return `${templates.length} templates`;
      }
    }

    return null;
  };

  const sortedTemplates = [...templates];
  sortedTemplates.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  return (
    <div className='content-section templates-container'>
      <div className='templates-count-text'>{templateCount()}</div>
      <div id='templates' className='card-container'>
        {sortedTemplates.map((template) => (
          <TemplateCard key={template.id} template={template} />
        ))}
      </div>
    </div>
  );
}

export default TemplateList;
