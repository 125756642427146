import { ReactKeycloakProvider } from '@react-keycloak/web';
import * as Sentry from '@sentry/browser';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import keycloakClient from './keycloak';
import store from './studioEditor/redux/store';

import { PageLoader } from './studioEditor/components/PageLoader';
import AuthenticatedApp from './AuthenticatedApp';
import ErrorFallback from './studioEditor/components/ErrorFallback';
import Preview from './studioEditor/pages/Preview';
import ZuuviLoader from './studioEditor/components/common/ZuuviLoader';

const keycloakInitOptions = {
  onLoad: 'login-required',
  // enableLogging: true,
  LoadingComponent: PageLoader
};

function App() {
  function keycloakEventHandler(event, error) {
    if (error) {
      console.error('keycloakEventHandler ERROR', event, error);
    }
  }

  const myErrorHandler = (error, info) => {
    Sentry.withScope((scope) => {
      scope.setExtras({ info, error });
      Sentry.captureMessage(`fallBackError - ${error.name}`);
    });
  };

  // This is here because of the way we do auth. Auth needs to be on the individual pages in router.
  const previewRegex = new RegExp('/preview/(template|campaign)/', 'gi');
  const previewTemplateRegex =
    /\/preview\/template\/([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-(:?8|9|A|B)[a-f0-9]{3}-[a-f0-9]{12})/i;
  const previewCampaignRegex =
    /\/preview\/campaign\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(\/.*)?$/i;
  const urlString = window.location.href;

  if (previewRegex.test(urlString)) {
    const templateMatch = urlString.match(previewTemplateRegex);
    const campaignMatch = urlString.match(previewCampaignRegex);
    const templateId = templateMatch ? templateMatch[1] : null;
    const campaignId = campaignMatch ? campaignMatch[1] : null;
    const creativesIds =
      campaignMatch && campaignMatch[2] !== undefined && campaignMatch[2].length > 1
        ? campaignMatch[2].slice(1)
        : null;
    return (
      <Provider store={store}>
        <Preview templateId={templateId} campaignId={campaignId} creativesIds={creativesIds} />
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <ReactKeycloakProvider
        authClient={keycloakClient}
        initOptions={keycloakInitOptions}
        onEvent={keycloakEventHandler}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={myErrorHandler}>
          <AuthenticatedApp />
        </ErrorBoundary>
        <div style={{ right: '60px' }}>
          <Toaster
            position='top-center'
            reverseOrder={true}
            toastOptions={{
              // Define default options
              className: '',
              style: {
                margin: '40px',
                background: '#363636',
                color: '#fff',
                zIndex: 1,
                maxWidth: '10000px'
              },
              // Default options for specific types
              loading: {
                duration: 30000,
                icon: <ZuuviLoader size='mini' color='white' />
              },
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black'
                }
              },
              error: {
                duration: 3000
              }
            }}
          />
        </div>
      </ReactKeycloakProvider>
    </Provider>
  );
}

export default App;
