import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Editor from './studioEditor';
import Dashboard from './studioEditor/pages/Dashboard';
import DpaExport from './studioEditor/pages/DpaExport';

function Routing() {
  return (
    <Router>
      <Routes>
        {/* Editor  */}
        <Route
          path='/editor/template/:templateId/format/width/:formatWidth/height/:formatHeight'
          element={<Editor editorType='template' />}
        />
        <Route path='/editor/template/:templateId' element={<Editor editorType='template' />} />
        <Route path='/editor/:templateId' element={<Editor editorType='template' />} />
        <Route path='/editor' element={<Editor editorType='template' />} />
        <Route path='/ad/:adId' element={<Editor editorType='banner' />} />
        <Route path='/set/:creativeSetId/:creativeId?' element={<Editor editorType='banner' />} />
        <Route path='/banner' element={<Editor editorType='banner' />} />

        {/* Dashboard  */}
        <Route path='/' element={<Dashboard />} />
        <Route path='/:groupId' element={<Dashboard />} />

        {/* DPA Export  */}
        <Route
          path='/catalog-ads/:clientId/:subaccountId/:campaignId/:adId/:adVersionId/:adWidth/:adHeight/:templateId/:publishedTemplateId/:publishedFormatId'
          element={<DpaExport />}
        />
      </Routes>
    </Router>
  );
}

export default Routing;
