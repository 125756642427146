/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ZuuviLogo } from '../assets/icons';

const StyledDiv = styled.div`
  text-align: center;
  position: fixed;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  background-color: #242424;
  padding-top: 200px;
`;

const StyledText = styled.p`
  color: white;
`;

function ErrorFallback(props) {
  const { error, resetErrorBoundary } = props;

  return (
    <StyledDiv>
      {/* <ZuuviLogo width='147' height='129' /> */}
      <StyledText>Oops!!! Something went totally sideways here (aka error 500).</StyledText>
      <StyledText>The good thing is that now we know.</StyledText>
      <StyledText>
        Try and refresh your page and contact support if the problem persists.
      </StyledText>
    </StyledDiv>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetErrorBoundary: PropTypes.func.isRequired
};

export default ErrorFallback;
