/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addFormat } from '../../redux/slices/template';
import {
  resetActiveAnimation,
  selectFormat,
  setActiveLayer
} from '../../redux/slices/editorSession';
import usePosthogCapture from '../../../utils/hooks/usePosthogCapture';
import './Formats.scss';
import * as api from '../../api';
import useEventListener from '../../../utils/hooks/useEventListener';

import { AttributeOpenedIcon } from '../../assets/icons';
import BaseButton from '../common/BaseButton';
import ToggleRevealSectionButton from '../common/ToggleRevealSectionButton';
import Format from './Format';
// import FormatEstimateSize from './FormatEstimateSize';

function List(props) {
  const { formats } = props;

  const sortedList = [...formats].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const list = sortedList.map((format) => <Format key={format.id} format={format} />);

  return <div id='formats'>{list}</div>;
}

function FormatsMenu() {
  const { editorType, selectedFormat } = useSelector((state) => state.editorSession);
  const template = useSelector((state) => state.template);
  const formats = useSelector((state) => state.template.formats);

  const initialFormatData = {
    width: '',
    height: '',
    title: ''
  };
  const [formatData, setFormatData] = useState(initialFormatData);

  const [isCreatingFormat, setIsCreatingFormat] = useState(false);
  const [formatsSectionOpened, setFormatsSectionOpened] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const formatOptionsReference = useRef();
  const focusInputRef = useRef();

  const dispatch = useDispatch();
  const capturePosthogData = usePosthogCapture();
  const navigate = useNavigate();

  const showFormatOptions = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => focusInputRef.current?.focus(), [isCreatingFormat]);

  useEventListener(
    'click',
    (event) => {
      // Any click outside of the element will close it.
      if (event.path && formatOptionsReference && formatOptionsReference.current) {
        if (!event.path.includes(formatOptionsReference.current)) {
          setShowOptions(false);
        }
      }
    },
    document
  );

  // TODO: should be moved to saga
  const handleSubmitDuplicate = async (event) => {
    event.preventDefault();
    const { width, height, title } = formatData;

    capturePosthogData({
      event: 'create-new-format-from-selected',
      type: 'format',
      menu: 'left',
      menu_type: 'formats'
    });
    toast.loading('Creating format ...', {
      id: 'creating-format'
    });

    // check also for empty string since empty string is 0 in javascript
    if ((isNaN(width) && isNaN(height)) || (width === '' && height === '')) {
      toast.error('Please give the format a width and height.', {
        id: 'creating-format'
      });
      return;
    }

    if (isNaN(width) || width === '') {
      toast.error('Please give the format a width.', {
        id: 'creating-format'
      });
      return;
    }

    if (isNaN(height) || height === '') {
      toast.error('Please give the format a height.', {
        id: 'creating-format'
      });
      return;
    }

    const data = {
      format: selectedFormat
    };

    const response = await api.templateStore.templateStoreRequest(
      `formats/${selectedFormat.id}/duplicate?width=${width}&height=${height}&title=${title}`,
      'POST',
      data,
      template.client_id
    );

    const duplicatedFormat = await response.json();
    dispatch(addFormat({ format: duplicatedFormat, originalFormatId: selectedFormat.id }));
    dispatch(selectFormat(duplicatedFormat));
    dispatch(setActiveLayer(null));

    setFormatData(initialFormatData);

    if (editorType !== 'banner') {
      navigate(`/editor/template/${template.id}/format/width/${width}/height/${height}`);
    }

    toast.success('Format created.', {
      id: 'creating-format'
    });
  };

  // TODO: should be moved to saga - createFormat
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { width, height, title } = formatData;
    capturePosthogData({
      event: 'create-new-format',
      type: 'format',
      menu: 'left',
      menu_type: 'formats'
    });

    toast.loading('Creating format ...', {
      id: 'creating-format'
    });

    if (isCreatingFormat) return;

    // check also for empty string since empty string is 0 in javascript
    if ((isNaN(width) && isNaN(height)) || (width === '' && height === '')) {
      toast.error('Please give the format a width and height.', {
        id: 'creating-format'
      });
      return;
    }
    if (isNaN(width) || width === '') {
      toast.error('Please give the format a width.', {
        id: 'creating-format'
      });
      return;
    }
    if (isNaN(height) || height === '') {
      toast.error('Please give the format a height.', {
        id: 'creating-format'
      });
      return;
    }

    setIsCreatingFormat(true);

    const newTitle = title || `${width}x${height}`;
    let largestNumber = 0;

    for (const f of formats) {
      if (f.title.includes(newTitle)) {
        largestNumber = 1;
        const x = f.title.match(/\(\d+\)$/gm);
        if (x) {
          const matchedNumber = parseInt(x[0].replace('(', '').replace(')', ''));
          largestNumber = matchedNumber >= largestNumber ? matchedNumber + 1 : largestNumber;
        }
      }
    }
    // selected format doesn't get updated immediately so we get the assets from the formats
    const formatWithAssets = formats.find(format => format.uuid === selectedFormat.uuid)

    const data = {
      width,
      height,
      title: largestNumber === 0 ? newTitle : `${newTitle} (${largestNumber})`,
      images: formatWithAssets?.object_data.images || [],
      videos: formatWithAssets?.object_data.videos || [],
      fonts: formatWithAssets?.object_data.fonts || [],
      template_id: template.id
    };

    const response = await api.templateStore.templateStoreRequest(
      'formats/create',
      'POST',
      data,
      template.client_id
    );
    const format = await response.json();
    // On success
    // We need to first clear the fields and then set them back to nothing
    // Leaving them at empty string will crash when
    setFormatData(initialFormatData);

    dispatch(addFormat({ format }));
    dispatch(selectFormat(format));
    dispatch(setActiveLayer(null));
    dispatch(resetActiveAnimation());
    setIsCreatingFormat(false);

    if (editorType !== 'banner') {
      navigate(`/editor/template/${template.id}/format/width/${width}/height/${height}`);
    }

    toast.success('Format created.', {
      id: 'creating-format'
    });
  };
  return (
    <>
      {editorType !== 'banner' && (
        <div id='create-format-container'>
          <form id='add-format' onSubmit={handleSubmit}>
            <div className='input-element'>
              <label>Width</label>
              <input
                ref={focusInputRef}
                type='number'
                min='1'
                value={formatData.width}
                onChange={(e) =>
                  setFormatData({ ...formatData, width: Math.max(0, parseInt(e.target.value)) })
                }
              />
            </div>
            <div className='input-element'>
              <label>Height</label>
              <input
                type='number'
                min='1'
                value={formatData.height}
                onChange={(e) =>
                  setFormatData({ ...formatData, height: Math.max(0, parseInt(e.target.value)) })
                }
              />
            </div>
            <div className='input-element'>
              <label>Title</label>
              <input
                type='text'
                value={formatData.title}
                onChange={(e) => setFormatData({ ...formatData, title: e.target.value })}
              />
            </div>
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              ref={formatOptionsReference}>
              <BaseButton
                text='Create new format'
                style={{
                  backgroundColor: '#4792E2',
                  width: selectedFormat ? '90%' : '100%',
                  borderRadius: ' 4px 0px 0px 4px',
                  color: 'white'
                }}
                id='create-new-format'
                type='submit'
                onClick={handleSubmit}
              />

              {selectedFormat && (
                <>
                  <BaseButton
                    text=''
                    onClick={() => {
                      capturePosthogData({
                        event: 'show-create-format-from-selected-button',
                        type: 'format',
                        menu: 'left',
                        menu_type: 'formats',
                        is_open: !showOptions
                      });
                      showFormatOptions();
                    }}
                    id='show-create-format-from-selected-button'
                    style={{
                      width: '10%',
                      borderRadius: '0px 4px 4px 0px',
                      borderLeft: 'none',
                      backgroundColor: '#4792E2'
                    }}>
                    <AttributeOpenedIcon stroke='white' />
                  </BaseButton>
                  <div>
                    {showOptions && (
                      <div className='format-create-options-list'>
                        <div className='format-option-item' onClick={handleSubmitDuplicate}>
                          <p> Create from selected format</p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      )}
      <ToggleRevealSectionButton
        isOpened={formatsSectionOpened}
        handleOnClick={() => {
          capturePosthogData({
            event: 'toggle-open-close-menu',
            type: 'formats',
            menu: 'left',
            menu_type: 'formats-overview',
            is_open: !formatsSectionOpened
          });
          setFormatsSectionOpened(!formatsSectionOpened);
        }}
        id='left-menu-open-close-formats-section'>
        <p className='color-white'>{editorType === 'banner' ? 'Creatives' : 'Formats'}</p>
      </ToggleRevealSectionButton>
      {formatsSectionOpened && <List formats={formats} />}
      {/* <FormatEstimateSize /> */}
    </>
  );
}

export default FormatsMenu;
