/* eslint-disable consistent-return */
import axios from 'axios';
import keycloak from '../../keycloak';
import store from '../redux/store';

const getHeaders = async (campaignId, accountId = null) => {
  const key = await keycloak;
  const storeState = store.getState();
  accountId = accountId || storeState.auth.currentAccountId;
  return {
    'Content-Type': 'application/json',
    'zuuvi-account-id': accountId,
    Authorization: `Bearer ${key.token}`,
    ...(campaignId && { 'creative-set-id': campaignId })
  };
};

const apiCall = async (callFunc, campaignId, accountId = null) => {
  const headers = await getHeaders(campaignId, accountId);

  try {
    const response = await callFunc(headers);
    return response;
  } catch (error) {
    return error;
  }
};

const get = async (url, campaignId = null, accountId = null) =>
  apiCall((headers) => axios.get(url, { headers }), campaignId, accountId);

const post = async (url, data, campaignId = null, accountId = null) =>
  apiCall((headers) => axios.post(url, data, { headers }), campaignId, accountId);

const postWithArrayBufferResponse = async (url, data, campaignId = null, accountId = null) =>
  apiCall(
    (headers) => axios.post(url, data, { headers, responseType: 'arraybuffer' }),
    campaignId,
    accountId
  );

const postAsFormdata = async (url, data, campaignId = null, accountId = null) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      if (key === 'selected_rows') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  return apiCall((headers) => axios.post(url, formData, { headers }), campaignId, accountId);
};

const putAsFormdata = async (url, data, campaignId = null, accountId = null) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value) {
      if (key === 'selected_rows') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  return apiCall((headers) => axios.put(url, formData, { headers }), campaignId, accountId);
};

const getBlob = async (url) =>
  apiCall(() => axios.get(url, { responseType: 'blob', timeout: 30000 }));

const getWithoutHeaders = async (url) => axios.get(url);

const deleteCall = async (url, campaignId = null, accountId = null) =>
  apiCall((headers) => axios.delete(url, { headers }), campaignId, accountId);

export {
  get,
  post,
  postWithArrayBufferResponse,
  getBlob,
  getWithoutHeaders,
  postAsFormdata,
  putAsFormdata,
  deleteCall
};
