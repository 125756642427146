import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import posthog from 'posthog-js';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import theme from './studioEditor/assets/theme';
import TagManager from 'react-gtm-module';

if (window._env_.REACT_ENV_GTM_VARIABLE) {
  const tagManagerArgs = {
    gtmId: window._env_.REACT_ENV_GTM_VARIABLE,
    dataLayerName: 'PageDataLayer'
  };

  TagManager.initialize(tagManagerArgs);
}

posthog.init(window._env_.REACT_ENV_POSTHOG_KEY, {
  api_host: 'https://app.posthog.com',
  autocapture: false,
  enable_recording_console_log: true,
  disable_session_recording: true,
  persistence: 'localStorage+cookie'
});

Sentry.init({
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (error && error.message) {
      event.fingerprint = [error.message];
    }
    return event;
  },

  environment: window._env_.REACT_ENV_SENTRY_ENV,
  release: window._env_.REACT_ENV_SENTRY_GIT_COMMIT_HASH,
  dsn: window._env_.REACT_ENV_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new posthog.SentryIntegration(
      posthog,
      window._env_.REACT_ENV_SENTRY_ORGANISATION,
      window._env_.REACT_ENV_SENTRY_PROJECT_ID
    )
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'TypeError: this.persistence.receivedFeatureFlags is not a function'
  ]
});

// Should be added at a later point, when dependencies are up to date with react 18
// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={theme}>
//       <App />
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
