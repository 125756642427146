import styled, { css } from 'styled-components';

const sharedBorder = css`
  border: 1px solid #747474;
  border-radius: 4px;
`;
export const StyledContentContainer = styled.div`
  ${(props) => props.regularPadding && 'padding: 30px;'};
  ${(props) => props.padding && `padding: ${props.padding};`}
  ${(props) => props.lineBottom && ` border-bottom: 1px solid #747474; border-radius: 4px`};
  ${(props) => props.border && `${sharedBorder}`};
`;
export const StyledTitle = styled.p`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '700')};
  font-size: 32px;
  line-height: 32px;
  margin: 0px;
  margin-bottom: 7px;
`;
export const StyledButton = styled.div`
  padding: 7px;

  ${(props) =>
    (props.medium || props.small) && 'height:34px; padding: 7px 15px 7px 10px; width: fit-content;'}
  ${(props) => !props.medium && !props.small && 'height: 58px; width: 176px;  margin-top: 7px;'}
  display: flex;
  border-radius: 4px;
  align-items: center;

  ${(props) =>
    props.enabled
      ? 'opacity: 1; &:hover { background-color: #3e3e3e; cursor: pointer; & > .export-icon { background-color: #4a90e2;}}'
      : 'opacity: 0.5;'};
`;
export const StyledNameBox = styled.div`
  ${(props) => props.limitedWidth && 'width: 112px;'};
  font-size: 14px;
`;
export const StyledIconBox = styled.div`
  ${sharedBorder};
  ${(props) =>
    props.small
      ? ' height: 30px; width: 30px;background-color:#6A6A6A'
      : ' height: 60px;width: 60px;'};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const StyledMediumText = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  margin-bottom: 7px;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0px')};
`;
export const StyledSmallText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
`;
