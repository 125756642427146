import styled from 'styled-components';
import ZuuviLogo from '../assets/zuuvi_logo_big.png';
import ZuuviLoader from './common/ZuuviLoader';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 1000;
`;

const LoadSpinner = () => (
  <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <ZuuviLoader size='large' />
    </div>
  </div>
);

export default LoadSpinner;
